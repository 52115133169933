#header{
	padding-bottom: 3rem;
  }
  
  body {
	margin-top: 0 !important;
	padding-top: 0 !important;
	min-width: 800px !important;
  }
  
  #wm-ipp a:hover {
	text-decoration: underline !important;
  }
  