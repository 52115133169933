#colorbox,
#cboxOverlay,
#cboxWrapper {
	position: absolute;
	top: 0;
	left: 0;
	z-index: 9999;
	overflow: hidden;
	-webkit-transform: translate3d(0, 0, 0);
}

#cboxWrapper {
	max-width: none;
}

#cboxOverlay {
	position: fixed;
	width: 100%;
	height: 100%;
}

#cboxMiddleLeft,
#cboxBottomLeft {
	clear: left;
}

#cboxContent {
	position: relative;
}

#cboxLoadedContent {
	overflow: auto;
	-webkit-overflow-scrolling: touch;
}

#cboxTitle {
	margin: 0;
}

#cboxLoadingOverlay,
#cboxLoadingGraphic {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
}

#cboxPrevious,
#cboxNext,
#cboxClose,
#cboxSlideshow {
	cursor: pointer;
}

.cboxPhoto {
	float: left;
	margin: auto;
	border: 0;
	display: block;
	max-width: none;
	-ms-interpolation-mode: bicubic;
}

.cboxIframe {
	width: 100%;
	height: 100%;
	display: block;
	border: 0;
	padding: 0;
	margin: 0;
}

#colorbox,
#cboxContent,
#cboxLoadedContent {
	box-sizing: content-box;
	-moz-box-sizing: content-box;
	-webkit-box-sizing: content-box;
}

#cboxOverlay {
	background: #000;
	opacity: 0.9;
	filter: alpha(opacity=90);
}

#colorbox {
	outline: 0;
}

#cboxContent {
	margin-top: 20px;
	background: #000;
}

.cboxIframe {
	background: #fff;
}

#cboxError {
	padding: 50px;
	border: 1px solid #ccc;
}

#cboxLoadedContent {
	border: 5px solid #000;
	background: #fff;
}

#cboxTitle {
	position: absolute;
	top: -20px;
	left: 0;
	color: #ccc;
}

#cboxCurrent {
	position: absolute;
	top: -20px;
	right: 0px;
	color: #ccc;
}

#cboxLoadingGraphic {
	background: url(./assets/files/loading.gif) no-repeat center center;
}

#cboxPrevious,
#cboxNext,
#cboxSlideshow,
#cboxClose {
	border: 0;
	padding: 0;
	margin: 0;
	overflow: visible;
	width: auto;
	background: none;
}

#cboxPrevious:active,
#cboxNext:active,
#cboxSlideshow:active,
#cboxClose:active {
	outline: 0;
}

#cboxSlideshow {
	position: absolute;
	top: -20px;
	right: 90px;
	color: #fff;
}

#cboxPrevious {
	position: absolute;
	top: 50%;
	left: 5px;
	margin-top: -32px;
	background: url(./assets/files/controls.png) no-repeat top left;
	width: 28px;
	height: 65px;
	text-indent: -9999px;
}

#cboxPrevious:hover {
	background-position: bottom left;
}

#cboxNext {
	position: absolute;
	top: 50%;
	right: 5px;
	margin-top: -32px;
	background: url(./assets/files/controls.png) no-repeat top right;
	width: 28px;
	height: 65px;
	text-indent: -9999px;
}

#cboxNext:hover {
	background-position: bottom right;
}

#cboxClose {
	position: absolute;
	top: 5px;
	right: 5px;
	display: block;
	background: url(./assets/files/controls.png) no-repeat top center;
	width: 38px;
	height: 19px;
	text-indent: -9999px;
}

#cboxClose:hover {
	background-position: bottom center;
}

.ctools-locked {
	color: red;
	border: 1px solid red;
	padding: 1em;
}

.ctools-owns-lock {
	background: #ffffdd none repeat scroll 0 0;
	border: 1px solid #f0c020;
	padding: 1em;
}

a.ctools-ajaxing,
input.ctools-ajaxing,
button.ctools-ajaxing,
select.ctools-ajaxing {
	padding-right: 18px !important;
	background: url(./assets/files/status-active.gif) right center no-repeat;
}

div.ctools-ajaxing {
	float: left;
	width: 18px;
	background: url(./assets/files/status-active.gif) center center no-repeat;
}

fieldset.allowed-child div.form-item,
fieldset.auto-main div.form-item {
	float: left;
	width: 300px;
}

fieldset.bms-fieldset-child {
	float: left;
	width: 49%;
}

fieldset.bms-fieldset-parent {
	float: right;
	width: 49%;
}

fieldset.bms-other-settings {
	float: none;
	clear: both;
}

div.vertical-tabs .vertical-tabs-panes fieldset.allowed-childwithlegend legend {
	display: block;
}

div.vertical-tabs .vertical-tabs-panes fieldset.allowed-childwithoutlegend legend {
	display: none;
}






@font-face {
	font-family: 'FontAwesome';
	src: url(./assets/mferefresh/font-awesome/fonts/fontawesome-webfont.eot?v=4.7.0);
	src: url(./assets/mferefresh/font-awesome/fonts/fontawesome-webfont.eot?#iefix&v=4.7.0) format('embedded-opentype'), url(./assets/mferefresh/font-awesome/fonts/fontawesome-webfont.woff2?v=4.7.0) format('woff2'), url(./assets/mferefresh/font-awesome/fonts/fontawesome-webfont.woff?v=4.7.0) format('woff'), url(./assets/mferefresh/font-awesome/fonts/fontawesome-webfont.ttf?v=4.7.0) format('truetype'), url(./assets/mferefresh/font-awesome/fonts/fontawesome-webfont.svg?v=4.7.0#fontawesomeregular) format('svg');
	font-weight: normal;
	font-style: normal
}

.fa {
	display: inline-block;
	font: normal normal normal 14px/1 FontAwesome;
	font-size: inherit;
	text-rendering: auto;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale
}

.fa-lg {
	font-size: 1.33333333em;
	line-height: .75em;
	vertical-align: -15%
}

.fa-2x {
	font-size: 2em
}

.fa-3x {
	font-size: 3em
}

.fa-4x {
	font-size: 4em
}

.fa-5x {
	font-size: 5em
}

.fa-fw {
	width: 1.28571429em;
	text-align: center
}

.fa-ul {
	padding-left: 0;
	margin-left: 2.14285714em;
	list-style-type: none
}

.fa-ul>li {
	position: relative
}

.fa-li {
	position: absolute;
	left: -2.14285714em;
	width: 2.14285714em;
	top: .14285714em;
	text-align: center
}

.fa-li.fa-lg {
	left: -1.85714286em
}

.fa-border {
	padding: .2em .25em .15em;
	border: solid .08em #eee;
	border-radius: .1em
}

.fa-pull-left {
	float: left
}

.fa-pull-right {
	float: right
}

.fa.fa-pull-left {
	margin-right: .3em
}

.fa.fa-pull-right {
	margin-left: .3em
}

.pull-right {
	float: right
}

.pull-left {
	float: left
}

.fa.pull-left {
	margin-right: .3em
}

.fa.pull-right {
	margin-left: .3em
}

.fa-spin {
	-webkit-animation: fa-spin 2s infinite linear;
	animation: fa-spin 2s infinite linear
}

.fa-pulse {
	-webkit-animation: fa-spin 1s infinite steps(8);
	animation: fa-spin 1s infinite steps(8)
}

@-webkit-keyframes fa-spin {
	0% {
		-webkit-transform: rotate(0deg);
		transform: rotate(0deg)
	}
	100% {
		-webkit-transform: rotate(359deg);
		transform: rotate(359deg)
	}
}

@keyframes fa-spin {
	0% {
		-webkit-transform: rotate(0deg);
		transform: rotate(0deg)
	}
	100% {
		-webkit-transform: rotate(359deg);
		transform: rotate(359deg)
	}
}

.fa-rotate-90 {
	-ms-filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=1)";
	-webkit-transform: rotate(90deg);
	-ms-transform: rotate(90deg);
	transform: rotate(90deg)
}

.fa-rotate-180 {
	-ms-filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=2)";
	-webkit-transform: rotate(180deg);
	-ms-transform: rotate(180deg);
	transform: rotate(180deg)
}

.fa-rotate-270 {
	-ms-filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=3)";
	-webkit-transform: rotate(270deg);
	-ms-transform: rotate(270deg);
	transform: rotate(270deg)
}

.fa-flip-horizontal {
	-ms-filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=0,mirror=1)";
	-webkit-transform: scale(-1, 1);
	-ms-transform: scale(-1, 1);
	transform: scale(-1, 1)
}

.fa-flip-vertical {
	-ms-filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=2,mirror=1)";
	-webkit-transform: scale(1, -1);
	-ms-transform: scale(1, -1);
	transform: scale(1, -1)
}

:root .fa-rotate-90,
:root .fa-rotate-180,
:root .fa-rotate-270,
:root .fa-flip-horizontal,
:root .fa-flip-vertical {
	filter: none
}

.fa-stack {
	position: relative;
	display: inline-block;
	width: 2em;
	height: 2em;
	line-height: 2em;
	vertical-align: middle
}

.fa-stack-1x,
.fa-stack-2x {
	position: absolute;
	left: 0;
	width: 100%;
	text-align: center
}

.fa-stack-1x {
	line-height: inherit
}

.fa-stack-2x {
	font-size: 2em
}

.fa-inverse {
	color: #fff
}

.fa-glass:before {
	content: "\f000"
}

.fa-music:before {
	content: "\f001"
}

.fa-search:before {
	content: "\f002"
}

.fa-envelope-o:before {
	content: "\f003"
}

.fa-heart:before {
	content: "\f004"
}

.fa-star:before {
	content: "\f005"
}

.fa-star-o:before {
	content: "\f006"
}

.fa-user:before {
	content: "\f007"
}

.fa-film:before {
	content: "\f008"
}

.fa-th-large:before {
	content: "\f009"
}

.fa-th:before {
	content: "\f00a"
}

.fa-th-list:before {
	content: "\f00b"
}

.fa-check:before {
	content: "\f00c"
}

.fa-remove:before,
.fa-close:before,
.fa-times:before {
	content: "\f00d"
}

.fa-search-plus:before {
	content: "\f00e"
}

.fa-search-minus:before {
	content: "\f010"
}

.fa-power-off:before {
	content: "\f011"
}

.fa-signal:before {
	content: "\f012"
}

.fa-gear:before,
.fa-cog:before {
	content: "\f013"
}

.fa-trash-o:before {
	content: "\f014"
}

.fa-home:before {
	content: "\f015"
}

.fa-file-o:before {
	content: "\f016"
}

.fa-clock-o:before {
	content: "\f017"
}

.fa-road:before {
	content: "\f018"
}

.fa-download:before {
	content: "\f019"
}

.fa-arrow-circle-o-down:before {
	content: "\f01a"
}

.fa-arrow-circle-o-up:before {
	content: "\f01b"
}

.fa-inbox:before {
	content: "\f01c"
}

.fa-play-circle-o:before {
	content: "\f01d"
}

.fa-rotate-right:before,
.fa-repeat:before {
	content: "\f01e"
}

.fa-refresh:before {
	content: "\f021"
}

.fa-list-alt:before {
	content: "\f022"
}

.fa-lock:before {
	content: "\f023"
}

.fa-flag:before {
	content: "\f024"
}

.fa-headphones:before {
	content: "\f025"
}

.fa-volume-off:before {
	content: "\f026"
}

.fa-volume-down:before {
	content: "\f027"
}

.fa-volume-up:before {
	content: "\f028"
}

.fa-qrcode:before {
	content: "\f029"
}

.fa-barcode:before {
	content: "\f02a"
}

.fa-tag:before {
	content: "\f02b"
}

.fa-tags:before {
	content: "\f02c"
}

.fa-book:before {
	content: "\f02d"
}

.fa-bookmark:before {
	content: "\f02e"
}

.fa-print:before {
	content: "\f02f"
}

.fa-camera:before {
	content: "\f030"
}

.fa-font:before {
	content: "\f031"
}

.fa-bold:before {
	content: "\f032"
}

.fa-italic:before {
	content: "\f033"
}

.fa-text-height:before {
	content: "\f034"
}

.fa-text-width:before {
	content: "\f035"
}

.fa-align-left:before {
	content: "\f036"
}

.fa-align-center:before {
	content: "\f037"
}

.fa-align-right:before {
	content: "\f038"
}

.fa-align-justify:before {
	content: "\f039"
}

.fa-list:before {
	content: "\f03a"
}

.fa-dedent:before,
.fa-outdent:before {
	content: "\f03b"
}

.fa-indent:before {
	content: "\f03c"
}

.fa-video-camera:before {
	content: "\f03d"
}

.fa-photo:before,
.fa-image:before,
.fa-picture-o:before {
	content: "\f03e"
}

.fa-pencil:before {
	content: "\f040"
}

.fa-map-marker:before {
	content: "\f041"
}

.fa-adjust:before {
	content: "\f042"
}

.fa-tint:before {
	content: "\f043"
}

.fa-edit:before,
.fa-pencil-square-o:before {
	content: "\f044"
}

.fa-share-square-o:before {
	content: "\f045"
}

.fa-check-square-o:before {
	content: "\f046"
}

.fa-arrows:before {
	content: "\f047"
}

.fa-step-backward:before {
	content: "\f048"
}

.fa-fast-backward:before {
	content: "\f049"
}

.fa-backward:before {
	content: "\f04a"
}

.fa-play:before {
	content: "\f04b"
}

.fa-pause:before {
	content: "\f04c"
}

.fa-stop:before {
	content: "\f04d"
}

.fa-forward:before {
	content: "\f04e"
}

.fa-fast-forward:before {
	content: "\f050"
}

.fa-step-forward:before {
	content: "\f051"
}

.fa-eject:before {
	content: "\f052"
}

.fa-chevron-left:before {
	content: "\f053"
}

.fa-chevron-right:before {
	content: "\f054"
}

.fa-plus-circle:before {
	content: "\f055"
}

.fa-minus-circle:before {
	content: "\f056"
}

.fa-times-circle:before {
	content: "\f057"
}

.fa-check-circle:before {
	content: "\f058"
}

.fa-question-circle:before {
	content: "\f059"
}

.fa-info-circle:before {
	content: "\f05a"
}

.fa-crosshairs:before {
	content: "\f05b"
}

.fa-times-circle-o:before {
	content: "\f05c"
}

.fa-check-circle-o:before {
	content: "\f05d"
}

.fa-ban:before {
	content: "\f05e"
}

.fa-arrow-left:before {
	content: "\f060"
}

.fa-arrow-right:before {
	content: "\f061"
}

.fa-arrow-up:before {
	content: "\f062"
}

.fa-arrow-down:before {
	content: "\f063"
}

.fa-mail-forward:before,
.fa-share:before {
	content: "\f064"
}

.fa-expand:before {
	content: "\f065"
}

.fa-compress:before {
	content: "\f066"
}

.fa-plus:before {
	content: "\f067"
}

.fa-minus:before {
	content: "\f068"
}

.fa-asterisk:before {
	content: "\f069"
}

.fa-exclamation-circle:before {
	content: "\f06a"
}

.fa-gift:before {
	content: "\f06b"
}

.fa-leaf:before {
	content: "\f06c"
}

.fa-fire:before {
	content: "\f06d"
}

.fa-eye:before {
	content: "\f06e"
}

.fa-eye-slash:before {
	content: "\f070"
}

.fa-warning:before,
.fa-exclamation-triangle:before {
	content: "\f071"
}

.fa-plane:before {
	content: "\f072"
}

.fa-calendar:before {
	content: "\f073"
}

.fa-random:before {
	content: "\f074"
}

.fa-comment:before {
	content: "\f075"
}

.fa-magnet:before {
	content: "\f076"
}

.fa-chevron-up:before {
	content: "\f077"
}

.fa-chevron-down:before {
	content: "\f078"
}

.fa-retweet:before {
	content: "\f079"
}

.fa-shopping-cart:before {
	content: "\f07a"
}

.fa-folder:before {
	content: "\f07b"
}

.fa-folder-open:before {
	content: "\f07c"
}

.fa-arrows-v:before {
	content: "\f07d"
}

.fa-arrows-h:before {
	content: "\f07e"
}

.fa-bar-chart-o:before,
.fa-bar-chart:before {
	content: "\f080"
}

.fa-twitter-square:before {
	content: "\f081"
}

.fa-facebook-square:before {
	content: "\f082"
}

.fa-camera-retro:before {
	content: "\f083"
}

.fa-key:before {
	content: "\f084"
}

.fa-gears:before,
.fa-cogs:before {
	content: "\f085"
}

.fa-comments:before {
	content: "\f086"
}

.fa-thumbs-o-up:before {
	content: "\f087"
}

.fa-thumbs-o-down:before {
	content: "\f088"
}

.fa-star-half:before {
	content: "\f089"
}

.fa-heart-o:before {
	content: "\f08a"
}

.fa-sign-out:before {
	content: "\f08b"
}

.fa-linkedin-square:before {
	content: "\f08c"
}

.fa-thumb-tack:before {
	content: "\f08d"
}

.fa-external-link:before {
	content: "\f08e"
}

.fa-sign-in:before {
	content: "\f090"
}

.fa-trophy:before {
	content: "\f091"
}

.fa-github-square:before {
	content: "\f092"
}

.fa-upload:before {
	content: "\f093"
}

.fa-lemon-o:before {
	content: "\f094"
}

.fa-phone:before {
	content: "\f095"
}

.fa-square-o:before {
	content: "\f096"
}

.fa-bookmark-o:before {
	content: "\f097"
}

.fa-phone-square:before {
	content: "\f098"
}

.fa-twitter:before {
	content: "\f099"
}

.fa-facebook-f:before,
.fa-facebook:before {
	content: "\f09a"
}

.fa-github:before {
	content: "\f09b"
}

.fa-unlock:before {
	content: "\f09c"
}

.fa-credit-card:before {
	content: "\f09d"
}

.fa-feed:before,
.fa-rss:before {
	content: "\f09e"
}

.fa-hdd-o:before {
	content: "\f0a0"
}

.fa-bullhorn:before {
	content: "\f0a1"
}

.fa-bell:before {
	content: "\f0f3"
}

.fa-certificate:before {
	content: "\f0a3"
}

.fa-hand-o-right:before {
	content: "\f0a4"
}

.fa-hand-o-left:before {
	content: "\f0a5"
}

.fa-hand-o-up:before {
	content: "\f0a6"
}

.fa-hand-o-down:before {
	content: "\f0a7"
}

.fa-arrow-circle-left:before {
	content: "\f0a8"
}

.fa-arrow-circle-right:before {
	content: "\f0a9"
}

.fa-arrow-circle-up:before {
	content: "\f0aa"
}

.fa-arrow-circle-down:before {
	content: "\f0ab"
}

.fa-globe:before {
	content: "\f0ac"
}

.fa-wrench:before {
	content: "\f0ad"
}

.fa-tasks:before {
	content: "\f0ae"
}

.fa-filter:before {
	content: "\f0b0"
}

.fa-briefcase:before {
	content: "\f0b1"
}

.fa-arrows-alt:before {
	content: "\f0b2"
}

.fa-group:before,
.fa-users:before {
	content: "\f0c0"
}

.fa-chain:before,
.fa-link:before {
	content: "\f0c1"
}

.fa-cloud:before {
	content: "\f0c2"
}

.fa-flask:before {
	content: "\f0c3"
}

.fa-cut:before,
.fa-scissors:before {
	content: "\f0c4"
}

.fa-copy:before,
.fa-files-o:before {
	content: "\f0c5"
}

.fa-paperclip:before {
	content: "\f0c6"
}

.fa-save:before,
.fa-floppy-o:before {
	content: "\f0c7"
}

.fa-square:before {
	content: "\f0c8"
}

.fa-navicon:before,
.fa-reorder:before,
.fa-bars:before {
	content: "\f0c9"
}

.fa-list-ul:before {
	content: "\f0ca"
}

.fa-list-ol:before {
	content: "\f0cb"
}

.fa-strikethrough:before {
	content: "\f0cc"
}

.fa-underline:before {
	content: "\f0cd"
}

.fa-table:before {
	content: "\f0ce"
}

.fa-magic:before {
	content: "\f0d0"
}

.fa-truck:before {
	content: "\f0d1"
}

.fa-pinterest:before {
	content: "\f0d2"
}

.fa-pinterest-square:before {
	content: "\f0d3"
}

.fa-google-plus-square:before {
	content: "\f0d4"
}

.fa-google-plus:before {
	content: "\f0d5"
}

.fa-money:before {
	content: "\f0d6"
}

.fa-caret-down:before {
	content: "\f0d7"
}

.fa-caret-up:before {
	content: "\f0d8"
}

.fa-caret-left:before {
	content: "\f0d9"
}

.fa-caret-right:before {
	content: "\f0da"
}

.fa-columns:before {
	content: "\f0db"
}

.fa-unsorted:before,
.fa-sort:before {
	content: "\f0dc"
}

.fa-sort-down:before,
.fa-sort-desc:before {
	content: "\f0dd"
}

.fa-sort-up:before,
.fa-sort-asc:before {
	content: "\f0de"
}

.fa-envelope:before {
	content: "\f0e0"
}

.fa-linkedin:before {
	content: "\f0e1"
}

.fa-rotate-left:before,
.fa-undo:before {
	content: "\f0e2"
}

.fa-legal:before,
.fa-gavel:before {
	content: "\f0e3"
}

.fa-dashboard:before,
.fa-tachometer:before {
	content: "\f0e4"
}

.fa-comment-o:before {
	content: "\f0e5"
}

.fa-comments-o:before {
	content: "\f0e6"
}

.fa-flash:before,
.fa-bolt:before {
	content: "\f0e7"
}

.fa-sitemap:before {
	content: "\f0e8"
}

.fa-umbrella:before {
	content: "\f0e9"
}

.fa-paste:before,
.fa-clipboard:before {
	content: "\f0ea"
}

.fa-lightbulb-o:before {
	content: "\f0eb"
}

.fa-exchange:before {
	content: "\f0ec"
}

.fa-cloud-download:before {
	content: "\f0ed"
}

.fa-cloud-upload:before {
	content: "\f0ee"
}

.fa-user-md:before {
	content: "\f0f0"
}

.fa-stethoscope:before {
	content: "\f0f1"
}

.fa-suitcase:before {
	content: "\f0f2"
}

.fa-bell-o:before {
	content: "\f0a2"
}

.fa-coffee:before {
	content: "\f0f4"
}

.fa-cutlery:before {
	content: "\f0f5"
}

.fa-file-text-o:before {
	content: "\f0f6"
}

.fa-building-o:before {
	content: "\f0f7"
}

.fa-hospital-o:before {
	content: "\f0f8"
}

.fa-ambulance:before {
	content: "\f0f9"
}

.fa-medkit:before {
	content: "\f0fa"
}

.fa-fighter-jet:before {
	content: "\f0fb"
}

.fa-beer:before {
	content: "\f0fc"
}

.fa-h-square:before {
	content: "\f0fd"
}

.fa-plus-square:before {
	content: "\f0fe"
}

.fa-angle-double-left:before {
	content: "\f100"
}

.fa-angle-double-right:before {
	content: "\f101"
}

.fa-angle-double-up:before {
	content: "\f102"
}

.fa-angle-double-down:before {
	content: "\f103"
}

.fa-angle-left:before {
	content: "\f104"
}

.fa-angle-right:before {
	content: "\f105"
}

.fa-angle-up:before {
	content: "\f106"
}

.fa-angle-down:before {
	content: "\f107"
}

.fa-desktop:before {
	content: "\f108"
}

.fa-laptop:before {
	content: "\f109"
}

.fa-tablet:before {
	content: "\f10a"
}

.fa-mobile-phone:before,
.fa-mobile:before {
	content: "\f10b"
}

.fa-circle-o:before {
	content: "\f10c"
}

.fa-quote-left:before {
	content: "\f10d"
}

.fa-quote-right:before {
	content: "\f10e"
}

.fa-spinner:before {
	content: "\f110"
}

.fa-circle:before {
	content: "\f111"
}

.fa-mail-reply:before,
.fa-reply:before {
	content: "\f112"
}

.fa-github-alt:before {
	content: "\f113"
}

.fa-folder-o:before {
	content: "\f114"
}

.fa-folder-open-o:before {
	content: "\f115"
}

.fa-smile-o:before {
	content: "\f118"
}

.fa-frown-o:before {
	content: "\f119"
}

.fa-meh-o:before {
	content: "\f11a"
}

.fa-gamepad:before {
	content: "\f11b"
}

.fa-keyboard-o:before {
	content: "\f11c"
}

.fa-flag-o:before {
	content: "\f11d"
}

.fa-flag-checkered:before {
	content: "\f11e"
}

.fa-terminal:before {
	content: "\f120"
}

.fa-code:before {
	content: "\f121"
}

.fa-mail-reply-all:before,
.fa-reply-all:before {
	content: "\f122"
}

.fa-star-half-empty:before,
.fa-star-half-full:before,
.fa-star-half-o:before {
	content: "\f123"
}

.fa-location-arrow:before {
	content: "\f124"
}

.fa-crop:before {
	content: "\f125"
}

.fa-code-fork:before {
	content: "\f126"
}

.fa-unlink:before,
.fa-chain-broken:before {
	content: "\f127"
}

.fa-question:before {
	content: "\f128"
}

.fa-info:before {
	content: "\f129"
}

.fa-exclamation:before {
	content: "\f12a"
}

.fa-superscript:before {
	content: "\f12b"
}

.fa-subscript:before {
	content: "\f12c"
}

.fa-eraser:before {
	content: "\f12d"
}

.fa-puzzle-piece:before {
	content: "\f12e"
}

.fa-microphone:before {
	content: "\f130"
}

.fa-microphone-slash:before {
	content: "\f131"
}

.fa-shield:before {
	content: "\f132"
}

.fa-calendar-o:before {
	content: "\f133"
}

.fa-fire-extinguisher:before {
	content: "\f134"
}

.fa-rocket:before {
	content: "\f135"
}

.fa-maxcdn:before {
	content: "\f136"
}

.fa-chevron-circle-left:before {
	content: "\f137"
}

.fa-chevron-circle-right:before {
	content: "\f138"
}

.fa-chevron-circle-up:before {
	content: "\f139"
}

.fa-chevron-circle-down:before {
	content: "\f13a"
}

.fa-html5:before {
	content: "\f13b"
}

.fa-css3:before {
	content: "\f13c"
}

.fa-anchor:before {
	content: "\f13d"
}

.fa-unlock-alt:before {
	content: "\f13e"
}

.fa-bullseye:before {
	content: "\f140"
}

.fa-ellipsis-h:before {
	content: "\f141"
}

.fa-ellipsis-v:before {
	content: "\f142"
}

.fa-rss-square:before {
	content: "\f143"
}

.fa-play-circle:before {
	content: "\f144"
}

.fa-ticket:before {
	content: "\f145"
}

.fa-minus-square:before {
	content: "\f146"
}

.fa-minus-square-o:before {
	content: "\f147"
}

.fa-level-up:before {
	content: "\f148"
}

.fa-level-down:before {
	content: "\f149"
}

.fa-check-square:before {
	content: "\f14a"
}

.fa-pencil-square:before {
	content: "\f14b"
}

.fa-external-link-square:before {
	content: "\f14c"
}

.fa-share-square:before {
	content: "\f14d"
}

.fa-compass:before {
	content: "\f14e"
}

.fa-toggle-down:before,
.fa-caret-square-o-down:before {
	content: "\f150"
}

.fa-toggle-up:before,
.fa-caret-square-o-up:before {
	content: "\f151"
}

.fa-toggle-right:before,
.fa-caret-square-o-right:before {
	content: "\f152"
}

.fa-euro:before,
.fa-eur:before {
	content: "\f153"
}

.fa-gbp:before {
	content: "\f154"
}

.fa-dollar:before,
.fa-usd:before {
	content: "\f155"
}

.fa-rupee:before,
.fa-inr:before {
	content: "\f156"
}

.fa-cny:before,
.fa-rmb:before,
.fa-yen:before,
.fa-jpy:before {
	content: "\f157"
}

.fa-ruble:before,
.fa-rouble:before,
.fa-rub:before {
	content: "\f158"
}

.fa-won:before,
.fa-krw:before {
	content: "\f159"
}

.fa-bitcoin:before,
.fa-btc:before {
	content: "\f15a"
}

.fa-file:before {
	content: "\f15b"
}

.fa-file-text:before {
	content: "\f15c"
}

.fa-sort-alpha-asc:before {
	content: "\f15d"
}

.fa-sort-alpha-desc:before {
	content: "\f15e"
}

.fa-sort-amount-asc:before {
	content: "\f160"
}

.fa-sort-amount-desc:before {
	content: "\f161"
}

.fa-sort-numeric-asc:before {
	content: "\f162"
}

.fa-sort-numeric-desc:before {
	content: "\f163"
}

.fa-thumbs-up:before {
	content: "\f164"
}

.fa-thumbs-down:before {
	content: "\f165"
}

.fa-youtube-square:before {
	content: "\f166"
}

.fa-youtube:before {
	content: "\f167"
}

.fa-xing:before {
	content: "\f168"
}

.fa-xing-square:before {
	content: "\f169"
}

.fa-youtube-play:before {
	content: "\f16a"
}

.fa-dropbox:before {
	content: "\f16b"
}

.fa-stack-overflow:before {
	content: "\f16c"
}

.fa-instagram:before {
	content: "\f16d"
}

.fa-flickr:before {
	content: "\f16e"
}

.fa-adn:before {
	content: "\f170"
}

.fa-bitbucket:before {
	content: "\f171"
}

.fa-bitbucket-square:before {
	content: "\f172"
}

.fa-tumblr:before {
	content: "\f173"
}

.fa-tumblr-square:before {
	content: "\f174"
}

.fa-long-arrow-down:before {
	content: "\f175"
}

.fa-long-arrow-up:before {
	content: "\f176"
}

.fa-long-arrow-left:before {
	content: "\f177"
}

.fa-long-arrow-right:before {
	content: "\f178"
}

.fa-apple:before {
	content: "\f179"
}

.fa-windows:before {
	content: "\f17a"
}

.fa-android:before {
	content: "\f17b"
}

.fa-linux:before {
	content: "\f17c"
}

.fa-dribbble:before {
	content: "\f17d"
}

.fa-skype:before {
	content: "\f17e"
}

.fa-foursquare:before {
	content: "\f180"
}

.fa-trello:before {
	content: "\f181"
}

.fa-female:before {
	content: "\f182"
}

.fa-male:before {
	content: "\f183"
}

.fa-gittip:before,
.fa-gratipay:before {
	content: "\f184"
}

.fa-sun-o:before {
	content: "\f185"
}

.fa-moon-o:before {
	content: "\f186"
}

.fa-archive:before {
	content: "\f187"
}

.fa-bug:before {
	content: "\f188"
}

.fa-vk:before {
	content: "\f189"
}

.fa-weibo:before {
	content: "\f18a"
}

.fa-renren:before {
	content: "\f18b"
}

.fa-pagelines:before {
	content: "\f18c"
}

.fa-stack-exchange:before {
	content: "\f18d"
}

.fa-arrow-circle-o-right:before {
	content: "\f18e"
}

.fa-arrow-circle-o-left:before {
	content: "\f190"
}

.fa-toggle-left:before,
.fa-caret-square-o-left:before {
	content: "\f191"
}

.fa-dot-circle-o:before {
	content: "\f192"
}

.fa-wheelchair:before {
	content: "\f193"
}

.fa-vimeo-square:before {
	content: "\f194"
}

.fa-turkish-lira:before,
.fa-try:before {
	content: "\f195"
}

.fa-plus-square-o:before {
	content: "\f196"
}

.fa-space-shuttle:before {
	content: "\f197"
}

.fa-slack:before {
	content: "\f198"
}

.fa-envelope-square:before {
	content: "\f199"
}

.fa-wordpress:before {
	content: "\f19a"
}

.fa-openid:before {
	content: "\f19b"
}

.fa-institution:before,
.fa-bank:before,
.fa-university:before {
	content: "\f19c"
}

.fa-mortar-board:before,
.fa-graduation-cap:before {
	content: "\f19d"
}

.fa-yahoo:before {
	content: "\f19e"
}

.fa-google:before {
	content: "\f1a0"
}

.fa-reddit:before {
	content: "\f1a1"
}

.fa-reddit-square:before {
	content: "\f1a2"
}

.fa-stumbleupon-circle:before {
	content: "\f1a3"
}

.fa-stumbleupon:before {
	content: "\f1a4"
}

.fa-delicious:before {
	content: "\f1a5"
}

.fa-digg:before {
	content: "\f1a6"
}

.fa-pied-piper-pp:before {
	content: "\f1a7"
}

.fa-pied-piper-alt:before {
	content: "\f1a8"
}

.fa-drupal:before {
	content: "\f1a9"
}

.fa-joomla:before {
	content: "\f1aa"
}

.fa-language:before {
	content: "\f1ab"
}

.fa-fax:before {
	content: "\f1ac"
}

.fa-building:before {
	content: "\f1ad"
}

.fa-child:before {
	content: "\f1ae"
}

.fa-paw:before {
	content: "\f1b0"
}

.fa-spoon:before {
	content: "\f1b1"
}

.fa-cube:before {
	content: "\f1b2"
}

.fa-cubes:before {
	content: "\f1b3"
}

.fa-behance:before {
	content: "\f1b4"
}

.fa-behance-square:before {
	content: "\f1b5"
}

.fa-steam:before {
	content: "\f1b6"
}

.fa-steam-square:before {
	content: "\f1b7"
}

.fa-recycle:before {
	content: "\f1b8"
}

.fa-automobile:before,
.fa-car:before {
	content: "\f1b9"
}

.fa-cab:before,
.fa-taxi:before {
	content: "\f1ba"
}

.fa-tree:before {
	content: "\f1bb"
}

.fa-spotify:before {
	content: "\f1bc"
}

.fa-deviantart:before {
	content: "\f1bd"
}

.fa-soundcloud:before {
	content: "\f1be"
}

.fa-database:before {
	content: "\f1c0"
}

.fa-file-pdf-o:before {
	content: "\f1c1"
}

.fa-file-word-o:before {
	content: "\f1c2"
}

.fa-file-excel-o:before {
	content: "\f1c3"
}

.fa-file-powerpoint-o:before {
	content: "\f1c4"
}

.fa-file-photo-o:before,
.fa-file-picture-o:before,
.fa-file-image-o:before {
	content: "\f1c5"
}

.fa-file-zip-o:before,
.fa-file-archive-o:before {
	content: "\f1c6"
}

.fa-file-sound-o:before,
.fa-file-audio-o:before {
	content: "\f1c7"
}

.fa-file-movie-o:before,
.fa-file-video-o:before {
	content: "\f1c8"
}

.fa-file-code-o:before {
	content: "\f1c9"
}

.fa-vine:before {
	content: "\f1ca"
}

.fa-codepen:before {
	content: "\f1cb"
}

.fa-jsfiddle:before {
	content: "\f1cc"
}

.fa-life-bouy:before,
.fa-life-buoy:before,
.fa-life-saver:before,
.fa-support:before,
.fa-life-ring:before {
	content: "\f1cd"
}

.fa-circle-o-notch:before {
	content: "\f1ce"
}

.fa-ra:before,
.fa-resistance:before,
.fa-rebel:before {
	content: "\f1d0"
}

.fa-ge:before,
.fa-empire:before {
	content: "\f1d1"
}

.fa-git-square:before {
	content: "\f1d2"
}

.fa-git:before {
	content: "\f1d3"
}

.fa-y-combinator-square:before,
.fa-yc-square:before,
.fa-hacker-news:before {
	content: "\f1d4"
}

.fa-tencent-weibo:before {
	content: "\f1d5"
}

.fa-qq:before {
	content: "\f1d6"
}

.fa-wechat:before,
.fa-weixin:before {
	content: "\f1d7"
}

.fa-send:before,
.fa-paper-plane:before {
	content: "\f1d8"
}

.fa-send-o:before,
.fa-paper-plane-o:before {
	content: "\f1d9"
}

.fa-history:before {
	content: "\f1da"
}

.fa-circle-thin:before {
	content: "\f1db"
}

.fa-header:before {
	content: "\f1dc"
}

.fa-paragraph:before {
	content: "\f1dd"
}

.fa-sliders:before {
	content: "\f1de"
}

.fa-share-alt:before {
	content: "\f1e0"
}

.fa-share-alt-square:before {
	content: "\f1e1"
}

.fa-bomb:before {
	content: "\f1e2"
}

.fa-soccer-ball-o:before,
.fa-futbol-o:before {
	content: "\f1e3"
}

.fa-tty:before {
	content: "\f1e4"
}

.fa-binoculars:before {
	content: "\f1e5"
}

.fa-plug:before {
	content: "\f1e6"
}

.fa-slideshare:before {
	content: "\f1e7"
}

.fa-twitch:before {
	content: "\f1e8"
}

.fa-yelp:before {
	content: "\f1e9"
}

.fa-newspaper-o:before {
	content: "\f1ea"
}

.fa-wifi:before {
	content: "\f1eb"
}

.fa-calculator:before {
	content: "\f1ec"
}

.fa-paypal:before {
	content: "\f1ed"
}

.fa-google-wallet:before {
	content: "\f1ee"
}

.fa-cc-visa:before {
	content: "\f1f0"
}

.fa-cc-mastercard:before {
	content: "\f1f1"
}

.fa-cc-discover:before {
	content: "\f1f2"
}

.fa-cc-amex:before {
	content: "\f1f3"
}

.fa-cc-paypal:before {
	content: "\f1f4"
}

.fa-cc-stripe:before {
	content: "\f1f5"
}

.fa-bell-slash:before {
	content: "\f1f6"
}

.fa-bell-slash-o:before {
	content: "\f1f7"
}

.fa-trash:before {
	content: "\f1f8"
}

.fa-copyright:before {
	content: "\f1f9"
}

.fa-at:before {
	content: "\f1fa"
}

.fa-eyedropper:before {
	content: "\f1fb"
}

.fa-paint-brush:before {
	content: "\f1fc"
}

.fa-birthday-cake:before {
	content: "\f1fd"
}

.fa-area-chart:before {
	content: "\f1fe"
}

.fa-pie-chart:before {
	content: "\f200"
}

.fa-line-chart:before {
	content: "\f201"
}

.fa-lastfm:before {
	content: "\f202"
}

.fa-lastfm-square:before {
	content: "\f203"
}

.fa-toggle-off:before {
	content: "\f204"
}

.fa-toggle-on:before {
	content: "\f205"
}

.fa-bicycle:before {
	content: "\f206"
}

.fa-bus:before {
	content: "\f207"
}

.fa-ioxhost:before {
	content: "\f208"
}

.fa-angellist:before {
	content: "\f209"
}

.fa-cc:before {
	content: "\f20a"
}

.fa-shekel:before,
.fa-sheqel:before,
.fa-ils:before {
	content: "\f20b"
}

.fa-meanpath:before {
	content: "\f20c"
}

.fa-buysellads:before {
	content: "\f20d"
}

.fa-connectdevelop:before {
	content: "\f20e"
}

.fa-dashcube:before {
	content: "\f210"
}

.fa-forumbee:before {
	content: "\f211"
}

.fa-leanpub:before {
	content: "\f212"
}

.fa-sellsy:before {
	content: "\f213"
}

.fa-shirtsinbulk:before {
	content: "\f214"
}

.fa-simplybuilt:before {
	content: "\f215"
}

.fa-skyatlas:before {
	content: "\f216"
}

.fa-cart-plus:before {
	content: "\f217"
}

.fa-cart-arrow-down:before {
	content: "\f218"
}

.fa-diamond:before {
	content: "\f219"
}

.fa-ship:before {
	content: "\f21a"
}

.fa-user-secret:before {
	content: "\f21b"
}

.fa-motorcycle:before {
	content: "\f21c"
}

.fa-street-view:before {
	content: "\f21d"
}

.fa-heartbeat:before {
	content: "\f21e"
}

.fa-venus:before {
	content: "\f221"
}

.fa-mars:before {
	content: "\f222"
}

.fa-mercury:before {
	content: "\f223"
}

.fa-intersex:before,
.fa-transgender:before {
	content: "\f224"
}

.fa-transgender-alt:before {
	content: "\f225"
}

.fa-venus-double:before {
	content: "\f226"
}

.fa-mars-double:before {
	content: "\f227"
}

.fa-venus-mars:before {
	content: "\f228"
}

.fa-mars-stroke:before {
	content: "\f229"
}

.fa-mars-stroke-v:before {
	content: "\f22a"
}

.fa-mars-stroke-h:before {
	content: "\f22b"
}

.fa-neuter:before {
	content: "\f22c"
}

.fa-genderless:before {
	content: "\f22d"
}

.fa-facebook-official:before {
	content: "\f230"
}

.fa-pinterest-p:before {
	content: "\f231"
}

.fa-whatsapp:before {
	content: "\f232"
}

.fa-server:before {
	content: "\f233"
}

.fa-user-plus:before {
	content: "\f234"
}

.fa-user-times:before {
	content: "\f235"
}

.fa-hotel:before,
.fa-bed:before {
	content: "\f236"
}

.fa-viacoin:before {
	content: "\f237"
}

.fa-train:before {
	content: "\f238"
}

.fa-subway:before {
	content: "\f239"
}

.fa-medium:before {
	content: "\f23a"
}

.fa-yc:before,
.fa-y-combinator:before {
	content: "\f23b"
}

.fa-optin-monster:before {
	content: "\f23c"
}

.fa-opencart:before {
	content: "\f23d"
}

.fa-expeditedssl:before {
	content: "\f23e"
}

.fa-battery-4:before,
.fa-battery:before,
.fa-battery-full:before {
	content: "\f240"
}

.fa-battery-3:before,
.fa-battery-three-quarters:before {
	content: "\f241"
}

.fa-battery-2:before,
.fa-battery-half:before {
	content: "\f242"
}

.fa-battery-1:before,
.fa-battery-quarter:before {
	content: "\f243"
}

.fa-battery-0:before,
.fa-battery-empty:before {
	content: "\f244"
}

.fa-mouse-pointer:before {
	content: "\f245"
}

.fa-i-cursor:before {
	content: "\f246"
}

.fa-object-group:before {
	content: "\f247"
}

.fa-object-ungroup:before {
	content: "\f248"
}

.fa-sticky-note:before {
	content: "\f249"
}

.fa-sticky-note-o:before {
	content: "\f24a"
}

.fa-cc-jcb:before {
	content: "\f24b"
}

.fa-cc-diners-club:before {
	content: "\f24c"
}

.fa-clone:before {
	content: "\f24d"
}

.fa-balance-scale:before {
	content: "\f24e"
}

.fa-hourglass-o:before {
	content: "\f250"
}

.fa-hourglass-1:before,
.fa-hourglass-start:before {
	content: "\f251"
}

.fa-hourglass-2:before,
.fa-hourglass-half:before {
	content: "\f252"
}

.fa-hourglass-3:before,
.fa-hourglass-end:before {
	content: "\f253"
}

.fa-hourglass:before {
	content: "\f254"
}

.fa-hand-grab-o:before,
.fa-hand-rock-o:before {
	content: "\f255"
}

.fa-hand-stop-o:before,
.fa-hand-paper-o:before {
	content: "\f256"
}

.fa-hand-scissors-o:before {
	content: "\f257"
}

.fa-hand-lizard-o:before {
	content: "\f258"
}

.fa-hand-spock-o:before {
	content: "\f259"
}

.fa-hand-pointer-o:before {
	content: "\f25a"
}

.fa-hand-peace-o:before {
	content: "\f25b"
}

.fa-trademark:before {
	content: "\f25c"
}

.fa-registered:before {
	content: "\f25d"
}

.fa-creative-commons:before {
	content: "\f25e"
}

.fa-gg:before {
	content: "\f260"
}

.fa-gg-circle:before {
	content: "\f261"
}

.fa-tripadvisor:before {
	content: "\f262"
}

.fa-odnoklassniki:before {
	content: "\f263"
}

.fa-odnoklassniki-square:before {
	content: "\f264"
}

.fa-get-pocket:before {
	content: "\f265"
}

.fa-wikipedia-w:before {
	content: "\f266"
}

.fa-safari:before {
	content: "\f267"
}

.fa-chrome:before {
	content: "\f268"
}

.fa-firefox:before {
	content: "\f269"
}

.fa-opera:before {
	content: "\f26a"
}

.fa-internet-explorer:before {
	content: "\f26b"
}

.fa-tv:before,
.fa-television:before {
	content: "\f26c"
}

.fa-contao:before {
	content: "\f26d"
}

.fa-500px:before {
	content: "\f26e"
}

.fa-amazon:before {
	content: "\f270"
}

.fa-calendar-plus-o:before {
	content: "\f271"
}

.fa-calendar-minus-o:before {
	content: "\f272"
}

.fa-calendar-times-o:before {
	content: "\f273"
}

.fa-calendar-check-o:before {
	content: "\f274"
}

.fa-industry:before {
	content: "\f275"
}

.fa-map-pin:before {
	content: "\f276"
}

.fa-map-signs:before {
	content: "\f277"
}

.fa-map-o:before {
	content: "\f278"
}

.fa-map:before {
	content: "\f279"
}

.fa-commenting:before {
	content: "\f27a"
}

.fa-commenting-o:before {
	content: "\f27b"
}

.fa-houzz:before {
	content: "\f27c"
}

.fa-vimeo:before {
	content: "\f27d"
}

.fa-black-tie:before {
	content: "\f27e"
}

.fa-fonticons:before {
	content: "\f280"
}

.fa-reddit-alien:before {
	content: "\f281"
}

.fa-edge:before {
	content: "\f282"
}

.fa-credit-card-alt:before {
	content: "\f283"
}

.fa-codiepie:before {
	content: "\f284"
}

.fa-modx:before {
	content: "\f285"
}

.fa-fort-awesome:before {
	content: "\f286"
}

.fa-usb:before {
	content: "\f287"
}

.fa-product-hunt:before {
	content: "\f288"
}

.fa-mixcloud:before {
	content: "\f289"
}

.fa-scribd:before {
	content: "\f28a"
}

.fa-pause-circle:before {
	content: "\f28b"
}

.fa-pause-circle-o:before {
	content: "\f28c"
}

.fa-stop-circle:before {
	content: "\f28d"
}

.fa-stop-circle-o:before {
	content: "\f28e"
}

.fa-shopping-bag:before {
	content: "\f290"
}

.fa-shopping-basket:before {
	content: "\f291"
}

.fa-hashtag:before {
	content: "\f292"
}

.fa-bluetooth:before {
	content: "\f293"
}

.fa-bluetooth-b:before {
	content: "\f294"
}

.fa-percent:before {
	content: "\f295"
}

.fa-gitlab:before {
	content: "\f296"
}

.fa-wpbeginner:before {
	content: "\f297"
}

.fa-wpforms:before {
	content: "\f298"
}

.fa-envira:before {
	content: "\f299"
}

.fa-universal-access:before {
	content: "\f29a"
}

.fa-wheelchair-alt:before {
	content: "\f29b"
}

.fa-question-circle-o:before {
	content: "\f29c"
}

.fa-blind:before {
	content: "\f29d"
}

.fa-audio-description:before {
	content: "\f29e"
}

.fa-volume-control-phone:before {
	content: "\f2a0"
}

.fa-braille:before {
	content: "\f2a1"
}

.fa-assistive-listening-systems:before {
	content: "\f2a2"
}

.fa-asl-interpreting:before,
.fa-american-sign-language-interpreting:before {
	content: "\f2a3"
}

.fa-deafness:before,
.fa-hard-of-hearing:before,
.fa-deaf:before {
	content: "\f2a4"
}

.fa-glide:before {
	content: "\f2a5"
}

.fa-glide-g:before {
	content: "\f2a6"
}

.fa-signing:before,
.fa-sign-language:before {
	content: "\f2a7"
}

.fa-low-vision:before {
	content: "\f2a8"
}

.fa-viadeo:before {
	content: "\f2a9"
}

.fa-viadeo-square:before {
	content: "\f2aa"
}

.fa-snapchat:before {
	content: "\f2ab"
}

.fa-snapchat-ghost:before {
	content: "\f2ac"
}

.fa-snapchat-square:before {
	content: "\f2ad"
}

.fa-pied-piper:before {
	content: "\f2ae"
}

.fa-first-order:before {
	content: "\f2b0"
}

.fa-yoast:before {
	content: "\f2b1"
}

.fa-themeisle:before {
	content: "\f2b2"
}

.fa-google-plus-circle:before,
.fa-google-plus-official:before {
	content: "\f2b3"
}

.fa-fa:before,
.fa-font-awesome:before {
	content: "\f2b4"
}

.fa-handshake-o:before {
	content: "\f2b5"
}

.fa-envelope-open:before {
	content: "\f2b6"
}

.fa-envelope-open-o:before {
	content: "\f2b7"
}

.fa-linode:before {
	content: "\f2b8"
}

.fa-address-book:before {
	content: "\f2b9"
}

.fa-address-book-o:before {
	content: "\f2ba"
}

.fa-vcard:before,
.fa-address-card:before {
	content: "\f2bb"
}

.fa-vcard-o:before,
.fa-address-card-o:before {
	content: "\f2bc"
}

.fa-user-circle:before {
	content: "\f2bd"
}

.fa-user-circle-o:before {
	content: "\f2be"
}

.fa-user-o:before {
	content: "\f2c0"
}

.fa-id-badge:before {
	content: "\f2c1"
}

.fa-drivers-license:before,
.fa-id-card:before {
	content: "\f2c2"
}

.fa-drivers-license-o:before,
.fa-id-card-o:before {
	content: "\f2c3"
}

.fa-quora:before {
	content: "\f2c4"
}

.fa-free-code-camp:before {
	content: "\f2c5"
}

.fa-telegram:before {
	content: "\f2c6"
}

.fa-thermometer-4:before,
.fa-thermometer:before,
.fa-thermometer-full:before {
	content: "\f2c7"
}

.fa-thermometer-3:before,
.fa-thermometer-three-quarters:before {
	content: "\f2c8"
}

.fa-thermometer-2:before,
.fa-thermometer-half:before {
	content: "\f2c9"
}

.fa-thermometer-1:before,
.fa-thermometer-quarter:before {
	content: "\f2ca"
}

.fa-thermometer-0:before,
.fa-thermometer-empty:before {
	content: "\f2cb"
}

.fa-shower:before {
	content: "\f2cc"
}

.fa-bathtub:before,
.fa-s15:before,
.fa-bath:before {
	content: "\f2cd"
}

.fa-podcast:before {
	content: "\f2ce"
}

.fa-window-maximize:before {
	content: "\f2d0"
}

.fa-window-minimize:before {
	content: "\f2d1"
}

.fa-window-restore:before {
	content: "\f2d2"
}

.fa-times-rectangle:before,
.fa-window-close:before {
	content: "\f2d3"
}

.fa-times-rectangle-o:before,
.fa-window-close-o:before {
	content: "\f2d4"
}

.fa-bandcamp:before {
	content: "\f2d5"
}

.fa-grav:before {
	content: "\f2d6"
}

.fa-etsy:before {
	content: "\f2d7"
}

.fa-imdb:before {
	content: "\f2d8"
}

.fa-ravelry:before {
	content: "\f2d9"
}

.fa-eercast:before {
	content: "\f2da"
}

.fa-microchip:before {
	content: "\f2db"
}

.fa-snowflake-o:before {
	content: "\f2dc"
}

.fa-superpowers:before {
	content: "\f2dd"
}

.fa-wpexplorer:before {
	content: "\f2de"
}

.fa-meetup:before {
	content: "\f2e0"
}

.sr-only {
	position: absolute;
	width: 1px;
	height: 1px;
	padding: 0;
	margin: -1px;
	overflow: hidden;
	clip: rect(0, 0, 0, 0);
	border: 0
}

.sr-only-focusable:active,
.sr-only-focusable:focus {
	position: static;
	width: auto;
	height: auto;
	margin: 0;
	overflow: visible;
	clip: auto
}

ï»¿html {
	font-family: sans-serif;
	-ms-text-size-adjust: 100%;
	-webkit-text-size-adjust: 100%
}

body {
	margin: 0
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
main,
menu,
nav,
section,
summary {
	display: block
}

audio,
canvas,
progress,
video {
	display: inline-block;
	vertical-align: baseline
}

audio:not([controls]) {
	display: none;
	height: 0
}

[hidden],
template {
	display: none
}

a {
	background-color: transparent
}

a:active,
a:hover {
	outline: 0
}

abbr[title] {
	border-bottom: 1px dotted
}

b,
strong {
	font-weight: bold
}

dfn {
	font-style: italic
}

h1 {
	font-size: 2em;
	margin: 0.67em 0
}

mark {
	background: #ff0;
	color: #000
}

small {
	font-size: 80%
}

sub,
sup {
	font-size: 75%;
	line-height: 0;
	position: relative;
	vertical-align: baseline
}

sup {
	top: -0.5em
}

sub {
	bottom: -0.25em
}

img {
	border: 0
}

svg:not(:root) {
	overflow: hidden
}

figure {
	margin: 1em 40px
}

hr {
	box-sizing: content-box;
	height: 0
}

pre {
	overflow: auto
}

code,
kbd,
pre,
samp {
	font-family: monospace, monospace;
	font-size: 1em
}

button,
input,
optgroup,
select,
textarea {
	color: inherit;
	font: inherit;
	margin: 0
}

button {
	overflow: visible
}

button,
select {
	text-transform: none
}

button,
html input[type="button"],
input[type="reset"],
input[type="submit"] {
	-webkit-appearance: button;
	cursor: pointer
}

button[disabled],
html input[disabled] {
	cursor: default
}

button::-moz-focus-inner,
input::-moz-focus-inner {
	border: 0;
	padding: 0
}

input {
	line-height: normal
}

input[type="checkbox"],
input[type="radio"] {
	box-sizing: border-box;
	padding: 0
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
	height: auto
}

input[type="search"] {
	-webkit-appearance: textfield;
	box-sizing: content-box
}

input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-decoration {
	-webkit-appearance: none
}

fieldset {
	border: 1px solid #c0c0c0;
	margin: 0 2px;
	padding: 0.35em 0.625em 0.75em
}

legend {
	border: 0;
	padding: 0
}

textarea {
	overflow: auto
}

optgroup {
	font-weight: bold
}

table {
	border-collapse: collapse;
	border-spacing: 0
}

td,
th {
	padding: 0
}

@media print {
	*,
	*:before,
	*:after {
		background: transparent !important;
		color: #000 !important;
		box-shadow: none !important;
		text-shadow: none !important
	}
	a,
	a:visited {
		text-decoration: underline
	}
	a[href]:after {
		content: " (" attr(href) ")"
	}
	abbr[title]:after {
		content: " (" attr(title) ")"
	}
	a[href^="#"]:after,
	a[href^="javascript:"]:after {
		content: ""
	}
	pre,
	blockquote {
		border: 1px solid #999;
		page-break-inside: avoid
	}
	thead {
		display: table-header-group
	}
	tr,
	img {
		page-break-inside: avoid
	}
	img {
		max-width: 100% !important
	}
	p,
	h2,
	h3 {
		orphans: 3;
		widows: 3
	}
	h2,
	h3 {
		page-break-after: avoid
	}
	.navbar {
		display: none
	}
	.btn>.caret,
	.dropup>.btn>.caret {
		border-top-color: #000 !important
	}
	.label {
		border: 1px solid #000
	}
	.table {
		border-collapse: collapse !important
	}
	.table td,
	.table th {
		background-color: #fff !important
	}
	.table-bordered th,
	.table-bordered td {
		border: 1px solid #ddd !important
	}
}

@font-face {
	font-family: 'Glyphicons Halflings';
	src: url(./assets/mferefresh/bootstrap/assets/fonts/bootstrap/glyphicons-halflings-regular.eot);
	src: url(./assets/mferefresh/bootstrap/assets/fonts/bootstrap/glyphicons-halflings-regular.eot?#iefix) format("embedded-opentype"), url(./assets/mferefresh/bootstrap/assets/fonts/bootstrap/glyphicons-halflings-regular.woff2) format("woff2"), url(./assets/mferefresh/bootstrap/assets/fonts/bootstrap/glyphicons-halflings-regular.woff) format("woff"), url(./assets/mferefresh/bootstrap/assets/fonts/bootstrap/glyphicons-halflings-regular.ttf) format("truetype"), url(./assets/mferefresh/bootstrap/assets/fonts/bootstrap/glyphicons-halflings-regular.svg#glyphicons_halflingsregular) format("svg")
}

.glyphicon {
	position: relative;
	top: 1px;
	display: inline-block;
	font-family: 'Glyphicons Halflings';
	font-style: normal;
	font-weight: normal;
	line-height: 1;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale
}

.glyphicon-asterisk:before {
	content: "\002a"
}

.glyphicon-plus:before {
	content: "\002b"
}

.glyphicon-euro:before,
.glyphicon-eur:before {
	content: "\20ac"
}

.glyphicon-minus:before {
	content: "\2212"
}

.glyphicon-cloud:before {
	content: "\2601"
}

.glyphicon-envelope:before {
	content: "\2709"
}

.glyphicon-pencil:before {
	content: "\270f"
}

.glyphicon-glass:before {
	content: "\e001"
}

.glyphicon-music:before {
	content: "\e002"
}

.glyphicon-search:before {
	content: "\e003"
}

.glyphicon-heart:before {
	content: "\e005"
}

.glyphicon-star:before {
	content: "\e006"
}

.glyphicon-star-empty:before {
	content: "\e007"
}

.glyphicon-user:before {
	content: "\e008"
}

.glyphicon-film:before {
	content: "\e009"
}

.glyphicon-th-large:before {
	content: "\e010"
}

.glyphicon-th:before {
	content: "\e011"
}

.glyphicon-th-list:before {
	content: "\e012"
}

.glyphicon-ok:before {
	content: "\e013"
}

.glyphicon-remove:before {
	content: "\e014"
}

.glyphicon-zoom-in:before {
	content: "\e015"
}

.glyphicon-zoom-out:before {
	content: "\e016"
}

.glyphicon-off:before {
	content: "\e017"
}

.glyphicon-signal:before {
	content: "\e018"
}

.glyphicon-cog:before {
	content: "\e019"
}

.glyphicon-trash:before {
	content: "\e020"
}

.glyphicon-home:before {
	content: "\e021"
}

.glyphicon-file:before {
	content: "\e022"
}

.glyphicon-time:before {
	content: "\e023"
}

.glyphicon-road:before {
	content: "\e024"
}

.glyphicon-download-alt:before {
	content: "\e025"
}

.glyphicon-download:before {
	content: "\e026"
}

.glyphicon-upload:before {
	content: "\e027"
}

.glyphicon-inbox:before {
	content: "\e028"
}

.glyphicon-play-circle:before {
	content: "\e029"
}

.glyphicon-repeat:before {
	content: "\e030"
}

.glyphicon-refresh:before {
	content: "\e031"
}

.glyphicon-list-alt:before {
	content: "\e032"
}

.glyphicon-lock:before {
	content: "\e033"
}

.glyphicon-flag:before {
	content: "\e034"
}

.glyphicon-headphones:before {
	content: "\e035"
}

.glyphicon-volume-off:before {
	content: "\e036"
}

.glyphicon-volume-down:before {
	content: "\e037"
}

.glyphicon-volume-up:before {
	content: "\e038"
}

.glyphicon-qrcode:before {
	content: "\e039"
}

.glyphicon-barcode:before {
	content: "\e040"
}

.glyphicon-tag:before {
	content: "\e041"
}

.glyphicon-tags:before {
	content: "\e042"
}

.glyphicon-book:before {
	content: "\e043"
}

.glyphicon-bookmark:before {
	content: "\e044"
}

.glyphicon-print:before {
	content: "\e045"
}

.glyphicon-camera:before {
	content: "\e046"
}

.glyphicon-font:before {
	content: "\e047"
}

.glyphicon-bold:before {
	content: "\e048"
}

.glyphicon-italic:before {
	content: "\e049"
}

.glyphicon-text-height:before {
	content: "\e050"
}

.glyphicon-text-width:before {
	content: "\e051"
}

.glyphicon-align-left:before {
	content: "\e052"
}

.glyphicon-align-center:before {
	content: "\e053"
}

.glyphicon-align-right:before {
	content: "\e054"
}

.glyphicon-align-justify:before {
	content: "\e055"
}

.glyphicon-list:before {
	content: "\e056"
}

.glyphicon-indent-left:before {
	content: "\e057"
}

.glyphicon-indent-right:before {
	content: "\e058"
}

.glyphicon-facetime-video:before {
	content: "\e059"
}

.glyphicon-picture:before {
	content: "\e060"
}

.glyphicon-map-marker:before {
	content: "\e062"
}

.glyphicon-adjust:before {
	content: "\e063"
}

.glyphicon-tint:before {
	content: "\e064"
}

.glyphicon-edit:before {
	content: "\e065"
}

.glyphicon-share:before {
	content: "\e066"
}

.glyphicon-check:before {
	content: "\e067"
}

.glyphicon-move:before {
	content: "\e068"
}

.glyphicon-step-backward:before {
	content: "\e069"
}

.glyphicon-fast-backward:before {
	content: "\e070"
}

.glyphicon-backward:before {
	content: "\e071"
}

.glyphicon-play:before {
	content: "\e072"
}

.glyphicon-pause:before {
	content: "\e073"
}

.glyphicon-stop:before {
	content: "\e074"
}

.glyphicon-forward:before {
	content: "\e075"
}

.glyphicon-fast-forward:before {
	content: "\e076"
}

.glyphicon-step-forward:before {
	content: "\e077"
}

.glyphicon-eject:before {
	content: "\e078"
}

.glyphicon-chevron-left:before {
	content: "\e079"
}

.glyphicon-chevron-right:before {
	content: "\e080"
}

.glyphicon-plus-sign:before {
	content: "\e081"
}

.glyphicon-minus-sign:before {
	content: "\e082"
}

.glyphicon-remove-sign:before {
	content: "\e083"
}

.glyphicon-ok-sign:before {
	content: "\e084"
}

.glyphicon-question-sign:before {
	content: "\e085"
}

.glyphicon-info-sign:before {
	content: "\e086"
}

.glyphicon-screenshot:before {
	content: "\e087"
}

.glyphicon-remove-circle:before {
	content: "\e088"
}

.glyphicon-ok-circle:before {
	content: "\e089"
}

.glyphicon-ban-circle:before {
	content: "\e090"
}

.glyphicon-arrow-left:before {
	content: "\e091"
}

.glyphicon-arrow-right:before {
	content: "\e092"
}

.glyphicon-arrow-up:before {
	content: "\e093"
}

.glyphicon-arrow-down:before {
	content: "\e094"
}

.glyphicon-share-alt:before {
	content: "\e095"
}

.glyphicon-resize-full:before {
	content: "\e096"
}

.glyphicon-resize-small:before {
	content: "\e097"
}

.glyphicon-exclamation-sign:before {
	content: "\e101"
}

.glyphicon-gift:before {
	content: "\e102"
}

.glyphicon-leaf:before {
	content: "\e103"
}

.glyphicon-fire:before {
	content: "\e104"
}

.glyphicon-eye-open:before {
	content: "\e105"
}

.glyphicon-eye-close:before {
	content: "\e106"
}

.glyphicon-warning-sign:before {
	content: "\e107"
}

.glyphicon-plane:before {
	content: "\e108"
}

.glyphicon-calendar:before {
	content: "\e109"
}

.glyphicon-random:before {
	content: "\e110"
}

.glyphicon-comment:before {
	content: "\e111"
}

.glyphicon-magnet:before {
	content: "\e112"
}

.glyphicon-chevron-up:before {
	content: "\e113"
}

.glyphicon-chevron-down:before {
	content: "\e114"
}

.glyphicon-retweet:before {
	content: "\e115"
}

.glyphicon-shopping-cart:before {
	content: "\e116"
}

.glyphicon-folder-close:before {
	content: "\e117"
}

.glyphicon-folder-open:before {
	content: "\e118"
}

.glyphicon-resize-vertical:before {
	content: "\e119"
}

.glyphicon-resize-horizontal:before {
	content: "\e120"
}

.glyphicon-hdd:before {
	content: "\e121"
}

.glyphicon-bullhorn:before {
	content: "\e122"
}

.glyphicon-bell:before {
	content: "\e123"
}

.glyphicon-certificate:before {
	content: "\e124"
}

.glyphicon-thumbs-up:before {
	content: "\e125"
}

.glyphicon-thumbs-down:before {
	content: "\e126"
}

.glyphicon-hand-right:before {
	content: "\e127"
}

.glyphicon-hand-left:before {
	content: "\e128"
}

.glyphicon-hand-up:before {
	content: "\e129"
}

.glyphicon-hand-down:before {
	content: "\e130"
}

.glyphicon-circle-arrow-right:before {
	content: "\e131"
}

.glyphicon-circle-arrow-left:before {
	content: "\e132"
}

.glyphicon-circle-arrow-up:before {
	content: "\e133"
}

.glyphicon-circle-arrow-down:before {
	content: "\e134"
}

.glyphicon-globe:before {
	content: "\e135"
}

.glyphicon-wrench:before {
	content: "\e136"
}

.glyphicon-tasks:before {
	content: "\e137"
}

.glyphicon-filter:before {
	content: "\e138"
}

.glyphicon-briefcase:before {
	content: "\e139"
}

.glyphicon-fullscreen:before {
	content: "\e140"
}

.glyphicon-dashboard:before {
	content: "\e141"
}

.glyphicon-paperclip:before {
	content: "\e142"
}

.glyphicon-heart-empty:before {
	content: "\e143"
}

.glyphicon-link:before {
	content: "\e144"
}

.glyphicon-phone:before {
	content: "\e145"
}

.glyphicon-pushpin:before {
	content: "\e146"
}

.glyphicon-usd:before {
	content: "\e148"
}

.glyphicon-gbp:before {
	content: "\e149"
}

.glyphicon-sort:before {
	content: "\e150"
}

.glyphicon-sort-by-alphabet:before {
	content: "\e151"
}

.glyphicon-sort-by-alphabet-alt:before {
	content: "\e152"
}

.glyphicon-sort-by-order:before {
	content: "\e153"
}

.glyphicon-sort-by-order-alt:before {
	content: "\e154"
}

.glyphicon-sort-by-attributes:before {
	content: "\e155"
}

.glyphicon-sort-by-attributes-alt:before {
	content: "\e156"
}

.glyphicon-unchecked:before {
	content: "\e157"
}

.glyphicon-expand:before {
	content: "\e158"
}

.glyphicon-collapse-down:before {
	content: "\e159"
}

.glyphicon-collapse-up:before {
	content: "\e160"
}

.glyphicon-log-in:before {
	content: "\e161"
}

.glyphicon-flash:before {
	content: "\e162"
}

.glyphicon-log-out:before {
	content: "\e163"
}

.glyphicon-new-window:before {
	content: "\e164"
}

.glyphicon-record:before {
	content: "\e165"
}

.glyphicon-save:before {
	content: "\e166"
}

.glyphicon-open:before {
	content: "\e167"
}

.glyphicon-saved:before {
	content: "\e168"
}

.glyphicon-import:before {
	content: "\e169"
}

.glyphicon-export:before {
	content: "\e170"
}

.glyphicon-send:before {
	content: "\e171"
}

.glyphicon-floppy-disk:before {
	content: "\e172"
}

.glyphicon-floppy-saved:before {
	content: "\e173"
}

.glyphicon-floppy-remove:before {
	content: "\e174"
}

.glyphicon-floppy-save:before {
	content: "\e175"
}

.glyphicon-floppy-open:before {
	content: "\e176"
}

.glyphicon-credit-card:before {
	content: "\e177"
}

.glyphicon-transfer:before {
	content: "\e178"
}

.glyphicon-cutlery:before {
	content: "\e179"
}

.glyphicon-header:before {
	content: "\e180"
}

.glyphicon-compressed:before {
	content: "\e181"
}

.glyphicon-earphone:before {
	content: "\e182"
}

.glyphicon-phone-alt:before {
	content: "\e183"
}

.glyphicon-tower:before {
	content: "\e184"
}

.glyphicon-stats:before {
	content: "\e185"
}

.glyphicon-sd-video:before {
	content: "\e186"
}

.glyphicon-hd-video:before {
	content: "\e187"
}

.glyphicon-subtitles:before {
	content: "\e188"
}

.glyphicon-sound-stereo:before {
	content: "\e189"
}

.glyphicon-sound-dolby:before {
	content: "\e190"
}

.glyphicon-sound-5-1:before {
	content: "\e191"
}

.glyphicon-sound-6-1:before {
	content: "\e192"
}

.glyphicon-sound-7-1:before {
	content: "\e193"
}

.glyphicon-copyright-mark:before {
	content: "\e194"
}

.glyphicon-registration-mark:before {
	content: "\e195"
}

.glyphicon-cloud-download:before {
	content: "\e197"
}

.glyphicon-cloud-upload:before {
	content: "\e198"
}

.glyphicon-tree-conifer:before {
	content: "\e199"
}

.glyphicon-tree-deciduous:before {
	content: "\e200"
}

.glyphicon-cd:before {
	content: "\e201"
}

.glyphicon-save-file:before {
	content: "\e202"
}

.glyphicon-open-file:before {
	content: "\e203"
}

.glyphicon-level-up:before {
	content: "\e204"
}

.glyphicon-copy:before {
	content: "\e205"
}

.glyphicon-paste:before {
	content: "\e206"
}

.glyphicon-alert:before {
	content: "\e209"
}

.glyphicon-equalizer:before {
	content: "\e210"
}

.glyphicon-king:before {
	content: "\e211"
}

.glyphicon-queen:before {
	content: "\e212"
}

.glyphicon-pawn:before {
	content: "\e213"
}

.glyphicon-bishop:before {
	content: "\e214"
}

.glyphicon-knight:before {
	content: "\e215"
}

.glyphicon-baby-formula:before {
	content: "\e216"
}

.glyphicon-tent:before {
	content: "\26fa"
}

.glyphicon-blackboard:before {
	content: "\e218"
}

.glyphicon-bed:before {
	content: "\e219"
}

.glyphicon-apple:before {
	content: "\f8ff"
}

.glyphicon-erase:before {
	content: "\e221"
}

.glyphicon-hourglass:before {
	content: "\231b"
}

.glyphicon-lamp:before {
	content: "\e223"
}

.glyphicon-duplicate:before {
	content: "\e224"
}

.glyphicon-piggy-bank:before {
	content: "\e225"
}

.glyphicon-scissors:before {
	content: "\e226"
}

.glyphicon-bitcoin:before {
	content: "\e227"
}

.glyphicon-btc:before {
	content: "\e227"
}

.glyphicon-xbt:before {
	content: "\e227"
}

.glyphicon-yen:before {
	content: "\00a5"
}

.glyphicon-jpy:before {
	content: "\00a5"
}

.glyphicon-ruble:before {
	content: "\20bd"
}

.glyphicon-rub:before {
	content: "\20bd"
}

.glyphicon-scale:before {
	content: "\e230"
}

.glyphicon-ice-lolly:before {
	content: "\e231"
}

.glyphicon-ice-lolly-tasted:before {
	content: "\e232"
}

.glyphicon-education:before {
	content: "\e233"
}

.glyphicon-option-horizontal:before {
	content: "\e234"
}

.glyphicon-option-vertical:before {
	content: "\e235"
}

.glyphicon-menu-hamburger:before {
	content: "\e236"
}

.glyphicon-modal-window:before {
	content: "\e237"
}

.glyphicon-oil:before {
	content: "\e238"
}

.glyphicon-grain:before {
	content: "\e239"
}

.glyphicon-sunglasses:before {
	content: "\e240"
}

.glyphicon-text-size:before {
	content: "\e241"
}

.glyphicon-text-color:before {
	content: "\e242"
}

.glyphicon-text-background:before {
	content: "\e243"
}

.glyphicon-object-align-top:before {
	content: "\e244"
}

.glyphicon-object-align-bottom:before {
	content: "\e245"
}

.glyphicon-object-align-horizontal:before {
	content: "\e246"
}

.glyphicon-object-align-left:before {
	content: "\e247"
}

.glyphicon-object-align-vertical:before {
	content: "\e248"
}

.glyphicon-object-align-right:before {
	content: "\e249"
}

.glyphicon-triangle-right:before {
	content: "\e250"
}

.glyphicon-triangle-left:before {
	content: "\e251"
}

.glyphicon-triangle-bottom:before {
	content: "\e252"
}

.glyphicon-triangle-top:before {
	content: "\e253"
}

.glyphicon-console:before {
	content: "\e254"
}

.glyphicon-superscript:before {
	content: "\e255"
}

.glyphicon-subscript:before {
	content: "\e256"
}

.glyphicon-menu-left:before {
	content: "\e257"
}

.glyphicon-menu-right:before {
	content: "\e258"
}

.glyphicon-menu-down:before {
	content: "\e259"
}

.glyphicon-menu-up:before {
	content: "\e260"
}

* {
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box
}

*:before,
*:after {
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box
}

html {
	font-size: 10px;
	-webkit-tap-highlight-color: transparent
}

body {
	font-family: "SourceSansRegular", helvetica, arial, sans-serif;
	font-size: 16px;
	line-height: 1.42857;
	color: #333;
	background-color: #F1F1F1
}

input,
button,
select,
textarea {
	font-family: inherit;
	font-size: inherit;
	line-height: inherit
}

a {
	color: #267998;
	text-decoration: none
}

a:hover,
a:focus {
	color: #008575;
	text-decoration: underline
}

a:focus {
	outline: 5px auto -webkit-focus-ring-color;
	outline-offset: -2px
}

figure {
	margin: 0
}

img {
	vertical-align: middle
}

.img-responsive {
	display: block;
	max-width: 100%;
	height: auto
}

.img-rounded {
	border-radius: 6px
}

.img-thumbnail {
	padding: 4px;
	line-height: 1.42857;
	background-color: #F1F1F1;
	border: 1px solid #ddd;
	border-radius: 4px;
	-webkit-transition: all 0.2s ease-in-out;
	-o-transition: all 0.2s ease-in-out;
	transition: all 0.2s ease-in-out;
	display: inline-block;
	max-width: 100%;
	height: auto
}

.img-circle {
	border-radius: 50%
}

hr {
	margin-top: 22px;
	margin-bottom: 22px;
	border: 0;
	border-top: 1px solid #eee
}

.sr-only {
	position: absolute;
	width: 1px;
	height: 1px;
	margin: -1px;
	padding: 0;
	overflow: hidden;
	clip: rect(0, 0, 0, 0);
	border: 0
}

.sr-only-focusable:active,
.sr-only-focusable:focus {
	position: static;
	width: auto;
	height: auto;
	margin: 0;
	overflow: visible;
	clip: auto
}

[role="button"] {
	cursor: pointer
}

h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
	font-family: inherit;
	font-weight: 500;
	line-height: 1.1;
	color: inherit
}

h1 small,
h1 .small,
h2 small,
h2 .small,
h3 small,
h3 .small,
h4 small,
h4 .small,
h5 small,
h5 .small,
h6 small,
h6 .small,
.h1 small,
.h1 .small,
.h2 small,
.h2 .small,
.h3 small,
.h3 .small,
.h4 small,
.h4 .small,
.h5 small,
.h5 .small,
.h6 small,
.h6 .small {
	font-weight: normal;
	line-height: 1;
	color: #777
}

h1,
.h1,
h2,
.h2,
h3,
.h3 {
	margin-top: 22px;
	margin-bottom: 11px
}

h1 small,
h1 .small,
.h1 small,
.h1 .small,
h2 small,
h2 .small,
.h2 small,
.h2 .small,
h3 small,
h3 .small,
.h3 small,
.h3 .small {
	font-size: 65%
}

h4,
.h4,
h5,
.h5,
h6,
.h6 {
	margin-top: 11px;
	margin-bottom: 11px
}

h4 small,
h4 .small,
.h4 small,
.h4 .small,
h5 small,
h5 .small,
.h5 small,
.h5 .small,
h6 small,
h6 .small,
.h6 small,
.h6 .small {
	font-size: 75%
}

h1,
.h1 {
	font-size: 45px
}

h2,
.h2 {
	font-size: 25px
}

h3,
.h3 {
	font-size: 20px
}

h4,
.h4 {
	font-size: 18px
}

h5,
.h5 {
	font-size: 16px
}

h6,
.h6 {
	font-size: 14px
}

p {
	margin: 0 0 11px
}

.lead {
	margin-bottom: 22px;
	font-size: 18px;
	font-weight: 300;
	line-height: 1.4
}

@media (min-width:768px) {
	.lead {
		font-size: 24px
	}
}

small,
.small {
	font-size: 87%
}

mark,
.mark {
	background-color: #fcf8e3;
	padding: .2em
}

.text-left {
	text-align: left
}

.text-right {
	text-align: right
}

.text-center {
	text-align: center
}

.text-justify {
	text-align: justify
}

.text-nowrap {
	white-space: nowrap
}

.text-lowercase {
	text-transform: lowercase
}

.text-uppercase,
.initialism {
	text-transform: uppercase
}

.text-capitalize {
	text-transform: capitalize
}

.text-muted {
	color: #777
}

.text-primary {
	color: #337ab7
}

a.text-primary:hover,
a.text-primary:focus {
	color: #286090
}

.text-success {
	color: #3c763d
}

a.text-success:hover,
a.text-success:focus {
	color: #2b542c
}

.text-info {
	color: #31708f
}

a.text-info:hover,
a.text-info:focus {
	color: #245269
}

.text-warning {
	color: #8a6d3b
}

a.text-warning:hover,
a.text-warning:focus {
	color: #66512c
}

.text-danger {
	color: #a94442
}

a.text-danger:hover,
a.text-danger:focus {
	color: #843534
}

.bg-primary {
	color: #fff
}

.bg-primary {
	background-color: #337ab7
}

a.bg-primary:hover,
a.bg-primary:focus {
	background-color: #286090
}

.bg-success {
	background-color: #dff0d8
}

a.bg-success:hover,
a.bg-success:focus {
	background-color: #c1e2b3
}

.bg-info {
	background-color: #d9edf7
}

a.bg-info:hover,
a.bg-info:focus {
	background-color: #afd9ee
}

.bg-warning {
	background-color: #fcf8e3
}

a.bg-warning:hover,
a.bg-warning:focus {
	background-color: #f7ecb5
}

.bg-danger {
	background-color: #f2dede
}

a.bg-danger:hover,
a.bg-danger:focus {
	background-color: #e4b9b9
}

.page-header {
	padding-bottom: 10px;
	margin: 44px 0 22px;
	border-bottom: 1px solid #eee
}

ul,
ol {
	margin-top: 0;
	margin-bottom: 11px
}

ul ul,
ul ol,
ol ul,
ol ol {
	margin-bottom: 0
}

.list-unstyled {
	padding-left: 0;
	list-style: none
}

.list-inline {
	padding-left: 0;
	list-style: none;
	margin-left: -5px
}

.list-inline>li {
	display: inline-block;
	padding-left: 5px;
	padding-right: 5px
}

dl {
	margin-top: 0;
	margin-bottom: 22px
}

dt,
dd {
	line-height: 1.42857
}

dt {
	font-weight: bold
}

dd {
	margin-left: 0
}

.dl-horizontal dd:before,
.dl-horizontal dd:after {
	content: " ";
	display: table
}

.dl-horizontal dd:after {
	clear: both
}

@media (min-width:768px) {
	.dl-horizontal dt {
		float: left;
		width: 160px;
		clear: left;
		text-align: right;
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap
	}
	.dl-horizontal dd {
		margin-left: 180px
	}
}

abbr[title],
abbr[data-original-title] {
	cursor: help;
	border-bottom: 1px dotted #777
}

.initialism {
	font-size: 90%
}

blockquote {
	padding: 11px 22px;
	margin: 0 0 22px;
	font-size: 20px;
	border-left: 5px solid #eee
}

blockquote p:last-child,
blockquote ul:last-child,
blockquote ol:last-child {
	margin-bottom: 0
}

blockquote footer,
blockquote small,
blockquote .small {
	display: block;
	font-size: 80%;
	line-height: 1.42857;
	color: #777
}

blockquote footer:before,
blockquote small:before,
blockquote .small:before {
	content: '\2014 \00A0'
}

.blockquote-reverse,
blockquote.pull-right {
	padding-right: 15px;
	padding-left: 0;
	border-right: 5px solid #eee;
	border-left: 0;
	text-align: right
}

.blockquote-reverse footer:before,
.blockquote-reverse small:before,
.blockquote-reverse .small:before,
blockquote.pull-right footer:before,
blockquote.pull-right small:before,
blockquote.pull-right .small:before {
	content: ''
}

.blockquote-reverse footer:after,
.blockquote-reverse small:after,
.blockquote-reverse .small:after,
blockquote.pull-right footer:after,
blockquote.pull-right small:after,
blockquote.pull-right .small:after {
	content: '\00A0 \2014'
}

address {
	margin-bottom: 22px;
	font-style: normal;
	line-height: 1.42857
}

code,
kbd,
pre,
samp {
	font-family: Menlo, Monaco, Consolas, "Courier New", monospace
}

code {
	padding: 2px 4px;
	font-size: 90%;
	color: #c7254e;
	background-color: #f9f2f4;
	border-radius: 4px
}

kbd {
	padding: 2px 4px;
	font-size: 90%;
	color: #fff;
	background-color: #333;
	border-radius: 3px;
	box-shadow: inset 0 -1px 0 rgba(0, 0, 0, 0.25)
}

kbd kbd {
	padding: 0;
	font-size: 100%;
	font-weight: bold;
	box-shadow: none
}

pre {
	display: block;
	padding: 10.5px;
	margin: 0 0 11px;
	font-size: 15px;
	line-height: 1.42857;
	word-break: break-all;
	word-wrap: break-word;
	color: #333;
	background-color: #f5f5f5;
	border: 1px solid #ccc;
	border-radius: 4px
}

pre code {
	padding: 0;
	font-size: inherit;
	color: inherit;
	white-space: pre-wrap;
	background-color: transparent;
	border-radius: 0
}

.pre-scrollable {
	max-height: 340px;
	overflow-y: scroll
}

.container {
	margin-right: auto;
	margin-left: auto;
	padding-left: 5px;
	padding-right: 5px
}

.container:before,
.container:after {
	content: " ";
	display: table
}

.container:after {
	clear: both
}

@media (min-width:768px) {
	.container {
		width: 730px
	}
}

@media (min-width:992px) {
	.container {
		width: 950px
	}
}

@media (min-width:1200px) {
	.container {
		width: 1150px
	}
}

.container-fluid {
	margin-right: auto;
	margin-left: auto;
	padding-left: 5px;
	padding-right: 5px
}

.container-fluid:before,
.container-fluid:after {
	content: " ";
	display: table
}

.container-fluid:after {
	clear: both
}

.row {
	margin-left: -5px;
	margin-right: -5px
}

.row:before,
.row:after {
	content: " ";
	display: table
}

.row:after {
	clear: both
}

.col-xs-1,
.col-sm-1,
.col-md-1,
.col-lg-1,
.col-xs-2,
.col-sm-2,
.col-md-2,
.col-lg-2,
.col-xs-3,
.col-sm-3,
.col-md-3,
.col-lg-3,
.col-xs-4,
.col-sm-4,
.col-md-4,
.col-lg-4,
.col-xs-5,
.col-sm-5,
.col-md-5,
.col-lg-5,
.col-xs-6,
.col-sm-6,
.col-md-6,
.col-lg-6,
.col-xs-7,
.col-sm-7,
.col-md-7,
.col-lg-7,
.col-xs-8,
.col-sm-8,
.col-md-8,
.col-lg-8,
.col-xs-9,
.col-sm-9,
.col-md-9,
.col-lg-9,
.col-xs-10,
.col-sm-10,
.col-md-10,
.col-lg-10,
.col-xs-11,
.col-sm-11,
.col-md-11,
.col-lg-11,
.col-xs-12,
.col-sm-12,
.col-md-12,
.col-lg-12 {
	position: relative;
	min-height: 1px;
	padding-left: 5px;
	padding-right: 5px
}

.col-xs-1,
.col-xs-2,
.col-xs-3,
.col-xs-4,
.col-xs-5,
.col-xs-6,
.col-xs-7,
.col-xs-8,
.col-xs-9,
.col-xs-10,
.col-xs-11,
.col-xs-12 {
	float: left
}

.col-xs-1 {
	width: 8.33333%
}

.col-xs-2 {
	width: 16.66667%
}

.col-xs-3 {
	width: 25%
}

.col-xs-4 {
	width: 33.33333%
}

.col-xs-5 {
	width: 41.66667%
}

.col-xs-6 {
	width: 50%
}

.col-xs-7 {
	width: 58.33333%
}

.col-xs-8 {
	width: 66.66667%
}

.col-xs-9 {
	width: 75%
}

.col-xs-10 {
	width: 83.33333%
}

.col-xs-11 {
	width: 91.66667%
}

.col-xs-12 {
	width: 100%
}

.col-xs-pull-0 {
	right: auto
}

.col-xs-pull-1 {
	right: 8.33333%
}

.col-xs-pull-2 {
	right: 16.66667%
}

.col-xs-pull-3 {
	right: 25%
}

.col-xs-pull-4 {
	right: 33.33333%
}

.col-xs-pull-5 {
	right: 41.66667%
}

.col-xs-pull-6 {
	right: 50%
}

.col-xs-pull-7 {
	right: 58.33333%
}

.col-xs-pull-8 {
	right: 66.66667%
}

.col-xs-pull-9 {
	right: 75%
}

.col-xs-pull-10 {
	right: 83.33333%
}

.col-xs-pull-11 {
	right: 91.66667%
}

.col-xs-pull-12 {
	right: 100%
}

.col-xs-push-0 {
	left: auto
}

.col-xs-push-1 {
	left: 8.33333%
}

.col-xs-push-2 {
	left: 16.66667%
}

.col-xs-push-3 {
	left: 25%
}

.col-xs-push-4 {
	left: 33.33333%
}

.col-xs-push-5 {
	left: 41.66667%
}

.col-xs-push-6 {
	left: 50%
}

.col-xs-push-7 {
	left: 58.33333%
}

.col-xs-push-8 {
	left: 66.66667%
}

.col-xs-push-9 {
	left: 75%
}

.col-xs-push-10 {
	left: 83.33333%
}

.col-xs-push-11 {
	left: 91.66667%
}

.col-xs-push-12 {
	left: 100%
}

.col-xs-offset-0 {
	margin-left: 0%
}

.col-xs-offset-1 {
	margin-left: 8.33333%
}

.col-xs-offset-2 {
	margin-left: 16.66667%
}

.col-xs-offset-3 {
	margin-left: 25%
}

.col-xs-offset-4 {
	margin-left: 33.33333%
}

.col-xs-offset-5 {
	margin-left: 41.66667%
}

.col-xs-offset-6 {
	margin-left: 50%
}

.col-xs-offset-7 {
	margin-left: 58.33333%
}

.col-xs-offset-8 {
	margin-left: 66.66667%
}

.col-xs-offset-9 {
	margin-left: 75%
}

.col-xs-offset-10 {
	margin-left: 83.33333%
}

.col-xs-offset-11 {
	margin-left: 91.66667%
}

.col-xs-offset-12 {
	margin-left: 100%
}

@media (min-width:768px) {
	.col-sm-1,
	.col-sm-2,
	.col-sm-3,
	.col-sm-4,
	.col-sm-5,
	.col-sm-6,
	.col-sm-7,
	.col-sm-8,
	.col-sm-9,
	.col-sm-10,
	.col-sm-11,
	.col-sm-12 {
		float: left
	}
	.col-sm-1 {
		width: 8.33333%
	}
	.col-sm-2 {
		width: 16.66667%
	}
	.col-sm-3 {
		width: 25%
	}
	.col-sm-4 {
		width: 33.33333%
	}
	.col-sm-5 {
		width: 41.66667%
	}
	.col-sm-6 {
		width: 50%
	}
	.col-sm-7 {
		width: 58.33333%
	}
	.col-sm-8 {
		width: 66.66667%
	}
	.col-sm-9 {
		width: 75%
	}
	.col-sm-10 {
		width: 83.33333%
	}
	.col-sm-11 {
		width: 91.66667%
	}
	.col-sm-12 {
		width: 100%
	}
	.col-sm-pull-0 {
		right: auto
	}
	.col-sm-pull-1 {
		right: 8.33333%
	}
	.col-sm-pull-2 {
		right: 16.66667%
	}
	.col-sm-pull-3 {
		right: 25%
	}
	.col-sm-pull-4 {
		right: 33.33333%
	}
	.col-sm-pull-5 {
		right: 41.66667%
	}
	.col-sm-pull-6 {
		right: 50%
	}
	.col-sm-pull-7 {
		right: 58.33333%
	}
	.col-sm-pull-8 {
		right: 66.66667%
	}
	.col-sm-pull-9 {
		right: 75%
	}
	.col-sm-pull-10 {
		right: 83.33333%
	}
	.col-sm-pull-11 {
		right: 91.66667%
	}
	.col-sm-pull-12 {
		right: 100%
	}
	.col-sm-push-0 {
		left: auto
	}
	.col-sm-push-1 {
		left: 8.33333%
	}
	.col-sm-push-2 {
		left: 16.66667%
	}
	.col-sm-push-3 {
		left: 25%
	}
	.col-sm-push-4 {
		left: 33.33333%
	}
	.col-sm-push-5 {
		left: 41.66667%
	}
	.col-sm-push-6 {
		left: 50%
	}
	.col-sm-push-7 {
		left: 58.33333%
	}
	.col-sm-push-8 {
		left: 66.66667%
	}
	.col-sm-push-9 {
		left: 75%
	}
	.col-sm-push-10 {
		left: 83.33333%
	}
	.col-sm-push-11 {
		left: 91.66667%
	}
	.col-sm-push-12 {
		left: 100%
	}
	.col-sm-offset-0 {
		margin-left: 0%
	}
	.col-sm-offset-1 {
		margin-left: 8.33333%
	}
	.col-sm-offset-2 {
		margin-left: 16.66667%
	}
	.col-sm-offset-3 {
		margin-left: 25%
	}
	.col-sm-offset-4 {
		margin-left: 33.33333%
	}
	.col-sm-offset-5 {
		margin-left: 41.66667%
	}
	.col-sm-offset-6 {
		margin-left: 50%
	}
	.col-sm-offset-7 {
		margin-left: 58.33333%
	}
	.col-sm-offset-8 {
		margin-left: 66.66667%
	}
	.col-sm-offset-9 {
		margin-left: 75%
	}
	.col-sm-offset-10 {
		margin-left: 83.33333%
	}
	.col-sm-offset-11 {
		margin-left: 91.66667%
	}
	.col-sm-offset-12 {
		margin-left: 100%
	}
}

@media (min-width:992px) {
	.col-md-1,
	.col-md-2,
	.col-md-3,
	.col-md-4,
	.col-md-5,
	.col-md-6,
	.col-md-7,
	.col-md-8,
	.col-md-9,
	.col-md-10,
	.col-md-11,
	.col-md-12 {
		float: left
	}
	.col-md-1 {
		width: 8.33333%
	}
	.col-md-2 {
		width: 16.66667%
	}
	.col-md-3 {
		width: 25%
	}
	.col-md-4 {
		width: 33.33333%
	}
	.col-md-5 {
		width: 41.66667%
	}
	.col-md-6 {
		width: 50%
	}
	.col-md-7 {
		width: 58.33333%
	}
	.col-md-8 {
		width: 66.66667%
	}
	.col-md-9 {
		width: 75%
	}
	.col-md-10 {
		width: 83.33333%
	}
	.col-md-11 {
		width: 91.66667%
	}
	.col-md-12 {
		width: 100%
	}
	.col-md-pull-0 {
		right: auto
	}
	.col-md-pull-1 {
		right: 8.33333%
	}
	.col-md-pull-2 {
		right: 16.66667%
	}
	.col-md-pull-3 {
		right: 25%
	}
	.col-md-pull-4 {
		right: 33.33333%
	}
	.col-md-pull-5 {
		right: 41.66667%
	}
	.col-md-pull-6 {
		right: 50%
	}
	.col-md-pull-7 {
		right: 58.33333%
	}
	.col-md-pull-8 {
		right: 66.66667%
	}
	.col-md-pull-9 {
		right: 75%
	}
	.col-md-pull-10 {
		right: 83.33333%
	}
	.col-md-pull-11 {
		right: 91.66667%
	}
	.col-md-pull-12 {
		right: 100%
	}
	.col-md-push-0 {
		left: auto
	}
	.col-md-push-1 {
		left: 8.33333%
	}
	.col-md-push-2 {
		left: 16.66667%
	}
	.col-md-push-3 {
		left: 25%
	}
	.col-md-push-4 {
		left: 33.33333%
	}
	.col-md-push-5 {
		left: 41.66667%
	}
	.col-md-push-6 {
		left: 50%
	}
	.col-md-push-7 {
		left: 58.33333%
	}
	.col-md-push-8 {
		left: 66.66667%
	}
	.col-md-push-9 {
		left: 75%
	}
	.col-md-push-10 {
		left: 83.33333%
	}
	.col-md-push-11 {
		left: 91.66667%
	}
	.col-md-push-12 {
		left: 100%
	}
	.col-md-offset-0 {
		margin-left: 0%
	}
	.col-md-offset-1 {
		margin-left: 8.33333%
	}
	.col-md-offset-2 {
		margin-left: 16.66667%
	}
	.col-md-offset-3 {
		margin-left: 25%
	}
	.col-md-offset-4 {
		margin-left: 33.33333%
	}
	.col-md-offset-5 {
		margin-left: 41.66667%
	}
	.col-md-offset-6 {
		margin-left: 50%
	}
	.col-md-offset-7 {
		margin-left: 58.33333%
	}
	.col-md-offset-8 {
		margin-left: 66.66667%
	}
	.col-md-offset-9 {
		margin-left: 75%
	}
	.col-md-offset-10 {
		margin-left: 83.33333%
	}
	.col-md-offset-11 {
		margin-left: 91.66667%
	}
	.col-md-offset-12 {
		margin-left: 100%
	}
}

@media (min-width:1200px) {
	.col-lg-1,
	.col-lg-2,
	.col-lg-3,
	.col-lg-4,
	.col-lg-5,
	.col-lg-6,
	.col-lg-7,
	.col-lg-8,
	.col-lg-9,
	.col-lg-10,
	.col-lg-11,
	.col-lg-12 {
		float: left
	}
	.col-lg-1 {
		width: 8.33333%
	}
	.col-lg-2 {
		width: 16.66667%
	}
	.col-lg-3 {
		width: 25%
	}
	.col-lg-4 {
		width: 33.33333%
	}
	.col-lg-5 {
		width: 41.66667%
	}
	.col-lg-6 {
		width: 50%
	}
	.col-lg-7 {
		width: 58.33333%
	}
	.col-lg-8 {
		width: 66.66667%
	}
	.col-lg-9 {
		width: 75%
	}
	.col-lg-10 {
		width: 83.33333%
	}
	.col-lg-11 {
		width: 91.66667%
	}
	.col-lg-12 {
		width: 100%
	}
	.col-lg-pull-0 {
		right: auto
	}
	.col-lg-pull-1 {
		right: 8.33333%
	}
	.col-lg-pull-2 {
		right: 16.66667%
	}
	.col-lg-pull-3 {
		right: 25%
	}
	.col-lg-pull-4 {
		right: 33.33333%
	}
	.col-lg-pull-5 {
		right: 41.66667%
	}
	.col-lg-pull-6 {
		right: 50%
	}
	.col-lg-pull-7 {
		right: 58.33333%
	}
	.col-lg-pull-8 {
		right: 66.66667%
	}
	.col-lg-pull-9 {
		right: 75%
	}
	.col-lg-pull-10 {
		right: 83.33333%
	}
	.col-lg-pull-11 {
		right: 91.66667%
	}
	.col-lg-pull-12 {
		right: 100%
	}
	.col-lg-push-0 {
		left: auto
	}
	.col-lg-push-1 {
		left: 8.33333%
	}
	.col-lg-push-2 {
		left: 16.66667%
	}
	.col-lg-push-3 {
		left: 25%
	}
	.col-lg-push-4 {
		left: 33.33333%
	}
	.col-lg-push-5 {
		left: 41.66667%
	}
	.col-lg-push-6 {
		left: 50%
	}
	.col-lg-push-7 {
		left: 58.33333%
	}
	.col-lg-push-8 {
		left: 66.66667%
	}
	.col-lg-push-9 {
		left: 75%
	}
	.col-lg-push-10 {
		left: 83.33333%
	}
	.col-lg-push-11 {
		left: 91.66667%
	}
	.col-lg-push-12 {
		left: 100%
	}
	.col-lg-offset-0 {
		margin-left: 0%
	}
	.col-lg-offset-1 {
		margin-left: 8.33333%
	}
	.col-lg-offset-2 {
		margin-left: 16.66667%
	}
	.col-lg-offset-3 {
		margin-left: 25%
	}
	.col-lg-offset-4 {
		margin-left: 33.33333%
	}
	.col-lg-offset-5 {
		margin-left: 41.66667%
	}
	.col-lg-offset-6 {
		margin-left: 50%
	}
	.col-lg-offset-7 {
		margin-left: 58.33333%
	}
	.col-lg-offset-8 {
		margin-left: 66.66667%
	}
	.col-lg-offset-9 {
		margin-left: 75%
	}
	.col-lg-offset-10 {
		margin-left: 83.33333%
	}
	.col-lg-offset-11 {
		margin-left: 91.66667%
	}
	.col-lg-offset-12 {
		margin-left: 100%
	}
}

table {
	background-color: transparent
}

caption {
	padding-top: 8px;
	padding-bottom: 8px;
	color: #777;
	text-align: left
}

th {
	text-align: left
}

.table {
	width: 100%;
	max-width: 100%;
	margin-bottom: 22px
}

.table>thead>tr>th,
.table>thead>tr>td,
.table>tbody>tr>th,
.table>tbody>tr>td,
.table>tfoot>tr>th,
.table>tfoot>tr>td {
	padding: 8px;
	line-height: 1.42857;
	vertical-align: top;
	border-top: 1px solid #ddd
}

.table>thead>tr>th {
	vertical-align: bottom;
	border-bottom: 2px solid #ddd
}

.table>caption+thead>tr:first-child>th,
.table>caption+thead>tr:first-child>td,
.table>colgroup+thead>tr:first-child>th,
.table>colgroup+thead>tr:first-child>td,
.table>thead:first-child>tr:first-child>th,
.table>thead:first-child>tr:first-child>td {
	border-top: 0
}

.table>tbody+tbody {
	border-top: 2px solid #ddd
}

.table .table {
	background-color: #F1F1F1
}

.table-condensed>thead>tr>th,
.table-condensed>thead>tr>td,
.table-condensed>tbody>tr>th,
.table-condensed>tbody>tr>td,
.table-condensed>tfoot>tr>th,
.table-condensed>tfoot>tr>td {
	padding: 5px
}

.table-bordered {
	border: 1px solid #ddd
}

.table-bordered>thead>tr>th,
.table-bordered>thead>tr>td,
.table-bordered>tbody>tr>th,
.table-bordered>tbody>tr>td,
.table-bordered>tfoot>tr>th,
.table-bordered>tfoot>tr>td {
	border: 1px solid #ddd
}

.table-bordered>thead>tr>th,
.table-bordered>thead>tr>td {
	border-bottom-width: 2px
}

.table-striped>tbody>tr:nth-of-type(odd) {
	background-color: #f9f9f9
}

.table-hover>tbody>tr:hover {
	background-color: #f5f5f5
}

table col[class*="col-"] {
	position: static;
	float: none;
	display: table-column
}

table td[class*="col-"],
table th[class*="col-"] {
	position: static;
	float: none;
	display: table-cell
}

.table>thead>tr>td.active,
.table>thead>tr>th.active,
.table>thead>tr.active>td,
.table>thead>tr.active>th,
.table>tbody>tr>td.active,
.table>tbody>tr>th.active,
.table>tbody>tr.active>td,
.table>tbody>tr.active>th,
.table>tfoot>tr>td.active,
.table>tfoot>tr>th.active,
.table>tfoot>tr.active>td,
.table>tfoot>tr.active>th {
	background-color: #f5f5f5
}

.table-hover>tbody>tr>td.active:hover,
.table-hover>tbody>tr>th.active:hover,
.table-hover>tbody>tr.active:hover>td,
.table-hover>tbody>tr:hover>.active,
.table-hover>tbody>tr.active:hover>th {
	background-color: #e8e8e8
}

.table>thead>tr>td.success,
.table>thead>tr>th.success,
.table>thead>tr.success>td,
.table>thead>tr.success>th,
.table>tbody>tr>td.success,
.table>tbody>tr>th.success,
.table>tbody>tr.success>td,
.table>tbody>tr.success>th,
.table>tfoot>tr>td.success,
.table>tfoot>tr>th.success,
.table>tfoot>tr.success>td,
.table>tfoot>tr.success>th {
	background-color: #dff0d8
}

.table-hover>tbody>tr>td.success:hover,
.table-hover>tbody>tr>th.success:hover,
.table-hover>tbody>tr.success:hover>td,
.table-hover>tbody>tr:hover>.success,
.table-hover>tbody>tr.success:hover>th {
	background-color: #d0e9c6
}

.table>thead>tr>td.info,
.table>thead>tr>th.info,
.table>thead>tr.info>td,
.table>thead>tr.info>th,
.table>tbody>tr>td.info,
.table>tbody>tr>th.info,
.table>tbody>tr.info>td,
.table>tbody>tr.info>th,
.table>tfoot>tr>td.info,
.table>tfoot>tr>th.info,
.table>tfoot>tr.info>td,
.table>tfoot>tr.info>th {
	background-color: #d9edf7
}

.table-hover>tbody>tr>td.info:hover,
.table-hover>tbody>tr>th.info:hover,
.table-hover>tbody>tr.info:hover>td,
.table-hover>tbody>tr:hover>.info,
.table-hover>tbody>tr.info:hover>th {
	background-color: #c4e3f3
}

.table>thead>tr>td.warning,
.table>thead>tr>th.warning,
.table>thead>tr.warning>td,
.table>thead>tr.warning>th,
.table>tbody>tr>td.warning,
.table>tbody>tr>th.warning,
.table>tbody>tr.warning>td,
.table>tbody>tr.warning>th,
.table>tfoot>tr>td.warning,
.table>tfoot>tr>th.warning,
.table>tfoot>tr.warning>td,
.table>tfoot>tr.warning>th {
	background-color: #fcf8e3
}

.table-hover>tbody>tr>td.warning:hover,
.table-hover>tbody>tr>th.warning:hover,
.table-hover>tbody>tr.warning:hover>td,
.table-hover>tbody>tr:hover>.warning,
.table-hover>tbody>tr.warning:hover>th {
	background-color: #faf2cc
}

.table>thead>tr>td.danger,
.table>thead>tr>th.danger,
.table>thead>tr.danger>td,
.table>thead>tr.danger>th,
.table>tbody>tr>td.danger,
.table>tbody>tr>th.danger,
.table>tbody>tr.danger>td,
.table>tbody>tr.danger>th,
.table>tfoot>tr>td.danger,
.table>tfoot>tr>th.danger,
.table>tfoot>tr.danger>td,
.table>tfoot>tr.danger>th {
	background-color: #f2dede
}

.table-hover>tbody>tr>td.danger:hover,
.table-hover>tbody>tr>th.danger:hover,
.table-hover>tbody>tr.danger:hover>td,
.table-hover>tbody>tr:hover>.danger,
.table-hover>tbody>tr.danger:hover>th {
	background-color: #ebcccc
}

.table-responsive {
	overflow-x: auto;
	min-height: 0.01%
}

@media screen and (max-width:767px) {
	.table-responsive {
		width: 100%;
		margin-bottom: 16.5px;
		overflow-y: hidden;
		-ms-overflow-style: -ms-autohiding-scrollbar;
		border: 1px solid #ddd
	}
	.table-responsive>.table {
		margin-bottom: 0
	}
	.table-responsive>.table>thead>tr>th,
	.table-responsive>.table>thead>tr>td,
	.table-responsive>.table>tbody>tr>th,
	.table-responsive>.table>tbody>tr>td,
	.table-responsive>.table>tfoot>tr>th,
	.table-responsive>.table>tfoot>tr>td {
		white-space: nowrap
	}
	.table-responsive>.table-bordered {
		border: 0
	}
	.table-responsive>.table-bordered>thead>tr>th:first-child,
	.table-responsive>.table-bordered>thead>tr>td:first-child,
	.table-responsive>.table-bordered>tbody>tr>th:first-child,
	.table-responsive>.table-bordered>tbody>tr>td:first-child,
	.table-responsive>.table-bordered>tfoot>tr>th:first-child,
	.table-responsive>.table-bordered>tfoot>tr>td:first-child {
		border-left: 0
	}
	.table-responsive>.table-bordered>thead>tr>th:last-child,
	.table-responsive>.table-bordered>thead>tr>td:last-child,
	.table-responsive>.table-bordered>tbody>tr>th:last-child,
	.table-responsive>.table-bordered>tbody>tr>td:last-child,
	.table-responsive>.table-bordered>tfoot>tr>th:last-child,
	.table-responsive>.table-bordered>tfoot>tr>td:last-child {
		border-right: 0
	}
	.table-responsive>.table-bordered>tbody>tr:last-child>th,
	.table-responsive>.table-bordered>tbody>tr:last-child>td,
	.table-responsive>.table-bordered>tfoot>tr:last-child>th,
	.table-responsive>.table-bordered>tfoot>tr:last-child>td {
		border-bottom: 0
	}
}

fieldset {
	padding: 0;
	margin: 0;
	border: 0;
	min-width: 0
}

legend {
	display: block;
	width: 100%;
	padding: 0;
	margin-bottom: 22px;
	font-size: 24px;
	line-height: inherit;
	color: #333;
	border: 0;
	border-bottom: 1px solid #e5e5e5
}

label {
	display: inline-block;
	max-width: 100%;
	margin-bottom: 5px;
	font-weight: bold
}

input[type="search"] {
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box
}

input[type="radio"],
input[type="checkbox"] {
	margin: 4px 0 0;
	margin-top: 1px \9;
	line-height: normal
}

input[type="file"] {
	display: block
}

input[type="range"] {
	display: block;
	width: 100%
}

select[multiple],
select[size] {
	height: auto
}

input[type="file"]:focus,
input[type="radio"]:focus,
input[type="checkbox"]:focus {
	outline: 5px auto -webkit-focus-ring-color;
	outline-offset: -2px
}

output {
	display: block;
	padding-top: 7px;
	font-size: 16px;
	line-height: 1.42857;
	color: #555
}

.form-control {
	display: block;
	width: 100%;
	height: 36px;
	padding: 6px 12px;
	font-size: 16px;
	line-height: 1.42857;
	color: #555;
	background-color: #fff;
	background-image: none;
	border: 1px solid #ccc;
	border-radius: 4px;
	-webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
	box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
	-webkit-transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
	-o-transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
	transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s
}

.form-control:focus {
	border-color: #66afe9;
	outline: 0;
	-webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(102, 175, 233, 0.6);
	box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(102, 175, 233, 0.6)
}

.form-control::-moz-placeholder {
	color: #999;
	opacity: 1
}

.form-control:-ms-input-placeholder {
	color: #999
}

.form-control::-webkit-input-placeholder {
	color: #999
}

.form-control::-ms-expand {
	border: 0;
	background-color: transparent
}

.form-control[disabled],
.form-control[readonly],
fieldset[disabled] .form-control {
	background-color: #eee;
	opacity: 1
}

.form-control[disabled],
fieldset[disabled] .form-control {
	cursor: not-allowed
}

textarea.form-control {
	height: auto
}

input[type="search"] {
	-webkit-appearance: none
}

@media screen and (-webkit-min-device-pixel-ratio:0) {
	input[type="date"].form-control,
	input[type="time"].form-control,
	input[type="datetime-local"].form-control,
	input[type="month"].form-control {
		line-height: 36px
	}
	input[type="date"].input-sm,
	.input-group-sm>input[type="date"].form-control,
	.input-group-sm>input[type="date"].input-group-addon,
	.input-group-sm>.input-group-btn>input[type="date"].btn,
	.input-group-sm input[type="date"],
	input[type="time"].input-sm,
	.input-group-sm>input[type="time"].form-control,
	.input-group-sm>input[type="time"].input-group-addon,
	.input-group-sm>.input-group-btn>input[type="time"].btn,
	.input-group-sm input[type="time"],
	input[type="datetime-local"].input-sm,
	.input-group-sm>input[type="datetime-local"].form-control,
	.input-group-sm>input[type="datetime-local"].input-group-addon,
	.input-group-sm>.input-group-btn>input[type="datetime-local"].btn,
	.input-group-sm input[type="datetime-local"],
	input[type="month"].input-sm,
	.input-group-sm>input[type="month"].form-control,
	.input-group-sm>input[type="month"].input-group-addon,
	.input-group-sm>.input-group-btn>input[type="month"].btn,
	.input-group-sm input[type="month"] {
		line-height: 33px
	}
	input[type="date"].input-lg,
	.input-group-lg>input[type="date"].form-control,
	.input-group-lg>input[type="date"].input-group-addon,
	.input-group-lg>.input-group-btn>input[type="date"].btn,
	.input-group-lg input[type="date"],
	input[type="time"].input-lg,
	.input-group-lg>input[type="time"].form-control,
	.input-group-lg>input[type="time"].input-group-addon,
	.input-group-lg>.input-group-btn>input[type="time"].btn,
	.input-group-lg input[type="time"],
	input[type="datetime-local"].input-lg,
	.input-group-lg>input[type="datetime-local"].form-control,
	.input-group-lg>input[type="datetime-local"].input-group-addon,
	.input-group-lg>.input-group-btn>input[type="datetime-local"].btn,
	.input-group-lg input[type="datetime-local"],
	input[type="month"].input-lg,
	.input-group-lg>input[type="month"].form-control,
	.input-group-lg>input[type="month"].input-group-addon,
	.input-group-lg>.input-group-btn>input[type="month"].btn,
	.input-group-lg input[type="month"] {
		line-height: 49px
	}
}

.form-group {
	margin-bottom: 15px
}

.radio,
.checkbox {
	position: relative;
	display: block;
	margin-top: 10px;
	margin-bottom: 10px
}

.radio label,
.checkbox label {
	min-height: 22px;
	padding-left: 20px;
	margin-bottom: 0;
	font-weight: normal;
	cursor: pointer
}

.radio input[type="radio"],
.radio-inline input[type="radio"],
.checkbox input[type="checkbox"],
.checkbox-inline input[type="checkbox"] {
	position: absolute;
	margin-left: -20px;
	margin-top: 4px \9
}

.radio+.radio,
.checkbox+.checkbox {
	margin-top: -5px
}

.radio-inline,
.checkbox-inline {
	position: relative;
	display: inline-block;
	padding-left: 20px;
	margin-bottom: 0;
	vertical-align: middle;
	font-weight: normal;
	cursor: pointer
}

.radio-inline+.radio-inline,
.checkbox-inline+.checkbox-inline {
	margin-top: 0;
	margin-left: 10px
}

input[type="radio"][disabled],
input[type="radio"].disabled,
fieldset[disabled] input[type="radio"],
input[type="checkbox"][disabled],
input[type="checkbox"].disabled,
fieldset[disabled] input[type="checkbox"] {
	cursor: not-allowed
}

.radio-inline.disabled,
fieldset[disabled] .radio-inline,
.checkbox-inline.disabled,
fieldset[disabled] .checkbox-inline {
	cursor: not-allowed
}

.radio.disabled label,
fieldset[disabled] .radio label,
.checkbox.disabled label,
fieldset[disabled] .checkbox label {
	cursor: not-allowed
}

.form-control-static {
	padding-top: 7px;
	padding-bottom: 7px;
	margin-bottom: 0;
	min-height: 38px
}

.form-control-static.input-lg,
.input-group-lg>.form-control-static.form-control,
.input-group-lg>.form-control-static.input-group-addon,
.input-group-lg>.input-group-btn>.form-control-static.btn,
.form-control-static.input-sm,
.input-group-sm>.form-control-static.form-control,
.input-group-sm>.form-control-static.input-group-addon,
.input-group-sm>.input-group-btn>.form-control-static.btn {
	padding-left: 0;
	padding-right: 0
}

.input-sm,
.input-group-sm>.form-control,
.input-group-sm>.input-group-addon,
.input-group-sm>.input-group-btn>.btn {
	height: 33px;
	padding: 5px 10px;
	font-size: 14px;
	line-height: 1.5;
	border-radius: 3px
}

select.input-sm,
.input-group-sm>select.form-control,
.input-group-sm>select.input-group-addon,
.input-group-sm>.input-group-btn>select.btn {
	height: 33px;
	line-height: 33px
}

textarea.input-sm,
.input-group-sm>textarea.form-control,
.input-group-sm>textarea.input-group-addon,
.input-group-sm>.input-group-btn>textarea.btn,
select[multiple].input-sm,
.input-group-sm>select[multiple].form-control,
.input-group-sm>select[multiple].input-group-addon,
.input-group-sm>.input-group-btn>select[multiple].btn {
	height: auto
}

.form-group-sm .form-control {
	height: 33px;
	padding: 5px 10px;
	font-size: 14px;
	line-height: 1.5;
	border-radius: 3px
}

.form-group-sm select.form-control {
	height: 33px;
	line-height: 33px
}

.form-group-sm textarea.form-control,
.form-group-sm select[multiple].form-control {
	height: auto
}

.form-group-sm .form-control-static {
	height: 33px;
	min-height: 36px;
	padding: 6px 10px;
	font-size: 14px;
	line-height: 1.5
}

.input-lg,
.input-group-lg>.form-control,
.input-group-lg>.input-group-addon,
.input-group-lg>.input-group-btn>.btn {
	height: 49px;
	padding: 10px 16px;
	font-size: 20px;
	line-height: 1.33333;
	border-radius: 6px
}

select.input-lg,
.input-group-lg>select.form-control,
.input-group-lg>select.input-group-addon,
.input-group-lg>.input-group-btn>select.btn {
	height: 49px;
	line-height: 49px
}

textarea.input-lg,
.input-group-lg>textarea.form-control,
.input-group-lg>textarea.input-group-addon,
.input-group-lg>.input-group-btn>textarea.btn,
select[multiple].input-lg,
.input-group-lg>select[multiple].form-control,
.input-group-lg>select[multiple].input-group-addon,
.input-group-lg>.input-group-btn>select[multiple].btn {
	height: auto
}

.form-group-lg .form-control {
	height: 49px;
	padding: 10px 16px;
	font-size: 20px;
	line-height: 1.33333;
	border-radius: 6px
}

.form-group-lg select.form-control {
	height: 49px;
	line-height: 49px
}

.form-group-lg textarea.form-control,
.form-group-lg select[multiple].form-control {
	height: auto
}

.form-group-lg .form-control-static {
	height: 49px;
	min-height: 42px;
	padding: 11px 16px;
	font-size: 20px;
	line-height: 1.33333
}

.has-feedback {
	position: relative
}

.has-feedback .form-control {
	padding-right: 45px
}

.form-control-feedback {
	position: absolute;
	top: 0;
	right: 0;
	z-index: 2;
	display: block;
	width: 36px;
	height: 36px;
	line-height: 36px;
	text-align: center;
	pointer-events: none
}

.input-lg+.form-control-feedback,
.input-group-lg>.form-control+.form-control-feedback,
.input-group-lg>.input-group-addon+.form-control-feedback,
.input-group-lg>.input-group-btn>.btn+.form-control-feedback,
.input-group-lg+.form-control-feedback,
.form-group-lg .form-control+.form-control-feedback {
	width: 49px;
	height: 49px;
	line-height: 49px
}

.input-sm+.form-control-feedback,
.input-group-sm>.form-control+.form-control-feedback,
.input-group-sm>.input-group-addon+.form-control-feedback,
.input-group-sm>.input-group-btn>.btn+.form-control-feedback,
.input-group-sm+.form-control-feedback,
.form-group-sm .form-control+.form-control-feedback {
	width: 33px;
	height: 33px;
	line-height: 33px
}

.has-success .help-block,
.has-success .control-label,
.has-success .radio,
.has-success .checkbox,
.has-success .radio-inline,
.has-success .checkbox-inline,
.has-success.radio label,
.has-success.checkbox label,
.has-success.radio-inline label,
.has-success.checkbox-inline label {
	color: #3c763d
}

.has-success .form-control {
	border-color: #3c763d;
	-webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
	box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075)
}

.has-success .form-control:focus {
	border-color: #2b542c;
	-webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 6px #67b168;
	box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 6px #67b168
}

.has-success .input-group-addon {
	color: #3c763d;
	border-color: #3c763d;
	background-color: #dff0d8
}

.has-success .form-control-feedback {
	color: #3c763d
}

.has-warning .help-block,
.has-warning .control-label,
.has-warning .radio,
.has-warning .checkbox,
.has-warning .radio-inline,
.has-warning .checkbox-inline,
.has-warning.radio label,
.has-warning.checkbox label,
.has-warning.radio-inline label,
.has-warning.checkbox-inline label {
	color: #8a6d3b
}

.has-warning .form-control {
	border-color: #8a6d3b;
	-webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
	box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075)
}

.has-warning .form-control:focus {
	border-color: #66512c;
	-webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 6px #c0a16b;
	box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 6px #c0a16b
}

.has-warning .input-group-addon {
	color: #8a6d3b;
	border-color: #8a6d3b;
	background-color: #fcf8e3
}

.has-warning .form-control-feedback {
	color: #8a6d3b
}

.has-error .help-block,
.has-error .control-label,
.has-error .radio,
.has-error .checkbox,
.has-error .radio-inline,
.has-error .checkbox-inline,
.has-error.radio label,
.has-error.checkbox label,
.has-error.radio-inline label,
.has-error.checkbox-inline label {
	color: #a94442
}

.has-error .form-control {
	border-color: #a94442;
	-webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
	box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075)
}

.has-error .form-control:focus {
	border-color: #843534;
	-webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 6px #ce8483;
	box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 6px #ce8483
}

.has-error .input-group-addon {
	color: #a94442;
	border-color: #a94442;
	background-color: #f2dede
}

.has-error .form-control-feedback {
	color: #a94442
}

.has-feedback label~.form-control-feedback {
	top: 27px
}

.has-feedback label.sr-only~.form-control-feedback {
	top: 0
}

.help-block {
	display: block;
	margin-top: 5px;
	margin-bottom: 10px;
	color: #737373
}

@media (min-width:768px) {
	.form-inline .form-group {
		display: inline-block;
		margin-bottom: 0;
		vertical-align: middle
	}
	.form-inline .form-control {
		display: inline-block;
		width: auto;
		vertical-align: middle
	}
	.form-inline .form-control-static {
		display: inline-block
	}
	.form-inline .input-group {
		display: inline-table;
		vertical-align: middle
	}
	.form-inline .input-group .input-group-addon,
	.form-inline .input-group .input-group-btn,
	.form-inline .input-group .form-control {
		width: auto
	}
	.form-inline .input-group>.form-control {
		width: 100%
	}
	.form-inline .control-label {
		margin-bottom: 0;
		vertical-align: middle
	}
	.form-inline .radio,
	.form-inline .checkbox {
		display: inline-block;
		margin-top: 0;
		margin-bottom: 0;
		vertical-align: middle
	}
	.form-inline .radio label,
	.form-inline .checkbox label {
		padding-left: 0
	}
	.form-inline .radio input[type="radio"],
	.form-inline .checkbox input[type="checkbox"] {
		position: relative;
		margin-left: 0
	}
	.form-inline .has-feedback .form-control-feedback {
		top: 0
	}
}

.form-horizontal .radio,
.form-horizontal .checkbox,
.form-horizontal .radio-inline,
.form-horizontal .checkbox-inline {
	margin-top: 0;
	margin-bottom: 0;
	padding-top: 7px
}

.form-horizontal .radio,
.form-horizontal .checkbox {
	min-height: 29px
}

.form-horizontal .form-group {
	margin-left: -5px;
	margin-right: -5px
}

.form-horizontal .form-group:before,
.form-horizontal .form-group:after {
	content: " ";
	display: table
}

.form-horizontal .form-group:after {
	clear: both
}

@media (min-width:768px) {
	.form-horizontal .control-label {
		text-align: right;
		margin-bottom: 0;
		padding-top: 7px
	}
}

.form-horizontal .has-feedback .form-control-feedback {
	right: 5px
}

@media (min-width:768px) {
	.form-horizontal .form-group-lg .control-label {
		padding-top: 11px;
		font-size: 20px
	}
}

@media (min-width:768px) {
	.form-horizontal .form-group-sm .control-label {
		padding-top: 6px;
		font-size: 14px
	}
}

.btn {
	display: inline-block;
	margin-bottom: 0;
	font-weight: normal;
	text-align: center;
	vertical-align: middle;
	touch-action: manipulation;
	cursor: pointer;
	background-image: none;
	border: 1px solid transparent;
	white-space: nowrap;
	padding: 6px 12px;
	font-size: 16px;
	line-height: 1.42857;
	border-radius: 4px;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none
}

.btn:focus,
.btn.focus,
.btn:active:focus,
.btn:active.focus,
.btn.active:focus,
.btn.active.focus {
	outline: 5px auto -webkit-focus-ring-color;
	outline-offset: -2px
}

.btn:hover,
.btn:focus,
.btn.focus {
	color: #333;
	text-decoration: none
}

.btn:active,
.btn.active {
	outline: 0;
	background-image: none;
	-webkit-box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
	box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125)
}

.btn.disabled,
.btn[disabled],
fieldset[disabled] .btn {
	cursor: not-allowed;
	opacity: .65;
	filter: alpha(opacity=65);
	-webkit-box-shadow: none;
	box-shadow: none
}

a.btn.disabled,
fieldset[disabled] a.btn {
	pointer-events: none
}

.btn-default {
	color: #333;
	background-color: #fff;
	border-color: #ccc
}

.btn-default:focus,
.btn-default.focus {
	color: #333;
	background-color: #e6e6e6;
	border-color: #8c8c8c
}

.btn-default:hover {
	color: #333;
	background-color: #e6e6e6;
	border-color: #adadad
}

.btn-default:active,
.btn-default.active,
.open>.btn-default.dropdown-toggle {
	color: #333;
	background-color: #e6e6e6;
	border-color: #adadad
}

.btn-default:active:hover,
.btn-default:active:focus,
.btn-default:active.focus,
.btn-default.active:hover,
.btn-default.active:focus,
.btn-default.active.focus,
.open>.btn-default.dropdown-toggle:hover,
.open>.btn-default.dropdown-toggle:focus,
.open>.btn-default.dropdown-toggle.focus {
	color: #333;
	background-color: #d4d4d4;
	border-color: #8c8c8c
}

.btn-default:active,
.btn-default.active,
.open>.btn-default.dropdown-toggle {
	background-image: none
}

.btn-default.disabled:hover,
.btn-default.disabled:focus,
.btn-default.disabled.focus,
.btn-default[disabled]:hover,
.btn-default[disabled]:focus,
.btn-default[disabled].focus,
fieldset[disabled] .btn-default:hover,
fieldset[disabled] .btn-default:focus,
fieldset[disabled] .btn-default.focus {
	background-color: #fff;
	border-color: #ccc
}

.btn-default .badge {
	color: #fff;
	background-color: #333
}

.btn-primary {
	color: #fff;
	background-color: #337ab7;
	border-color: #2e6da4
}

.btn-primary:focus,
.btn-primary.focus {
	color: #fff;
	background-color: #286090;
	border-color: #122b40
}

.btn-primary:hover {
	color: #fff;
	background-color: #286090;
	border-color: #204d74
}

.btn-primary:active,
.btn-primary.active,
.open>.btn-primary.dropdown-toggle {
	color: #fff;
	background-color: #286090;
	border-color: #204d74
}

.btn-primary:active:hover,
.btn-primary:active:focus,
.btn-primary:active.focus,
.btn-primary.active:hover,
.btn-primary.active:focus,
.btn-primary.active.focus,
.open>.btn-primary.dropdown-toggle:hover,
.open>.btn-primary.dropdown-toggle:focus,
.open>.btn-primary.dropdown-toggle.focus {
	color: #fff;
	background-color: #204d74;
	border-color: #122b40
}

.btn-primary:active,
.btn-primary.active,
.open>.btn-primary.dropdown-toggle {
	background-image: none
}

.btn-primary.disabled:hover,
.btn-primary.disabled:focus,
.btn-primary.disabled.focus,
.btn-primary[disabled]:hover,
.btn-primary[disabled]:focus,
.btn-primary[disabled].focus,
fieldset[disabled] .btn-primary:hover,
fieldset[disabled] .btn-primary:focus,
fieldset[disabled] .btn-primary.focus {
	background-color: #337ab7;
	border-color: #2e6da4
}

.btn-primary .badge {
	color: #337ab7;
	background-color: #fff
}

.btn-success {
	color: #fff;
	background-color: #3a853a;
	border-color: #327332
}

.btn-success:focus,
.btn-success.focus {
	color: #fff;
	background-color: #2b612b;
	border-color: #0c1a0c
}

.btn-success:hover {
	color: #fff;
	background-color: #2b612b;
	border-color: #204920
}

.btn-success:active,
.btn-success.active,
.open>.btn-success.dropdown-toggle {
	color: #fff;
	background-color: #2b612b;
	border-color: #204920
}

.btn-success:active:hover,
.btn-success:active:focus,
.btn-success:active.focus,
.btn-success.active:hover,
.btn-success.active:focus,
.btn-success.active.focus,
.open>.btn-success.dropdown-toggle:hover,
.open>.btn-success.dropdown-toggle:focus,
.open>.btn-success.dropdown-toggle.focus {
	color: #fff;
	background-color: #204920;
	border-color: #0c1a0c
}

.btn-success:active,
.btn-success.active,
.open>.btn-success.dropdown-toggle {
	background-image: none
}

.btn-success.disabled:hover,
.btn-success.disabled:focus,
.btn-success.disabled.focus,
.btn-success[disabled]:hover,
.btn-success[disabled]:focus,
.btn-success[disabled].focus,
fieldset[disabled] .btn-success:hover,
fieldset[disabled] .btn-success:focus,
fieldset[disabled] .btn-success.focus {
	background-color: #3a853a;
	border-color: #327332
}

.btn-success .badge {
	color: #3a853a;
	background-color: #fff
}

.btn-info {
	color: #fff;
	background-color: #267998;
	border-color: #216984
}

.btn-info:focus,
.btn-info.focus {
	color: #fff;
	background-color: #1c596f;
	border-color: #07181e
}

.btn-info:hover {
	color: #fff;
	background-color: #1c596f;
	border-color: #154253
}

.btn-info:active,
.btn-info.active,
.open>.btn-info.dropdown-toggle {
	color: #fff;
	background-color: #1c596f;
	border-color: #154253
}

.btn-info:active:hover,
.btn-info:active:focus,
.btn-info:active.focus,
.btn-info.active:hover,
.btn-info.active:focus,
.btn-info.active.focus,
.open>.btn-info.dropdown-toggle:hover,
.open>.btn-info.dropdown-toggle:focus,
.open>.btn-info.dropdown-toggle.focus {
	color: #fff;
	background-color: #154253;
	border-color: #07181e
}

.btn-info:active,
.btn-info.active,
.open>.btn-info.dropdown-toggle {
	background-image: none
}

.btn-info.disabled:hover,
.btn-info.disabled:focus,
.btn-info.disabled.focus,
.btn-info[disabled]:hover,
.btn-info[disabled]:focus,
.btn-info[disabled].focus,
fieldset[disabled] .btn-info:hover,
fieldset[disabled] .btn-info:focus,
fieldset[disabled] .btn-info.focus {
	background-color: #267998;
	border-color: #216984
}

.btn-info .badge {
	color: #267998;
	background-color: #fff
}

.btn-warning {
	color: #fff;
	background-color: #f0ad4e;
	border-color: #eea236
}

.btn-warning:focus,
.btn-warning.focus {
	color: #fff;
	background-color: #ec971f;
	border-color: #985f0d
}

.btn-warning:hover {
	color: #fff;
	background-color: #ec971f;
	border-color: #d58512
}

.btn-warning:active,
.btn-warning.active,
.open>.btn-warning.dropdown-toggle {
	color: #fff;
	background-color: #ec971f;
	border-color: #d58512
}

.btn-warning:active:hover,
.btn-warning:active:focus,
.btn-warning:active.focus,
.btn-warning.active:hover,
.btn-warning.active:focus,
.btn-warning.active.focus,
.open>.btn-warning.dropdown-toggle:hover,
.open>.btn-warning.dropdown-toggle:focus,
.open>.btn-warning.dropdown-toggle.focus {
	color: #fff;
	background-color: #d58512;
	border-color: #985f0d
}

.btn-warning:active,
.btn-warning.active,
.open>.btn-warning.dropdown-toggle {
	background-image: none
}

.btn-warning.disabled:hover,
.btn-warning.disabled:focus,
.btn-warning.disabled.focus,
.btn-warning[disabled]:hover,
.btn-warning[disabled]:focus,
.btn-warning[disabled].focus,
fieldset[disabled] .btn-warning:hover,
fieldset[disabled] .btn-warning:focus,
fieldset[disabled] .btn-warning.focus {
	background-color: #f0ad4e;
	border-color: #eea236
}

.btn-warning .badge {
	color: #f0ad4e;
	background-color: #fff
}

.btn-danger {
	color: #fff;
	background-color: #d9534f;
	border-color: #d43f3a
}

.btn-danger:focus,
.btn-danger.focus {
	color: #fff;
	background-color: #c9302c;
	border-color: #761c19
}

.btn-danger:hover {
	color: #fff;
	background-color: #c9302c;
	border-color: #ac2925
}

.btn-danger:active,
.btn-danger.active,
.open>.btn-danger.dropdown-toggle {
	color: #fff;
	background-color: #c9302c;
	border-color: #ac2925
}

.btn-danger:active:hover,
.btn-danger:active:focus,
.btn-danger:active.focus,
.btn-danger.active:hover,
.btn-danger.active:focus,
.btn-danger.active.focus,
.open>.btn-danger.dropdown-toggle:hover,
.open>.btn-danger.dropdown-toggle:focus,
.open>.btn-danger.dropdown-toggle.focus {
	color: #fff;
	background-color: #ac2925;
	border-color: #761c19
}

.btn-danger:active,
.btn-danger.active,
.open>.btn-danger.dropdown-toggle {
	background-image: none
}

.btn-danger.disabled:hover,
.btn-danger.disabled:focus,
.btn-danger.disabled.focus,
.btn-danger[disabled]:hover,
.btn-danger[disabled]:focus,
.btn-danger[disabled].focus,
fieldset[disabled] .btn-danger:hover,
fieldset[disabled] .btn-danger:focus,
fieldset[disabled] .btn-danger.focus {
	background-color: #d9534f;
	border-color: #d43f3a
}

.btn-danger .badge {
	color: #d9534f;
	background-color: #fff
}

.btn-link {
	color: #267998;
	font-weight: normal;
	border-radius: 0
}

.btn-link,
.btn-link:active,
.btn-link.active,
.btn-link[disabled],
fieldset[disabled] .btn-link {
	background-color: transparent;
	-webkit-box-shadow: none;
	box-shadow: none
}

.btn-link,
.btn-link:hover,
.btn-link:focus,
.btn-link:active {
	border-color: transparent
}

.btn-link:hover,
.btn-link:focus {
	color: #008575;
	text-decoration: underline;
	background-color: transparent
}

.btn-link[disabled]:hover,
.btn-link[disabled]:focus,
fieldset[disabled] .btn-link:hover,
fieldset[disabled] .btn-link:focus {
	color: #777;
	text-decoration: none
}

.btn-lg,
.btn-group-lg>.btn {
	padding: 10px 16px;
	font-size: 20px;
	line-height: 1.33333;
	border-radius: 6px
}

.btn-sm,
.btn-group-sm>.btn {
	padding: 5px 10px;
	font-size: 14px;
	line-height: 1.5;
	border-radius: 3px
}

.btn-xs,
.btn-group-xs>.btn {
	padding: 1px 5px;
	font-size: 14px;
	line-height: 1.5;
	border-radius: 3px
}

.btn-block {
	display: block;
	width: 100%
}

.btn-block+.btn-block {
	margin-top: 5px
}

input[type="submit"].btn-block,
input[type="reset"].btn-block,
input[type="button"].btn-block {
	width: 100%
}

.fade {
	opacity: 0;
	-webkit-transition: opacity 0.15s linear;
	-o-transition: opacity 0.15s linear;
	transition: opacity 0.15s linear
}

.fade.in {
	opacity: 1
}

.collapse {
	display: none
}

.collapse.in {
	display: block
}

tr.collapse.in {
	display: table-row
}

tbody.collapse.in {
	display: table-row-group
}

.collapsing {
	position: relative;
	height: 0;
	overflow: hidden;
	-webkit-transition-property: height, visibility;
	transition-property: height, visibility;
	-webkit-transition-duration: 0.35s;
	transition-duration: 0.35s;
	-webkit-transition-timing-function: ease;
	transition-timing-function: ease
}

.caret {
	display: inline-block;
	width: 0;
	height: 0;
	margin-left: 2px;
	vertical-align: middle;
	border-top: 4px dashed;
	border-top: 4px solid \9;
	border-right: 4px solid transparent;
	border-left: 4px solid transparent
}

.dropup,
.dropdown {
	position: relative
}

.dropdown-toggle:focus {
	outline: 0
}

.dropdown-menu {
	position: absolute;
	top: 100%;
	left: 0;
	z-index: 1000;
	display: none;
	float: left;
	min-width: 160px;
	padding: 5px 0;
	margin: 2px 0 0;
	list-style: none;
	font-size: 16px;
	text-align: left;
	background-color: #fff;
	border: 1px solid #ccc;
	border: 1px solid rgba(0, 0, 0, 0.15);
	border-radius: 4px;
	-webkit-box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
	box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
	background-clip: padding-box
}

.dropdown-menu.pull-right {
	right: 0;
	left: auto
}

.dropdown-menu .divider {
	height: 1px;
	margin: 10px 0;
	overflow: hidden;
	background-color: #e5e5e5
}

.dropdown-menu>li>a {
	display: block;
	padding: 3px 20px;
	clear: both;
	font-weight: normal;
	line-height: 1.42857;
	color: #333;
	white-space: nowrap
}

.dropdown-menu>li>a:hover,
.dropdown-menu>li>a:focus {
	text-decoration: none;
	color: #262626;
	background-color: #f5f5f5
}

.dropdown-menu>.active>a,
.dropdown-menu>.active>a:hover,
.dropdown-menu>.active>a:focus {
	color: #fff;
	text-decoration: none;
	outline: 0;
	background-color: #337ab7
}

.dropdown-menu>.disabled>a,
.dropdown-menu>.disabled>a:hover,
.dropdown-menu>.disabled>a:focus {
	color: #777
}

.dropdown-menu>.disabled>a:hover,
.dropdown-menu>.disabled>a:focus {
	text-decoration: none;
	background-color: transparent;
	background-image: none;
	filter: progid:DXImageTransform.Microsoft.gradient(enabled=false);
	cursor: not-allowed
}

.open>.dropdown-menu {
	display: block
}

.open>a {
	outline: 0
}

.dropdown-menu-right {
	left: auto;
	right: 0
}

.dropdown-menu-left {
	left: 0;
	right: auto
}

.dropdown-header {
	display: block;
	padding: 3px 20px;
	font-size: 14px;
	line-height: 1.42857;
	color: #777;
	white-space: nowrap
}

.dropdown-backdrop {
	position: fixed;
	left: 0;
	right: 0;
	bottom: 0;
	top: 0;
	z-index: 990
}

.pull-right>.dropdown-menu {
	right: 0;
	left: auto
}

.dropup .caret,
.navbar-fixed-bottom .dropdown .caret {
	border-top: 0;
	border-bottom: 4px dashed;
	border-bottom: 4px solid \9;
	content: ""
}

.dropup .dropdown-menu,
.navbar-fixed-bottom .dropdown .dropdown-menu {
	top: auto;
	bottom: 100%;
	margin-bottom: 2px
}

@media (min-width:768px) {
	.navbar-right .dropdown-menu {
		right: 0;
		left: auto
	}
	.navbar-right .dropdown-menu-left {
		left: 0;
		right: auto
	}
}

.btn-group,
.btn-group-vertical {
	position: relative;
	display: inline-block;
	vertical-align: middle
}

.btn-group>.btn,
.btn-group-vertical>.btn {
	position: relative;
	float: left
}

.btn-group>.btn:hover,
.btn-group>.btn:focus,
.btn-group>.btn:active,
.btn-group>.btn.active,
.btn-group-vertical>.btn:hover,
.btn-group-vertical>.btn:focus,
.btn-group-vertical>.btn:active,
.btn-group-vertical>.btn.active {
	z-index: 2
}

.btn-group .btn+.btn,
.btn-group .btn+.btn-group,
.btn-group .btn-group+.btn,
.btn-group .btn-group+.btn-group {
	margin-left: -1px
}

.btn-toolbar {
	margin-left: -5px
}

.btn-toolbar:before,
.btn-toolbar:after {
	content: " ";
	display: table
}

.btn-toolbar:after {
	clear: both
}

.btn-toolbar .btn,
.btn-toolbar .btn-group,
.btn-toolbar .input-group {
	float: left
}

.btn-toolbar>.btn,
.btn-toolbar>.btn-group,
.btn-toolbar>.input-group {
	margin-left: 5px
}

.btn-group>.btn:not(:first-child):not(:last-child):not(.dropdown-toggle) {
	border-radius: 0
}

.btn-group>.btn:first-child {
	margin-left: 0
}

.btn-group>.btn:first-child:not(:last-child):not(.dropdown-toggle) {
	border-bottom-right-radius: 0;
	border-top-right-radius: 0
}

.btn-group>.btn:last-child:not(:first-child),
.btn-group>.dropdown-toggle:not(:first-child) {
	border-bottom-left-radius: 0;
	border-top-left-radius: 0
}

.btn-group>.btn-group {
	float: left
}

.btn-group>.btn-group:not(:first-child):not(:last-child)>.btn {
	border-radius: 0
}

.btn-group>.btn-group:first-child:not(:last-child)>.btn:last-child,
.btn-group>.btn-group:first-child:not(:last-child)>.dropdown-toggle {
	border-bottom-right-radius: 0;
	border-top-right-radius: 0
}

.btn-group>.btn-group:last-child:not(:first-child)>.btn:first-child {
	border-bottom-left-radius: 0;
	border-top-left-radius: 0
}

.btn-group .dropdown-toggle:active,
.btn-group.open .dropdown-toggle {
	outline: 0
}

.btn-group>.btn+.dropdown-toggle {
	padding-left: 8px;
	padding-right: 8px
}

.btn-group>.btn-lg+.dropdown-toggle,
.btn-group-lg.btn-group>.btn+.dropdown-toggle {
	padding-left: 12px;
	padding-right: 12px
}

.btn-group.open .dropdown-toggle {
	-webkit-box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
	box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125)
}

.btn-group.open .dropdown-toggle.btn-link {
	-webkit-box-shadow: none;
	box-shadow: none
}

.btn .caret {
	margin-left: 0
}

.btn-lg .caret,
.btn-group-lg>.btn .caret {
	border-width: 5px 5px 0;
	border-bottom-width: 0
}

.dropup .btn-lg .caret,
.dropup .btn-group-lg>.btn .caret {
	border-width: 0 5px 5px
}

.btn-group-vertical>.btn,
.btn-group-vertical>.btn-group,
.btn-group-vertical>.btn-group>.btn {
	display: block;
	float: none;
	width: 100%;
	max-width: 100%
}

.btn-group-vertical>.btn-group:before,
.btn-group-vertical>.btn-group:after {
	content: " ";
	display: table
}

.btn-group-vertical>.btn-group:after {
	clear: both
}

.btn-group-vertical>.btn-group>.btn {
	float: none
}

.btn-group-vertical>.btn+.btn,
.btn-group-vertical>.btn+.btn-group,
.btn-group-vertical>.btn-group+.btn,
.btn-group-vertical>.btn-group+.btn-group {
	margin-top: -1px;
	margin-left: 0
}

.btn-group-vertical>.btn:not(:first-child):not(:last-child) {
	border-radius: 0
}

.btn-group-vertical>.btn:first-child:not(:last-child) {
	border-top-right-radius: 4px;
	border-top-left-radius: 4px;
	border-bottom-right-radius: 0;
	border-bottom-left-radius: 0
}

.btn-group-vertical>.btn:last-child:not(:first-child) {
	border-top-right-radius: 0;
	border-top-left-radius: 0;
	border-bottom-right-radius: 4px;
	border-bottom-left-radius: 4px
}

.btn-group-vertical>.btn-group:not(:first-child):not(:last-child)>.btn {
	border-radius: 0
}

.btn-group-vertical>.btn-group:first-child:not(:last-child)>.btn:last-child,
.btn-group-vertical>.btn-group:first-child:not(:last-child)>.dropdown-toggle {
	border-bottom-right-radius: 0;
	border-bottom-left-radius: 0
}

.btn-group-vertical>.btn-group:last-child:not(:first-child)>.btn:first-child {
	border-top-right-radius: 0;
	border-top-left-radius: 0
}

.btn-group-justified {
	display: table;
	width: 100%;
	table-layout: fixed;
	border-collapse: separate
}

.btn-group-justified>.btn,
.btn-group-justified>.btn-group {
	float: none;
	display: table-cell;
	width: 1%
}

.btn-group-justified>.btn-group .btn {
	width: 100%
}

.btn-group-justified>.btn-group .dropdown-menu {
	left: auto
}

[data-toggle="buttons"]>.btn input[type="radio"],
[data-toggle="buttons"]>.btn input[type="checkbox"],
[data-toggle="buttons"]>.btn-group>.btn input[type="radio"],
[data-toggle="buttons"]>.btn-group>.btn input[type="checkbox"] {
	position: absolute;
	clip: rect(0, 0, 0, 0);
	pointer-events: none
}

.input-group {
	position: relative;
	display: table;
	border-collapse: separate
}

.input-group[class*="col-"] {
	float: none;
	padding-left: 0;
	padding-right: 0
}

.input-group .form-control {
	position: relative;
	z-index: 2;
	float: left;
	width: 100%;
	margin-bottom: 0
}

.input-group .form-control:focus {
	z-index: 3
}

.input-group-addon,
.input-group-btn,
.input-group .form-control {
	display: table-cell
}

.input-group-addon:not(:first-child):not(:last-child),
.input-group-btn:not(:first-child):not(:last-child),
.input-group .form-control:not(:first-child):not(:last-child) {
	border-radius: 0
}

.input-group-addon,
.input-group-btn {
	width: 1%;
	white-space: nowrap;
	vertical-align: middle
}

.input-group-addon {
	padding: 6px 12px;
	font-size: 16px;
	font-weight: normal;
	line-height: 1;
	color: #555;
	text-align: center;
	background-color: #eee;
	border: 1px solid #ccc;
	border-radius: 4px
}

.input-group-addon.input-sm,
.input-group-sm>.input-group-addon,
.input-group-sm>.input-group-btn>.input-group-addon.btn {
	padding: 5px 10px;
	font-size: 14px;
	border-radius: 3px
}

.input-group-addon.input-lg,
.input-group-lg>.input-group-addon,
.input-group-lg>.input-group-btn>.input-group-addon.btn {
	padding: 10px 16px;
	font-size: 20px;
	border-radius: 6px
}

.input-group-addon input[type="radio"],
.input-group-addon input[type="checkbox"] {
	margin-top: 0
}

.input-group .form-control:first-child,
.input-group-addon:first-child,
.input-group-btn:first-child>.btn,
.input-group-btn:first-child>.btn-group>.btn,
.input-group-btn:first-child>.dropdown-toggle,
.input-group-btn:last-child>.btn:not(:last-child):not(.dropdown-toggle),
.input-group-btn:last-child>.btn-group:not(:last-child)>.btn {
	border-bottom-right-radius: 0;
	border-top-right-radius: 0
}

.input-group-addon:first-child {
	border-right: 0
}

.input-group .form-control:last-child,
.input-group-addon:last-child,
.input-group-btn:last-child>.btn,
.input-group-btn:last-child>.btn-group>.btn,
.input-group-btn:last-child>.dropdown-toggle,
.input-group-btn:first-child>.btn:not(:first-child),
.input-group-btn:first-child>.btn-group:not(:first-child)>.btn {
	border-bottom-left-radius: 0;
	border-top-left-radius: 0
}

.input-group-addon:last-child {
	border-left: 0
}

.input-group-btn {
	position: relative;
	font-size: 0;
	white-space: nowrap
}

.input-group-btn>.btn {
	position: relative
}

.input-group-btn>.btn+.btn {
	margin-left: -1px
}

.input-group-btn>.btn:hover,
.input-group-btn>.btn:focus,
.input-group-btn>.btn:active {
	z-index: 2
}

.input-group-btn:first-child>.btn,
.input-group-btn:first-child>.btn-group {
	margin-right: -1px
}

.input-group-btn:last-child>.btn,
.input-group-btn:last-child>.btn-group {
	z-index: 2;
	margin-left: -1px
}

.nav {
	margin-bottom: 0;
	padding-left: 0;
	list-style: none
}

.nav:before,
.nav:after {
	content: " ";
	display: table
}

.nav:after {
	clear: both
}

.nav>li {
	position: relative;
	display: block
}

.nav>li>a {
	position: relative;
	display: block;
	padding: 10px 15px
}

.nav>li>a:hover,
.nav>li>a:focus {
	text-decoration: none;
	background-color: #eee
}

.nav>li.disabled>a {
	color: #777
}

.nav>li.disabled>a:hover,
.nav>li.disabled>a:focus {
	color: #777;
	text-decoration: none;
	background-color: transparent;
	cursor: not-allowed
}

.nav .open>a,
.nav .open>a:hover,
.nav .open>a:focus {
	background-color: #eee;
	border-color: #267998
}

.nav .nav-divider {
	height: 1px;
	margin: 10px 0;
	overflow: hidden;
	background-color: #e5e5e5
}

.nav>li>a>img {
	max-width: none
}

.nav-tabs {
	border-bottom: 1px solid #ddd
}

.nav-tabs>li {
	float: left;
	margin-bottom: -1px
}

.nav-tabs>li>a {
	margin-right: 2px;
	line-height: 1.42857;
	border: 1px solid transparent;
	border-radius: 4px 4px 0 0
}

.nav-tabs>li>a:hover {
	border-color: #eee #eee #ddd
}

.nav-tabs>li.active>a,
.nav-tabs>li.active>a:hover,
.nav-tabs>li.active>a:focus {
	color: #555;
	background-color: #F1F1F1;
	border: 1px solid #ddd;
	border-bottom-color: transparent;
	cursor: default
}

.nav-pills>li {
	float: left
}

.nav-pills>li>a {
	border-radius: 4px
}

.nav-pills>li+li {
	margin-left: 2px
}

.nav-pills>li.active>a,
.nav-pills>li.active>a:hover,
.nav-pills>li.active>a:focus {
	color: #fff;
	background-color: #337ab7
}

.nav-stacked>li {
	float: none
}

.nav-stacked>li+li {
	margin-top: 2px;
	margin-left: 0
}

.nav-justified,
.nav-tabs.nav-justified {
	width: 100%
}

.nav-justified>li,
.nav-tabs.nav-justified>li {
	float: none
}

.nav-justified>li>a,
.nav-tabs.nav-justified>li>a {
	text-align: center;
	margin-bottom: 5px
}

.nav-justified>.dropdown .dropdown-menu {
	top: auto;
	left: auto
}

@media (min-width:768px) {
	.nav-justified>li,
	.nav-tabs.nav-justified>li {
		display: table-cell;
		width: 1%
	}
	.nav-justified>li>a,
	.nav-tabs.nav-justified>li>a {
		margin-bottom: 0
	}
}

.nav-tabs-justified,
.nav-tabs.nav-justified {
	border-bottom: 0
}

.nav-tabs-justified>li>a,
.nav-tabs.nav-justified>li>a {
	margin-right: 0;
	border-radius: 4px
}

.nav-tabs-justified>.active>a,
.nav-tabs.nav-justified>.active>a,
.nav-tabs-justified>.active>a:hover,
.nav-tabs.nav-justified>.active>a:hover,
.nav-tabs-justified>.active>a:focus,
.nav-tabs.nav-justified>.active>a:focus {
	border: 1px solid #ddd
}

@media (min-width:768px) {
	.nav-tabs-justified>li>a,
	.nav-tabs.nav-justified>li>a {
		border-bottom: 1px solid #ddd;
		border-radius: 4px 4px 0 0
	}
	.nav-tabs-justified>.active>a,
	.nav-tabs.nav-justified>.active>a,
	.nav-tabs-justified>.active>a:hover,
	.nav-tabs.nav-justified>.active>a:hover,
	.nav-tabs-justified>.active>a:focus,
	.nav-tabs.nav-justified>.active>a:focus {
		border-bottom-color: #F1F1F1
	}
}

.tab-content>.tab-pane {
	display: none
}

.tab-content>.active {
	display: block
}

.nav-tabs .dropdown-menu {
	margin-top: -1px;
	border-top-right-radius: 0;
	border-top-left-radius: 0
}

.navbar {
	position: relative;
	min-height: 50px;
	margin-bottom: 22px;
	border: 1px solid transparent
}

.navbar:before,
.navbar:after {
	content: " ";
	display: table
}

.navbar:after {
	clear: both
}

@media (min-width:768px) {
	.navbar {
		border-radius: 4px
	}
}

.navbar-header:before,
.navbar-header:after {
	content: " ";
	display: table
}

.navbar-header:after {
	clear: both
}

@media (min-width:768px) {
	.navbar-header {
		float: left
	}
}

.navbar-collapse {
	overflow-x: visible;
	padding-right: 5px;
	padding-left: 5px;
	border-top: 1px solid transparent;
	box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.1);
	-webkit-overflow-scrolling: touch
}

.navbar-collapse:before,
.navbar-collapse:after {
	content: " ";
	display: table
}

.navbar-collapse:after {
	clear: both
}

.navbar-collapse.in {
	overflow-y: auto
}

@media (min-width:768px) {
	.navbar-collapse {
		width: auto;
		border-top: 0;
		box-shadow: none
	}
	.navbar-collapse.collapse {
		display: block !important;
		height: auto !important;
		padding-bottom: 0;
		overflow: visible !important
	}
	.navbar-collapse.in {
		overflow-y: visible
	}
	.navbar-fixed-top .navbar-collapse,
	.navbar-static-top .navbar-collapse,
	.navbar-fixed-bottom .navbar-collapse {
		padding-left: 0;
		padding-right: 0
	}
}

.navbar-fixed-top .navbar-collapse,
.navbar-fixed-bottom .navbar-collapse {
	max-height: 340px
}

@media (max-device-width:480px) and (orientation:landscape) {
	.navbar-fixed-top .navbar-collapse,
	.navbar-fixed-bottom .navbar-collapse {
		max-height: 200px
	}
}

.container>.navbar-header,
.container>.navbar-collapse,
.container-fluid>.navbar-header,
.container-fluid>.navbar-collapse {
	margin-right: -5px;
	margin-left: -5px
}

@media (min-width:768px) {
	.container>.navbar-header,
	.container>.navbar-collapse,
	.container-fluid>.navbar-header,
	.container-fluid>.navbar-collapse {
		margin-right: 0;
		margin-left: 0
	}
}

.navbar-static-top {
	z-index: 1000;
	border-width: 0 0 1px
}

@media (min-width:768px) {
	.navbar-static-top {
		border-radius: 0
	}
}

.navbar-fixed-top,
.navbar-fixed-bottom {
	position: fixed;
	right: 0;
	left: 0;
	z-index: 1030
}

@media (min-width:768px) {
	.navbar-fixed-top,
	.navbar-fixed-bottom {
		border-radius: 0
	}
}

.navbar-fixed-top {
	top: 0;
	border-width: 0 0 1px
}

.navbar-fixed-bottom {
	bottom: 0;
	margin-bottom: 0;
	border-width: 1px 0 0
}

.navbar-brand {
	float: left;
	padding: 14px 5px;
	font-size: 20px;
	line-height: 22px;
	height: 50px
}

.navbar-brand:hover,
.navbar-brand:focus {
	text-decoration: none
}

.navbar-brand>img {
	display: block
}

@media (min-width:768px) {
	.navbar>.container .navbar-brand,
	.navbar>.container-fluid .navbar-brand {
		margin-left: -5px
	}
}

.navbar-toggle {
	position: relative;
	float: right;
	margin-right: 5px;
	padding: 9px 10px;
	margin-top: 8px;
	margin-bottom: 8px;
	background-color: transparent;
	background-image: none;
	border: 1px solid transparent;
	border-radius: 4px
}

.navbar-toggle:focus {
	outline: 0
}

.navbar-toggle .icon-bar {
	display: block;
	width: 22px;
	height: 2px;
	border-radius: 1px
}

.navbar-toggle .icon-bar+.icon-bar {
	margin-top: 4px
}

@media (min-width:768px) {
	.navbar-toggle {
		display: none
	}
}

.navbar-nav {
	margin: 7px -5px
}

.navbar-nav>li>a {
	padding-top: 10px;
	padding-bottom: 10px;
	line-height: 22px
}

@media (max-width:767px) {
	.navbar-nav .open .dropdown-menu {
		position: static;
		float: none;
		width: auto;
		margin-top: 0;
		background-color: transparent;
		border: 0;
		box-shadow: none
	}
	.navbar-nav .open .dropdown-menu>li>a,
	.navbar-nav .open .dropdown-menu .dropdown-header {
		padding: 5px 15px 5px 25px
	}
	.navbar-nav .open .dropdown-menu>li>a {
		line-height: 22px
	}
	.navbar-nav .open .dropdown-menu>li>a:hover,
	.navbar-nav .open .dropdown-menu>li>a:focus {
		background-image: none
	}
}

@media (min-width:768px) {
	.navbar-nav {
		float: left;
		margin: 0
	}
	.navbar-nav>li {
		float: left
	}
	.navbar-nav>li>a {
		padding-top: 14px;
		padding-bottom: 14px
	}
}

.navbar-form {
	margin-left: -5px;
	margin-right: -5px;
	padding: 10px 5px;
	border-top: 1px solid transparent;
	border-bottom: 1px solid transparent;
	-webkit-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.1), 0 1px 0 rgba(255, 255, 255, 0.1);
	box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.1), 0 1px 0 rgba(255, 255, 255, 0.1);
	margin-top: 7px;
	margin-bottom: 7px
}

@media (min-width:768px) {
	.navbar-form .form-group {
		display: inline-block;
		margin-bottom: 0;
		vertical-align: middle
	}
	.navbar-form .form-control {
		display: inline-block;
		width: auto;
		vertical-align: middle
	}
	.navbar-form .form-control-static {
		display: inline-block
	}
	.navbar-form .input-group {
		display: inline-table;
		vertical-align: middle
	}
	.navbar-form .input-group .input-group-addon,
	.navbar-form .input-group .input-group-btn,
	.navbar-form .input-group .form-control {
		width: auto
	}
	.navbar-form .input-group>.form-control {
		width: 100%
	}
	.navbar-form .control-label {
		margin-bottom: 0;
		vertical-align: middle
	}
	.navbar-form .radio,
	.navbar-form .checkbox {
		display: inline-block;
		margin-top: 0;
		margin-bottom: 0;
		vertical-align: middle
	}
	.navbar-form .radio label,
	.navbar-form .checkbox label {
		padding-left: 0
	}
	.navbar-form .radio input[type="radio"],
	.navbar-form .checkbox input[type="checkbox"] {
		position: relative;
		margin-left: 0
	}
	.navbar-form .has-feedback .form-control-feedback {
		top: 0
	}
}

@media (max-width:767px) {
	.navbar-form .form-group {
		margin-bottom: 5px
	}
	.navbar-form .form-group:last-child {
		margin-bottom: 0
	}
}

@media (min-width:768px) {
	.navbar-form {
		width: auto;
		border: 0;
		margin-left: 0;
		margin-right: 0;
		padding-top: 0;
		padding-bottom: 0;
		-webkit-box-shadow: none;
		box-shadow: none
	}
}

.navbar-nav>li>.dropdown-menu {
	margin-top: 0;
	border-top-right-radius: 0;
	border-top-left-radius: 0
}

.navbar-fixed-bottom .navbar-nav>li>.dropdown-menu {
	margin-bottom: 0;
	border-top-right-radius: 4px;
	border-top-left-radius: 4px;
	border-bottom-right-radius: 0;
	border-bottom-left-radius: 0
}

.navbar-btn {
	margin-top: 7px;
	margin-bottom: 7px
}

.navbar-btn.btn-sm,
.btn-group-sm>.navbar-btn.btn {
	margin-top: 8.5px;
	margin-bottom: 8.5px
}

.navbar-btn.btn-xs,
.btn-group-xs>.navbar-btn.btn {
	margin-top: 14px;
	margin-bottom: 14px
}

.navbar-text {
	margin-top: 14px;
	margin-bottom: 14px
}

@media (min-width:768px) {
	.navbar-text {
		float: left;
		margin-left: 5px;
		margin-right: 5px
	}
}

@media (min-width:768px) {
	.navbar-left {
		float: left !important
	}
	.navbar-right {
		float: right !important;
		margin-right: -5px
	}
	.navbar-right~.navbar-right {
		margin-right: 0
	}
}

.navbar-default {
	background-color: #f8f8f8;
	border-color: #e7e7e7
}

.navbar-default .navbar-brand {
	color: #777
}

.navbar-default .navbar-brand:hover,
.navbar-default .navbar-brand:focus {
	color: #5e5e5e;
	background-color: transparent
}

.navbar-default .navbar-text {
	color: #777
}

.navbar-default .navbar-nav>li>a {
	color: #777
}

.navbar-default .navbar-nav>li>a:hover,
.navbar-default .navbar-nav>li>a:focus {
	color: #333;
	background-color: transparent
}

.navbar-default .navbar-nav>.active>a,
.navbar-default .navbar-nav>.active>a:hover,
.navbar-default .navbar-nav>.active>a:focus {
	color: #555;
	background-color: #e7e7e7
}

.navbar-default .navbar-nav>.disabled>a,
.navbar-default .navbar-nav>.disabled>a:hover,
.navbar-default .navbar-nav>.disabled>a:focus {
	color: #ccc;
	background-color: transparent
}

.navbar-default .navbar-toggle {
	border-color: #ddd
}

.navbar-default .navbar-toggle:hover,
.navbar-default .navbar-toggle:focus {
	background-color: #ddd
}

.navbar-default .navbar-toggle .icon-bar {
	background-color: #888
}

.navbar-default .navbar-collapse,
.navbar-default .navbar-form {
	border-color: #e7e7e7
}

.navbar-default .navbar-nav>.open>a,
.navbar-default .navbar-nav>.open>a:hover,
.navbar-default .navbar-nav>.open>a:focus {
	background-color: #e7e7e7;
	color: #555
}

@media (max-width:767px) {
	.navbar-default .navbar-nav .open .dropdown-menu>li>a {
		color: #777
	}
	.navbar-default .navbar-nav .open .dropdown-menu>li>a:hover,
	.navbar-default .navbar-nav .open .dropdown-menu>li>a:focus {
		color: #333;
		background-color: transparent
	}
	.navbar-default .navbar-nav .open .dropdown-menu>.active>a,
	.navbar-default .navbar-nav .open .dropdown-menu>.active>a:hover,
	.navbar-default .navbar-nav .open .dropdown-menu>.active>a:focus {
		color: #555;
		background-color: #e7e7e7
	}
	.navbar-default .navbar-nav .open .dropdown-menu>.disabled>a,
	.navbar-default .navbar-nav .open .dropdown-menu>.disabled>a:hover,
	.navbar-default .navbar-nav .open .dropdown-menu>.disabled>a:focus {
		color: #ccc;
		background-color: transparent
	}
}

.navbar-default .navbar-link {
	color: #777
}

.navbar-default .navbar-link:hover {
	color: #333
}

.navbar-default .btn-link {
	color: #777
}

.navbar-default .btn-link:hover,
.navbar-default .btn-link:focus {
	color: #333
}

.navbar-default .btn-link[disabled]:hover,
.navbar-default .btn-link[disabled]:focus,
fieldset[disabled] .navbar-default .btn-link:hover,
fieldset[disabled] .navbar-default .btn-link:focus {
	color: #ccc
}

.navbar-inverse {
	background-color: #222;
	border-color: #090909
}

.navbar-inverse .navbar-brand {
	color: #9d9d9d
}

.navbar-inverse .navbar-brand:hover,
.navbar-inverse .navbar-brand:focus {
	color: #fff;
	background-color: transparent
}

.navbar-inverse .navbar-text {
	color: #9d9d9d
}

.navbar-inverse .navbar-nav>li>a {
	color: #9d9d9d
}

.navbar-inverse .navbar-nav>li>a:hover,
.navbar-inverse .navbar-nav>li>a:focus {
	color: #fff;
	background-color: transparent
}

.navbar-inverse .navbar-nav>.active>a,
.navbar-inverse .navbar-nav>.active>a:hover,
.navbar-inverse .navbar-nav>.active>a:focus {
	color: #fff;
	background-color: #090909
}

.navbar-inverse .navbar-nav>.disabled>a,
.navbar-inverse .navbar-nav>.disabled>a:hover,
.navbar-inverse .navbar-nav>.disabled>a:focus {
	color: #444;
	background-color: transparent
}

.navbar-inverse .navbar-toggle {
	border-color: #333
}

.navbar-inverse .navbar-toggle:hover,
.navbar-inverse .navbar-toggle:focus {
	background-color: #333
}

.navbar-inverse .navbar-toggle .icon-bar {
	background-color: #fff
}

.navbar-inverse .navbar-collapse,
.navbar-inverse .navbar-form {
	border-color: #101010
}

.navbar-inverse .navbar-nav>.open>a,
.navbar-inverse .navbar-nav>.open>a:hover,
.navbar-inverse .navbar-nav>.open>a:focus {
	background-color: #090909;
	color: #fff
}

@media (max-width:767px) {
	.navbar-inverse .navbar-nav .open .dropdown-menu>.dropdown-header {
		border-color: #090909
	}
	.navbar-inverse .navbar-nav .open .dropdown-menu .divider {
		background-color: #090909
	}
	.navbar-inverse .navbar-nav .open .dropdown-menu>li>a {
		color: #9d9d9d
	}
	.navbar-inverse .navbar-nav .open .dropdown-menu>li>a:hover,
	.navbar-inverse .navbar-nav .open .dropdown-menu>li>a:focus {
		color: #fff;
		background-color: transparent
	}
	.navbar-inverse .navbar-nav .open .dropdown-menu>.active>a,
	.navbar-inverse .navbar-nav .open .dropdown-menu>.active>a:hover,
	.navbar-inverse .navbar-nav .open .dropdown-menu>.active>a:focus {
		color: #fff;
		background-color: #090909
	}
	.navbar-inverse .navbar-nav .open .dropdown-menu>.disabled>a,
	.navbar-inverse .navbar-nav .open .dropdown-menu>.disabled>a:hover,
	.navbar-inverse .navbar-nav .open .dropdown-menu>.disabled>a:focus {
		color: #444;
		background-color: transparent
	}
}

.navbar-inverse .navbar-link {
	color: #9d9d9d
}

.navbar-inverse .navbar-link:hover {
	color: #fff
}

.navbar-inverse .btn-link {
	color: #9d9d9d
}

.navbar-inverse .btn-link:hover,
.navbar-inverse .btn-link:focus {
	color: #fff
}

.navbar-inverse .btn-link[disabled]:hover,
.navbar-inverse .btn-link[disabled]:focus,
fieldset[disabled] .navbar-inverse .btn-link:hover,
fieldset[disabled] .navbar-inverse .btn-link:focus {
	color: #444
}

.breadcrumb {
	padding: 8px 15px;
	margin-bottom: 22px;
	list-style: none;
	background-color: #f5f5f5;
	border-radius: 4px
}

.breadcrumb>li {
	display: inline-block
}

.breadcrumb>li+li:before {
	content: "/Â ";
	padding: 0 5px;
	color: #ccc
}

.breadcrumb>.active {
	color: #777
}

.pagination {
	display: inline-block;
	padding-left: 0;
	margin: 22px 0;
	border-radius: 4px
}

.pagination>li {
	display: inline
}

.pagination>li>a,
.pagination>li>span {
	position: relative;
	float: left;
	padding: 6px 12px;
	line-height: 1.42857;
	text-decoration: none;
	color: #267998;
	background-color: #fff;
	border: 1px solid #ddd;
	margin-left: -1px
}

.pagination>li:first-child>a,
.pagination>li:first-child>span {
	margin-left: 0;
	border-bottom-left-radius: 4px;
	border-top-left-radius: 4px
}

.pagination>li:last-child>a,
.pagination>li:last-child>span {
	border-bottom-right-radius: 4px;
	border-top-right-radius: 4px
}

.pagination>li>a:hover,
.pagination>li>a:focus,
.pagination>li>span:hover,
.pagination>li>span:focus {
	z-index: 2;
	color: #008575;
	background-color: #eee;
	border-color: #ddd
}

.pagination>.active>a,
.pagination>.active>a:hover,
.pagination>.active>a:focus,
.pagination>.active>span,
.pagination>.active>span:hover,
.pagination>.active>span:focus {
	z-index: 3;
	color: #fff;
	background-color: #337ab7;
	border-color: #337ab7;
	cursor: default
}

.pagination>.disabled>span,
.pagination>.disabled>span:hover,
.pagination>.disabled>span:focus,
.pagination>.disabled>a,
.pagination>.disabled>a:hover,
.pagination>.disabled>a:focus {
	color: #777;
	background-color: #fff;
	border-color: #ddd;
	cursor: not-allowed
}

.pagination-lg>li>a,
.pagination-lg>li>span {
	padding: 10px 16px;
	font-size: 20px;
	line-height: 1.33333
}

.pagination-lg>li:first-child>a,
.pagination-lg>li:first-child>span {
	border-bottom-left-radius: 6px;
	border-top-left-radius: 6px
}

.pagination-lg>li:last-child>a,
.pagination-lg>li:last-child>span {
	border-bottom-right-radius: 6px;
	border-top-right-radius: 6px
}

.pagination-sm>li>a,
.pagination-sm>li>span {
	padding: 5px 10px;
	font-size: 14px;
	line-height: 1.5
}

.pagination-sm>li:first-child>a,
.pagination-sm>li:first-child>span {
	border-bottom-left-radius: 3px;
	border-top-left-radius: 3px
}

.pagination-sm>li:last-child>a,
.pagination-sm>li:last-child>span {
	border-bottom-right-radius: 3px;
	border-top-right-radius: 3px
}

.pager {
	padding-left: 0;
	margin: 22px 0;
	list-style: none;
	text-align: center
}

.pager:before,
.pager:after {
	content: " ";
	display: table
}

.pager:after {
	clear: both
}

.pager li {
	display: inline
}

.pager li>a,
.pager li>span {
	display: inline-block;
	padding: 5px 14px;
	background-color: #fff;
	border: 1px solid #ddd;
	border-radius: 15px
}

.pager li>a:hover,
.pager li>a:focus {
	text-decoration: none;
	background-color: #eee
}

.pager .next>a,
.pager .next>span {
	float: right
}

.pager .previous>a,
.pager .previous>span {
	float: left
}

.pager .disabled>a,
.pager .disabled>a:hover,
.pager .disabled>a:focus,
.pager .disabled>span {
	color: #777;
	background-color: #fff;
	cursor: not-allowed
}

.label {
	display: inline;
	padding: .2em .6em .3em;
	font-size: 75%;
	font-weight: bold;
	line-height: 1;
	color: #fff;
	text-align: center;
	white-space: nowrap;
	vertical-align: baseline;
	border-radius: .25em
}

.label:empty {
	display: none
}

.btn .label {
	position: relative;
	top: -1px
}

a.label:hover,
a.label:focus {
	color: #fff;
	text-decoration: none;
	cursor: pointer
}

.label-default {
	background-color: #777
}

.label-default[href]:hover,
.label-default[href]:focus {
	background-color: #5e5e5e
}

.label-primary {
	background-color: #337ab7
}

.label-primary[href]:hover,
.label-primary[href]:focus {
	background-color: #286090
}

.label-success {
	background-color: #5cb85c
}

.label-success[href]:hover,
.label-success[href]:focus {
	background-color: #449d44
}

.label-info {
	background-color: #5bc0de
}

.label-info[href]:hover,
.label-info[href]:focus {
	background-color: #31b0d5
}

.label-warning {
	background-color: #f0ad4e
}

.label-warning[href]:hover,
.label-warning[href]:focus {
	background-color: #ec971f
}

.label-danger {
	background-color: #d9534f
}

.label-danger[href]:hover,
.label-danger[href]:focus {
	background-color: #c9302c
}

.badge {
	display: inline-block;
	min-width: 10px;
	padding: 3px 7px;
	font-size: 14px;
	font-weight: bold;
	color: #fff;
	line-height: 1;
	vertical-align: middle;
	white-space: nowrap;
	text-align: center;
	background-color: #777;
	border-radius: 10px
}

.badge:empty {
	display: none
}

.btn .badge {
	position: relative;
	top: -1px
}

.btn-xs .badge,
.btn-group-xs>.btn .badge,
.btn-group-xs>.btn .badge {
	top: 0;
	padding: 1px 5px
}

.list-group-item.active>.badge,
.nav-pills>.active>a>.badge {
	color: #267998;
	background-color: #fff
}

.list-group-item>.badge {
	float: right
}

.list-group-item>.badge+.badge {
	margin-right: 5px
}

.nav-pills>li>a>.badge {
	margin-left: 3px
}

a.badge:hover,
a.badge:focus {
	color: #fff;
	text-decoration: none;
	cursor: pointer
}

.jumbotron {
	padding-top: 30px;
	padding-bottom: 30px;
	margin-bottom: 30px;
	color: inherit;
	background-color: #eee
}

.jumbotron h1,
.jumbotron .h1 {
	color: inherit
}

.jumbotron p {
	margin-bottom: 15px;
	font-size: 24px;
	font-weight: 200
}

.jumbotron>hr {
	border-top-color: #d5d5d5
}

.container .jumbotron,
.container-fluid .jumbotron {
	border-radius: 6px;
	padding-left: 5px;
	padding-right: 5px
}

.jumbotron .container {
	max-width: 100%
}

@media screen and (min-width:768px) {
	.jumbotron {
		padding-top: 48px;
		padding-bottom: 48px
	}
	.container .jumbotron,
	.container-fluid .jumbotron {
		padding-left: 60px;
		padding-right: 60px
	}
	.jumbotron h1,
	.jumbotron .h1 {
		font-size: 72px
	}
}

.thumbnail {
	display: block;
	padding: 4px;
	margin-bottom: 22px;
	line-height: 1.42857;
	background-color: #F1F1F1;
	border: 1px solid #ddd;
	border-radius: 4px;
	-webkit-transition: border 0.2s ease-in-out;
	-o-transition: border 0.2s ease-in-out;
	transition: border 0.2s ease-in-out
}

.thumbnail>img,
.thumbnail a>img {
	display: block;
	max-width: 100%;
	height: auto;
	margin-left: auto;
	margin-right: auto
}

.thumbnail .caption {
	padding: 9px;
	color: #333
}

a.thumbnail:hover,
a.thumbnail:focus,
a.thumbnail.active {
	border-color: #267998
}

.alert {
	padding: 15px;
	margin-bottom: 22px;
	border: 1px solid transparent;
	border-radius: 4px
}

.alert h4 {
	margin-top: 0;
	color: inherit
}

.alert .alert-link {
	font-weight: bold
}

.alert>p,
.alert>ul {
	margin-bottom: 0
}

.alert>p+p {
	margin-top: 5px
}

.alert-dismissable,
.alert-dismissible {
	padding-right: 35px
}

.alert-dismissable .close,
.alert-dismissible .close {
	position: relative;
	top: -2px;
	right: -21px;
	color: inherit
}

.alert-success {
	background-color: #dff0d8;
	border-color: #d6e9c6;
	color: #3c763d
}

.alert-success hr {
	border-top-color: #c9e2b3
}

.alert-success .alert-link {
	color: #2b542c
}

.alert-info {
	background-color: #d9edf7;
	border-color: #bce8f1;
	color: #31708f
}

.alert-info hr {
	border-top-color: #a6e1ec
}

.alert-info .alert-link {
	color: #245269
}

.alert-warning {
	background-color: #fcf8e3;
	border-color: #faebcc;
	color: #8a6d3b
}

.alert-warning hr {
	border-top-color: #f7e1b5
}

.alert-warning .alert-link {
	color: #66512c
}

.alert-danger {
	background-color: #f2dede;
	border-color: #ebccd1;
	color: #a94442
}

.alert-danger hr {
	border-top-color: #e4b9c0
}

.alert-danger .alert-link {
	color: #843534
}

@-webkit-keyframes progress-bar-stripes {
	from {
		background-position: 40px 0
	}
	to {
		background-position: 0 0
	}
}

@keyframes progress-bar-stripes {
	from {
		background-position: 40px 0
	}
	to {
		background-position: 0 0
	}
}

.progress {
	overflow: hidden;
	height: 22px;
	margin-bottom: 22px;
	background-color: #f5f5f5;
	border-radius: 4px;
	-webkit-box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.1);
	box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.1)
}

.progress-bar {
	float: left;
	width: 0%;
	height: 100%;
	font-size: 14px;
	line-height: 22px;
	color: #fff;
	text-align: center;
	background-color: #337ab7;
	-webkit-box-shadow: inset 0 -1px 0 rgba(0, 0, 0, 0.15);
	box-shadow: inset 0 -1px 0 rgba(0, 0, 0, 0.15);
	-webkit-transition: width 0.6s ease;
	-o-transition: width 0.6s ease;
	transition: width 0.6s ease
}

.progress-striped .progress-bar,
.progress-bar-striped {
	background-image: -webkit-linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
	background-image: -o-linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
	background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
	background-size: 40px 40px
}

.progress.active .progress-bar,
.progress-bar.active {
	-webkit-animation: progress-bar-stripes 2s linear infinite;
	-o-animation: progress-bar-stripes 2s linear infinite;
	animation: progress-bar-stripes 2s linear infinite
}

.progress-bar-success {
	background-color: #5cb85c
}

.progress-striped .progress-bar-success {
	background-image: -webkit-linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
	background-image: -o-linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
	background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent)
}

.progress-bar-info {
	background-color: #5bc0de
}

.progress-striped .progress-bar-info {
	background-image: -webkit-linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
	background-image: -o-linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
	background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent)
}

.progress-bar-warning {
	background-color: #f0ad4e
}

.progress-striped .progress-bar-warning {
	background-image: -webkit-linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
	background-image: -o-linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
	background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent)
}

.progress-bar-danger {
	background-color: #d9534f
}

.progress-striped .progress-bar-danger {
	background-image: -webkit-linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
	background-image: -o-linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
	background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent)
}

.media {
	margin-top: 15px
}

.media:first-child {
	margin-top: 0
}

.media,
.media-body {
	zoom: 1;
	overflow: hidden
}

.media-body {
	width: 10000px
}

.media-object {
	display: block
}

.media-object.img-thumbnail {
	max-width: none
}

.media-right,
.media>.pull-right {
	padding-left: 10px
}

.media-left,
.media>.pull-left {
	padding-right: 10px
}

.media-left,
.media-right,
.media-body {
	display: table-cell;
	vertical-align: top
}

.media-middle {
	vertical-align: middle
}

.media-bottom {
	vertical-align: bottom
}

.media-heading {
	margin-top: 0;
	margin-bottom: 5px
}

.media-list {
	padding-left: 0;
	list-style: none
}

.list-group {
	margin-bottom: 20px;
	padding-left: 0
}

.list-group-item {
	position: relative;
	display: block;
	padding: 10px 15px;
	margin-bottom: -1px;
	background-color: #fff;
	border: 1px solid #ddd
}

.list-group-item:first-child {
	border-top-right-radius: 4px;
	border-top-left-radius: 4px
}

.list-group-item:last-child {
	margin-bottom: 0;
	border-bottom-right-radius: 4px;
	border-bottom-left-radius: 4px
}

a.list-group-item,
button.list-group-item {
	color: #555
}

a.list-group-item .list-group-item-heading,
button.list-group-item .list-group-item-heading {
	color: #333
}

a.list-group-item:hover,
a.list-group-item:focus,
button.list-group-item:hover,
button.list-group-item:focus {
	text-decoration: none;
	color: #555;
	background-color: #f5f5f5
}

button.list-group-item {
	width: 100%;
	text-align: left
}

.list-group-item.disabled,
.list-group-item.disabled:hover,
.list-group-item.disabled:focus {
	background-color: #eee;
	color: #777;
	cursor: not-allowed
}

.list-group-item.disabled .list-group-item-heading,
.list-group-item.disabled:hover .list-group-item-heading,
.list-group-item.disabled:focus .list-group-item-heading {
	color: inherit
}

.list-group-item.disabled .list-group-item-text,
.list-group-item.disabled:hover .list-group-item-text,
.list-group-item.disabled:focus .list-group-item-text {
	color: #777
}

.list-group-item.active,
.list-group-item.active:hover,
.list-group-item.active:focus {
	z-index: 2;
	color: #fff;
	background-color: #337ab7;
	border-color: #337ab7
}

.list-group-item.active .list-group-item-heading,
.list-group-item.active .list-group-item-heading>small,
.list-group-item.active .list-group-item-heading>.small,
.list-group-item.active:hover .list-group-item-heading,
.list-group-item.active:hover .list-group-item-heading>small,
.list-group-item.active:hover .list-group-item-heading>.small,
.list-group-item.active:focus .list-group-item-heading,
.list-group-item.active:focus .list-group-item-heading>small,
.list-group-item.active:focus .list-group-item-heading>.small {
	color: inherit
}

.list-group-item.active .list-group-item-text,
.list-group-item.active:hover .list-group-item-text,
.list-group-item.active:focus .list-group-item-text {
	color: #c7ddef
}

.list-group-item-success {
	color: #3c763d;
	background-color: #dff0d8
}

a.list-group-item-success,
button.list-group-item-success {
	color: #3c763d
}

a.list-group-item-success .list-group-item-heading,
button.list-group-item-success .list-group-item-heading {
	color: inherit
}

a.list-group-item-success:hover,
a.list-group-item-success:focus,
button.list-group-item-success:hover,
button.list-group-item-success:focus {
	color: #3c763d;
	background-color: #d0e9c6
}

a.list-group-item-success.active,
a.list-group-item-success.active:hover,
a.list-group-item-success.active:focus,
button.list-group-item-success.active,
button.list-group-item-success.active:hover,
button.list-group-item-success.active:focus {
	color: #fff;
	background-color: #3c763d;
	border-color: #3c763d
}

.list-group-item-info {
	color: #31708f;
	background-color: #d9edf7
}

a.list-group-item-info,
button.list-group-item-info {
	color: #31708f
}

a.list-group-item-info .list-group-item-heading,
button.list-group-item-info .list-group-item-heading {
	color: inherit
}

a.list-group-item-info:hover,
a.list-group-item-info:focus,
button.list-group-item-info:hover,
button.list-group-item-info:focus {
	color: #31708f;
	background-color: #c4e3f3
}

a.list-group-item-info.active,
a.list-group-item-info.active:hover,
a.list-group-item-info.active:focus,
button.list-group-item-info.active,
button.list-group-item-info.active:hover,
button.list-group-item-info.active:focus {
	color: #fff;
	background-color: #31708f;
	border-color: #31708f
}

.list-group-item-warning {
	color: #8a6d3b;
	background-color: #fcf8e3
}

a.list-group-item-warning,
button.list-group-item-warning {
	color: #8a6d3b
}

a.list-group-item-warning .list-group-item-heading,
button.list-group-item-warning .list-group-item-heading {
	color: inherit
}

a.list-group-item-warning:hover,
a.list-group-item-warning:focus,
button.list-group-item-warning:hover,
button.list-group-item-warning:focus {
	color: #8a6d3b;
	background-color: #faf2cc
}

a.list-group-item-warning.active,
a.list-group-item-warning.active:hover,
a.list-group-item-warning.active:focus,
button.list-group-item-warning.active,
button.list-group-item-warning.active:hover,
button.list-group-item-warning.active:focus {
	color: #fff;
	background-color: #8a6d3b;
	border-color: #8a6d3b
}

.list-group-item-danger {
	color: #a94442;
	background-color: #f2dede
}

a.list-group-item-danger,
button.list-group-item-danger {
	color: #a94442
}

a.list-group-item-danger .list-group-item-heading,
button.list-group-item-danger .list-group-item-heading {
	color: inherit
}

a.list-group-item-danger:hover,
a.list-group-item-danger:focus,
button.list-group-item-danger:hover,
button.list-group-item-danger:focus {
	color: #a94442;
	background-color: #ebcccc
}

a.list-group-item-danger.active,
a.list-group-item-danger.active:hover,
a.list-group-item-danger.active:focus,
button.list-group-item-danger.active,
button.list-group-item-danger.active:hover,
button.list-group-item-danger.active:focus {
	color: #fff;
	background-color: #a94442;
	border-color: #a94442
}

.list-group-item-heading {
	margin-top: 0;
	margin-bottom: 5px
}

.list-group-item-text {
	margin-bottom: 0;
	line-height: 1.3
}

.panel {
	margin-bottom: 22px;
	background-color: #fff;
	border: 1px solid transparent;
	border-radius: 4px;
	-webkit-box-shadow: 0 1px 1px rgba(0, 0, 0, 0.05);
	box-shadow: 0 1px 1px rgba(0, 0, 0, 0.05)
}

.panel-body {
	padding: 15px
}

.panel-body:before,
.panel-body:after {
	content: " ";
	display: table
}

.panel-body:after {
	clear: both
}

.panel-heading {
	padding: 10px 15px;
	border-bottom: 1px solid transparent;
	border-top-right-radius: 3px;
	border-top-left-radius: 3px
}

.panel-heading>.dropdown .dropdown-toggle {
	color: inherit
}

.panel-title {
	margin-top: 0;
	margin-bottom: 0;
	font-size: 18px;
	color: inherit
}

.panel-title>a,
.panel-title>small,
.panel-title>.small,
.panel-title>small>a,
.panel-title>.small>a {
	color: inherit
}

.panel-footer {
	padding: 10px 15px;
	background-color: #f5f5f5;
	border-top: 1px solid #ddd;
	border-bottom-right-radius: 3px;
	border-bottom-left-radius: 3px
}

.panel>.list-group,
.panel>.panel-collapse>.list-group {
	margin-bottom: 0
}

.panel>.list-group .list-group-item,
.panel>.panel-collapse>.list-group .list-group-item {
	border-width: 1px 0;
	border-radius: 0
}

.panel>.list-group:first-child .list-group-item:first-child,
.panel>.panel-collapse>.list-group:first-child .list-group-item:first-child {
	border-top: 0;
	border-top-right-radius: 3px;
	border-top-left-radius: 3px
}

.panel>.list-group:last-child .list-group-item:last-child,
.panel>.panel-collapse>.list-group:last-child .list-group-item:last-child {
	border-bottom: 0;
	border-bottom-right-radius: 3px;
	border-bottom-left-radius: 3px
}

.panel>.panel-heading+.panel-collapse>.list-group .list-group-item:first-child {
	border-top-right-radius: 0;
	border-top-left-radius: 0
}

.panel-heading+.list-group .list-group-item:first-child {
	border-top-width: 0
}

.list-group+.panel-footer {
	border-top-width: 0
}

.panel>.table,
.panel>.table-responsive>.table,
.panel>.panel-collapse>.table {
	margin-bottom: 0
}

.panel>.table caption,
.panel>.table-responsive>.table caption,
.panel>.panel-collapse>.table caption {
	padding-left: 15px;
	padding-right: 15px
}

.panel>.table:first-child,
.panel>.table-responsive:first-child>.table:first-child {
	border-top-right-radius: 3px;
	border-top-left-radius: 3px
}

.panel>.table:first-child>thead:first-child>tr:first-child,
.panel>.table:first-child>tbody:first-child>tr:first-child,
.panel>.table-responsive:first-child>.table:first-child>thead:first-child>tr:first-child,
.panel>.table-responsive:first-child>.table:first-child>tbody:first-child>tr:first-child {
	border-top-left-radius: 3px;
	border-top-right-radius: 3px
}

.panel>.table:first-child>thead:first-child>tr:first-child td:first-child,
.panel>.table:first-child>thead:first-child>tr:first-child th:first-child,
.panel>.table:first-child>tbody:first-child>tr:first-child td:first-child,
.panel>.table:first-child>tbody:first-child>tr:first-child th:first-child,
.panel>.table-responsive:first-child>.table:first-child>thead:first-child>tr:first-child td:first-child,
.panel>.table-responsive:first-child>.table:first-child>thead:first-child>tr:first-child th:first-child,
.panel>.table-responsive:first-child>.table:first-child>tbody:first-child>tr:first-child td:first-child,
.panel>.table-responsive:first-child>.table:first-child>tbody:first-child>tr:first-child th:first-child {
	border-top-left-radius: 3px
}

.panel>.table:first-child>thead:first-child>tr:first-child td:last-child,
.panel>.table:first-child>thead:first-child>tr:first-child th:last-child,
.panel>.table:first-child>tbody:first-child>tr:first-child td:last-child,
.panel>.table:first-child>tbody:first-child>tr:first-child th:last-child,
.panel>.table-responsive:first-child>.table:first-child>thead:first-child>tr:first-child td:last-child,
.panel>.table-responsive:first-child>.table:first-child>thead:first-child>tr:first-child th:last-child,
.panel>.table-responsive:first-child>.table:first-child>tbody:first-child>tr:first-child td:last-child,
.panel>.table-responsive:first-child>.table:first-child>tbody:first-child>tr:first-child th:last-child {
	border-top-right-radius: 3px
}

.panel>.table:last-child,
.panel>.table-responsive:last-child>.table:last-child {
	border-bottom-right-radius: 3px;
	border-bottom-left-radius: 3px
}

.panel>.table:last-child>tbody:last-child>tr:last-child,
.panel>.table:last-child>tfoot:last-child>tr:last-child,
.panel>.table-responsive:last-child>.table:last-child>tbody:last-child>tr:last-child,
.panel>.table-responsive:last-child>.table:last-child>tfoot:last-child>tr:last-child {
	border-bottom-left-radius: 3px;
	border-bottom-right-radius: 3px
}

.panel>.table:last-child>tbody:last-child>tr:last-child td:first-child,
.panel>.table:last-child>tbody:last-child>tr:last-child th:first-child,
.panel>.table:last-child>tfoot:last-child>tr:last-child td:first-child,
.panel>.table:last-child>tfoot:last-child>tr:last-child th:first-child,
.panel>.table-responsive:last-child>.table:last-child>tbody:last-child>tr:last-child td:first-child,
.panel>.table-responsive:last-child>.table:last-child>tbody:last-child>tr:last-child th:first-child,
.panel>.table-responsive:last-child>.table:last-child>tfoot:last-child>tr:last-child td:first-child,
.panel>.table-responsive:last-child>.table:last-child>tfoot:last-child>tr:last-child th:first-child {
	border-bottom-left-radius: 3px
}

.panel>.table:last-child>tbody:last-child>tr:last-child td:last-child,
.panel>.table:last-child>tbody:last-child>tr:last-child th:last-child,
.panel>.table:last-child>tfoot:last-child>tr:last-child td:last-child,
.panel>.table:last-child>tfoot:last-child>tr:last-child th:last-child,
.panel>.table-responsive:last-child>.table:last-child>tbody:last-child>tr:last-child td:last-child,
.panel>.table-responsive:last-child>.table:last-child>tbody:last-child>tr:last-child th:last-child,
.panel>.table-responsive:last-child>.table:last-child>tfoot:last-child>tr:last-child td:last-child,
.panel>.table-responsive:last-child>.table:last-child>tfoot:last-child>tr:last-child th:last-child {
	border-bottom-right-radius: 3px
}

.panel>.panel-body+.table,
.panel>.panel-body+.table-responsive,
.panel>.table+.panel-body,
.panel>.table-responsive+.panel-body {
	border-top: 1px solid #ddd
}

.panel>.table>tbody:first-child>tr:first-child th,
.panel>.table>tbody:first-child>tr:first-child td {
	border-top: 0
}

.panel>.table-bordered,
.panel>.table-responsive>.table-bordered {
	border: 0
}

.panel>.table-bordered>thead>tr>th:first-child,
.panel>.table-bordered>thead>tr>td:first-child,
.panel>.table-bordered>tbody>tr>th:first-child,
.panel>.table-bordered>tbody>tr>td:first-child,
.panel>.table-bordered>tfoot>tr>th:first-child,
.panel>.table-bordered>tfoot>tr>td:first-child,
.panel>.table-responsive>.table-bordered>thead>tr>th:first-child,
.panel>.table-responsive>.table-bordered>thead>tr>td:first-child,
.panel>.table-responsive>.table-bordered>tbody>tr>th:first-child,
.panel>.table-responsive>.table-bordered>tbody>tr>td:first-child,
.panel>.table-responsive>.table-bordered>tfoot>tr>th:first-child,
.panel>.table-responsive>.table-bordered>tfoot>tr>td:first-child {
	border-left: 0
}

.panel>.table-bordered>thead>tr>th:last-child,
.panel>.table-bordered>thead>tr>td:last-child,
.panel>.table-bordered>tbody>tr>th:last-child,
.panel>.table-bordered>tbody>tr>td:last-child,
.panel>.table-bordered>tfoot>tr>th:last-child,
.panel>.table-bordered>tfoot>tr>td:last-child,
.panel>.table-responsive>.table-bordered>thead>tr>th:last-child,
.panel>.table-responsive>.table-bordered>thead>tr>td:last-child,
.panel>.table-responsive>.table-bordered>tbody>tr>th:last-child,
.panel>.table-responsive>.table-bordered>tbody>tr>td:last-child,
.panel>.table-responsive>.table-bordered>tfoot>tr>th:last-child,
.panel>.table-responsive>.table-bordered>tfoot>tr>td:last-child {
	border-right: 0
}

.panel>.table-bordered>thead>tr:first-child>td,
.panel>.table-bordered>thead>tr:first-child>th,
.panel>.table-bordered>tbody>tr:first-child>td,
.panel>.table-bordered>tbody>tr:first-child>th,
.panel>.table-responsive>.table-bordered>thead>tr:first-child>td,
.panel>.table-responsive>.table-bordered>thead>tr:first-child>th,
.panel>.table-responsive>.table-bordered>tbody>tr:first-child>td,
.panel>.table-responsive>.table-bordered>tbody>tr:first-child>th {
	border-bottom: 0
}

.panel>.table-bordered>tbody>tr:last-child>td,
.panel>.table-bordered>tbody>tr:last-child>th,
.panel>.table-bordered>tfoot>tr:last-child>td,
.panel>.table-bordered>tfoot>tr:last-child>th,
.panel>.table-responsive>.table-bordered>tbody>tr:last-child>td,
.panel>.table-responsive>.table-bordered>tbody>tr:last-child>th,
.panel>.table-responsive>.table-bordered>tfoot>tr:last-child>td,
.panel>.table-responsive>.table-bordered>tfoot>tr:last-child>th {
	border-bottom: 0
}

.panel>.table-responsive {
	border: 0;
	margin-bottom: 0
}

.panel-group {
	margin-bottom: 22px
}

.panel-group .panel {
	margin-bottom: 0;
	border-radius: 4px
}

.panel-group .panel+.panel {
	margin-top: 5px
}

.panel-group .panel-heading {
	border-bottom: 0
}

.panel-group .panel-heading+.panel-collapse>.panel-body,
.panel-group .panel-heading+.panel-collapse>.list-group {
	border-top: 1px solid #ddd
}

.panel-group .panel-footer {
	border-top: 0
}

.panel-group .panel-footer+.panel-collapse .panel-body {
	border-bottom: 1px solid #ddd
}

.panel-default {
	border-color: #ddd
}

.panel-default>.panel-heading {
	color: #333;
	background-color: #f5f5f5;
	border-color: #ddd
}

.panel-default>.panel-heading+.panel-collapse>.panel-body {
	border-top-color: #ddd
}

.panel-default>.panel-heading .badge {
	color: #f5f5f5;
	background-color: #333
}

.panel-default>.panel-footer+.panel-collapse>.panel-body {
	border-bottom-color: #ddd
}

.panel-primary {
	border-color: #337ab7
}

.panel-primary>.panel-heading {
	color: #fff;
	background-color: #337ab7;
	border-color: #337ab7
}

.panel-primary>.panel-heading+.panel-collapse>.panel-body {
	border-top-color: #337ab7
}

.panel-primary>.panel-heading .badge {
	color: #337ab7;
	background-color: #fff
}

.panel-primary>.panel-footer+.panel-collapse>.panel-body {
	border-bottom-color: #337ab7
}

.panel-success {
	border-color: #d6e9c6
}

.panel-success>.panel-heading {
	color: #3c763d;
	background-color: #dff0d8;
	border-color: #d6e9c6
}

.panel-success>.panel-heading+.panel-collapse>.panel-body {
	border-top-color: #d6e9c6
}

.panel-success>.panel-heading .badge {
	color: #dff0d8;
	background-color: #3c763d
}

.panel-success>.panel-footer+.panel-collapse>.panel-body {
	border-bottom-color: #d6e9c6
}

.panel-info {
	border-color: #bce8f1
}

.panel-info>.panel-heading {
	color: #31708f;
	background-color: #d9edf7;
	border-color: #bce8f1
}

.panel-info>.panel-heading+.panel-collapse>.panel-body {
	border-top-color: #bce8f1
}

.panel-info>.panel-heading .badge {
	color: #d9edf7;
	background-color: #31708f
}

.panel-info>.panel-footer+.panel-collapse>.panel-body {
	border-bottom-color: #bce8f1
}

.panel-warning {
	border-color: #faebcc
}

.panel-warning>.panel-heading {
	color: #8a6d3b;
	background-color: #fcf8e3;
	border-color: #faebcc
}

.panel-warning>.panel-heading+.panel-collapse>.panel-body {
	border-top-color: #faebcc
}

.panel-warning>.panel-heading .badge {
	color: #fcf8e3;
	background-color: #8a6d3b
}

.panel-warning>.panel-footer+.panel-collapse>.panel-body {
	border-bottom-color: #faebcc
}

.panel-danger {
	border-color: #ebccd1
}

.panel-danger>.panel-heading {
	color: #a94442;
	background-color: #f2dede;
	border-color: #ebccd1
}

.panel-danger>.panel-heading+.panel-collapse>.panel-body {
	border-top-color: #ebccd1
}

.panel-danger>.panel-heading .badge {
	color: #f2dede;
	background-color: #a94442
}

.panel-danger>.panel-footer+.panel-collapse>.panel-body {
	border-bottom-color: #ebccd1
}

.embed-responsive {
	position: relative;
	display: block;
	height: 0;
	padding: 0;
	overflow: hidden
}

.embed-responsive .embed-responsive-item,
.embed-responsive iframe,
.embed-responsive embed,
.embed-responsive object,
.embed-responsive video {
	position: absolute;
	top: 0;
	left: 0;
	bottom: 0;
	height: 100%;
	width: 100%;
	border: 0
}

.embed-responsive-16by9 {
	padding-bottom: 56.25%
}

.embed-responsive-4by3 {
	padding-bottom: 75%
}

.well {
	min-height: 20px;
	padding: 19px;
	margin-bottom: 20px;
	background-color: #f5f5f5;
	border: 1px solid #e3e3e3;
	border-radius: 4px;
	-webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.05);
	box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.05)
}

.well blockquote {
	border-color: #ddd;
	border-color: rgba(0, 0, 0, 0.15)
}

.well-lg {
	padding: 24px;
	border-radius: 6px
}

.well-sm {
	padding: 9px;
	border-radius: 3px
}

.close {
	float: right;
	font-size: 24px;
	font-weight: bold;
	line-height: 1;
	color: #000;
	text-shadow: 0 1px 0 #fff;
	opacity: .2;
	filter: alpha(opacity=20)
}

.close:hover,
.close:focus {
	color: #000;
	text-decoration: none;
	cursor: pointer;
	opacity: .5;
	filter: alpha(opacity=50)
}

button.close {
	padding: 0;
	cursor: pointer;
	background: transparent;
	border: 0;
	-webkit-appearance: none
}

.modal-open {
	overflow: hidden
}

.modal {
	display: none;
	overflow: hidden;
	position: fixed;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	z-index: 1050;
	-webkit-overflow-scrolling: touch;
	outline: 0
}

.modal.fade .modal-dialog {
	-webkit-transform: translate(0, -25%);
	-ms-transform: translate(0, -25%);
	-o-transform: translate(0, -25%);
	transform: translate(0, -25%);
	-webkit-transition: -webkit-transform 0.3s ease-out;
	-moz-transition: -moz-transform 0.3s ease-out;
	-o-transition: -o-transform 0.3s ease-out;
	transition: transform 0.3s ease-out
}

.modal.in .modal-dialog {
	-webkit-transform: translate(0, 0);
	-ms-transform: translate(0, 0);
	-o-transform: translate(0, 0);
	transform: translate(0, 0)
}

.modal-open .modal {
	overflow-x: hidden;
	overflow-y: auto
}

.modal-dialog {
	position: relative;
	width: auto;
	margin: 10px
}

.modal-content {
	position: relative;
	background-color: #fff;
	border: 1px solid #999;
	border: 1px solid rgba(0, 0, 0, 0.2);
	border-radius: 6px;
	-webkit-box-shadow: 0 3px 9px rgba(0, 0, 0, 0.5);
	box-shadow: 0 3px 9px rgba(0, 0, 0, 0.5);
	background-clip: padding-box;
	outline: 0
}

.modal-backdrop {
	position: fixed;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	z-index: 1040;
	background-color: #000
}

.modal-backdrop.fade {
	opacity: 0;
	filter: alpha(opacity=0)
}

.modal-backdrop.in {
	opacity: .5;
	filter: alpha(opacity=50)
}

.modal-header {
	padding: 15px;
	border-bottom: 1px solid #e5e5e5
}

.modal-header:before,
.modal-header:after {
	content: " ";
	display: table
}

.modal-header:after {
	clear: both
}

.modal-header .close {
	margin-top: -2px
}

.modal-title {
	margin: 0;
	line-height: 1.42857
}

.modal-body {
	position: relative;
	padding: 15px
}

.modal-footer {
	padding: 15px;
	text-align: right;
	border-top: 1px solid #e5e5e5
}

.modal-footer:before,
.modal-footer:after {
	content: " ";
	display: table
}

.modal-footer:after {
	clear: both
}

.modal-footer .btn+.btn {
	margin-left: 5px;
	margin-bottom: 0
}

.modal-footer .btn-group .btn+.btn {
	margin-left: -1px
}

.modal-footer .btn-block+.btn-block {
	margin-left: 0
}

.modal-scrollbar-measure {
	position: absolute;
	top: -9999px;
	width: 50px;
	height: 50px;
	overflow: scroll
}

@media (min-width:768px) {
	.modal-dialog {
		width: 600px;
		margin: 30px auto
	}
	.modal-content {
		-webkit-box-shadow: 0 5px 15px rgba(0, 0, 0, 0.5);
		box-shadow: 0 5px 15px rgba(0, 0, 0, 0.5)
	}
	.modal-sm {
		width: 300px
	}
}

@media (min-width:992px) {
	.modal-lg {
		width: 900px
	}
}

.tooltip {
	position: absolute;
	z-index: 1070;
	display: block;
	font-family: "SourceSansRegular", helvetica, arial, sans-serif;
	font-style: normal;
	font-weight: normal;
	letter-spacing: normal;
	line-break: auto;
	line-height: 1.42857;
	text-align: left;
	text-align: start;
	text-decoration: none;
	text-shadow: none;
	text-transform: none;
	white-space: normal;
	word-break: normal;
	word-spacing: normal;
	word-wrap: normal;
	font-size: 14px;
	opacity: 0;
	filter: alpha(opacity=0)
}

.tooltip.in {
	opacity: .9;
	filter: alpha(opacity=90)
}

.tooltip.top {
	margin-top: -3px;
	padding: 5px 0
}

.tooltip.right {
	margin-left: 3px;
	padding: 0 5px
}

.tooltip.bottom {
	margin-top: 3px;
	padding: 5px 0
}

.tooltip.left {
	margin-left: -3px;
	padding: 0 5px
}

.tooltip-inner {
	max-width: 200px;
	padding: 3px 8px;
	color: #fff;
	text-align: center;
	background-color: #000;
	border-radius: 4px
}

.tooltip-arrow {
	position: absolute;
	width: 0;
	height: 0;
	border-color: transparent;
	border-style: solid
}

.tooltip.top .tooltip-arrow {
	bottom: 0;
	left: 50%;
	margin-left: -5px;
	border-width: 5px 5px 0;
	border-top-color: #000
}

.tooltip.top-left .tooltip-arrow {
	bottom: 0;
	right: 5px;
	margin-bottom: -5px;
	border-width: 5px 5px 0;
	border-top-color: #000
}

.tooltip.top-right .tooltip-arrow {
	bottom: 0;
	left: 5px;
	margin-bottom: -5px;
	border-width: 5px 5px 0;
	border-top-color: #000
}

.tooltip.right .tooltip-arrow {
	top: 50%;
	left: 0;
	margin-top: -5px;
	border-width: 5px 5px 5px 0;
	border-right-color: #000
}

.tooltip.left .tooltip-arrow {
	top: 50%;
	right: 0;
	margin-top: -5px;
	border-width: 5px 0 5px 5px;
	border-left-color: #000
}

.tooltip.bottom .tooltip-arrow {
	top: 0;
	left: 50%;
	margin-left: -5px;
	border-width: 0 5px 5px;
	border-bottom-color: #000
}

.tooltip.bottom-left .tooltip-arrow {
	top: 0;
	right: 5px;
	margin-top: -5px;
	border-width: 0 5px 5px;
	border-bottom-color: #000
}

.tooltip.bottom-right .tooltip-arrow {
	top: 0;
	left: 5px;
	margin-top: -5px;
	border-width: 0 5px 5px;
	border-bottom-color: #000
}

.popover {
	position: absolute;
	top: 0;
	left: 0;
	z-index: 1060;
	display: none;
	max-width: 276px;
	padding: 1px;
	font-family: "SourceSansRegular", helvetica, arial, sans-serif;
	font-style: normal;
	font-weight: normal;
	letter-spacing: normal;
	line-break: auto;
	line-height: 1.42857;
	text-align: left;
	text-align: start;
	text-decoration: none;
	text-shadow: none;
	text-transform: none;
	white-space: normal;
	word-break: normal;
	word-spacing: normal;
	word-wrap: normal;
	font-size: 16px;
	background-color: #fff;
	background-clip: padding-box;
	border: 1px solid #ccc;
	border: 1px solid rgba(0, 0, 0, 0.2);
	border-radius: 6px;
	-webkit-box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
	box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2)
}

.popover.top {
	margin-top: -10px
}

.popover.right {
	margin-left: 10px
}

.popover.bottom {
	margin-top: 10px
}

.popover.left {
	margin-left: -10px
}

.popover-title {
	margin: 0;
	padding: 8px 14px;
	font-size: 16px;
	background-color: #f7f7f7;
	border-bottom: 1px solid #ebebeb;
	border-radius: 5px 5px 0 0
}

.popover-content {
	padding: 9px 14px
}

.popover>.arrow,
.popover>.arrow:after {
	position: absolute;
	display: block;
	width: 0;
	height: 0;
	border-color: transparent;
	border-style: solid
}

.popover>.arrow {
	border-width: 11px
}

.popover>.arrow:after {
	border-width: 10px;
	content: ""
}

.popover.top>.arrow {
	left: 50%;
	margin-left: -11px;
	border-bottom-width: 0;
	border-top-color: #999;
	border-top-color: rgba(0, 0, 0, 0.25);
	bottom: -11px
}

.popover.top>.arrow:after {
	content: " ";
	bottom: 1px;
	margin-left: -10px;
	border-bottom-width: 0;
	border-top-color: #fff
}

.popover.right>.arrow {
	top: 50%;
	left: -11px;
	margin-top: -11px;
	border-left-width: 0;
	border-right-color: #999;
	border-right-color: rgba(0, 0, 0, 0.25)
}

.popover.right>.arrow:after {
	content: " ";
	left: 1px;
	bottom: -10px;
	border-left-width: 0;
	border-right-color: #fff
}

.popover.bottom>.arrow {
	left: 50%;
	margin-left: -11px;
	border-top-width: 0;
	border-bottom-color: #999;
	border-bottom-color: rgba(0, 0, 0, 0.25);
	top: -11px
}

.popover.bottom>.arrow:after {
	content: " ";
	top: 1px;
	margin-left: -10px;
	border-top-width: 0;
	border-bottom-color: #fff
}

.popover.left>.arrow {
	top: 50%;
	right: -11px;
	margin-top: -11px;
	border-right-width: 0;
	border-left-color: #999;
	border-left-color: rgba(0, 0, 0, 0.25)
}

.popover.left>.arrow:after {
	content: " ";
	right: 1px;
	border-right-width: 0;
	border-left-color: #fff;
	bottom: -10px
}

.carousel {
	position: relative
}

.carousel-inner {
	position: relative;
	overflow: hidden;
	width: 100%
}

.carousel-inner>.item {
	display: none;
	position: relative;
	-webkit-transition: 0.6s ease-in-out left;
	-o-transition: 0.6s ease-in-out left;
	transition: 0.6s ease-in-out left
}

.carousel-inner>.item>img,
.carousel-inner>.item>a>img {
	display: block;
	max-width: 100%;
	height: auto;
	line-height: 1
}

@media all and (transform-3d),
(-webkit-transform-3d) {
	.carousel-inner>.item {
		-webkit-transition: -webkit-transform 0.6s ease-in-out;
		-moz-transition: -moz-transform 0.6s ease-in-out;
		-o-transition: -o-transform 0.6s ease-in-out;
		transition: transform 0.6s ease-in-out;
		-webkit-backface-visibility: hidden;
		-moz-backface-visibility: hidden;
		backface-visibility: hidden;
		-webkit-perspective: 1000px;
		-moz-perspective: 1000px;
		perspective: 1000px
	}
	.carousel-inner>.item.next,
	.carousel-inner>.item.active.right {
		-webkit-transform: translate3d(100%, 0, 0);
		transform: translate3d(100%, 0, 0);
		left: 0
	}
	.carousel-inner>.item.prev,
	.carousel-inner>.item.active.left {
		-webkit-transform: translate3d(-100%, 0, 0);
		transform: translate3d(-100%, 0, 0);
		left: 0
	}
	.carousel-inner>.item.next.left,
	.carousel-inner>.item.prev.right,
	.carousel-inner>.item.active {
		-webkit-transform: translate3d(0, 0, 0);
		transform: translate3d(0, 0, 0);
		left: 0
	}
}

.carousel-inner>.active,
.carousel-inner>.next,
.carousel-inner>.prev {
	display: block
}

.carousel-inner>.active {
	left: 0
}

.carousel-inner>.next,
.carousel-inner>.prev {
	position: absolute;
	top: 0;
	width: 100%
}

.carousel-inner>.next {
	left: 100%
}

.carousel-inner>.prev {
	left: -100%
}

.carousel-inner>.next.left,
.carousel-inner>.prev.right {
	left: 0
}

.carousel-inner>.active.left {
	left: -100%
}

.carousel-inner>.active.right {
	left: 100%
}

.carousel-control {
	position: absolute;
	top: 0;
	left: 0;
	bottom: 0;
	width: 15%;
	opacity: .5;
	filter: alpha(opacity=50);
	font-size: 20px;
	color: #fff;
	text-align: center;
	text-shadow: 0 1px 2px rgba(0, 0, 0, 0.6);
	background-color: transparent
}

.carousel-control.left {
	background-image: -webkit-linear-gradient(left, rgba(0, 0, 0, 0.5) 0%, rgba(0, 0, 0, 0.0001) 100%);
	background-image: -o-linear-gradient(left, rgba(0, 0, 0, 0.5) 0%, rgba(0, 0, 0, 0.0001) 100%);
	background-image: linear-gradient(to right, rgba(0, 0, 0, 0.5) 0%, rgba(0, 0, 0, 0.0001) 100%);
	background-repeat: repeat-x;
	filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#80000000', endColorstr='#00000000', GradientType=1)
}

.carousel-control.right {
	left: auto;
	right: 0;
	background-image: -webkit-linear-gradient(left, rgba(0, 0, 0, 0.0001) 0%, rgba(0, 0, 0, 0.5) 100%);
	background-image: -o-linear-gradient(left, rgba(0, 0, 0, 0.0001) 0%, rgba(0, 0, 0, 0.5) 100%);
	background-image: linear-gradient(to right, rgba(0, 0, 0, 0.0001) 0%, rgba(0, 0, 0, 0.5) 100%);
	background-repeat: repeat-x;
	filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#00000000', endColorstr='#80000000', GradientType=1)
}

.carousel-control:hover,
.carousel-control:focus {
	outline: 0;
	color: #fff;
	text-decoration: none;
	opacity: .9;
	filter: alpha(opacity=90)
}

.carousel-control .icon-prev,
.carousel-control .icon-next,
.carousel-control .glyphicon-chevron-left,
.carousel-control .glyphicon-chevron-right {
	position: absolute;
	top: 50%;
	margin-top: -10px;
	z-index: 5;
	display: inline-block
}

.carousel-control .icon-prev,
.carousel-control .glyphicon-chevron-left {
	left: 50%;
	margin-left: -10px
}

.carousel-control .icon-next,
.carousel-control .glyphicon-chevron-right {
	right: 50%;
	margin-right: -10px
}

.carousel-control .icon-prev,
.carousel-control .icon-next {
	width: 20px;
	height: 20px;
	line-height: 1;
	font-family: serif
}

.carousel-control .icon-prev:before {
	content: '\2039'
}

.carousel-control .icon-next:before {
	content: '\203a'
}

.carousel-indicators {
	position: absolute;
	bottom: 10px;
	left: 50%;
	z-index: 15;
	width: 60%;
	margin-left: -30%;
	padding-left: 0;
	list-style: none;
	text-align: center
}

.carousel-indicators li {
	display: inline-block;
	width: 10px;
	height: 10px;
	margin: 1px;
	text-indent: -999px;
	border: 1px solid #fff;
	border-radius: 10px;
	cursor: pointer;
	background-color: #000 \9;
	background-color: transparent
}

.carousel-indicators .active {
	margin: 0;
	width: 12px;
	height: 12px;
	background-color: #fff
}

.carousel-caption {
	position: absolute;
	left: 15%;
	right: 15%;
	bottom: 20px;
	z-index: 10;
	padding-top: 20px;
	padding-bottom: 20px;
	color: #fff;
	text-align: center;
	text-shadow: 0 1px 2px rgba(0, 0, 0, 0.6)
}

.carousel-caption .btn {
	text-shadow: none
}

@media screen and (min-width:768px) {
	.carousel-control .glyphicon-chevron-left,
	.carousel-control .glyphicon-chevron-right,
	.carousel-control .icon-prev,
	.carousel-control .icon-next {
		width: 30px;
		height: 30px;
		margin-top: -10px;
		font-size: 30px
	}
	.carousel-control .glyphicon-chevron-left,
	.carousel-control .icon-prev {
		margin-left: -10px
	}
	.carousel-control .glyphicon-chevron-right,
	.carousel-control .icon-next {
		margin-right: -10px
	}
	.carousel-caption {
		left: 20%;
		right: 20%;
		padding-bottom: 30px
	}
	.carousel-indicators {
		bottom: 20px
	}
}

.clearfix:before,
.clearfix:after {
	content: " ";
	display: table
}

.clearfix:after {
	clear: both
}

.center-block {
	display: block;
	margin-left: auto;
	margin-right: auto
}

.pull-right {
	float: right !important
}

.pull-left {
	float: left !important
}

.hide {
	display: none !important
}

.show {
	display: block !important
}

.invisible {
	visibility: hidden
}

.text-hide {
	font: 0/0 a;
	color: transparent;
	text-shadow: none;
	background-color: transparent;
	border: 0
}

.hidden {
	display: none !important
}

.affix {
	position: fixed
}

@-ms-viewport {
	width: device-width
}

.visible-xs {
	display: none !important
}

.visible-sm {
	display: none !important
}

.visible-md {
	display: none !important
}

.visible-lg {
	display: none !important
}

.visible-xs-block,
.visible-xs-inline,
.visible-xs-inline-block,
.visible-sm-block,
.visible-sm-inline,
.visible-sm-inline-block,
.visible-md-block,
.visible-md-inline,
.visible-md-inline-block,
.visible-lg-block,
.visible-lg-inline,
.visible-lg-inline-block {
	display: none !important
}

@media (max-width:767px) {
	.visible-xs {
		display: block !important
	}
	table.visible-xs {
		display: table !important
	}
	tr.visible-xs {
		display: table-row !important
	}
	th.visible-xs,
	td.visible-xs {
		display: table-cell !important
	}
}

@media (max-width:767px) {
	.visible-xs-block {
		display: block !important
	}
}

@media (max-width:767px) {
	.visible-xs-inline {
		display: inline !important
	}
}

@media (max-width:767px) {
	.visible-xs-inline-block {
		display: inline-block !important
	}
}

@media (min-width:768px) and (max-width:991px) {
	.visible-sm {
		display: block !important
	}
	table.visible-sm {
		display: table !important
	}
	tr.visible-sm {
		display: table-row !important
	}
	th.visible-sm,
	td.visible-sm {
		display: table-cell !important
	}
}

@media (min-width:768px) and (max-width:991px) {
	.visible-sm-block {
		display: block !important
	}
}

@media (min-width:768px) and (max-width:991px) {
	.visible-sm-inline {
		display: inline !important
	}
}

@media (min-width:768px) and (max-width:991px) {
	.visible-sm-inline-block {
		display: inline-block !important
	}
}

@media (min-width:992px) and (max-width:1199px) {
	.visible-md {
		display: block !important
	}
	table.visible-md {
		display: table !important
	}
	tr.visible-md {
		display: table-row !important
	}
	th.visible-md,
	td.visible-md {
		display: table-cell !important
	}
}

@media (min-width:992px) and (max-width:1199px) {
	.visible-md-block {
		display: block !important
	}
}

@media (min-width:992px) and (max-width:1199px) {
	.visible-md-inline {
		display: inline !important
	}
}

@media (min-width:992px) and (max-width:1199px) {
	.visible-md-inline-block {
		display: inline-block !important
	}
}

@media (min-width:1200px) {
	.visible-lg {
		display: block !important
	}
	table.visible-lg {
		display: table !important
	}
	tr.visible-lg {
		display: table-row !important
	}
	th.visible-lg,
	td.visible-lg {
		display: table-cell !important
	}
}

@media (min-width:1200px) {
	.visible-lg-block {
		display: block !important
	}
}

@media (min-width:1200px) {
	.visible-lg-inline {
		display: inline !important
	}
}

@media (min-width:1200px) {
	.visible-lg-inline-block {
		display: inline-block !important
	}
}

@media (max-width:767px) {
	.hidden-xs {
		display: none !important
	}
}

@media (min-width:768px) and (max-width:991px) {
	.hidden-sm {
		display: none !important
	}
}

@media (min-width:992px) and (max-width:1199px) {
	.hidden-md {
		display: none !important
	}
}

@media (min-width:1200px) {
	.hidden-lg {
		display: none !important
	}
}

.visible-print {
	display: none !important
}

@media print {
	.visible-print {
		display: block !important
	}
	table.visible-print {
		display: table !important
	}
	tr.visible-print {
		display: table-row !important
	}
	th.visible-print,
	td.visible-print {
		display: table-cell !important
	}
}

.visible-print-block {
	display: none !important
}

@media print {
	.visible-print-block {
		display: block !important
	}
}

.visible-print-inline {
	display: none !important
}

@media print {
	.visible-print-inline {
		display: inline !important
	}
}

.visible-print-inline-block {
	display: none !important
}

@media print {
	.visible-print-inline-block {
		display: inline-block !important
	}
}

@media print {
	.hidden-print {
		display: none !important
	}
}

ï»¿html.overlay-open .navbar-fixed-top {
	z-index: 400
}

html.js fieldset.collapsed {
	border-left-width: 1px;
	border-right-width: 1px;
	height: auto
}

html.js input.form-autocomplete {
	background-image: none
}

html.js input.form-autocomplete {
	background-image: none
}

html.js .autocomplete-throbber {
	background-position: 100% 2px;
	background-repeat: no-repeat;
	display: inline-block;
	height: 15px;
	margin: 2px 0 0 2px;
	width: 15px
}

html.js .autocomplete-throbber.throbbing {
	background-position: 100% -18px
}

body {
	position: relative
}

body.admin-expanded.admin-vertical.admin-nw .navbar,
body.admin-expanded.admin-vertical.admin-sw .navbar {
	margin-left: 260px
}

body.navbar-is-fixed-top {
	padding-top: 64px
}

body.navbar-is-fixed-bottom {
	padding-bottom: 64px !important
}

body.toolbar {
	padding-top: 30px !important
}

body.toolbar .navbar-fixed-top {
	top: 30px
}

body.toolbar.navbar-is-fixed-top {
	padding-top: 94px !important
}

body.toolbar-drawer {
	padding-top: 64px !important
}

body.toolbar-drawer .navbar-fixed-top {
	top: 64px
}

body.toolbar-drawer.navbar-is-fixed-top {
	padding-top: 128px !important
}

body.admin-menu .navbar-fixed-top {
	top: 29px
}

body.admin-menu.navbar-is-fixed-top {
	padding-top: 93px !important
}

body div#admin-toolbar {
	z-index: 1600
}

body #toolbar,
body #admin-menu,
body #admin-toolbar {
	-webkit-box-shadow: none;
	-moz-box-shadow: none;
	box-shadow: none
}

body #admin-menu {
	margin: 0;
	padding: 0;
	position: fixed;
	z-index: 1600
}

body #admin-menu .dropdown li {
	line-height: normal
}

@media screen and (min-width:768px) {
	.navbar.container {
		max-width: 720px
	}
}

@media screen and (min-width:992px) {
	.navbar.container {
		max-width: 940px
	}
}

@media screen and (min-width:1200px) {
	.navbar.container {
		max-width: 1140px
	}
}

.navbar.container>.container,
.navbar.container-fluid>.container-fluid {
	margin: 0;
	padding: 0;
	width: auto
}

#overlay-container,
.overlay-modal-background,
.overlay-element {
	z-index: 1500
}

#toolbar {
	z-index: 1600
}

.modal {
	z-index: 1620
}

.modal-dialog {
	z-index: 1630
}

.ctools-modal-dialog .modal-body {
	width: 100% !important;
	overflow: auto
}

.modal-backdrop {
	z-index: 1610
}

.footer {
	margin-top: 45px;
	padding-top: 35px;
	padding-bottom: 36px;
	border-top: 1px solid #E5E5E5
}

.element-invisible {
	margin: 0;
	padding: 0;
	width: 1px
}

.navbar .logo {
	margin-right: -15px;
	padding-left: 15px;
	padding-right: 15px
}

@media screen and (min-width:768px) {
	.navbar .logo {
		margin-right: 0;
		padding-left: 0
	}
}

ul.secondary {
	float: left
}

@media screen and (min-width:768px) {
	ul.secondary {
		float: right
	}
}

.page-header {
	margin-top: 0
}

.region-sidebar-first .block:first-child h2.block-title,
.region-sidebar-second .block:first-child h2.block-title {
	margin-top: 0
}

p:last-child {
	margin-bottom: 0
}

.region-help>.glyphicon {
	font-size: 20px;
	float: left;
	margin: -0.05em 0.5em 0 0
}

.region-help .block {
	overflow: hidden
}

form#search-block-form {
	margin: 0
}

.navbar #block-search-form {
	float: none;
	margin: 5px 0 5px 5px
}

@media screen and (min-width:992px) {
	.navbar #block-search-form {
		float: right
	}
}

.navbar #block-search-form .input-group-btn {
	width: auto
}

.navbar-search .control-group {
	margin-bottom: 0px
}

ul.action-links {
	margin: 12px 0;
	padding: 0
}

ul.action-links li {
	display: inline;
	margin: 0;
	padding: 0 6px 0 0
}

ul.action-links .glyphicon {
	padding-right: 0.5em
}

input,
textarea,
select,
.uneditable-input {
	max-width: 100%;
	width: auto
}

input.error {
	color: #a94442;
	border-color: #ebccd1
}

fieldset legend.panel-heading {
	float: left;
	line-height: 1em;
	margin: 0
}

fieldset .panel-body {
	clear: both;
	display: inherit
}

fieldset .panel-heading a.panel-title {
	color: inherit;
	display: block;
	margin: -10px -15px;
	padding: 10px 15px
}

fieldset .panel-heading a.panel-title:hover {
	text-decoration: none
}

.form-group:last-child,
.panel:last-child {
	margin-bottom: 0
}

.form-horizontal .form-group {
	margin-left: 0;
	margin-right: 0
}

.form-actions {
	clear: both
}

.managed-files.table td:first-child {
	width: 100%
}

div.image-widget-data {
	float: none;
	overflow: hidden
}

table.sticky-header {
	z-index: 1
}

.resizable-textarea textarea {
	border-radius: 4px 4px 0 0
}

.text-format-wrapper {
	margin-bottom: 15px
}

.text-format-wrapper>.form-type-textarea,
.text-format-wrapper .filter-wrapper {
	margin-bottom: 0
}

.filter-wrapper {
	border-radius: 0 0 4px 4px
}

.filter-wrapper .panel-body {
	padding: 7px
}

.filter-wrapper .form-type-select {
	min-width: 30%
}

.filter-wrapper .form-type-select .filter-list {
	width: 100%
}

.filter-help {
	margin-top: 5px;
	text-align: center
}

@media screen and (min-width:768px) {
	.filter-help {
		float: right
	}
}

.filter-help .glyphicon {
	margin: 0 5px 0 0;
	vertical-align: text-top
}

.radio:first-child,
.checkbox:first-child {
	margin-top: 0
}

.radio:last-child,
.checkbox:last-child {
	margin-bottom: 0
}

.help-block,
.control-group .help-inline {
	color: #777;
	font-size: 12px;
	margin: 5px 0 10px;
	padding: 0
}

.panel-heading {
	display: block
}

a.tabledrag-handle .handle {
	height: auto;
	width: auto
}

.error {
	color: #a94442
}

div.error,
table tr.error {
	background-color: #f2dede;
	color: #a94442
}

.form-group.error,
.form-group.has-error {
	background: none
}

.form-group.error label,
.form-group.error .control-label,
.form-group.has-error label,
.form-group.has-error .control-label {
	color: #a94442;
	font-weight: 600
}

.form-group.error input,
.form-group.error textarea,
.form-group.error select,
.form-group.error .uneditable-input,
.form-group.has-error input,
.form-group.has-error textarea,
.form-group.has-error select,
.form-group.has-error .uneditable-input {
	color: #555
}

.form-group.error .help-block,
.form-group.error .help-inline,
.form-group.has-error .help-block,
.form-group.has-error .help-inline {
	color: #777
}

.nav-tabs {
	margin-bottom: 10px
}

ul li.collapsed,
ul li.expanded,
ul li.leaf {
	list-style: none;
	list-style-image: none
}

.tabs--primary {
	background: #ffffff;
	margin-bottom: 0;
	padding-top: 20px;
	padding-left: 15px
}

@media (min-width:992px) {
	.tabs--primary {
		padding-left: 25px
	}
}

.tabs--secondary {
	margin: 0 0 10px
}

.submitted {
	margin-bottom: 1em;
	font-style: italic;
	font-weight: normal;
	color: #777
}

.form-type-password-confirm {
	position: relative
}

.form-type-password-confirm label {
	display: block
}

.form-type-password-confirm label .label {
	float: right
}

.form-type-password-confirm .password-help {
	padding-left: 2em
}

@media (min-width:768px) {
	.form-type-password-confirm .password-help {
		border-left: 1px solid #ddd;
		left: 50%;
		margin-left: 5px;
		position: absolute
	}
}

@media (min-width:992px) {
	.form-type-password-confirm .password-help {
		left: 33.33333%
	}
}

.form-type-password-confirm .progress {
	background: transparent;
	-moz-border-radius: 0 0 5px 5px;
	-webkit-border-radius: 0 0 5px 5px;
	border-radius: 0 0 5px 5px;
	-webkit-box-shadow: none;
	-moz-box-shadow: none;
	box-shadow: none;
	height: 4px;
	margin: -5px 0px 0
}

.form-type-password-confirm .form-type-password {
	clear: left
}

.form-type-password-confirm .form-control-feedback {
	right: 15px
}

.form-type-password-confirm .help-block {
	clear: both
}

ul.pagination li>a.progress-disabled {
	float: left
}

.form-autocomplete .glyphicon {
	color: #777;
	font-size: 120%
}

.form-autocomplete .glyphicon.glyphicon-spin {
	color: #337ab7
}

.form-autocomplete .input-group-addon {
	background-color: #fff
}

.ajax-progress-bar {
	border-radius: 0 0 4px 4px;
	border: 1px solid #ccc;
	margin: -1px 0 0;
	padding: 6px 12px;
	width: 100%
}

.ajax-progress-bar .progress {
	height: 8px;
	margin: 0
}

.ajax-progress-bar .percentage,
.ajax-progress-bar .message {
	color: #777;
	font-size: 14px;
	line-height: 1em;
	margin: 5px 0 0;
	padding: 0
}

.glyphicon-spin {
	display: inline-block;
	-moz-animation: spin 1s infinite linear;
	-o-animation: spin 1s infinite linear;
	-webkit-animation: spin 1s infinite linear;
	animation: spin 1s infinite linear
}

a .glyphicon-spin {
	display: inline-block;
	text-decoration: none
}

@-moz-keyframes spin {
	0% {
		-moz-transform: rotate(0deg)
	}
	100% {
		-moz-transform: rotate(359deg)
	}
}

@-webkit-keyframes spin {
	0% {
		-webkit-transform: rotate(0deg)
	}
	100% {
		-webkit-transform: rotate(359deg)
	}
}

@-o-keyframes spin {
	0% {
		-o-transform: rotate(0deg)
	}
	100% {
		-o-transform: rotate(359deg)
	}
}

@-ms-keyframes spin {
	0% {
		-ms-transform: rotate(0deg)
	}
	100% {
		-ms-transform: rotate(359deg)
	}
}

@keyframes spin {
	0% {
		transform: rotate(0deg)
	}
	100% {
		transform: rotate(359deg)
	}
}

.tabbable {
	margin-bottom: 20px
}

.tabs-below>.nav-tabs,
.tabs-left>.nav-tabs,
.tabs-right>.nav-tabs {
	border-bottom: 0
}

.tabs-below>.nav-tabs .summary,
.tabs-left>.nav-tabs .summary,
.tabs-right>.nav-tabs .summary {
	color: #777;
	font-size: 14px
}

.tab-pane>.panel-heading {
	display: none
}

.tab-content>.active {
	display: block
}

.tabs-below>.nav-tabs {
	border-top: 1px solid #ddd
}

.tabs-below>.nav-tabs>li {
	margin-top: -1px;
	margin-bottom: 0
}

.tabs-below>.nav-tabs>li>a {
	border-radius: 0 0 4px 4px
}

.tabs-below>.nav-tabs>li>a:hover,
.tabs-below>.nav-tabs>li>a:focus {
	border-top-color: #ddd;
	border-bottom-color: transparent
}

.tabs-below>.nav-tabs>.active>a,
.tabs-below>.nav-tabs>.active>a:hover,
.tabs-below>.nav-tabs>.active>a:focus {
	border-color: transparent #ddd #ddd #ddd
}

.tabs-left>.nav-tabs,
.tabs-right>.nav-tabs {
	padding-bottom: 20px;
	width: 220px
}

.tabs-left>.nav-tabs>li,
.tabs-right>.nav-tabs>li {
	float: none
}

.tabs-left>.nav-tabs>li:focus,
.tabs-right>.nav-tabs>li:focus {
	outline: 0
}

.tabs-left>.nav-tabs>li>a,
.tabs-right>.nav-tabs>li>a {
	margin-right: 0;
	margin-bottom: 3px
}

.tabs-left>.nav-tabs>li>a:focus,
.tabs-right>.nav-tabs>li>a:focus {
	outline: 0
}

.tabs-left>.tab-content,
.tabs-right>.tab-content {
	border-radius: 0 4px 4px 4px;
	-webkit-box-shadow: 0 1px 1px rgba(0, 0, 0, 0.05);
	-moz-box-shadow: 0 1px 1px rgba(0, 0, 0, 0.05);
	box-shadow: 0 1px 1px rgba(0, 0, 0, 0.05);
	border: 1px solid #ddd;
	overflow: hidden;
	padding: 10px 15px
}

.tabs-left>.nav-tabs {
	float: left;
	margin-right: -1px
}

.tabs-left>.nav-tabs>li>a {
	border-radius: 4px 0 0 4px
}

.tabs-left>.nav-tabs>li>a:hover,
.tabs-left>.nav-tabs>li>a:focus {
	border-color: #eee #ddd #eee #eee
}

.tabs-left>.nav-tabs>.active>a,
.tabs-left>.nav-tabs>.active>a:hover,
.tabs-left>.nav-tabs>.active>a:focus {
	border-color: #ddd transparent #ddd #ddd;
	-webkit-box-shadow: -1px 1px 1px rgba(0, 0, 0, 0.05);
	-moz-box-shadow: -1px 1px 1px rgba(0, 0, 0, 0.05);
	box-shadow: -1px 1px 1px rgba(0, 0, 0, 0.05)
}

.tabs-right>.nav-tabs {
	float: right;
	margin-left: -1px
}

.tabs-right>.nav-tabs>li>a {
	border-radius: 0 4px 4px 0
}

.tabs-right>.nav-tabs>li>a:hover,
.tabs-right>.nav-tabs>li>a:focus {
	border-color: #eee #eee #eee #ddd;
	-webkit-box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.05);
	-moz-box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.05);
	box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.05)
}

.tabs-right>.nav-tabs>.active>a,
.tabs-right>.nav-tabs>.active>a:hover,
.tabs-right>.nav-tabs>.active>a:focus {
	border-color: #ddd #ddd #ddd transparent
}

th.checkbox,
td.checkbox,
th.radio,
td.radio {
	display: table-cell
}

.views-display-settings .label {
	font-size: 100%;
	color: #666666
}

.views-display-settings .footer {
	padding: 0;
	margin: 4px 0 0 0
}

.views-exposed-form .views-exposed-widget .btn {
	margin-top: 1.8em
}

table .radio input[type="radio"],
table .checkbox input[type="checkbox"] {
	max-width: inherit
}

.form-horizontal .form-group label {
	position: relative;
	min-height: 1px;
	margin-top: 0;
	margin-bottom: 0;
	padding-top: 7px;
	padding-left: 5px;
	padding-right: 5px;
	text-align: right
}

@media (min-width:768px) {
	.form-horizontal .form-group label {
		float: left;
		width: 16.66667%
	}
}

.alert {
	margin: 0;
	border-radius: 0
}

.alert a {
	font-weight: bold
}

.alert-success a,
.alert-success a:hover,
.alert-success a:focus {
	color: #2b542c
}

.alert-info a,
.alert-info a:hover,
.alert-info a:focus {
	color: #245269
}

.alert-warning a,
.alert-warning a:hover,
.alert-warning a:focus {
	color: #66512c
}

.alert-danger a,
.alert-danger a:hover,
.alert-danger a:focus {
	color: #843534
}

div.image-style-new,
div.image-style-new div {
	display: block
}

div.image-style-new div.input-group {
	display: table
}

td.module,
.table-striped>tbody>tr:nth-child(odd)>td.module,
.table>tbody>tr>td.module {
	background: #ddd;
	font-weight: 700
}

.book-toc>.dropdown-menu {
	overflow: hidden
}

.book-toc>.dropdown-menu>.dropdown-header {
	white-space: nowrap
}

.book-toc>.dropdown-menu>li:nth-child(1)>a {
	font-weight: bold
}

.book-toc>.dropdown-menu .dropdown-menu {
	-webkit-box-shadow: none;
	-moz-box-shadow: none;
	box-shadow: none;
	border: 0;
	display: block;
	font-size: 14px;
	margin: 0;
	padding: 0;
	position: static;
	width: 100%
}

.book-toc>.dropdown-menu .dropdown-menu>li {
	padding-left: 20px
}

.book-toc>.dropdown-menu .dropdown-menu>li>a {
	margin-left: -40px;
	padding-left: 60px
}

#features-filter .form-item.form-type-checkbox {
	padding-left: 20px
}

fieldset.features-export-component {
	font-size: 14px
}

html.js #features-export-form fieldset.features-export-component,
fieldset.features-export-component {
	margin: 0 0 11px
}

fieldset.features-export-component .panel-heading {
	padding: 5px 10px
}

fieldset.features-export-component .panel-heading a.panel-title {
	font-size: 14px;
	font-weight: 500;
	margin: -5px -10px;
	padding: 5px 10px
}

fieldset.features-export-component .panel-body {
	padding: 0 10px
}

div.features-export-list {
	margin: -11px 0 10px;
	padding: 0 10px
}

fieldset.features-export-component .component-select .form-type-checkbox,
div.features-export-list .form-type-checkbox {
	line-height: 1em;
	margin: 5px 5px 5px 0 !important;
	min-height: 0;
	padding: 3px 3px 3px 25px !important
}

fieldset.features-export-component .component-select .form-type-checkbox input[type=checkbox],
div.features-export-list .form-type-checkbox input[type=checkbox] {
	margin-top: 0
}

body.navbar-is-fixed-top.navbar-administration.navbar-horizontal.navbar-tray-open .navbar-fixed-top {
	top: 79px
}

body.navbar-is-fixed-top.navbar-administration .navbar-fixed-top {
	top: 39px
}

@media (min-width:768px) {
	body.navbar-is-fixed-top.navbar-administration.navbar-vertical.navbar-tray-open .navbar-fixed-top {
		left: 240px;
		left: 24rem
	}
}

.navbar-administration #navbar-administration.navbar-oriented .navbar-bar {
	z-index: 1032
}

.navbar-administration #navbar-administration .navbar-tray {
	z-index: 1031
}

body.navbar-is-fixed-top.navbar-administration {
	padding-top: 103px !important
}

body.navbar-is-fixed-top.navbar-administration.navbar-horizontal.navbar-tray-open {
	padding-top: 143px !important
}

body.navbar-tray-open.navbar-vertical.navbar-fixed {
	margin-left: 240px;
	margin-left: 24rem
}

#navbar-administration.navbar-oriented .navbar-tray-vertical {
	width: 24rem
}

a.icon-before .glyphicon {
	margin-right: .25em
}

a.icon-after .glyphicon {
	margin-left: .25em
}

.btn.icon-before .glyphicon {
	margin-left: -.25em;
	margin-right: .25em
}

.btn.icon-after .glyphicon {
	margin-left: .25em;
	margin-right: -.25em
}

@font-face {
	font-family: "icns";
	src: url(./assets/files/websymbolsligaregular.eot);
	src: url(./assets/files/websymbolsligaregular.eot?#iefix) format("eot"), url(./assets/mferefresh/fonts/websymbolsligaregular.ttf) format("truetype"), url(./assets/mferefresh/fonts/websymbolsligaregular.woff) format("woff"), url(./assets/mferefresh/fonts/websymbolsligaregular.svg) format("svg");
	font-weight: normal;
	font-style: normal
}

@font-face {
	font-family: "SourceSans";
	src: url(./assets/mferefresh/fonts/source-sans/SourceSansPro-Light.woff2) format("woff2"), url(./assets/mferefresh/fonts/source-sans/SourceSansPro-Light.woff) format("woff");
	font-weight: normal;
	font-style: normal
}

@font-face {
	font-family: "SourceSansRegular";
	src: url(./assets/mferefresh/fonts/source-sans/SourceSansPro-Regular.woff2) format("woff2"), url(./assets/mferefresh/fonts/source-sans/SourceSansPro-Regular.woff) format("woff");
	font-weight: normal;
	font-style: normal
}

@font-face {
	font-family: "SourceSansRegular";
	src: url(./assets/mferefresh/fonts/source-sans/SourceSansPro-Bold.woff2) format("woff2"), url(./assets/mferefresh/fonts/source-sans/SourceSansPro-Bold.woff) format("woff");
	font-weight: bold;
	font-style: normal
}

@font-face {
	font-family: "SourceSansRegular";
	src: url(./assets/mferefresh/fonts/source-sans/SourceSansPro-Italic.woff2) format("woff2"), url(./assets/mferefresh/fonts/source-sans/SourceSansPro-Italic.woff) format("woff");
	font-weight: normal;
	font-style: italic
}

@font-face {
	font-family: "SourceSansRegular";
	src: url(./assets/mferefresh/fonts/source-sans/SourceSansPro-BoldItalic.woff2) format("woff2"), url(./assets/mferefresh/fonts/source-sans/SourceSansPro-BoldItalic.woff) format("woff");
	font-weight: bold;
	font-style: italic
}

@font-face {
	font-family: "SourceSansSemibold";
	src: url(./assets/mferefresh/fonts/source-sans/SourceSansPro-SemiBold.woff2) format("woff2"), url(./assets/mferefresh/fonts/source-sans/SourceSansPro-Semibold.woff) format("woff");
	font-weight: normal;
	font-style: normal
}

@font-face {
	font-family: "LatoLight";
	src: url(./assets/mferefresh/fonts/lato/Lato-Light.woff2) format("woff2"), url(./assets/mferefresh/fonts/lato/Lato-Light.woff) format("woff");
	font-weight: normal;
	font-style: normal
}

@font-face {
	font-family: "Lato";
	src: url(./assets/mferefresh/fonts/lato/Lato-Regular.woff2) format("woff2"), url(./assets/mferefresh/fonts/lato/Lato-Regular.woff) format("woff");
	font-weight: normal;
	font-style: normal
}

@font-face {
	font-family: "Lato";
	src: url(./assets/mferefresh/fonts/lato/Lato-Bold.woff2) format("woff2"), url(./assets/mferefresh/fonts/lato/Lato-Bold.woff) format("woff");
	font-weight: bold;
	font-style: normal
}

@font-face {
	font-family: "LatoMedium";
	src: url(./assets/mferefresh/fonts/lato/Lato-Medium.woff2) format("woff2"), url(./assets/mferefresh/fonts/lato/Lato-Medium.woff) format("woff");
	font-weight: normal;
	font-style: normal
}

@font-face {
	font-family: "LatoBold";
	src: url(./assets/mferefresh/fonts/lato/Lato-Bold.woff2) format("woff2"), url(./assets/mferefresh/fonts/lato/Lato-Bold.woff) format("woff");
	font-weight: bold;
	font-style: normal
}

@font-face {
	font-family: "Merriweather";
	src: url(./assets/mferefresh/fonts/merriweather/Merriweather-Regular.woff2) format("woff2"), url(./assets/mferefresh/fonts/merriweather/Merriweather-Regular.woff) format("woff");
	font-weight: normal;
	font-style: normal
}

@font-face {
	font-family: "MerriweatherLight";
	src: url(./assets/mferefresh/fonts/merriweather/Merriweather-Light.woff2) format("woff2"), url(./assets/mferefresh/fonts/merriweather/Merriweather-Light.woff) format("woff");
	font-weight: normal;
	font-style: normal
}

@font-face {
	font-family: "MerriweatherLightItalic";
	src: url(./assets/mferefresh/fonts/merriweather/Merriweather-LightItalic.woff2) format("woff2"), url(./assets/mferefresh/fonts/merriweather/Merriweather-LightItalic.woff) format("woff");
	font-weight: normal;
	font-style: normal
}

@font-face {
	font-family: "poppins";
	src: url(./assets/mferefresh/fonts/poppins/poppins-regular.woff2) format("woff2"), url(./assets/mferefresh/fonts/poppins/poppins-regular.woff) format("woff");
	font-weight: normal;
	font-style: normal
}

@font-face {
	font-family: "poppinssemibold";
	src: url(./assets/mferefresh/fonts/poppins/poppins-semibold.woff2) format("woff2"), url(./assets/mferefresh/fonts/poppins/poppins-semibold.woff) format("woff");
	font-weight: normal;
	font-style: normal
}

@font-face {
	font-family: "poppinsbold";
	src: url(./assets/mferefresh/fonts/poppins/poppins-bold.woff2) format("woff2"), url(./assets/mferefresh/fonts/poppins/poppins-bold.woff) format("woff");
	font-weight: normal;
	font-style: normal
}

@font-face {
	font-family: 'MFE';
	src: url(./assets/mferefresh/fonts/mfe/MFE.eot?qmio0g);
	src: url(./assets/mferefresh/fonts/mfe//MFE.eot?qmio0g#iefix) format("embedded-opentype"), url(./assets/mferefresh/fonts/mfe//MFE.ttf?qmio0g) format("truetype"), url(./assets/mferefresh/fonts/mfe//MFE.woff?qmio0g) format("woff"), url(./assets/mferefresh/fonts/mfe//MFE.svg?qmio0g#MFE) format("svg");
	font-weight: normal;
	font-style: normal
}

h1 {
	font-family: "SourceSans", helvetica, arial, sans-serif
}

h2 {
	font-family: "Merriweather", georgia, serif;
	color: #0C3F4D;
	margin-top: 30px
}

h3 {
	font-family: "SourceSansSemibold", helvetica, arial, sans-serif;
	margin-top: 22px
}

p.leading {
	font-size: 18px;
	font-family: "SourceSans", helvetica, arial, sans-serif;
	margin-bottom: 23px
}

@media (min-width:992px) {
	p.leading {
		font-size: 22px;
		margin-bottom: 28px
	}
}

p.leading:last-child {
	margin-bottom: 0
}

.maori-large {
	font-family: "MerriweatherLightItalic", georgia, serif;
	font-size: 20px
}

[class^="mfe-icon-"],
[class*=" mfe-icon-"] {
	font-family: "MFE" !important;
	speak: none;
	font-style: normal;
	font-weight: normal;
	font-variant: normal;
	text-transform: none;
	line-height: 1;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale
}

.mfe-icon-facebook:before {
	content: "\e900"
}

.mfe-icon-home-filled:before {
	content: "\e901"
}

.mfe-icon-home:before {
	content: "\e902"
}

.mfe-icon-instagram:before {
	content: "\e903"
}

.mfe-icon-linkedin:before {
	content: "\e904"
}

.mfe-icon-minus:before {
	content: "\e905"
}

.mfe-icon-plus:before {
	content: "\e906"
}

.mfe-icon-twitter:before {
	content: "\e907"
}

.mfe-icon-up:before {
	content: "\e908"
}

.mfe-icon-youtube:before {
	content: "\e909"
}

.field {
	background: #ffffff;
	padding: 0 15px 15px
}

@media (min-width:992px) {
	.field {
		padding: 0 25px 25px
	}
}

.field h1:first-child,
.field h2:first-child,
.field h3:first-child,
.field h4:first-child {
	margin-top: 0
}

article.node>.field:first-of-type {
	padding-top: 25px
}

.field-name-body {
	margin-bottom: 3px;
	overflow: hidden
}

table {
	border: none
}

table caption {
	padding: 8px 0;
	border-top: 1px solid #C6DDE5;
	font-style: italic
}

table>thead {
	vertical-align: middle;
	text-transform: uppercase;
	font-weight: normal
}

table>thead th {
	vertical-align: middle !important;
	text-transform: uppercase;
	background: #317F9C !important;
	color: #ffffff;
	font-weight: normal
}

table>thead th a {
	color: #ffffff;
	position: relative;
	display: block
}

table>thead th a:hover,
table>thead th a:focus {
	color: #ffffff
}

table>thead th .active img {
	display: none
}

table>thead th a[href*="sort=asc"]:before {
	content: "Ã‹";
	font-family: 'icns';
	-webkit-font-smoothing: antialiased
}

table>thead th a[href*="sort=desc"]:before {
	content: "Ãˆ";
	font-family: 'icns';
	-webkit-font-smoothing: antialiased
}

table>tbody>tr>th.active,
table>tbody>tr>td.active {
	background-color: transparent !important
}

table>tbody>tr.active>td,
table>tbody>tr.active>th {
	background-color: transparent !important
}

table>tfoot>tr>th.active,
table>tfoot>tr>td.active {
	background-color: transparent !important
}

table>tfoot>tr.active>td,
table>tfoot>tr.active>th {
	background-color: transparent !important
}

table th a img {
	line-height: 1;
	margin-right: 3px;
	float: left
}

table td,
table th {
	padding: 10px 5px;
	border: 1px solid #d3d3d3
}

table p {
	margin: 0;
	padding: 0
}

table.plain *,
table#plain * {
	background: none !important;
	border: none !important
}

table.plain td,
table.plain th,
table#plain td,
table#plain th {
	padding: 3px
}

.table>thead>tr>th,
.table>thead>tr>td {
	padding: 10px 5px
}

.table>tbody>tr>th,
.table>tbody>tr>td {
	padding: 10px 5px
}

.table>tfoot>tr>th,
.table>tfoot>tr>td {
	padding: 10px 5px
}

table.small-text,
.small-text table {
	font-size: 90%
}

td.vertical-align-bottom {
	vertical-align: bottom
}

.clear {
	clear: both
}

sub,
super {
	font-size: 12px !important
}

p.smallprint {
	font-size: 12px
}

.contentimageleft,
.contentimageright {
	clear: both;
	overflow: hidden;
	margin-bottom: 10px
}

.contentimageleft {
	float: left;
	margin-right: 15px
}

.contentimageright {
	float: right;
	margin-left: 15px
}

.contentimageleft img,
.contentimageright img {
	margin-bottom: 5px
}

.contentimageleft p,
.contentimageright p {
	display: block
}

#furtherinfo,
.highlight-box,
.highlight-box2,
.highlight-box3,
.highlight-box4,
.highlight-box5,
.highlight-box6,
.tips-box {
	overflow: hidden;
	margin-bottom: 20px;
	padding: 20px;
	border: 1px solid #96e5ff;
	background-color: #eaeadf;
	background-color: #e3e5e4;
	color: #474342;
	border: none
}

#furtherinfo *,
.highlight-box *,
.highlight-box2 *,
.highlight-box3 *,
.highlight-box4 *,
.highlight-box5 *,
.highlight-box6 *,
.tips-box * {
	color: #474342
}

#furtherinfo h2,
.highlight-box h2,
.highlight-box2 h2,
.highlight-box3 h2,
.highlight-box4 h2,
.highlight-box5 h2,
.highlight-box6 h2,
.tips-box h2 {
	margin: 0;
	padding: 0 0 7px 0;
	line-height: 1.2
}

#furtherinfo a,
.highlight-box a,
.highlight-box2 a,
.highlight-box3 a,
.highlight-box4 a,
.highlight-box5 a,
.highlight-box6 a,
.tips-box a {
	color: #1f9682 !important
}

#furtherinfo a:hover,
#furtherinfo a:focus,
.highlight-box a:hover,
.highlight-box a:focus,
.highlight-box2 a:hover,
.highlight-box2 a:focus,
.highlight-box3 a:hover,
.highlight-box3 a:focus,
.highlight-box4 a:hover,
.highlight-box4 a:focus,
.highlight-box5 a:hover,
.highlight-box5 a:focus,
.highlight-box6 a:hover,
.highlight-box6 a:focus,
.tips-box a:hover,
.tips-box a:focus {
	color: #17556c !important;
	text-decoration: underline
}

#furtherinfo hr,
.highlight-box hr,
.highlight-box2 hr,
.highlight-box3 hr,
.highlight-box4 hr,
.highlight-box5 hr,
.highlight-box6 hr,
.tips-box hr {
	border: none;
	border-bottom: 1px #fff solid;
	padding-top: 0;
	padding-bottom: 10px;
	margin-top: 0;
	margin-bottom: 20px
}

.highlight-box *:last-child {
	margin-bottom: 0;
	padding-bottom: 0
}

.highlight-red,
.highlight-green {
	color: #fff;
	display: inline-block;
	padding: 20px;
	margin: 5px 0
}

.highlight-red {
	background-color: #474342
}

.highlight-green {
	background-color: #1f9682
}

.description-text ul ul {
	margin: 0.3em 0 1em 0
}

.description-text ul ul li {
	list-style: circle
}

.description-text ul ul ul li {
	list-style: square
}

.description-text table {
	margin: 0
}

.image-caption-box-left {
	float: left
}

.image-caption-box-right {
	float: right
}

.smallprint {
	font-size: 13px;
	font-weight: bold
}

.smalltext,
div.notes {
	font-style: italic
}

.smalltext {
	font-size: 13px
}

.word {
	background-repeat: no-repeat;
	padding-left: 24px
}

.pdf {
	background-repeat: no-repeat;
	padding-left: 24px;
	background: url(./assets/mferefresh/images/icon-pdf.png) no-repeat left 2px
}

.word {
	background: url(./assets/mferefresh/images/icon-doc.png) no-repeat left 2px
}

.imagecaptionleft,
.imagecaptionright {
	background-color: #f1f1f1;
	font-size: 13px
}

.imagecaption {
	background-color: #f1f1f1;
	font-size: 13px
}

.imagecaption p {
	padding: 0 10px 10px 10px;
	margin: 0
}

.imagecaptionleft p,
.imagecaptionright p,
.imagecaption span,
.imagecaptionleft span,
.imagecaptionright span {
	padding: 0 10px 10px 10px;
	margin: 0
}

.imagecaptionleft {
	float: left;
	margin: 5px 15px 13px 0
}

.imagecaption {
	float: right;
	width: auto;
	margin: 5px 0 13px 15px;
	padding: 5px
}

.image-caption-box-left,
.image-caption-box-right {
	background-color: #f1f1f1;
	padding: 5px;
	font-size: 13px
}

.image-caption-box-left p,
.image-caption-box-right p {
	padding: 5px 0;
	margin: 0
}

.image-caption-box-left {
	float: left;
	margin: 5px 15px 13px 0
}

.image-caption-box-right {
	float: right;
	margin: 5px 0 13px 15px
}

.imagecaption img {
	margin-bottom: 6px
}

.imagecaptionleft img {
	margin-bottom: 6px;
	float: left
}

.imagecaption span,
.imagecaptionleft span {
	display: block;
	clear: left
}

.img-float-right,
.img-float-left {
	margin-bottom: 10px
}

.img-float-right {
	float: right;
	margin-left: 10px
}

.img-float-left {
	float: left;
	margin-right: 10px
}

table td.shade,
table th.shade,
table tr:hover td.shade {
	background: #ddd
}

table td.total {
	font-weight: bold
}

table tr.total td,
table tr.total:hover td {
	font-weight: bold
}

table th.total,
table tr:hover td.total,
table td.table-subheader {
	font-weight: bold
}

table tr.table-subheader td,
table tr.table-subheader:hover td {
	font-weight: bold
}

table th.table-subheader,
table tr:hover td.table-subheader {
	font-weight: bold
}

.vertical-align-middle {
	vertical-align: middle
}

.table-cell-center {
	text-align: center
}

.table-header-right,
.table-cell-right {
	text-align: right
}

.wide-table-scroll {
	overflow: scroll
}

.wide-table-scroll caption {
	margin-bottom: 4px
}

.texturaldescription {
	margin: 5px 0 0 0;
	font-style: italic;
	font-size: 14px
}

.smiley-score h4 {
	float: left;
	display: block;
	margin: 0 10px 15px 0;
	position: relative;
	text-align: center;
	clear: left;
	font-size: 15px;
	-webkit-border-radius: 3px;
	border-radius: 3px;
	background-clip: padding-box;
	padding: 6px 8px;
	color: #fff
}

.smiley-score h4 span {
	color: #fff !important
}

.smiley-score h4.enviro-report-getting-worse {
	background: #FF6452
}

.smiley-score h4.enviro-report-getting-better {
	background: #63DF69
}

.smiley-score h4.enviro-report-mixed {
	background: #FFB34B
}

.smiley-score img {
	display: none
}

.pullquote-left,
.blockpull-left,
.blockpull,
.pullquote-right,
.blockpull-right {
	background-color: #17556c;
	padding: 1em;
	width: 33%;
	-webkit-font-smoothing: antialiased
}

.pullquote-left h4,
.pullquote-left p:last-child,
.blockpull-left h4,
.blockpull-left p:last-child,
.blockpull h4,
.blockpull p:last-child,
.pullquote-right h4,
.pullquote-right p:last-child,
.blockpull-right h4,
.blockpull-right p:last-child {
	margin: 0
}

.pullquote-left h4,
.blockpull-left h4,
.blockpull h4,
.pullquote-right h4,
.blockpull-right h4 {
	margin-bottom: .3em;
	font-weight: bold
}

.pullquote-left h4 span,
.blockpull-left h4 span,
.blockpull h4 span,
.pullquote-right h4 span,
.blockpull-right h4 span {
	color: #fff !important
}

.pullquote-left p,
.blockpull-left p,
.blockpull p,
.pullquote-right p,
.blockpull-right p {
	color: #fff;
	font-size: 13px
}

.pullquote-left,
.blockpull-left,
.blockpull {
	margin: 3px 15px 15px 0;
	float: left
}

.pullquote-right,
.blockpull-right {
	margin: 3px 0 15px 15px;
	float: right
}

.pullquote-left-smiley-green,
.pullquote-left-smiley-red,
.pullquote-left-smiley-orange {
	margin: 3px 15px 15px 0;
	padding: 13px 15px 0 48px;
	width: 33%;
	float: left
}

.pullquote-right-smiley-green,
.pullquote-right-smiley-red,
.pullquote-right-smiley-orange {
	margin: 3px 0 15px 15px;
	padding: 1em;
	width: 33%;
	float: right
}

.pullquote-left-smiley-green,
.pullquote-right-smiley-green {
	background: #63DF69
}

.pullquote-left-smiley-red,
.pullquote-right-smiley-red {
	background: #FF6452
}

.pullquote-left-smiley-orange,
.pullquote-right-smiley-orange {
	background: #FFB34B
}

.pullquote-left-smiley-green p,
.pullquote-left-smiley-red p,
.pullquote-left-smiley-orange p,
.pullquote-right-smiley-green p,
.pullquote-right-smiley-red p,
.pullquote-right-smiley-orange p {
	color: #fff;
	font-size: 18px;
	margin: 0;
	padding: 0;
	font-weight: bold;
	-webkit-font-smoothing: antialiased
}

.blockpull.rating1,
.blockpull.rating2,
.blockpull.rating3,
.blockpull.rating4 {
	background: none;
	background-color: #0b2265;
	margin: 3px 0 15px 15px;
	padding: 1em;
	width: 33%;
	float: right;
	text-align: left
}

.blockpull.rating1 p,
.blockpull.rating2 p,
.blockpull.rating3 p,
.blockpull.rating4 p {
	color: #fff
}

.blockpull.rating1 h2,
.blockpull.rating1 h3,
.blockpull.rating1 h4,
.blockpull.rating1 h5,
.blockpull.rating1 h6 {
	color: #a4d559
}

.blockpull.rating2 h2,
.blockpull.rating2 h3,
.blockpull.rating2 h4,
.blockpull.rating2 h5,
.blockpull.rating2 h6 {
	color: #ffba52
}

.blockpull.rating3 h2,
.blockpull.rating3 h3,
.blockpull.rating3 h4,
.blockpull.rating3 h5 {
	color: #ff4e4e
}

.description-link,
.description-showall,
.description-hideall,
.description-link-show strong {
	cursor: pointer;
	font-weight: normal;
	text-decoration: underline
}

.figure-box-float-right {
	float: right
}

.er-indicator,
.er-indicators,
.er-case-studies {
	line-height: 1.3;
	margin: 10px 0
}

.global-navigation {
	clear: both
}

.global-navigation ul.nav li {
	background: none
}

.global-navigation ul.nav li a {
	font-weight: normal
}

.block-tb-megamenu-main-menu {
	display: none;
	margin-bottom: 20px
}

.tb-megamenu [class^="icon-"],
.tb-megamenu [class*=" icon-"] {
	display: none
}

.tb-megamenu {
	background: none
}

.tb-megamenu .tb-megamenu-nav .level-2>a {
	font-family: "Lato", helvetica, arial, sans-serif;
	font-weight: bold;
	font-size: 17px
}

.tb-megamenu .nav {
	-webkit-font-smoothing: antialiased
}

.tb-megamenu .nav>li>a {
	padding: 0;
	border-right: none
}

.tb-megamenu .nav li.dropdown.active>.dropdown-toggle,
.tb-megamenu .nav li.dropdown.open.active>.dropdown-toggle {
	border-color: #267998
}

.tb-megamenu .nav li.dropdown>.dropdown-toggle .caret {
	display: none
}

.tb-megamenu .nav>li.dropdown.open.active>a:hover {
	border-color: #267998;
	text-decoration: none !important
}

.tb-megamenu .nav>.active>a {
	background: none
}

.tb-megamenu .nav>.active>a:hover,
.tb-megamenu .nav>.active>a:focus {
	background: #0c414f;
	border: 1px solid transparent
}

.tb-megamenu .nav-collapse .nav>li>a:hover,
.tb-megamenu .nav-collapse .nav>li>a:focus {
	background-color: #0c414f;
	color: #fff;
	border: 1px solid transparent
}

.tb-megamenu .nav li.dropdown.open>.dropdown-toggle {
	background-color: #008575;
	color: #fff;
	border: 1px solid #008575
}

.tb-megamenu .dropdown-menu {
	background-color: #0c414f;
	border: none;
	-webkit-box-shadow: 0 5px 2px -2px rgba(0, 0, 0, 0.3);
	-moz-box-shadow: 0 5px 2px -2px rgba(0, 0, 0, 0.3);
	box-shadow: 0 5px 2px -2px rgba(0, 0, 0, 0.3)
}

.tb-megamenu .mega-dropdown-inner {
	padding: 20px;
	border: none
}

.tb-megamenu .mega-dropdown-inner .mega-dropdown-inner {
	padding: 0;
	border: none
}

.tb-megamenu .mega-inner {
	padding: 0
}

.tb-megamenu .mega-nav .mega-group>.mega-group-title {
	text-transform: none;
	padding: 0
}

.tb-megamenu .dropdown-menu .mega-nav .mega-group>.mega-group-title,
.tb-megamenu .dropdown-menu .active .mega-nav .mega-group>.mega-group-title {
	text-transform: none;
	padding: 0
}

.tb-megamenu .span12.mega-col-nav .mega-inner {
	padding: 0
}

.tb-megamenu .mega-nav,
.tb-megamenu .dropdown-menu .mega-nav {
	margin-top: -18px
}

.tb-megamenu .mega-nav,
.tb-megamenu .dropdown-menu .mega-nav .mega-nav {
	margin-top: 0
}

.tb-megamenu .mega-nav>li {
	text-transform: none;
	margin-top: 18px
}

.tb-megamenu .dropdown-menu .mega-nav>li {
	text-transform: none;
	margin-top: 18px
}

.tb-megamenu .dropdown-menu li>a:hover,
.tb-megamenu .dropdown-menu li>a:focus {
	background: none;
	filter: none;
	text-decoration: underline
}

.tb-megamenu .dropdown-submenu:hover>a {
	background: none;
	filter: none;
	text-decoration: underline
}

.tb-megamenu .mega-nav>li li,
.tb-megamenu .dropdown-menu .mega-nav>li li {
	margin-top: 0
}

.tb-megamenu .mega-nav>li a,
.tb-megamenu .dropdown-menu .mega-nav>li a {
	padding: 0;
	color: #fffff0;
	line-height: 1.3
}

.tb-megamenu .mega-nav>li li,
.tb-megamenu .dropdown-menu .mega-nav>li li.active a {
	color: #fff
}

.tb-megamenu .mega-nav .mega-group>.mega-group-title {
	margin-bottom: 2px
}

.tb-megamenu .dropdown-menu .mega-nav .mega-group>.mega-group-title,
.tb-megamenu .dropdown-menu .active .mega-nav .mega-group>.mega-group-title {
	margin-bottom: 2px
}

.tb-megamenu .mega-nav .mega-group>.mega-group-title:hover {
	color: #fffff0;
	text-decoration: underline
}

.tb-megamenu .dropdown-menu .mega-nav .mega-group>.mega-group-title:hover,
.tb-megamenu .dropdown-menu .active .mega-nav .mega-group>.mega-group-title:hover {
	color: #fffff0;
	text-decoration: underline
}

.tb-megamenu .mega-nav .mega-group>.mega-group-title:active {
	color: #fffff0
}

.tb-megamenu .dropdown-menu .mega-nav .mega-group>.mega-group-title:active,
.tb-megamenu .dropdown-menu .active .mega-nav .mega-group>.mega-group-title:active {
	color: #fffff0;
	text-decoration: underline
}

.tb-megamenu .mega-nav .mega-group>.mega-group-title:focus {
	color: #fffff0;
	text-decoration: underline
}

.tb-megamenu .dropdown-menu .mega-nav .mega-group>.mega-group-title:focus,
.tb-megamenu .dropdown-menu .active .mega-nav .mega-group>.mega-group-title:focus {
	color: #fffff0;
	text-decoration: underline
}

.tb-megamenu .mega-nav>li .tb-megamenu-submenu,
.tb-megamenu .dropdown-menu .mega-nav>li .tb-megamenu-submenu {
	margin: 0
}

.tb-megamenu .mega-nav>li li a {
	color: #fff;
	border: none;
	margin-bottom: 0;
	font-size: 14px;
	margin: 2px 0;
	font-weight: normal
}

.tb-megamenu .dropdown-menu .mega-nav>li li a {
	color: #fff;
	border: none;
	margin-bottom: 0;
	font-size: 14px;
	margin: 2px 0;
	font-weight: normal
}

.tb-megamenu .dropdown-menu .mega-nav>li li a:hover {
	text-decoration: underline
}

.tb-megamenu .dropdown-menu .active>a {
	background: none
}

.tb-megamenu .dropdown-menu .active>a:hover {
	background: none;
	text-decoration: underline
}

.tb-megamenu .collapse {
	-webkit-transition: none;
	-o-transition: none;
	transition: none
}

.tb-megamenu .nav-collapse .dropdown-menu {
	background: #28778f
}

.tb-megamenu .mega-nav>li a,
.tb-megamenu .dropdown-menu .mega-nav>li a {
	margin-left: 0
}

.tb-megamenu .nav-collapse .nav li a {
	border: none
}

.tb-megamenu .nav-collapse .nav>li>a {
	border-bottom: 1px solid rgba(255, 255, 255, 0.5)
}

.tb-megamenu .nav-collapse .dropdown-menu a {
	border-bottom: 1px solid rgba(255, 255, 255, 0.5)
}

.tb-megamenu .nav li.dropdown.active>.dropdown-toggle,
.tb-megamenu .nav li.dropdown.open.active>.dropdown-toggle {
	background-color: #008575;
	color: #fff;
	border: 1px solid #008575
}

.tb-megamenu .nav>li.dropdown.open.active>a:hover {
	background-color: #008575;
	color: #fff;
	border: 1px solid #008575;
	text-decoration: underline
}

.tb-megamenu .mega-align-justify>.dropdown-menu {
	top: 40px;
	left: 0;
	right: 0;
	-webkit-border-radius: 8px;
	border-radius: 8px;
	background-clip: padding-box
}

.tb-megamenu .nav-collapse {
	top: 22px
}

.tb-megamenu .nav-collapse .nav>li>a {
	border-bottom: 1px #267998 solid;
	float: left;
	width: 100%;
	display: block;
	text-align: left;
	padding: 0;
	text-indent: 10px;
	background-color: #17556c
}

.tb-megamenu .nav-collapse .dropdown-menu {
	position: relative;
	display: none
}

.tb-megamenu .nav-collapse .dropdown-menu a {
	padding: 0;
	text-indent: 10px;
	background-color: #17556c
}

.tb-megamenu .mega.open>.mega-dropdown-menu,
.tb-megamenu .mega.dropdown-submenu:hover>.mega-dropdown-menu {
	display: none
}

@media (min-width:992px) {
	.block-tb-megamenu-main-menu {
		display: block
	}
	.tb-megamenu .nav-collapse .nav>li>a,
	.tb-megamenu .nav-collapse .dropdown-menu a {
		border: none;
		border-left: 1px solid #267998
	}
	.tb-megamenu .nav-collapse {
		top: 0;
		height: 0;
		display: block !important
	}
	.tb-megamenu .nav-collapse.collapse {
		height: 0
	}
	.tb-megamenu .nav-collapse .nav>li {
		margin-right: 5px
	}
	.tb-megamenu .nav-collapse .nav>li:last-of-type {
		float: right;
		margin-right: 0
	}
	.tb-megamenu .nav-collapse .nav>li>a {
		float: none;
		width: auto;
		text-align: center;
		padding: 0 20px;
		text-indent: 0;
		background-color: transparent;
		height: 30px;
		line-height: 30px;
		-webkit-border-radius: 17px;
		border-radius: 17px;
		background-clip: padding-box;
		border: 1px solid #6c93a1;
		font-family: "Merriweather", georgia, serif;
		font-size: 14px
	}
	.tb-megamenu .nav-collapse .nav>li>a:hover {
		background: #008575
	}
}

@media (min-width:992px) and (min-width:1200px) {
	.tb-megamenu .nav-collapse .nav>li>a {
		font-size: 16px
	}
}

@media (min-width:992px) {
	.tb-megamenu .nav-collapse .dropdown-menu {
		position: absolute;
		z-index: 1001
	}
	.tb-megamenu .nav-collapse .dropdown-menu a {
		padding: 0 20px;
		text-indent: 0;
		background: none
	}
	.tb-megamenu .mega.open>.mega-dropdown-menu,
	.tb-megamenu .mega.dropdown-submenu:hover>.mega-dropdown-menu {
		display: block
	}
}

/* .mobile-navigation {
	clear: both;
	position: absolute;
	top: 20px;
	width: 100%;
	z-index: 1000;
	-webkit-box-shadow: 0 5px 8px #a8a8a8;
	-moz-box-shadow: 0 5px 8px #a8a8a8;
	box-shadow: 0 5px 8px #a8a8a8
} */

/* .mobile-navigation.collapsed {
	display: none !important
} */

/* .mobile-navigation.opened {
	max-height: 1500px
} */

@media (min-width:992px) {
	.mobile-navigation {
		display: none !important
	}
}

.mobile-navigation .menu.main-menu {
	margin: 0;
	padding: 0
}

.mobile-navigation .menu.main-menu li {
	position: relative
}

.mobile-navigation .menu.main-menu a {
	color: #fff;
	display: block;
	padding: 8px 20px;
	border-top: 1px rgba(255, 255, 255, 0.2) solid;
	position: relative;
	background: #2b6e87
}

.mobile-navigation .menu.main-menu a.active {
	background: #008575 !important;
	color: #ffffff !important
}

.mobile-navigation .menu.main-menu a:hover,
.mobile-navigation .menu.main-menu a:focus {
	background: #008575 !important;
	color: #ffffff !important
}

.mobile-navigation .menu.main-menu .menu {
	display: none
}

.mobile-navigation .menu.main-menu .menu>li.active-trail>a {
	background: #114459
}

.mobile-navigation .menu.main-menu .menu a {
	padding-left: 30px;
	background: #17556c
}

.mobile-navigation .menu.main-menu .menu a:before {
	content: ' ';
	position: absolute;
	left: 10px;
	top: 9px
}

.mobile-navigation .menu.main-menu .menu .menu>.active-trail>a:before {
	content: 'Ã‹'
}

.mobile-navigation .menu.main-menu .menu .menu a {
	padding-left: 38px;
	background-color: #114459
}

.mobile-navigation .menu.main-menu .menu .menu a:before {
	content: "Ã—";
	font-family: 'icns';
	-webkit-font-smoothing: antialiased;
	position: absolute;
	left: 18px;
	top: 8px
}

.mobile-navigation .menu.main-menu .menu .menu a.active:before {
	content: 'Ã•'
}

.mobile-navigation .menu.main-menu .menu .menu .menu a {
	background-color: #e3e5e4;
	color: #474342;
	padding-left: 48px
}

.mobile-navigation .menu.main-menu .menu .menu .menu a:before {
	left: 28px
}

.mobile-navigation .menu.main-menu .menu .menu .menu .menu a {
	background: #474342;
	color: #fff;
	padding-left: 58px
}

.mobile-navigation .menu.main-menu .menu .menu .menu .menu a:before {
	left: 38px
}

.mobile-navigation .menu.main-menu li.expanded a {
	padding-right: 48px
}

.mobile-navigation .menu.main-menu span.expand {
	position: absolute;
	right: 0;
	top: 0;
	border: none;
	text-indent: -1000000px;
	cursor: pointer;
	overflow: hidden;
	display: block;
	height: 39px;
	width: 39px;
	line-height: 39px;
	color: #ffffff
}

.mobile-navigation .menu.main-menu span.expand:before {
	display: block;
	text-indent: 0;
	text-align: center
}

.mobile-navigation .menu.main-menu .active-trail>.menu {
	display: block;
	background-color: #0c414f
}

.mobile-navigation>.menu.main-menu>li>a {
	text-transform: uppercase
}

.mobile-navigation>.menu.main-menu>li>a :before {
	content: none !important
}

.mobile-navigation>.menu.main-menu>li.first>a {
	border-top: none
}

.mobile-navigation .menu-about {
	background: #008575;
	padding-top: 20px;
	padding-bottom: 20px
}

.mobile-navigation .menu-about li a {
	padding: 0 0 0 20px;
	color: #ffffff;
	line-height: 32px
}

.mobile-navigation .menu-about li a:hover {
	background: none;
	text-decoration: underline
}

.mobile-navigation .menu-about li a:focus {
	background: none;
	text-decoration: underline
}

#header {
	padding: 0;
	background-color: #1B546B;
	background-image: url(./assets/mferefresh/images/sprite.png);
	background-position-y: -551px;
	background-position-x: -150px;
	background-repeat: no-repeat;
	padding-top: 15px;
	padding-bottom: 20px;
	position: relative;
	-webkit-transition: background-position 0.5s;
	-o-transition: background-position 0.5s;
	transition: background-position 0.5s
}

@media (min-width:992px) {
	#header {
		padding-top: 23px;
		padding-bottom: 0;
		background-position-x: 0px
	}
}

.navbar {
	background: transparent;
	border: none;
	min-height: 0;
	margin-bottom: 0
}

@media (min-width:768px) and (max-width:991px) {
	.navbar {
		padding: 0
	}
}

@media (min-width:992px) {
	.navbar {
		margin-top: 20px
	}
}

.navbar .navbar-main {
	border: none;
	box-shadow: none;
	padding: 0
}

@media (max-width:768px) {
	.navbar .navbar-main {
		margin-left: -15px;
		margin-right: -15px
	}
}

.logo-header {
	float: none;
	text-align: center
}

@media (min-width:992px) {
	.logo-header {
		float: left;
		text-align: left
	}
}

.logo-header a {
	background-image: url(./assets/mferefresh/images/sprite.png);
	background-position: 0px -121px;
	width: 250px;
	height: 67px;
	background-repeat: no-repeat;
	overflow: hidden;
	display: block;
	text-indent: -1000000px
}

.site-search {
	height: 30px;
	line-height: 30px;
	float: right;
	width: auto;
	margin-top: 20px
}

@media (min-width:992px) {
	.site-search {
		position: relative;
		left: inherit
	}
}

.site-search form {
	margin: 0 10px
}

@media (min-width:992px) {
	.site-search form {
		margin: 0
	}
}

.site-search .views-exposed-form .views-exposed-widgets>div:first-of-type {
	margin-right: -30px
}

.site-search .views-exposed-form .views-exposed-widget {
	float: left;
	width: auto;
	padding: 0 !important;
	margin-right: 5px
}

.site-search .views-exposed-form .views-exposed-widget.views-submit-button {
	clear: none
}

.site-search .views-exposed-form .views-exposed-widget .form-submit {
	background-image: url(./assets/mferefresh/images/sprite.png);
	background-position: 0px -28px;
	width: 19px;
	height: 19px;
	background-repeat: no-repeat;
	background-color: transparent;
	text-indent: -1000000px;
	border: none;
	padding: 0;
	margin: 0;
	vertical-align: text-bottom;
	opacity: .8;
	filter: alpha(opacity=80)
}

.site-search .views-exposed-form .views-exposed-widget .form-submit:hover {
	opacity: 1;
	filter: alpha(opacity=100)
}

.site-search .views-exposed-form .views-exposed-widget .form-submitactive {
	outline: none;
	opacity: 1;
	filter: alpha(opacity=100)
}

.site-search .form-text {
	background: #2f667b;
	color: #FFFFFF;
	padding: 0 32px 0 15px;
	margin-right: 3px;
	height: 30px !important;
	line-height: 30px !important;
	border: none;
	float: left;
	width: 200px;
	-webkit-border-radius: 17px;
	border-radius: 17px;
	background-clip: padding-box
}

.site-search .form-text:focus {
	outline: none
}

.site-search .form-text::-moz-placeholder {
	color: #FFFFFF;
	opacity: 1;
	filter: alpha(opacity=100)
}

.site-search .form-text::-webkit-input-placeholder {
	color: #FFFFFF;
	opacity: 1;
	filter: alpha(opacity=100)
}

.site-search .form-text:-ms-input-placeholder {
	color: #FFFFFF;
	opacity: 1;
	filter: alpha(opacity=100)
}

.site-search .form-text:-moz-placeholder {
	color: #FFFFFF;
	opacity: 1;
	filter: alpha(opacity=100)
}

@media (min-width:768px) and (max-width:991px) {
	.site-search .form-text {
		width: 300px
	}
}

.aux-links {
	display: none
}

@media (min-width:992px) {
	.aux-links {
		display: block;
		float: right;
		margin-top: 20px;
		margin-right: 15px;
		font-size: 15px
	}
	.aux-links ul li {
		display: inline;
		float: left
	}
	.aux-links ul li a {
		padding: 0 10px;
		line-height: 30px;
		height: 30px;
		display: block;
		color: #ffffff
	}
	.aux-links ul li a:hover,
	.aux-links ul li a:focus {
		text-decoration: underline;
		background: none !important;
		border: none !important
	}
	.aux-links ul li a.active {
		color: #00d9bf
	}
}

#header .navbar-header {
	float: left;
	margin: 20px 0 5px !important
}

#header .navbar-header .navbar-toggle {
	float: none;
	line-height: 30px;
	height: 30px;
	border: 1px solid #6c93a1;
	min-width: auto;
	padding: 0 25px;
	color: #fff;
	-webkit-border-radius: 17px;
	border-radius: 17px;
	background-clip: padding-box;
	margin: 0;
	text-transform: uppercase
}

#header .navbar-header .navbar-toggle.open {
	background: #008575
}

#header .navbar-header .navbar-toggle:hover {
	background: #008575
}

#header .navbar-header .navbar-toggle:focus {
	background: #008575
}

@media (min-width:768px) and (max-width:991px) {
	#header .navbar-header .navbar-toggle {
		display: block;
		min-width: 250px
	}
}

.breadcrumb {
	padding: 0;
	margin: 15px 0 0;
	background: none;
	font-size: 13px;
	font-family: "SourceSans", helvetica, arial, sans-serif;
	-webkit-border-radius: 0;
	border-radius: 0;
	background-clip: padding-box
}

.breadcrumb .delimiter {
	padding: 0 5px
}

.breadcrumb a {
	color: #0c414f
}

.secondary-nav {
	margin-bottom: 15px
}

.secondary-nav .block-title {
	display: block;
	margin: 0;
	color: #ffffff;
	background: #267998;
	font-size: 20px;
	padding: 18px 18px 12px;
	position: relative;
	-webkit-font-smoothing: antialiased
}

.secondary-nav .block-title a {
	color: #ffffff;
	display: block
}

.secondary-nav .menu {
	margin: 0
}

.secondary-nav .menu li {
	float: none;
	display: block
}

.secondary-nav .menu li.active {
	background: #d3e9e8
}

.secondary-nav .menu li.active>a {
	font-weight: bold
}

.secondary-nav .menu li.active>a.active {
	background: #008575;
	color: #fff
}

.secondary-nav .menu a {
	display: block;
	border-bottom: 1px #f1f1f1 solid;
	padding: 10px 18px;
	color: #333;
	position: relative;
	font-size: 14px
}

.secondary-nav .menu a:hover,
.secondary-nav .menu a:focus {
	color: #fff;
	background: #008575
}

.secondary-nav .menu .menu a {
	margin: 0;
	text-indent: 0;
	padding-left: 32px;
	font-size: 13px
}

.secondary-nav .menu .menu a:before {
	font-family: "FontAwesome";
	content: '\f105';
	position: absolute;
	left: 15px;
	top: 10px;
	font-weight: normal
}

.secondary-nav .menu .menu a.active:before {
	content: '\f138';
	font-size: 13px;
	top: 11px;
	left: 12px
}

.secondary-nav .menu .menu .expanded>a:before {
	content: '\f107'
}

.secondary-nav .menu .menu .menu a {
	padding-left: 47px
}

.secondary-nav .menu .menu .menu a:before {
	left: 28px
}

.secondary-nav .menu .menu .menu .menu a {
	padding-left: 60px;
	font-size: 12px
}

.secondary-nav .menu .menu .menu .menu a:before {
	left: 42px
}

.secondary-nav li a {
	position: relative
}

.footer {
	border: none;
	background: #1B546B;
	padding-top: 17px;
	padding-bottom: 0
}

.footer .footer-top {
	color: #ffffff
}

.footer .footer-top .nav a {
	padding: 0 0 3px;
	color: #ffffff
}

.footer .footer-top .nav a:hover,
.footer .footer-top .nav a:focus {
	background: none;
	text-decoration: underline
}

.footer .footer-top .row>div[class^=col-]:not(:first-child):not(:last-child) {
	padding-left: 15px;
	padding-right: 15px
}

@media (min-width:768px) {
	.footer .footer-top .row>div:after {
		content: '';
		display: block;
		position: absolute;
		top: 0;
		right: 0;
		border-right: 1px dotted #2b6e87;
		height: 70px;
		margin-top: -35px
	}
}

@media (min-width:768px) and (max-width:991px) {
	.footer .footer-top .row div:nth-child(3):after {
		display: none
	}
	.footer .footer-top .row>div:last-child {
		padding-top: 17px
	}
	.footer .footer-top .row>div:last-child:after {
		left: 0;
		height: 0;
		width: 100px;
		margin: 0;
		border-right: none;
		border-top: 1px dotted #2b6e87
	}
}

@media (min-width:992px) {
	.footer .footer-top .row>div:last-child:after {
		display: none
	}
}

.footer .footer-top .footer-logo {
	padding-bottom: 17px
}

.footer .footer-top .footer-logo:after {
	content: '';
	display: block;
	width: 100px;
	position: absolute;
	border-bottom: 1px dotted #2b6e87;
	bottom: 0;
	margin-left: -17px
}

@media (min-width:768px) {
	.footer .footer-top .footer-logo {
		padding-bottom: 0
	}
	.footer .footer-top .footer-logo:after {
		display: none
	}
}

.footer .footer-top .footer-logo .mfe-logo-small {
	display: block;
	text-indent: -1000000px;
	background-repeat: no-repeat;
	background-image: url(./assets/mferefresh/images/sprite.png);
	background-position: 0px -57px;
	width: 199px;
	height: 54px
}

.footer .footer-top .footer-message {
	padding: 17px 0;
	margin-bottom: 17px
}

.footer .footer-top .footer-message:after {
	content: '';
	display: block;
	width: 100px;
	position: absolute;
	border-bottom: 1px dotted #2b6e87;
	bottom: 0;
	margin-left: -17px
}

@media (min-width:768px) {
	.footer .footer-top .footer-message {
		padding: 0;
		margin-bottom: 0
	}
	.footer .footer-top .footer-message:after {
		display: none
	}
}

@media (min-width:768px) {
	.footer .footer-top .footer-contact {
		text-align: right
	}
}

.footer .footer-top .footer-contact h4 {
	margin-top: 0
}

.footer .footer-top .footer-contact .nav li:last-child {
	font-size: 20px
}

.footer .footer-bottom {
	background-color: #0c414f;
	margin-top: 35px
}

.footer .footer-bottom .nzgovt a {
	text-indent: -1000000px;
	display: block;
	margin: 10px 0;
	background-image: url(./assets/mferefresh/images/sprite.png);
	background-position: 0px 0px;
	width: 179px;
	height: 18px
}

@media (min-width:768px) {
	.footer {
		padding-top: 35px
	}
}

@media (min-width:992px) {
	.footer {
		background-color: #1B546B;
		background-repeat: no-repeat;
		background-image: url(./assets/mferefresh/images/sprite.png);
		background-position: 0px -305px
	}
}

.field-type-paragraphs {
	padding: 0 !important;
	background: #ffffff !important;
	margin-bottom: 10px
}

.field-type-paragraphs .entity-paragraphs-item {
	padding: 15px
}

@media (min-width:992px) {
	.field-type-paragraphs .entity-paragraphs-item {
		padding: 25px
	}
}

.field-type-paragraphs .entity-paragraphs-item.paragraph-no-padding {
	padding: 0
}

.field-type-paragraphs .entity-paragraphs-item .field-type-paragraphs {
	margin: 0
}

.field-type-paragraphs .entity-paragraphs-item .field-type-paragraphs .entity-paragraphs-item {
	padding: 0
}

.field-type-paragraphs .field {
	padding: 0;
	background: none
}

.field-type-paragraphs .header:before {
	content: '';
	position: absolute;
	top: 0;
	left: 5px;
	width: 150px;
	height: 3px
}

.field-type-paragraphs .header .inner {
	padding: 15px
}

@media (min-width:992px) {
	.field-type-paragraphs .header .inner {
		padding: 25px
	}
}

.field-type-paragraphs .header .inner h2 {
	margin: 0;
	display: inline
}

.field-type-paragraphs .header .inner .field-more-information {
	float: none
}

@media (min-width:992px) {
	.field-type-paragraphs .header .inner .field-more-information {
		float: right
	}
}

.field-type-paragraphs .header .inner .field-more-information a {
	line-height: 30px;
	display: block
}

.field-type-paragraphs .header .inner .field-more-information a:before {
	content: "\f105";
	font-family: "FontAwesome";
	display: inline-block;
	padding-right: 5px
}

.paragraphs-item-topic-component .coloured-background a {
	color: #fff;
	border-bottom: 1px dotted #fff;
	text-decoration: none
}

.paragraphs-item-topic-component .coloured-background a:hover {
	text-decoration: underline;
	border-bottom: none
}

.paragraphs-item-topic-component p.leading {
	font-size: 16px
}

@media (min-width:992px) {
	.paragraphs-item-topic-component p.leading {
		font-size: 18px
	}
}

.paragraphs-item-topic-component figure {
	margin: 0 15px 15px;
	overflow: auto
}

@media (min-width:992px) {
	.paragraphs-item-topic-component figure {
		margin: 0 25px 25px
	}
}

.paragraphs-item-topic-component figure img {
	float: right
}

.paragraphs-item-topic-component .field-links {
	list-style: none;
	margin: 15px 0 0
}

@media (min-width:992px) {
	.paragraphs-item-topic-component .field-links {
		margin-top: 25px
	}
}

.paragraphs-item-topic-component .field-links li {
	list-style: none;
	margin: 0 5px 5px 0;
	display: inline-block
}

.paragraphs-item-topic-component .field-links li a {
	border-bottom: none;
	display: block;
	color: #fff;
	background: #2f667b;
	padding: 7px 22px;
	-webkit-border-radius: 17px;
	border-radius: 17px;
	background-clip: padding-box;
	text-decoration: none;
	font-size: 15px
}

.paragraphs-item-topic-component .field-links li a:hover {
	background: #fff;
	color: #2f667b;
	border: 2px solid #2f667b;
	padding: 5px 20px;
	text-decoration: none
}

.paragraphs-item-topic-component .paragraph-container {
	display: -webkit-flex;
	-webkit-flex-wrap: wrap;
	display: flex;
	flex-wrap: wrap
}

.paragraphs-item-topic-component .component-body .inner {
	padding: 15px
}

@media (min-width:992px) {
	.paragraphs-item-topic-component .component-body .inner {
		padding: 25px
	}
}

.paragraphs-item-topic-component .component-body .image-background .inner {
	width: 100%;
	height: 100%;
	position: relative;
	min-height: 200px;
	background-size: cover !important
}

.paragraphs-item-topic-component .component-body .coloured-background .inner {
	color: #ffffff;
	height: 100%
}

.paragraphs-item-topic-component .component-body.no-padding-top .inner {
	padding-top: 0
}

.paragraphs-item-topic-component .transparent-section {
	position: absolute;
	padding: 15px;
	overflow: hidden;
	top: 50%;
	min-width: 85%;
	max-width: 95%;
	transform: translate(0, -50%);
	z-index: 100
}

@media (min-width:992px) {
	.paragraphs-item-topic-component .transparent-section {
		padding: 25px
	}
}

.paragraphs-item-topic-component .transparent-section h3 {
	margin-top: 0
}

.paragraphs-item-topic-component .transparent-section ul {
	list-style: none;
	margin: 0;
	padding: 0
}

.paragraphs-item-topic-component .transparent-section ul li {
	list-style: none;
	padding: 0;
	margin: 0
}

.paragraphs-item-topic-component .transparent-section ul li a:before {
	content: "\f105";
	font-family: "FontAwesome";
	display: inline-block;
	padding-right: 5px
}

.paragraphs-item-topic-component .transparent-section.transparent-section-left {
	left: 0
}

.paragraphs-item-topic-component .transparent-section.transparent-section-right {
	right: 0
}

.paragraphs-item-topic-component .transparent-section.transparent-section-center {
	min-width: 350px;
	left: 50%;
	-webkit-transform: translate(-50%, -50%);
	-ms-transform: translate(-50%, -50%);
	-o-transform: translate(-50%, -50%);
	transform: translate(-50%, -50%)
}

.paragraphs-item-topic-component .transparent-section:after {
	content: '';
	display: block;
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background: #ffffff;
	opacity: .8;
	filter: alpha(opacity=80)
}

.paragraphs-item-topic-component .transparent-section>* {
	position: relative;
	z-index: 100
}

.paragraphs-item-topic-component .full-width-paragraph-container .inner {
	padding-top: 0
}

.paragraphs-item-topic-component.view-mode-full_width_image figure {
	margin: 0
}

.paragraphs-item-topic-component.view-mode-full_width_image figure img {
	float: none;
	width: 100%;
	height: auto
}

.paragraphs-item-topic-component.view-mode-full_width_image figure figcaption {
	padding: 7px;
	font-style: italic
}

@media (min-width:992px) {
	.paragraphs-item-topic-component.view-mode-full_width_image figure figcaption {
		padding: 12px
	}
}

.paragraphs-item-topic-component.view-mode-full_width_banner img {
	width: 100%;
	height: auto
}

.paragraph-air .header:before {
	background: #6DC7B7
}

.paragraph-air .coloured-background .inner {
	background: #6DC7B7
}

.paragraph-climate-change .header:before {
	background: #DA6B28
}

.paragraph-climate-change .coloured-background .inner {
	background: #DA6B28
}

.paragraph-fresh-water .header:before {
	background: #317f9C
}

.paragraph-fresh-water .coloured-background .inner {
	background: #317f9C
}

.paragraph-marine .header:before {
	background: #1B546B
}

.paragraph-marine .coloured-background .inner {
	background: #1B546B
}

.paragraph-land .header:before {
	background: #8A381C
}

.paragraph-land .coloured-background .inner {
	background: #8A381C
}

.paragraph-rma .header:before {
	background: #2C9986
}

.paragraph-rma .coloured-background .inner {
	background: #2C9986
}

.paragraph-waste .header:before {
	background: #583630
}

.paragraph-waste .coloured-background .inner {
	background: #583630
}

@media (min-width:768px) {
	.view-mode-topic_view_1 .coloured-background {
		margin-right: 0;
		padding-right: 0
	}
	.view-mode-image_left_color_right .coloured-background {
		padding-left: 0;
		margin-left: 0
	}
	.view-mode-topic_view_1 .image-background {
		padding-left: 0;
		margin-right: 0;
		display: -webkit-box;
		display: -moz-box;
		display: -ms-flexbox;
		display: -webkit-flex;
		display: flex;
		position: relative
	}
	.view-mode-image_left_color_right .image-background {
		padding-right: 0;
		margin-left: 0;
		display: -webkit-box;
		display: -moz-box;
		display: -ms-flexbox;
		display: -webkit-flex;
		display: flex;
		position: relative
	}
}

@media (max-width:767px) {
	.no-transparent-section .component-body .image-background .inner {
		display: none
	}
}

.paragraphs-item-multi-image-colored-background .field-heading-image-description {
	overflow: auto;
	background: #ECECE3;
	padding: 15px !important
}

.paragraphs-item-multi-image-colored-background .field-heading-image-description .paragraphs-item-heading-image-description:first-child {
	margin-bottom: 30px
}

.paragraphs-item-multi-image-colored-background .field-heading-image-description .paragraphs-item-heading-image-description img {
	margin: 20px 0
}

@media (min-width:768px) {
	.paragraphs-item-multi-image-colored-background .field-heading-image-description .paragraphs-item-heading-image-description {
		width: 50%;
		float: left;
		padding: 0 !important
	}
	.paragraphs-item-multi-image-colored-background .field-heading-image-description .paragraphs-item-heading-image-description:first-child {
		margin-bottom: 0
	}
	.paragraphs-item-multi-image-colored-background .field-heading-image-description .paragraphs-item-heading-image-description:nth-child(odd) {
		padding-right: 15px !important
	}
	.paragraphs-item-multi-image-colored-background .field-heading-image-description .paragraphs-item-heading-image-description:nth-child(even) {
		padding-left: 15px !important
	}
}

@media (min-width:992px) {
	.paragraphs-item-multi-image-colored-background .field-heading-image-description {
		padding: 40px 60px !important
	}
	.paragraphs-item-multi-image-colored-background .field-heading-image-description .paragraphs-item-heading-image-description:nth-child(odd) {
		padding-right: 25px !important
	}
	.paragraphs-item-multi-image-colored-background .field-heading-image-description .paragraphs-item-heading-image-description:nth-child(even) {
		padding-left: 25px !important
	}
}

.paragraphs-item-left-right-text-columns .inner {
	padding: 0 15px 15px
}

@media (min-width:992px) {
	.paragraphs-item-left-right-text-columns .inner {
		padding: 0 25px 25px
	}
}

.paragraphs-item-left-right-text-columns .col-left,
.paragraphs-item-left-right-text-columns .col-right {
	position: relative
}

@media (min-width:992px) {
	.paragraphs-item-left-right-text-columns .col-left .inner {
		padding-right: 12px
	}
	.paragraphs-item-left-right-text-columns .col-right .inner {
		padding-left: 12px
	}
}

.paragraphs-item-left-right-text-columns iframe {
	top: 0;
	left: 0;
	width: 100%;
	height: 320px
}

.mfe-horizontal-tabs>.header {
	position: relative
}

.mfe-horizontal-tabs>.header:before {
	left: 0
}

.mfe-horizontal-tabs>.content>.inner {
	padding: 0 15px 15px
}

@media (min-width:992px) {
	.mfe-horizontal-tabs>.content>.inner {
		padding: 0 25px 25px
	}
}

.mfe-horizontal-tabs>.content.no-header {
	padding-top: 15px
}

@media (min-width:992px) {
	.mfe-horizontal-tabs>.content.no-header {
		padding-top: 25px
	}
}

.mfe-horizontal-tabs .nav-tabs {
	margin-bottom: 5px
}

.mfe-horizontal-tabs .nav-tabs>li>a {
	background-color: #0c414f;
	color: #fff
}

.mfe-horizontal-tabs .nav-tabs>li>a:hover {
	border-color: transparent;
	background-color: #1B546B
}

.mfe-horizontal-tabs .nav-tabs>li.active a,
.mfe-horizontal-tabs .nav-tabs>li.active a:hover,
.mfe-horizontal-tabs .nav-tabs>li.active a:focus {
	background-color: #ffffff;
	color: #000;
	border-color: #ddd;
	border-bottom-color: transparent
}

.mfe-horizontal-tabs .nav-pills {
	margin-bottom: 5px
}

.mfe-horizontal-tabs .nav-pills>li>a {
	background-color: #0c414f;
	color: #fff;
	border: 1px solid transparent
}

.mfe-horizontal-tabs .nav-pills>li>a:hover {
	background-color: #1B546B
}

.mfe-horizontal-tabs .nav-pills>li.active a,
.mfe-horizontal-tabs .nav-pills>li.active a:hover,
.mfe-horizontal-tabs .nav-pills>li.active a:focus {
	background-color: #ffffff;
	color: #000;
	border: 1px solid #ddd
}

.mfe-using-layout {
	background: #ffffff;
	padding-top: 15px
}

@media (min-width:992px) {
	.mfe-using-layout {
		padding-top: 25px
	}
}

.node-type-second-level-landing .page-header:before {
	content: '';
	position: absolute;
	top: 0;
	left: 5px;
	width: 150px;
	height: 3px
}

.node-type-second-level-landing.section-air .page-header:before {
	background: #6DC7B7
}

.node-type-second-level-landing.section-climate-change .page-header:before {
	background: #DA6B28
}

.node-type-second-level-landing.section-fresh-water .page-header:before {
	background: #317f9C
}

.node-type-second-level-landing.section-marine .page-header:before {
	background: #1B546B
}

.node-type-second-level-landing.section-land .page-header:before {
	background: #8A381C
}

.node-type-second-level-landing.section-rma .page-header:before {
	background: #2C9986
}

.node-type-second-level-landing.section-waste .page-header:before {
	background: #583630
}

.node-type-publication-summary-page .page-header {
	padding-top: 15px
}

@media (min-width:992px) {
	.node-type-publication-summary-page .page-header {
		padding-top: 25px
	}
}

.node-type-publication-summary-page .sidebar-second-inner {
	background-color: #ddd;
	padding: 15px
}

@media (min-width:992px) {
	.node-type-publication-summary-page .sidebar-second-inner {
		padding: 25px
	}
}

.node-type-publication-summary-page .sidebar-second-inner .views-field-field-publication-cover img {
	margin: 0 auto;
	display: block;
	border: 1px solid rgba(0, 0, 0, 0.1);
	max-width: 100%;
	height: auto
}

.node-type-publication-summary-page .node {
	background: #ffffff
}

.node-type-publication-summary-page .node .inline {
	display: inline-block;
	font-size: 16px
}

.node-type-publication-summary-page .book-navigation {
	display: none
}

.field-name-field-section-topic {
	background-color: #fff;
	padding: 20px 0
}

.field-name-field-section-topic .field-label {
	float: left
}

.view-publications-summary-blocks .views-field-field-publication-cover {
	margin-bottom: 20px
}

.sidebar-second-inner .view-publications-summary-blocks p {
	margin: 1em 0
}

.view-publications-summary-blocks a {
	color: #1c596f
}

.view-publications-summary-blocks a:hover,
.view-publications-summary-blocks a:focus {
	color: #005248
}

.view-publications-summary-blocks.view-display-id-attachment_1 a,
.view-publications-summary-blocks.view-display-id-attachment_2 a,
.view-publications-summary-blocks.view-display-id-attachment_8 a {
	display: block;
	padding: 10px;
	color: #fff;
	text-transform: uppercase;
	margin-bottom: 1px;
	line-height: 1.3;
	-webkit-transition: background-color 0.2s;
	-o-transition: background-color 0.2s;
	transition: background-color 0.2s
}

.view-publications-summary-blocks.view-display-id-attachment_1 a:hover,
.view-publications-summary-blocks.view-display-id-attachment_1 a:focus,
.view-publications-summary-blocks.view-display-id-attachment_2 a:hover,
.view-publications-summary-blocks.view-display-id-attachment_2 a:focus,
.view-publications-summary-blocks.view-display-id-attachment_8 a:hover,
.view-publications-summary-blocks.view-display-id-attachment_8 a:focus {
	background-color: #008575;
	text-decoration: none
}

.view-publications-summary-blocks.view-display-id-attachment_1 .view-header {
	display: none
}

.view-publications-summary-blocks.view-display-id-attachment_1 a {
	background-color: #17556c
}

.view-publications-summary-blocks.view-display-id-attachment_2 a {
	background-color: #17556c
}

.view-publications-summary-blocks.view-display-id-attachment_8 a {
	background-color: #267998
}

.view-publications-summary-blocks.view-display-id-attachment_6 {
	margin: 1em 0 .6em 0
}

.view-publications-summary-blocks.view-display-id-attachment_6,
.view-publications-summary-blocks.view-display-id-attachment_9 {
	line-height: 1.4
}

.view-publications-summary-blocks.view-display-id-attachment_6 .views-label,
.view-publications-summary-blocks.view-display-id-attachment_9 .views-label {
	display: block
}

.node-type-publication-content-page .page-header {
	padding-top: 15px
}

@media (min-width:992px) {
	.node-type-publication-content-page .page-header {
		padding-top: 25px
	}
}

.node-type-publication-content-page .book-navigation .book-toc {
	display: none
}

.node-type-publication-content-page .book-navigation .pager a:hover,
.node-type-publication-content-page .book-navigation .pager a:focus,
.node-type-publication-content-page .book-navigation .pager a:active {
	background-color: #008575;
	color: #ffffff
}

.page-user ul.tabs {
	padding: 25px 25px 20px 25px;
	border: none
}

.page-user ul.tabs li {
	float: left;
	margin: 0
}

.page-user ul.tabs a {
	border: 1px #888 solid;
	padding: 8px 16px
}

.page-user ul.tabs a:hover,
.page-user ul.tabs a:focus {
	border: 1px #888 solid;
	padding: 8px 16px
}

.page-user ul.tabs li.active a {
	border: 1px #888 solid;
	padding: 8px 16px
}

#user-login,
.view-user-login-text,
.profile {
	background-color: #fff;
	padding: 0 35px
}

.view-user-login-text p {
	margin: 0
}

#user-login {
	padding: 25px
}

#user-login .form-submit {
	font-size: 14px;
	padding: 6px 12px;
	text-transform: uppercase
}

.profile {
	margin: 0;
	padding: 10px 25px;
	line-height: 1.2
}

.profile .field-collection-container>* {
	display: none
}

.profile .field {
	padding: 0;
	margin: 0
}

.profile .field.field-og-user-node {
	margin-bottom: 25px
}

.profile .field.field-og-user-node a {
	display: block
}

.profile h3 {
	border: none;
	margin: 15px 0 4px 0
}

.view-user-login-text {
	display: none
}

.toboggan-unified #login-message {
	color: #8b8b8b;
	font-size: 42px;
	margin-bottom: 32px;
	display: block
}

.toboggan-unified #login-links a {
	float: left;
	width: 50%;
	padding: 0;
	background: transparent;
	border: none;
	color: #333
}

.toboggan-unified #login-links a.lt-active {
	background: transparent;
	border: none;
	color: #333;
	background: #fff
}

.toboggan-unified #login-links a.lt-active:hover {
	background: transparent;
	border: none;
	color: #333;
	background: #fff
}

#login-form {
	clear: both
}

#login-form label {
	display: block;
	margin-bottom: 5px
}

#login-form .form-text {
	border: none;
	background: #EAEAE0;
	padding: 0 8px;
	height: 28px;
	line-height: 28px;
	width: 100%
}

#login-form .form-item-name .description {
	display: none
}

#login-form .form-item-pass .description {
	float: right;
	font-size: 12px;
	display: inline-block;
	padding: 7px 0 0 0;
	font-weight: bold;
	font-style: normal
}

#login-form .form-submit {
	padding: 14px 32px 15px 20px;
	line-height: 1;
	font-size: 14px;
	background: #267998 url(./assets/mferefresh/images/btn.png) no-repeat 76px 14px
}

.toboggan-unified .description {
	line-height: 1.2;
	font-style: italic
}

#register-form .form-text {
	border: none;
	background: #EAEAE0;
	padding: 0 8px;
	height: 28px;
	line-height: 28px;
	width: 100%
}

#register-form .form-submit {
	padding: 14px 32px 15px 20px;
	line-height: 1;
	font-size: 14px;
	background: #267998 url(./assets/mferefresh/images/btn.png) no-repeat 76px 14px
}

#register-form form {
	background-color: #fff;
	padding: 25px 35px;
	clear: both
}

#register-form .form-submit {
	background-position: 168px 14px
}

.field-name-field-password-help {
	font-size: 12px;
	border: 1px solid #77C9E3;
	padding: 8px;
	color: #77C9E3;
	background: #F2FBFD;
	line-height: 1.2
}

.field-name-field-password-help legend {
	font-weight: bold;
	display: block;
	margin-bottom: 3px
}

.view-your-submissions,
.view-flag-bookmarks-tab,
.imce-frame {
	background: #fff;
	padding: 20px 0
}

.view-flag-bookmarks-tab {
	padding-left: 25px;
	padding-right: 25px
}

.imce-frame {
	width: 100% !important;
	border: none !important
}

.view-your-submissions {
	padding: 15px
}

@media (min-width:992px) {
	.view-your-submissions {
		padding: 25px
	}
}

.view-your-submissions ul,
.view-your-submissions li {
	margin: 0;
	padding: 0;
	list-style: none
}

.page-groups .page-header {
	padding-top: 15px
}

@media (min-width:992px) {
	.page-groups .page-header {
		padding-top: 25px
	}
}

.page-groups .view-content {
	padding: 15px;
	background: #ffffff
}

@media (min-width:992px) {
	.page-groups .view-content {
		padding: 25px
	}
}

.page-groups .view-content table {
	table-layout: fixed;
	margin: 0
}

.page-groups .view-content table td h3 {
	margin: 0
}

.node-type-group .field-group-group {
	padding: 0
}

.node-type-group .field-group-group a {
	color: #ffffff;
	display: block;
	font-weight: bold;
	text-transform: uppercase;
	padding: 20px 35px;
	background-color: #008575;
	font-size: 16px;
	text-align: center
}

.node-type-group .field-group-group a:before {
	font-family: "FontAwesome";
	content: "\f055";
	margin-right: 5px
}

.node-type-group .field-group-group a:hover,
.node-type-group .field-group-group a:focus {
	text-decoration: none;
	background-color: #267998
}

.main-slideshow {
	position: relative
}

.main-slideshow .field-group-wrapper {
	margin-bottom: 25px
}

@media (min-width:768px) {
	.main-slideshow .field-group-wrapper {
		position: absolute;
		width: 90%;
		margin-bottom: 15px;
		right: 20px;
		left: 30px;
		bottom: 10px
	}
}

@media (min-width:992px) {
	.main-slideshow .field-group-wrapper {
		position: absolute;
		width: 94%;
		margin-bottom: 15px;
		right: 20px;
		left: 30px;
		bottom: 10px
	}
}

.views_slideshow_cycle_main .views-slideshow-cycle-main-frame-row {
	width: 100% !important
}

.main-slideshow .views-field-title {
	font-size: 24px !important;
	line-height: normal
}

.main-slideshow .views-field-title a {
	font-size: 24px !important;
	line-height: normal
}

.views-field-field-link-slideshow {
	color: #fff;
	display: inline-block;
	padding: 10px 10px 10px 15px;
	text-transform: uppercase;
	font-size: 12px;
	line-height: 1;
	width: 100%;
	background-color: #1B546B
}

.views-field-field-link-slideshow:before {
	content: "\f105";
	font-family: FontAwesome;
	float: left;
	padding-right: 5px
}

.views-field-field-link-slideshow:hover {
	text-decoration: none;
	background-color: #1b6d85
}

.flexslider {
	border: none;
	background: transparent;
	margin: 0 0 10px
}

@media (max-width:768px) {
	.flexslider {
		margin: 0
	}
	.flexslider .slides img {
		height: auto !important
	}
}

@media (min-width:992px) {
	.flexslider .slides img {
		height: 394px !important
	}
}

@media (min-width:1199px) {
	.flexslider .slides img {
		height: 477px !important
	}
}

.flexslider .views-field-field-link-slideshow a {
	color: #fff
}

.flexslider .views-field-field-link-slideshow a:hover {
	color: #fff;
	text-decoration: none
}

.main-slideshow .views-slideshow-pager-field-item {
	display: inline-block
}

.main-slideshow .views-slideshow-controls-bottom {
	bottom: 10px;
	position: absolute;
	left: 10px;
	z-index: 500
}

.main-slideshow .active .views-content-nid {
	background: #e21a2c
}

.main-slideshow .views-content-nid {
	background: #fff;
	border: 3px solid #000;
	cursor: pointer;
	display: inline-block;
	font-size: 0;
	height: 10px;
	width: 10px
}

.main-slideshow .active .views-content-nid {
	background: #e21a2c
}

/* a.btn-readmore {
	background: url(./assets/files/btn.png) no-repeat 0 0 #050505;
	color: #fff;
	display: inline-block;
	padding: 7px 7px 7px 25px;
	text-transform: uppercase;
	font-size: 12px
} */

.block .promo-2 {
	margin-top: 10px
}

.home-promos p {
	font-size: 18px
}

/* .promo-2 {
	background: url(/sites/default/files/media/homepage2.PNG) 0 0 repeat;
	position: relative;
	margin-bottom: 0;
	float: right;
	z-index: 999;
	width: 380px
} */

.promo-2 h2 {
	font-size: 20px;
	font-weight: bold;
	margin: 20px
}

.promo-2 .txt-intro {
	color: #494747;
	line-height: 1;
	margin: -5px 0px 0px 20px;
	text-transform: uppercase
}

.promo-2 .txt-small {
	font-size: 14px;
	margin: 10px 10px 10px 20px;
	line-height: 1
}

.promo-2 .btn-readmore {
	bottom: 10px;
	right: 10px;
	position: absolute
}

.views-view-grid.cols-3 .views-col {
	width: 33.33%
}

.views-view-grid .views-col {
	float: left
}

.top-banner {
	position: relative
}

.panel .panel-column {
	margin-left: 10px
}

.top-banner .grid-inner {
	padding: 0 5px;
	position: relative
}

.top-banner .views-field-name {
	color: #000;
	font-weight: normal;
	font-size: 18px;
	line-height: 1;
	margin: 0 0 10px;
	text-transform: uppercase;
	font-family: 'futura_lt_btlight'
}

.banner-view .field-group-wrapper {
	text-align: right
}

.top-banner-inner,
.banner-view .field-group-wrapper {
	right: 20px;
	left: 30px;
	bottom: 20px;
	text-align: left
}

@media (min-width:768px) {
	.top-banner-inner,
	.banner-view .field-group-wrapper {
		position: absolute;
		max-width: 85%
	}
}

@media (min-width:992px) {
	.top-banner-inner,
	.banner-view .field-group-wrapper {
		left: 25px;
		bottom: 5px
	}
}

.page-home .views-field.views-field-field-feature-slider-link.btn-readmore a,
a.btn-readmore {
	color: #fff;
	display: inline-block;
	padding: 7px 7px 7px 15px;
	text-transform: uppercase;
	font-size: 12px;
	line-height: 1;
	width: 100%;
	background-color: #1B546B
}

.page-home .views-field.views-field-field-feature-slider-link.btn-readmore a:before,
a.btn-readmore:before {
	content: "\f105";
	font-family: "FontAwesome";
	float: left;
	padding-right: 5px
}

.page-home .views-field.views-field-field-feature-slider-link.btn-readmore a:hover,
a.btn-readmore:hover {
	color: #fff;
	text-decoration: none;
	background-color: #1b6d85
}

.page-home .paragraphs-item-interim-homepage .views-field-body a,
.page-home .paragraphs-item-interim-homepage .field-promo-text a {
	color: #99ccff
}

.page-home .paragraphs-item-interim-homepage .views-field-body a:hover,
.page-home .paragraphs-item-interim-homepage .field-promo-text a:hover {
	text-decoration: underline
}

.page-home .view-collection-promo .views-field.views-field-title,
.page-home .main-slideshow .views-field.views-field-title {
	background: url(./assets/mferefresh/images/bg-black.png) 0 0 repeat;
	padding: 10px 10px 5px 20px;
	color: #fff;
	font-size: 20px
}

.page-home .main-slideshow .views-field.views-field-body,
.page-home .view-collection-promo .views-field.views-field-body {
	background: url(./assets/mferefresh/images/bg-black.png) 0 0 repeat;
	padding: 5px 10px 10px 20px;
	color: #fff
}

#views_slideshow_cycle_teaser_section_slideshow-block .views_slideshow_slide {
	height: auto !important;
	max-width: 100% !important
}

#views_slideshow_cycle_teaser_section_slideshow-block .views_slideshow_slide img {
	height: auto !important;
	max-width: 100% !important
}

.page-home .views-field-field-feature-slider-image img {
	max-width: 100% !important;
	height: auto !important
}

.page-home .flex-direction-nav {
	display: none
}

.zone-postscript-wrapper {
	margin-bottom: 60px
}

.banner a.btn-readmore {
	position: absolute;
	bottom: 0;
	left: 0
}

ol.flex-control-nav.flex-control-paging {
	position: absolute;
	bottom: 0;
	z-index: 999
}

.promo-1 .banner img {
	max-width: 100%;
	height: auto
}

.homepage-slider {
	margin-bottom: 20px !important
}

.homepage-bottom {
	background-color: #F1F1F1 !important
}

.homepage-bean img {
	max-width: 100%;
	height: auto
}

.page-home .field-promo-text {
	padding: 7px 20px;
	background: url(./assets/mferefresh/images/bg-black.png);
	color: #fff;
	right: 20px;
	left: 30px;
	bottom: 20px
}

@media (min-width:768px) {
	.page-home .field-promo-text {
		position: absolute;
		max-width: 85%
	}
}

.page-home .field-homepage-promo {
	position: relative
}

@media (min-width:768px) {
	.page-home .field-homepage-promo {
		width: 50%;
		float: left;
		display: inline-block;
		padding-right: 5px
	}
}

@media (min-width:992px) {
	.page-home .field-homepage-promo {
		width: 100%;
		float: none;
		display: block;
		padding-right: 0;
		margin-bottom: 10px
	}
}

.page-home .field-homepage-promo-2 {
	position: relative
}

@media (min-width:768px) {
	.page-home .field-homepage-promo-2 {
		width: 50%;
		float: left;
		display: inline-block;
		padding-left: 5px
	}
}

@media (min-width:768px) and (min-width:992px) {
	.page-home .field-homepage-promo-2 {
		width: 100%;
		float: none;
		display: block;
		padding-left: 0
	}
}

@media (max-width:768px) {
	.page-home .field-homepage-promo-2 {
		padding-top: 20px
	}
}

.page-home .field-homepage-right {
	padding-top: 20px
}

@media (min-width:992px) {
	.page-home .field-homepage-right {
		padding-top: 0px
	}
	.page-home .field-homepage-right .img {
		height: auto !important
	}
}

.page-home .field-homepage-center {
	padding-top: 20px
}

@media (min-width:992px) {
	.page-home .field-homepage-center {
		padding-top: 0px
	}
	.page-home .field-homepage-center .img {
		height: auto !important
	}
}

.page-home .field-homepage-left {
	padding-top: 20px
}

@media (min-width:992px) {
	.page-home .field-homepage-left {
		padding-top: 0px
	}
	.page-home .field-homepage-left .img {
		height: auto !important
	}
}

.page-home .homepage-bean .entity-bean {
	position: relative
}

.front .field-type-paragraphs {
	background: none
}

.news-consultations {
	background-color: #fff;
	margin-bottom: 20px;
	margin-top: 20px;
	width: 100%
}

@media (min-width:992px) {
	.news-consultations {
		margin-top: 20px
	}
}

.news-consultations .quicktabs-tabs {
	margin: 0 0 10px 0;
	overflow: hidden
}

.news-consultations .quicktabs-tabs li {
	margin: 0;
	display: block;
	float: left;
	text-align: center;
	padding: 0;
	width: 50%
}

#quicktabs-homepage_quick_tabs_news_consult .views-field-field-link-to- {
	max-width: 420px;
	float: left;
	line-height: 18px;
	font-size: 16px
}

.news-consultations .quicktabs-tabs {
	padding: 0
}

.news-consultations .quicktabs-tabs a {
	padding: 20px 0;
	background-color: #f1f1f1;
	display: block;
	margin: 0;
	font-family: "Merriweather", georgia, serif;
	font-size: 24px;
	color: #0C3F4D;
	line-height: 1
}

.news-consultations .quicktabs-tabs li.active a {
	background-color: #fff;
	color: #333
}

.news-consultations .quicktabs_main {
	clear: both;
	padding: 20px 20px
}

.news-consultations .quicktabs_main .view-content a {
	color: #0e8571
}

.news-consultations .quicktabs_main .view-content a:hover {
	color: #474342
}

.news-consultations .quicktabs_main .views-row {
	border-bottom: 1px #ddd solid;
	overflow: hidden;
	padding: 10px 0
}

.news-consultations .quicktabs_main .views-field-title {
	font-size: 15px;
	line-height: 1.2;
	float: left
}

@media (min-width:992px) {
	.news-consultations .quicktabs_main .views-field-field-date {
		float: right;
		color: #4c4c4c
	}
}

.views-exposed-form .views-exposed-widget {
	padding: 0
}

.news-consultations .view-footer p {
	padding: 0;
	margin: 0;
	text-align: center
}

.news-consultations .view-footer p a {
	display: inline;
	zoom: 1;
	display: inline-block;
	background: #267998;
	color: #fff;
	padding: 8px 12px;
	font-size: 13px;
	font-weight: bold
}

.news-consultations .view-footer p a {
	background-color: #1f9682
}

.news-consultations .view-footer p a:after {
	content: "\f105";
	font-family: "FontAwesome";
	font-style: normal;
	text-rendering: optimizeLegibility;
	padding-left: 5px
}

.news-consultations .view-content {
	margin-bottom: 25px
}

.publications-block {
	background-color: #fff;
	margin-bottom: 20px;
	margin-top: 20px;
	padding-top: 20px
}

.publications-block h2 {
	font-size: 24px;
	text-align: center;
	margin-bottom: 0
}

.publications-block .view-header {
	overflow: auto;
	padding: 0;
	margin: 25px 0 0
}

.publications-block .view-header form {
	overflow: hidden;
	float: left;
	width: 234px;
	margin-bottom: 20px;
	position: relative;
	left: 50%;
	margin-left: -117px
}

.publications-block .view-header form label {
	display: none
}

.publications-block .view-header form .form-text {
	float: left;
	padding: 0 5px;
	height: 30px;
	width: 200px;
	-webkit-border-radius: 0;
	border-radius: 0;
	background-clip: padding-box;
	border-right: none;
	background: #eaeae0
}

.publications-block .view-header form .views-exposed-widget {
	padding: 0;
	margin: 0
}

.publications-block .view-header form .views-exposed-widget.views-submit-button {
	clear: none;
	margin: 0
}

.publications-block .view-header form .views-exposed-widget .btn {
	margin-top: 0;
	text-indent: -1000000px;
	padding: 0;
	height: 30px;
	width: 30px;
	position: relative;
	background-color: #267998;
	border: none;
	-webkit-border-radius: 0;
	border-radius: 0;
	background-clip: padding-box
}

.publications-block .view-header form .views-exposed-widget .btn:after {
	content: '';
	height: 20px;
	width: 20px;
	background-image: url(./assets/mferefresh/images/sprite.png);
	background-position: 0px -28px;
	background-repeat: no-repeat;
	position: absolute;
	left: 50%;
	top: 50%;
	-webkit-transform: translate(-50%, -50%);
	-ms-transform: translate(-50%, -50%);
	-o-transform: translate(-50%, -50%);
	transform: translate(-50%, -50%)
}

.publications-block .views-field-title a {
	font-size: 16px
}

.publications-block .view-content ul {
	padding: 0 15px;
	list-style: none
}

.publications-block .view-content ul li.publication {
	padding: 10px 30px;
	background: url(./assets/mferefresh/images/doc.png) no-repeat 2px 12px;
	border-bottom: 1px solid #ddd;
	overflow: auto;
	list-style: none
}

.publications-block .view-content ul li.publication .views-field.views-field-title {
	float: left;
	width: 75%
}

.publications-block .view-content ul li.publication .views-field.views-field-field-publication-date {
	float: right
}

.publications-block .view-footer {
	padding: 8px 20px
}

.publications-block .view-footer p {
	padding: 0;
	text-align: center;
	margin-bottom: 25px
}

.publications-block .view-footer p a {
	display: inline;
	zoom: 1;
	display: inline-block;
	background: #267998;
	color: #fff;
	padding: 8px 12px;
	font-size: 13px;
	font-weight: bold
}

.publications-block .view-footer p a:hover {
	background-color: #474342;
	text-decoration: none
}

.publications-block .view-footer p a:after {
	content: "\f105";
	font-family: "FontAwesome";
	padding-left: 5px;
	font-style: normal;
	text-rendering: optimizeLegibility
}

h2.block-title {
	margin-top: 0
}

.sidebar-block {
	background: #ffffff !important;
	padding: 15px
}

@media (min-width:992px) {
	.sidebar-block {
		padding: 25px
	}
}

.sidebar-block .views-field {
	margin-bottom: 15px
}

@media (min-width:992px) {
	.sidebar-block .views-field {
		margin-bottom: 25px
	}
}

.sidebar-first-inner .block,
.sidebar-second-inner .block {
	background: #ffffff
}

.sidebar-first-inner .block h2:first-child,
.sidebar-first-inner .block h3:first-child,
.sidebar-first-inner .block h4:first-child,
.sidebar-second-inner .block h2:first-child,
.sidebar-second-inner .block h3:first-child,
.sidebar-second-inner .block h4:first-child {
	margin-top: 0
}

.sidebar-first-inner .block h2.block-title,
.sidebar-second-inner .block h2.block-title {
	font-family: "SourceSansRegular", helvetica, arial, sans-serif
}

.sidebar-second-inner .block-views {
	background: transparent
}

.sidebar-second-inner .block-views .block-title {
	background: #ffffff;
	padding: 15px 15px 0;
	margin-bottom: 0
}

@media (min-width:992px) {
	.sidebar-second-inner .block-views .block-title {
		padding: 25px 25px 0
	}
}

.padded-block {
	padding: 15px
}

@media (min-width:992px) {
	.padded-block {
		padding: 25px
	}
}

.careers-intro {
	background: #ffffff;
	padding: 15px;
	border-bottom: 1px solid #267998;
	margin-bottom: 5px
}

@media (min-width:992px) {
	.careers-intro {
		padding: 25px
	}
}

.block-facetapi {
	padding: 15px;
	background: #ffffff;
	margin-bottom: 5px
}

@media (min-width:992px) {
	.block-facetapi {
		padding: 25px
	}
}

.block-facetapi .facetapi-facetapi-checkbox-links {
	overflow: hidden;
	margin: 0;
	padding: 0
}

.block-facetapi .facetapi-facetapi-checkbox-links li {
	clear: both;
	overflow: hidden;
	font-size: 13px;
	margin: 3px 0;
	display: block;
	float: left
}

.block-facetapi .facetapi-facetapi-checkbox-links li.expanded ul {
	margin: 0;
	padding: 0
}

.block-facetapi .facetapi-facetapi-checkbox-links input {
	margin: 8px 5px 8px 0;
	display: none
}

.block-facetapi .facetapi-facetapi-checkbox-links a {
	display: inline;
	zoom: 1;
	display: inline-block;
	position: relative;
	padding-left: 22px;
	line-height: 14px
}

.block-facetapi .facetapi-facetapi-checkbox-links a:before {
	content: '';
	position: absolute;
	width: 14px;
	height: 14px;
	line-height: 14px;
	left: 0;
	top: 0;
	background: #eaeae0 url(./assets/mferefresh/images/tick.png) no-repeat -5000px -5000px
}

.block-facetapi .facetapi-facetapi-checkbox-links a:hover:before,
.block-facetapi .facetapi-facetapi-checkbox-links a.facetapi-active:before {
	background-position: center
}

.block-current-search {
	list-style: none;
	padding: 15px;
	margin-bottom: 5px
}

@media (min-width:992px) {
	.block-current-search {
		padding: 25px
	}
}

.block-current-search .wrapper-active {
	padding: 0;
	margin: 0
}

.block-current-search .wrapper-active li {
	list-style: none
}

.block-current-search .wrapper-active a {
	text-indent: -5000em;
	display: inline;
	zoom: 1;
	display: inline-block;
	width: 15px;
	height: 15px;
	line-height: 15px;
	position: relative;
	padding-left: 0px
}

.block-current-search .wrapper-active a:before {
	content: 'ÃŽ';
	font-family: 'icns';
	position: absolute;
	width: 10px;
	height: 15px;
	line-height: 12px;
	top: 0;
	left: -6px;
	color: #267998;
	font-size: 20px;
	text-indent: 0
}

.block-current-search .wrapper-active a:hover:before {
	color: #1f9682
}

.publication-html h2.block-title {
	padding: 15px 15px 7px;
	margin-bottom: 0;
	font-weight: normal;
	font-family: "SourceSans", helvetica, arial, sans-serif;
	border-bottom: 2px solid #267998
}

@media (min-width:992px) {
	.publication-html h2.block-title {
		padding: 25px 25px 12px
	}
}

.publication-html h2.block-title a {
	color: #000000
}

.publication-html h2.block-title a:hover,
.publication-html h2.block-title a:active {
	text-decoration: none;
	color: #008575
}

.publication-html .menu {
	padding: 10px 0
}

.publication-html .menu li a {
	padding: 10px 15px
}

@media (min-width:992px) {
	.publication-html .menu li a {
		padding: 10px 25px
	}
}

.publication-html .menu li a:hover,
.publication-html .menu li a:focus {
	background: none
}

.publication-html .menu li a.active {
	color: #000000
}

.publication-html .menu li a.active:hover {
	color: #008575
}

.block-subscribe {
	background: #474342;
	color: #fff;
	clear: both;
	margin: 0;
	padding: 15px
}

.block-subscribe h3 {
	margin-top: 0
}

.block-subscribe h3 a {
	color: #fff;
	font-size: 28px;
	font-family: "SourceSans", helvetica, arial, sans-serif
}

@media (min-width:992px) {
	.block-subscribe {
		padding: 25px
	}
	.block-subscribe .content {
		width: 600px;
		margin: 0 auto;
		position: relative;
		padding-left: 90px
	}
	.block-subscribe .content h3 a:before {
		content: '';
		background: url(./assets/mferefresh/images/subscribe.png) 0 0 no-repeat;
		overflow: hidden;
		display: block;
		height: 82px;
		width: 64px;
		position: absolute;
		left: 0
	}
}

.landing-menu-links h3,
.landing-page-children h3 {
	margin: 0;
	font-size: 26px
}

.landing-menu-links ul,
.landing-page-children ul {
	margin: 0;
	padding: 0;
	list-style: none
}

.landing-menu-links ul li,
.landing-page-children ul li {
	list-style: none;
	padding: 0;
	margin: 0
}

.landing-menu-links {
	margin-top: 5px
}

.landing-menu-links .landing-menu-child {
	overflow: hidden;
	clear: both;
	margin-bottom: 5px;
	padding: 15px;
	background: #ffffff
}

.landing-menu-links .landing-menu-child>p {
	margin-top: 10px
}

.landing-menu-links .landing-menu-child ul li a {
	position: relative;
	padding-left: 20px;
	display: block
}

.landing-menu-links .landing-menu-child ul li a:before {
	content: "Ã—";
	font-family: 'icns';
	-webkit-font-smoothing: antialiased;
	position: absolute;
	top: 1px;
	left: 0;
	line-height: 1
}

@media (min-width:992px) {
	.landing-menu-links .landing-menu-child {
		padding: 25px
	}
	.landing-menu-links .landing-menu-child .col1,
	.landing-menu-links .landing-menu-child .col2 {
		width: 50%;
		float: left
	}
}

.landing-page-children {
	margin-top: 5px
}

.landing-page-children h2.block-title {
	position: absolute;
	width: 1px;
	height: 1px;
	margin: -1px;
	padding: 0;
	overflow: hidden;
	clip: rect(0, 0, 0, 0);
	border: 0
}

.landing-page-children .views-row {
	padding: 15px;
	background: #ffffff;
	margin-bottom: 5px
}

.landing-page-children .views-row .views-field-body {
	margin-top: 10px;
	margin-bottom: 5px
}

@media (min-width:992px) {
	.landing-page-children .views-row {
		padding: 25px
	}
}

.region-sidebar-first-inner .block-promo-blocks-block {
	background: #f1f1f1
}

.view-promo-blocks .views-row {
	margin-bottom: 15px;
	background-color: #fff
}

.view-promo-blocks .views-row img {
	width: 100%;
	height: auto
}

.Promo-standout .inner,
.Promo-green .inner,
.Promo-blue .inner {
	padding: 15px 15px 25px 15px;
	color: #fff
}

.Promo-standout a,
.Promo-green a,
.Promo-blue a {
	color: #fff
}

.region-sidebar-first-inner .Promo-standout a:hover,
.region-sidebar-first-inner .Promo-green a:hover,
.region-sidebar-first-inner .Promo-blue a:hover {
	color: #fff;
	color: rgba(255, 255, 255, 0.8)
}

.Promo-standout {
	background-color: #474342
}

.Promo-green {
	background-color: #1f9682
}

.Promo-blue {
	background-color: #267998
}

.view-promo-blocks p {
	margin: 0
}

.view-header {
	background: #ffffff;
	padding: 15px 15px 7px;
	margin-bottom: 5px
}

@media (min-width:992px) {
	.view-header {
		padding: 25px 25px 12px
	}
}

.view-filters {
	background: #ffffff;
	padding: 15px;
	border-bottom: 1px solid #267998;
	margin-bottom: 5px
}

@media (min-width:992px) {
	.view-filters {
		padding: 25px
	}
}

.views-exposed-form .views-exposed-widget {
	padding: 0;
	margin-right: 1em;
	margin-bottom: 15px;
	float: none
}

@media (min-width:992px) {
	.views-exposed-form .views-exposed-widget {
		float: left
	}
}

.views-exposed-form .views-exposed-widget .form-submit {
	margin: 0;
	padding: 10px 20px
}

.views-exposed-form .views-exposed-widget.views-submit-button,
.views-exposed-form .views-exposed-widget.views-reset-button {
	margin-bottom: 0;
	float: left;
	clear: none;
	width: auto
}

.views-exposed-form .views-exposed-widget.views-submit-button {
	clear: left
}

.views-exposed-form .views-exposed-widget .container-inline-date>.form-item {
	display: block;
	margin: 0
}

.views-exposed-form .views-exposed-widget .container-inline-date .date-padding {
	float: none
}

.views-exposed-form .views-exposed-widget .container-inline-date .form-item .form-item {
	float: none
}

.view-content table {
	background: #ffffff
}

@media (min-width:992px) {
	.view-waste-minimisation-fund-funded-projects .views-exposed-form .views-exposed-widget {
		width: 30%
	}
	.view-waste-minimisation-fund-funded-projects .views-exposed-form .views-exposed-widget.views-submit-button,
	.view-waste-minimisation-fund-funded-projects .views-exposed-form .views-exposed-widget.views-reset-button {
		width: auto
	}
}

.view-legislation-search .view-header {
	margin-bottom: 0
}

.view-legislation-search .view-filters label {
	display: none
}

.view-legislation-search .view-filters .views-exposed-widget {
	padding: 0
}

.view-legislation-search .view-filters .views-exposed-widget.views-widget-filter-search_api_views_fulltext {
	width: 80%;
	margin: 0;
	float: left;
	-webkit-transition: width 0.5s;
	-o-transition: width 0.5s;
	transition: width 0.5s
}

@media (min-width:992px) {
	.view-legislation-search .view-filters .views-exposed-widget.views-widget-filter-search_api_views_fulltext {
		width: 50%
	}
}

.view-legislation-search .view-filters .views-exposed-widget .form-text {
	-webkit-border-radius: 0;
	border-radius: 0;
	background-clip: padding-box;
	border-right: none;
	background: #eaeae0;
	height: 34px
}

.view-legislation-search .view-filters .views-exposed-widget.views-submit-button {
	clear: none;
	margin: 0
}

.view-legislation-search .view-filters .views-exposed-widget.views-submit-button .btn {
	margin-top: 0;
	text-indent: -1000000px;
	padding: 0;
	height: 34px;
	width: 34px;
	position: relative;
	background-color: #267998;
	border: none;
	-webkit-border-radius: 0;
	border-radius: 0;
	background-clip: padding-box
}

.view-legislation-search .view-filters .views-exposed-widget.views-submit-button .btn:after {
	content: '';
	height: 20px;
	width: 20px;
	background-image: url(./assets/mferefresh/images/sprite.png);
	background-position: 0px -28px;
	background-repeat: no-repeat;
	position: absolute;
	left: 50%;
	top: 50%;
	-webkit-transform: translate(-50%, -50%);
	-ms-transform: translate(-50%, -50%);
	-o-transform: translate(-50%, -50%);
	transform: translate(-50%, -50%)
}

.view-legislation-search .view-filters .views-exposed-widget.views-submit-button .btn:hover {
	background-color: #008575;
	border: none
}

.view-legislation-search .view-content table {
	font-size: 100%
}

.view-consultations .view-header {
	padding-bottom: 15px
}

@media (min-width:992px) {
	.view-consultations .view-header {
		padding-bottom: 25px
	}
}

.row-padding {
	padding: 7px 15px;
	background: #ffffff;
	margin-bottom: 5px
}

@media (min-width:992px) {
	.row-padding {
		padding: 12px 25px
	}
}

.view-padding .view-content {
	padding: 15px;
	background: #ffffff
}

@media (min-width:992px) {
	.view-padding .view-content {
		padding: 25px
	}
}

.no-header-margin .view-header {
	margin-bottom: 0
}

.view-combined-header .view-header {
	margin-bottom: 0
}

.view-combined-header .view-filters {
	padding-top: 0
}

.view-table-border table th,
.view-table-border table td {
	border-left: 1px solid #D3D3D3
}

.view-table-border table th:first-of-type,
.view-table-border table td:first-of-type {
	border-left: none
}

.view-list ul,
.view-list ol {
	list-style: none;
	padding: 0;
	margin: 0
}

.view-list ul li,
.view-list ol li {
	padding: 15px;
	list-style: none;
	background: #ffffff;
	margin: 0 0 5px;
	overflow: hidden;
	position: relative
}

@media (min-width:992px) {
	.view-list ul li,
	.view-list ol li {
		padding: 25px
	}
}

.view-list ul li .views-field,
.view-list ol li .views-field {
	margin-bottom: 5px;
	clear: left
}

@media (min-width:768px) {
	.view-list ul li .views-field,
	.view-list ol li .views-field {
		max-width: 580px
	}
}

@media (min-width:992px) {
	.view-list ul li .views-field,
	.view-list ol li .views-field {
		max-width: 540px
	}
}

@media (min-width:1200px) {
	.view-list ul li .views-field,
	.view-list ol li .views-field {
		max-width: 640px
	}
}

.view-list ul li .views-field-float-right,
.view-list ol li .views-field-float-right {
	background: #dddddd;
	padding: 10px;
	margin: -15px;
	margin-bottom: 0;
	width: 50%
}

@media (min-width:768px) {
	.view-list ul li .views-field-float-right,
	.view-list ol li .views-field-float-right {
		display: block;
		float: right;
		margin: -15px;
		background: #dddddd;
		padding: 10px;
		height: 100%;
		width: 150px;
		z-index: 100;
		position: relative
	}
}

@media (min-width:768px) and (min-width:992px) {
	.view-list ul li .views-field-float-right,
	.view-list ol li .views-field-float-right {
		margin: -25px
	}
}

@media (min-width:768px) and (min-width:1200px) {
	.view-list ul li .views-field-float-right,
	.view-list ol li .views-field-float-right {
		width: 200px
	}
}

@media (min-width:768px) {
	.view-list ul li .views-field-float-right img,
	.view-list ol li .views-field-float-right img {
		max-width: 100%;
		height: auto
	}
}

.view-list ul li .views-field-title,
.view-list ol li .views-field-title {
	font-size: 20px;
	margin-bottom: 10px;
	font-weight: bold
}

.view-list ul li .views-field-title *,
.view-list ol li .views-field-title * {
	margin: 0
}

@media (min-width:768px) {
	.view-list ul li.background-right:after,
	.view-list ol li.background-right:after {
		content: '';
		background: #dddddd;
		width: 150px;
		position: absolute;
		right: 0;
		top: 0;
		height: 100%
	}
}

@media (min-width:768px) and (min-width:1200px) {
	.view-list ul li.background-right:after,
	.view-list ol li.background-right:after {
		width: 200px
	}
}

.bef-checkboxes label {
	font-weight: normal
}

.view-satellite-data-search .views-exposed-widget input.date-date {
	width: 250px
}

.view-authorised-wood-burners .view-header,
.view-authorised-wood-burners .view-filters {
	background-color: #fff;
	border-bottom: none;
	padding: 0
}

.view-authorised-wood-burners .view-filters form {
	padding: 15px
}

@media (min-width:992px) {
	.view-authorised-wood-burners .view-filters form {
		padding: 25px
	}
}

.view-authorised-wood-burners .view-header {
	padding: 15px;
	margin-bottom: 5px
}

@media (min-width:992px) {
	.view-authorised-wood-burners .view-header {
		padding: 25px
	}
}

.view-authorised-wood-burners .view-header p:last-child {
	margin-bottom: 23px
}

@media (min-width:992px) {
	.view-authorised-wood-burners .view-header p:last-child {
		margin-bottom: 28px
	}
}

.view-authorised-wood-burners .view-filters .views-exposed-widget {
	box-sizing: border-box;
	font-size: 14px;
	margin-bottom: 15px;
	clear: both;
	float: none
}

@media (min-width:992px) {
	.view-authorised-wood-burners .view-filters .views-exposed-widget {
		float: left;
		clear: none
	}
}

.view-authorised-wood-burners .view-filters .views-widget-filter-field_old_appliance_value {
	margin-right: 0
}

.view-authorised-wood-burners .view-filters .views-exposed-widget>label {
	font-weight: bold !important
}

.view-authorised-wood-burners .view-filters .views-exposed-widget label {
	font-weight: normal;
	display: block;
	margin-bottom: 5px
}

.view-authorised-wood-burners .view-filters .views-exposed-widget .form-item label {
	display: inline;
	margin: 0;
	font-style: normal;
	font-size: 13px
}

.view-authorised-wood-burners .view-filters .form-type-radio label,
.view-authorised-wood-burners .view-filters .form-type-bef-checkbox label {
	line-height: 24px
}

.view-authorised-wood-burners .view-filters .form-type-radio a.checkbox,
.view-authorised-wood-burners .view-filters .form-type-bef-checkbox a.checkbox {
	float: left;
	margin-right: 4px
}

.view-authorised-wood-burners .view-filters .form-text {
	line-height: 34px
}

.view-authorised-wood-burners .view-filters .form-select {
	line-height: 1;
	padding: 0;
	margin: 0
}

.view-authorised-wood-burners .view-filters .views-submit-button,
.view-authorised-wood-burners .view-filters .views-reset-button {
	margin-bottom: 0;
	float: left;
	clear: none
}

.view-authorised-wood-burners .view-filters .views-submit-button {
	clear: left
}

.view-authorised-wood-burners .view-filters .form-submit {
	margin: 0;
	padding: 10px 20px
}

@media (min-width:992px) {
	.view-authorised-wood-burners .view-filters .form-text,
	.view-authorised-wood-burners .view-filters .form-select {
		width: 180px
	}
}

.view-authorised-wood-burners .view-content {
	margin-top: 5px
}

.view-authorised-wood-burners .view-content table {
	font-size: 90%;
	background: #ffffff
}

.view-authorised-wood-burners .view-content table thead>tr>th {
	vertical-align: middle;
	text-transform: uppercase;
	background: #317F9C;
	color: #ffffff;
	font-weight: normal
}

.view-authorised-wood-burners .view-content table th,
.view-authorised-wood-burners .view-content table td {
	padding: 10px 5px
}

#edit-field-old-appliance-value-wrapper {
	margin-right: 0
}

.block-last-updated-date-block {
	color: #fff;
	font-style: italic;
	font-weight: 600;
	font-size: 14px;
	-webkit-font-smoothing: antialiased
}

.block-last-updated-date-block .view-content {
	background: #267998;
	overflow: hidden;
	padding: 8px 15px;
	margin-top: 0
}

.block-last-updated-date-block .views-label,
.block-last-updated-date-block .field-content {
	display: inline;
	zoom: 1;
	display: inline-block;
	float: left
}

.block-last-updated-date-block .views-label {
	margin-right: 3px
}

@media (min-width:992px) {
	.block-last-updated-date-block {
		position: absolute;
		top: 0;
		right: 5px
	}
}

.no-header-padding .page-header {
	padding-top: 15px
}

@media (min-width:992px) {
	.no-header-padding .page-header {
		padding-top: 25px
	}
}

@media (min-width:992px) {
	.page-publication-search .page-header {
		padding-top: 25px
	}
}

.page-publication-search .view-header {
	background: #ffffff;
	padding: 15px 15px 7px;
	margin-bottom: 0
}

@media (min-width:992px) {
	.page-publication-search .view-header {
		padding: 25px 25px 12px
	}
}

.page-publication-search .view-filters {
	background: #ffffff;
	padding: 0 15px 15px;
	border-bottom: 1px solid #267998;
	margin-bottom: 5px
}

@media (min-width:992px) {
	.page-publication-search .view-filters {
		padding: 0 25px 25px
	}
}

.page-publication-search .view-filters label {
	display: none
}

.page-publication-search .view-filters .views-exposed-widget {
	padding: 0
}

.page-publication-search .view-filters .views-exposed-widget.views-widget-filter-search_api_views_fulltext {
	width: 80%;
	margin: 0;
	float: left;
	-webkit-transition: width 0.5s;
	-o-transition: width 0.5s;
	transition: width 0.5s
}

@media (min-width:992px) {
	.page-publication-search .view-filters .views-exposed-widget.views-widget-filter-search_api_views_fulltext {
		width: 50%
	}
}

.page-publication-search .view-filters .views-exposed-widget .form-text {
	-webkit-border-radius: 0;
	border-radius: 0;
	background-clip: padding-box;
	border-right: none;
	background: #eaeae0;
	height: 34px
}

.page-publication-search .view-filters .views-exposed-widget.views-submit-button {
	clear: none;
	margin: 0
}

.page-publication-search .view-filters .views-exposed-widget.views-submit-button .btn {
	margin-top: 0;
	text-indent: -1000000px;
	padding: 0;
	height: 34px;
	width: 34px;
	position: relative;
	background-color: #267998;
	border: none;
	-webkit-border-radius: 0;
	border-radius: 0;
	background-clip: padding-box
}

.page-publication-search .view-filters .views-exposed-widget.views-submit-button .btn:after {
	content: '';
	height: 20px;
	width: 20px;
	background-image: url(./assets/mferefresh/images/sprite.png);
	background-position: 0px -28px;
	background-repeat: no-repeat;
	position: absolute;
	left: 50%;
	top: 50%;
	-webkit-transform: translate(-50%, -50%);
	-ms-transform: translate(-50%, -50%);
	-o-transform: translate(-50%, -50%);
	transform: translate(-50%, -50%)
}

.page-publication-search .view-filters .views-exposed-widget.views-submit-button .btn:hover {
	background-color: #008575;
	border: none
}

.page-publication-search .publications ul {
	list-style: none;
	margin: 0;
	padding: 0
}

.page-publication-search .publications ul li.publication {
	position: relative;
	list-style: none;
	background: #ffffff;
	margin: 0 0 5px;
	padding: 15px;
	overflow: hidden
}

@media (min-width:768px) {
	.page-publication-search .publications ul li.publication:after {
		content: '';
		background: #dddddd;
		width: 120px;
		position: absolute;
		right: 0;
		top: 0;
		height: 100%
	}
}

@media (min-width:992px) {
	.page-publication-search .publications ul li.publication {
		padding: 25px
	}
}

@media (min-width:1200px) {
	.page-publication-search .publications ul li.publication:after {
		width: 160px
	}
}

.page-publication-search .publications ul li.publication .views-field {
	clear: left
}

@media (min-width:768px) {
	.page-publication-search .publications ul li.publication .views-field {
		max-width: 580px
	}
}

@media (min-width:992px) {
	.page-publication-search .publications ul li.publication .views-field {
		max-width: 540px
	}
}

@media (min-width:1200px) {
	.page-publication-search .publications ul li.publication .views-field {
		max-width: 640px
	}
}

.page-publication-search .publications ul li.publication .views-field-field-publication-cover {
	display: none
}

.page-publication-search .publications ul li.publication .views-field-field-publication-cover img {
	max-width: 100%;
	height: auto
}

@media (min-width:768px) {
	.page-publication-search .publications ul li.publication .views-field-field-publication-cover {
		display: block;
		float: right;
		margin: -15px;
		background: #dddddd;
		padding: 10px;
		width: 120px;
		height: 100%;
		z-index: 100;
		position: relative
	}
}

@media (min-width:992px) {
	.page-publication-search .publications ul li.publication .views-field-field-publication-cover {
		margin: -25px
	}
}

@media (min-width:1200px) {
	.page-publication-search .publications ul li.publication .views-field-field-publication-cover {
		width: 160px
	}
}

.page-publication-search .publications ul li.publication .views-field-title {
	font-size: 20px;
	margin-bottom: 10px;
	font-weight: bold
}

.page-publication-search .publications ul li.publication .views-field-field-publication-date {
	margin-bottom: 10px;
	font-style: italic
}

.page-site-search .page-header {
	padding-top: 15px
}

@media (min-width:992px) {
	.page-site-search .page-header {
		padding-top: 25px
	}
}

.view-site-main-search .view-header {
	margin-bottom: 0
}

.view-site-main-search .view-filters {
	padding-top: 0
}

.view-site-main-search .views-exposed-widget.views-widget-filter-search_api_views_fulltext {
	width: 80%;
	margin: 0;
	float: left;
	-webkit-transition: width 0.5s;
	-o-transition: width 0.5s;
	transition: width 0.5s
}

@media (min-width:992px) {
	.view-site-main-search .views-exposed-widget.views-widget-filter-search_api_views_fulltext {
		width: 50%
	}
}

.view-site-main-search .views-exposed-widget .form-text {
	-webkit-border-radius: 0;
	border-radius: 0;
	background-clip: padding-box;
	border-right: none;
	background: #eaeae0;
	height: 34px
}

.view-site-main-search .views-exposed-widget.views-submit-button {
	clear: none;
	margin: 0
}

.view-site-main-search .views-exposed-widget.views-submit-button .btn {
	margin-top: 0;
	text-indent: -1000000px;
	padding: 0;
	height: 34px;
	width: 34px;
	position: relative;
	background-color: #267998;
	border: none;
	-webkit-border-radius: 0;
	border-radius: 0;
	background-clip: padding-box
}

.view-site-main-search .views-exposed-widget.views-submit-button .btn:after {
	content: '';
	height: 20px;
	width: 20px;
	background-image: url(./assets/mferefresh/images/sprite.png);
	background-position: 0px -28px;
	background-repeat: no-repeat;
	position: absolute;
	left: 50%;
	top: 50%;
	-webkit-transform: translate(-50%, -50%);
	-ms-transform: translate(-50%, -50%);
	-o-transform: translate(-50%, -50%);
	transform: translate(-50%, -50%)
}

.view-site-main-search .views-exposed-widget.views-submit-button .btn:hover {
	background-color: #008575;
	border: none
}

.view-site-main-search .views-exposed-widget label {
	display: none
}

.view-site-main-search .publications {
	padding: 15px;
	background: #ffffff
}

@media (min-width:992px) {
	.view-site-main-search .publications {
		padding: 25px
	}
}

.view-site-main-search .publications ul {
	padding: 0;
	margin: 0;
	list-style: none
}

.view-site-main-search .publications ul li {
	list-style: none;
	margin-bottom: 10px
}

.view-site-main-search .views-field-title {
	font-size: 20px;
	font-weight: normal;
	margin-bottom: 5px
}

.view-site-main-search .views-field-url {
	margin-bottom: 5px;
	font-style: italic
}

.view-site-main-search .views-field-url a {
	color: #777777
}

.view-site-main-search .search-keyword {
	font-weight: bold
}

.page-node-18258 .page-header {
	padding-bottom: 15px;
	border-bottom: 1px solid #267998
}

@media (min-width:992px) {
	.page-node-18258 .page-header {
		padding-bottom: 25px
	}
}

.view-news-page {
	padding: 15px;
	margin-top: 5px;
	background: #ffffff
}

@media (min-width:992px) {
	.view-news-page {
		padding: 25px
	}
}

.view-news-page .view-content .views-row {
	border-top: 1px solid #F1F1F1;
	padding: 15px 0;
	overflow: hidden
}

@media (min-width:992px) {
	.view-news-page .view-content .views-row {
		padding: 25px 0
	}
}

.view-news-page .view-content .views-row:first-child {
	border-top: none;
	padding-top: 0
}

.view-news-page .view-content .views-row:last-child {
	padding-bottom: 0
}

.view-news-page .view-content .views-field-title {
	font-size: 20px;
	margin-bottom: 2px;
	font-weight: bold
}

.view-news-page .view-content .views-field-field-date {
	padding: 5px 0;
	font-style: italic;
	color: #4d4d4d
}

.view-news-page.view-display-id-block_2 {
	padding: 0
}

.view-news-page.view-display-id-block_2 a {
	display: block;
	padding: 15px;
	text-align: center;
	background: #267998;
	color: #ffffff;
	-webkit-transition: background 0.2s;
	-o-transition: background 0.2s;
	transition: background 0.2s
}

.view-news-page.view-display-id-block_2 a:hover,
.view-news-page.view-display-id-block_2 a.active,
.view-news-page.view-display-id-block_2 a.focus {
	background: #008575;
	text-decoration: none
}

@media (min-width:992px) {
	.view-news-page.view-display-id-block_2 a {
		padding: 25px
	}
}

.block-views-vacancy-block {
	padding: 15px;
	background: #ffffff;
	border-bottom: 1px solid #267998;
	margin-bottom: 5px
}

@media (min-width:992px) {
	.block-views-vacancy-block {
		padding: 25px
	}
}

.block-views-vacancy-block .view-vacancy .view-header {
	padding: 0
}

.block-views-vacancy-block .view-vacancy .view-content {
	margin-top: 7px
}

@media (min-width:992px) {
	.block-views-vacancy-block .view-vacancy .view-content {
		margin-top: 12px
	}
}

.block-views-vacancy-block .view-vacancy .view-content .views-row {
	margin-bottom: 15px
}

@media (min-width:992px) {
	.block-views-vacancy-block .view-vacancy .view-content .views-row {
		margin-bottom: 25px
	}
}

.block-views-vacancy-block .view-vacancy .view-content .views-row:last-child {
	margin-bottom: 0
}

.block-views-vacancy-block .view-vacancy .view-content .views-field {
	display: block;
	margin: 5px 0
}

.block-views-vacancy-block .view-vacancy .view-content .views-field-field-link {
	font-size: 18px;
	font-weight: bold
}

.block-views-vacancy-block .view-vacancy .view-content .views-field-field-closing-date {
	font-style: italic
}

.view-section-overview .view-content {
	padding: 0 15px 15px
}

@media (min-width:992px) {
	.view-section-overview .view-content {
		padding: 0 25px 25px
	}
}

.view-section-overview .view-content .row {
	margin-left: -6px;
	margin-right: -6px
}

.view-section-overview .view-content .row>div {
	padding-left: 6px;
	padding-right: 6px
}

.view-section-overview .view-content a.column {
	display: block;
	background-color: #F1F1F1;
	color: #333
}

@media (max-width:767px) {
	.view-section-overview .view-content a.column {
		margin-bottom: 5px
	}
}

.view-section-overview .view-content a.column:hover,
.view-section-overview .view-content a.column:active,
.view-section-overview .view-content a.column:focus {
	color: #333;
	text-decoration: none
}

.view-section-overview .view-content a.column:hover img,
.view-section-overview .view-content a.column:active img,
.view-section-overview .view-content a.column:focus img {
	opacity: .8;
	filter: alpha(opacity=80)
}

.view-section-overview .view-content a.column:hover span,
.view-section-overview .view-content a.column:active span,
.view-section-overview .view-content a.column:focus span {
	background-color: #f6f6f6
}

@media (max-width:768px) {
	.view-section-overview .view-content a.column img {
		display: none
	}
}

.view-section-overview .view-content a.column span {
	display: block;
	padding: 7px
}

@media (min-width:992px) {
	.view-section-overview .view-content a.column span {
		padding: 12px
	}
}

.view-section-overview .view-content a.column span h4 {
	font-family: "SourceSansSemibold", helvetica, arial, sans-serif
}

.view-section-overview .view-content a.column span p {
	font-family: "SourceSans", helvetica, arial, sans-serif
}

.ui-accordion.ui-widget {
	font-family: inherit;
	font-size: 100%
}

.ui-accordion .views-accordion-header {
	background: #1B546B;
	font-weight: bold;
	border: 0;
	-webkit-border-radius: 0;
	-moz-border-radius: 0;
	border-radius: 0;
	padding: 10px 15px 10px 50px
}

.ui-accordion .views-accordion-header .ui-accordion-header-icon {
	background: none;
	display: inline-block;
	position: absolute;
	padding: 0 10px;
	width: 30px;
	height: 30px;
	z-index: 1;
	top: calc(50% - 1px);
	left: 11px;
	margin-top: 0;
	overflow: visible
}

.ui-accordion .views-accordion-header .ui-accordion-header-icon:before,
.ui-accordion .views-accordion-header .ui-accordion-header-icon:after {
	background: #fff;
	-webkit-transition: all 600ms cubic-bezier(0.4, 0, 0.2, 1);
	transition: all 600ms cubic-bezier(0.4, 0, 0.2, 1);
	content: "";
	display: block;
	height: 2px;
	position: absolute;
	width: 10px;
	z-index: -1;
	left: 0;
	top: 0;
	-webkit-transform: rotate(-180deg);
	transform: rotate(-180deg);
	will-change: transform
}

.ui-accordion .views-accordion-header .ui-accordion-header-icon:before {
	left: 13px;
	transform: rotate(45deg)
}

.ui-accordion .views-accordion-header .ui-accordion-header-icon:after {
	right: 13px;
	left: auto;
	transform: rotate(-45deg)
}

.ui-accordion .views-accordion-header.ui-state-active {
	background: #008575
}

.ui-accordion .views-accordion-header.ui-state-active .ui-accordion-header-icon:before {
	-webkit-transform: rotate(135deg);
	transform: rotate(135deg)
}

.ui-accordion .views-accordion-header.ui-state-active .ui-accordion-header-icon:after {
	-webkit-transform: rotate(-135deg);
	transform: rotate(-135deg)
}

.ui-accordion .views-accordion-header a {
	color: #fff
}

.ui-accordion .ui-accordion-content {
	-webkit-border-radius: 0;
	-moz-border-radius: 0;
	border-radius: 0;
	border: 1px solid #008575;
	border-top: none;
	padding: 15px
}

.ui-accordion .ui-accordion-content .field {
	margin: 0;
	padding: 0
}

.view-content-block {
	background-color: #fff;
	padding: 15px
}

@media (min-width:992px) {
	.view-content-block {
		padding: 25px
	}
}

.view-content-block .view-filters {
	padding: 0 0 7px
}

@media (min-width:992px) {
	.view-content-block .view-filters {
		padding: 0 0 12px
	}
}

.view-content-block .field {
	padding: 0;
	background: none
}

.view-content-block table {
	margin-bottom: 0
}

.field-name-field-related-publications-basic,
.field-name-field-related-legislation,
.field-name-field-related-pages-in-this-site,
.field-name-field-related-publications {
	border-top: 2px solid transparent;
	margin-bottom: 3px;
	background: #fff;
	padding-top: 15px
}

@media (min-width:992px) {
	.field-name-field-related-publications-basic,
	.field-name-field-related-legislation,
	.field-name-field-related-pages-in-this-site,
	.field-name-field-related-publications {
		padding-top: 25px
	}
}

.field-name-field-related-publications-basic p.link-description,
.field-name-field-related-legislation p.link-description,
.field-name-field-related-pages-in-this-site p.link-description,
.field-name-field-related-publications p.link-description {
	margin: 0 0 .8em 0
}

.field-name-field-related-publications-basic a,
.field-name-field-related-legislation a,
.field-name-field-related-pages-in-this-site a,
.field-name-field-related-publications a {
	position: relative;
	padding-right: 14px;
	line-height: 1.1;
	display: inline;
	zoom: 1;
	display: inline-block;
	clear: both
}

.field-name-field-related-publications-basic a:after,
.field-name-field-related-legislation a:after,
.field-name-field-related-pages-in-this-site a:after,
.field-name-field-related-publications a:after {
	content: "Ã—";
	font-family: 'icns';
	-webkit-font-smoothing: antialiased;
	-webkit-transition: right 0.2s linear;
	-o-transition: right 0.2s linear;
	transition: right 0.2s linear;
	position: absolute;
	right: 0;
	top: 0
}

.field-name-field-related-publications-basic a:hover,
.field-name-field-related-publications-basic a:focus,
.field-name-field-related-legislation a:hover,
.field-name-field-related-legislation a:focus,
.field-name-field-related-pages-in-this-site a:hover,
.field-name-field-related-pages-in-this-site a:focus,
.field-name-field-related-publications a:hover,
.field-name-field-related-publications a:focus {
	color: #17556c !important
}

.field-name-field-related-publications-basic a:hover:after,
.field-name-field-related-publications-basic a:focus:after,
.field-name-field-related-legislation a:hover:after,
.field-name-field-related-legislation a:focus:after,
.field-name-field-related-pages-in-this-site a:hover:after,
.field-name-field-related-pages-in-this-site a:focus:after,
.field-name-field-related-publications a:hover:after,
.field-name-field-related-publications a:focus:after {
	right: -4px
}

.field-name-field-related-publications-basic .field-label,
.field-name-field-related-legislation .field-label,
.field-name-field-related-pages-in-this-site .field-label,
.field-name-field-related-publications .field-label {
	font-size: 20px;
	font-weight: 400;
	margin-bottom: 10px;
	margin-top: 0;
	font-family: "SourceSansRegular", helvetica, arial, sans-serif
}

.field-name-field-related-publications-basic .field-items,
.field-name-field-related-legislation .field-items,
.field-name-field-related-pages-in-this-site .field-items,
.field-name-field-related-publications .field-items {
	line-height: 1.8;
	font-size: 14px
}

.field-name-field-related-publications-basic .field-items p:last-child,
.field-name-field-related-legislation .field-items p:last-child,
.field-name-field-related-pages-in-this-site .field-items p:last-child,
.field-name-field-related-publications .field-items p:last-child {
	margin: 0
}

.field-name-field-related-publications-basic,
.field-name-field-related-publications {
	border-color: #267998
}

.node .field-name-field-related-publications-basic a,
.field-name-field-related-publications-basic a,
.node .field-name-field-related-publications a,
.field-name-field-related-publications a {
	color: #267998
}

.field-name-field-related-legislation {
	border-color: #1f9682
}

.node .field-name-field-related-legislation a,
.field-name-field-related-legislation a {
	color: #1f9682
}

.field-name-field-related-pages-in-this-site {
	border-color: #474342
}

.node .field-name-field-related-pages-in-this-site a,
.field-name-field-related-pages-in-this-site a {
	color: #474342
}

.leaflet-control input[type=radio] {
	-webkit-appearance: radio
}

.leaflet-popup-content-wrapper {
	border-radius: 0;
	padding: 0
}

.leaflet-popup-content-wrapper .leaflet-popup-content {
	margin: 0;
	font-size: 14px
}

.leaflet-popup-content-wrapper .leaflet-popup-content .popup-title {
	padding: 10px 20px 10px 10px;
	background: #0c414f;
	color: #fff;
	line-height: 20px;
	font-family: "SourceSansSemibold", helvetica, arial, sans-serif
}

.leaflet-popup-content-wrapper .leaflet-popup-content .popup-body-content {
	padding: 10px;
	font-family: "SourceSansRegular", helvetica, arial, sans-serif
}

.leaflet-popup-content-wrapper .leaflet-popup-content .popup-body-content ul {
	padding: 0;
	margin: 0;
	list-style: none
}

.leaflet-popup-content-wrapper .leaflet-popup-content .popup-body-content ul li {
	line-height: 20px;
	padding: 5px 0;
	min-width: 180px;
	border-bottom: 1px dotted #dddddd;
	margin: 0
}

.leaflet-popup-content-wrapper .leaflet-popup-content .popup-body-content ul li:first-of-type {
	padding-top: 0
}

.leaflet-popup-content-wrapper .leaflet-popup-content .popup-body-content ul li:last-of-type {
	border-bottom: none;
	padding-bottom: 0
}

.leaflet-popup-content-wrapper .leaflet-popup-content .popup-body-content ul li.link a {
	display: block;
	background: #0c414f;
	color: #fff;
	text-align: center;
	padding: 5px 0;
	margin-top: 5px;
	text-decoration: none
}

.leaflet-popup-content-wrapper .leaflet-popup-content .popup-body-content ul li.link a:hover {
	background: #17556c
}

.leaflet-popup-content-wrapper .leaflet-popup-content .popup-body-content ul li span {
	display: inline-block;
	height: 20px;
	width: 20px;
	float: left;
	margin-right: 10px;
	-webkit-border-radius: 15px;
	-moz-border-radius: 15px;
	border-radius: 15px
}

.leaflet-popup-content-wrapper .leaflet-popup-content .popup-body-content ul li span.algae-caution {
	border-radius: 0;
	-moz-border-radius: 0;
	-webkit-border-radius: 0;
	background-image: url(./assets/mferefresh/images/caution.png)
}

.leaflet-swim-key,
.leaflet-region-list {
	-webkit-border-radius: 0;
	border-radius: 0;
	background-clip: padding-box;
	-webkit-box-shadow: none;
	-moz-box-shadow: none;
	box-shadow: none
}

.leaflet-swim-key>a,
.leaflet-region-list>a {
	-webkit-border-radius: 0 !important;
	border-radius: 0 !important;
	background-clip: padding-box;
	text-decoration: none
}

.leaflet-swim-key ul,
.leaflet-region-list ul {
	margin: 0;
	padding: 0
}

.leaflet-swim-key ul li,
.leaflet-region-list ul li {
	margin-left: 0;
	list-style: none;
	background: #e6eae3;
	margin-bottom: 1px;
	padding: 0 5px 0 0;
	clear: both;
	height: 30px;
	line-height: 30px
}

.leaflet-swim-key ul li a,
.leaflet-swim-key ul li a:hover,
.leaflet-swim-key ul li a:focus,
.leaflet-region-list ul li a,
.leaflet-region-list ul li a:hover,
.leaflet-region-list ul li a:focus {
	width: 100%;
	height: 30px;
	line-height: 30px;
	background: none;
	color: #000;
	text-align: left;
	background: #e6eae3;
	display: block;
	-webkit-border-radius: 0 !important;
	border-radius: 0 !important;
	background-clip: padding-box;
	padding-right: 10px
}

.leaflet-swim-key ul li span,
.leaflet-region-list ul li span {
	display: block;
	width: 30px;
	height: 30px;
	float: left;
	margin-right: 10px
}

.leaflet-region-list>a,
.leaflet-region-list>a:hover,
.leaflet-region-list>a:focus {
	width: 100%;
	background: #17556c url(./assets/mferefresh/images/region-control.png) no-repeat 7px 50%;
	background-size: 22px;
	-webkit-background-size: 22px;
	-moz-background-size: 22px;
	padding: 0 5px 0 40px;
	color: #ffffff;
	border-bottom: 0;
	margin-bottom: 1px;
	height: 36px;
	line-height: 36px;
	font-family: "SourceSans", helvetica, arial, sans-serif;
	font-size: 13px;
	text-align: left
}

.leaflet-region-list ul li {
	padding-right: 0
}

.leaflet-region-list ul li a span {
	text-indent: -1000000px;
	overflow: hidden;
	background: #39849f url(./assets/mferefresh/images/region-control-arrow.png) no-repeat 50% 50%;
	margin-right: 10px;
	-webkit-background-size: 10px;
	-moz-background-size: 10px;
	background-size: 10px
}

.leaflet-region-list.leaflet-region-list-hide>a,
.leaflet-region-list.leaflet-region-list-hide>a:hover,
.leaflet-region-list.leaflet-region-list-hide>a:focus {
	width: 36px !important;
	overflow: hidden;
	text-indent: -1000000px;
	padding: 0
}

.leaflet-region-list.leaflet-region-list-hide ul {
	display: none
}

.leaflet-swim-key a,
.leaflet-swim-key a:hover,
.leaflet-swim-key a:focus {
	width: 100%;
	padding: 0 5px 0 40px;
	color: #ffffff;
	border-bottom: 0;
	margin-bottom: 1px;
	height: 36px;
	line-height: 36px;
	font-family: "SourceSans", helvetica, arial, sans-serif;
	font-size: 13px;
	text-align: left;
	background: #17556c url(./assets/mferefresh/images/swim-control.png) no-repeat 5px 50%
}

.leaflet-swim-key ul li {
	min-width: 180px
}

.leaflet-swim-key.leaflet-swim-key-hide a,
.leaflet-swim-key.leaflet-swim-key-hide a:hover,
.leaflet-swim-key.leaflet-swim-key-hide a:focus {
	width: 36px !important;
	overflow: hidden;
	text-indent: -1000000px;
	padding: 0
}

.leaflet-swim-key.leaflet-swim-key-hide ul {
	display: none
}

.leaflet-control-buttonlayers a {
	height: 36px;
	line-height: 36px;
	background: #bbbbbb;
	display: inline-block;
	min-width: 100px;
	text-align: center;
	color: #fff;
	font-weight: normal;
	font-size: 14px;
	font-family: "SourceSansRegular", helvetica, arial, sans-serif;
	opacity: 0.9
}

.leaflet-control-buttonlayers a:hover {
	color: #ffffff;
	background: #267998;
	text-decoration: none
}

.leaflet-control-buttonlayers a:focus {
	color: #ffffff;
	text-decoration: none
}

.leaflet-control-buttonlayers a.layer-selected {
	background: #267998
}

.leaflet-control-buttonlayers a.layer-selected:hover {
	cursor: default
}

.leaflet-touch .leaflet-bar a {
	height: 36px;
	line-height: 36px
}

.leaflet-touch .leaflet-control-zoomhome>a {
	width: 36px
}

.leaflet-touch .leaflet-swim-key a,
.leaflet-touch .leaflet-region-list a {
	width: 100%
}

.leaflet-touch .leaflet-region-list ul li a {
	height: 30px;
	line-height: 30px
}

.leaflet-touch .leaflet-swim-key-hide a,
.leaflet-touch .leaflet-region-list-hide a {
	width: 36px
}

.leaflet-touch .leaflet-control-layers,
.leaflet-touch .leaflet-bar {
	border: none
}

.leaflet-left .leaflet-control {
	margin-left: 0
}

.leaflet-top .leaflet-control {
	margin-top: 0
}

.leaflet-top.leaflet-right .leaflet-control {
	margin-right: 0
}

.leaflet-bottom.leaflet-left {
	height: 50%
}

.leaflet-control-zoomhome {
	border-radius: 0;
	box-shadow: none;
	margin-top: -54px
}

.leaflet-control-zoomhome>a,
.leaflet-control-zoomhome>a:hover,
.leaflet-control-zoomhome>a:focus {
	border-radius: 0 !important;
	border-bottom: 0;
	width: 36px;
	height: 36px;
	line-height: 36px;
	color: #000;
	font-size: 25px;
	font-family: "SourceSans", helvetica, arial, sans-serif;
	font-weight: 700
}

.leaflet-control-zoomhome>a.leaflet-control-zoomhome-in,
.leaflet-control-zoomhome>a:hover.leaflet-control-zoomhome-in,
.leaflet-control-zoomhome>a:focus.leaflet-control-zoomhome-in {
	background: #dddddd
}

.leaflet-control-zoomhome>a.leaflet-control-zoomhome-out,
.leaflet-control-zoomhome>a:hover.leaflet-control-zoomhome-out,
.leaflet-control-zoomhome>a:focus.leaflet-control-zoomhome-out {
	background: #eeeeee
}

.leaflet-control-zoomhome>a.leaflet-control-zoomhome-home,
.leaflet-control-zoomhome>a:hover.leaflet-control-zoomhome-home,
.leaflet-control-zoomhome>a:focus.leaflet-control-zoomhome-home {
	background: #c0e4ee url(./assets/mferefresh/images/zoom-control-globe.png) no-repeat 50% 50%;
	-webkit-background-size: 20px;
	-moz-background-size: 20px;
	background-size: 20px;
	text-indent: -1000000px
}

.leaflet-label {
	background: #17556c;
	background-clip: padding-box;
	border: none !important;
	color: #ffffff;
	display: block;
	font: 12px/20px "SourceSansRegular", helvetica, arial, sans-serif;
	font-weight: 400;
	padding: 4px 12px;
	border-radius: 2px;
	box-shadow: 0 2px 10px rgba(0, 0, 0, 0.4)
}

.leaflet-label:before,
.leaflet-label:after {
	border-top: 6px solid transparent;
	border-bottom: 6px solid transparent;
	content: none;
	position: absolute;
	top: 8px
}

.leaflet-label:before {
	border-right: 6px solid black;
	border-right-color: #17556c;
	left: -6px
}

.leaflet-label:after {
	border-left: 6px solid black;
	border-left-color: #17556c;
	right: -6px
}

.leaflet-label-right:before,
.leaflet-label-left:after {
	content: ""
}

/* .mfe-council-point {
	background-size: 3px 3px;
	background: url("/sites/all/modules/geo_csv_import/leaflet_label/images/triangle.png") no-repeat
} */

.view-region-level-map .view-header,
.view-river-level-map .view-header {
	padding: 0;
	margin: 0
}

.node-type-region .page-header,
.node-type-river .page-header,
.node-type-lake .page-header {
	padding-bottom: 15px
}

@media (min-width:992px) {
	.node-type-region .page-header,
	.node-type-river .page-header,
	.node-type-lake .page-header {
		padding-bottom: 25px
	}
}

.webform-client-form {
	padding: 15px;
	background: #ffffff
}

@media (min-width:992px) {
	.webform-client-form {
		padding: 25px
	}
}

.webform-client-form fieldset {
	-webkit-border-radius: 0;
	border-radius: 0;
	background-clip: padding-box;
	border: none;
	-webkit-box-shadow: none;
	-moz-box-shadow: none;
	box-shadow: none
}

.webform-client-form fieldset>legend {
	background-color: #ffffff !important;
	border: none;
	padding: 0;
	font-weight: bold
}

.webform-client-form fieldset>.panel-body {
	padding: 15px 0 0
}

.webform-client-form .webform-container-inline div,
.webform-client-form .webform-container-inline div.form-item {
	display: inline-block
}

.webform-client-form .webform-component-date .form-item {
	margin-right: 5px
}

.webform-client-form .webform-component-time .form-item {
	margin-right: 5px
}

.webform-client-form .webform-component-time .form-item:nth-child(2) {
	margin-left: 5px;
	margin-right: 20px
}

.consultation-blocks .view-content {
	padding-left: 25px;
	padding-right: 25px
}

.consultation-blocks .view-content .row .col-md-4 .field-consultation-image img {
	min-width: 100%
}

@media (max-width:991px) {
	.consultation-blocks .view-content .row .col-md-4 .field-consultation-image img {
		min-width: 100%
	}
}

.consultation-blocks h2 {
	font-family: "Merriweather", georgia, serif;
	font-size: 25px
}

.consultation-blocks .field-content a {
	font-family: "SourceSansSemibold", helvetica, arial, sans-serif;
	font-size: 20px;
	color: #4b4b4b
}

.consultation-blocks .field-content a:focus {
	text-decoration: none
}

.consultation-blocks .field-name-field-opening-and-closing-dates {
	padding-left: 0;
	font-family: "SourceSans", helvetica, arial, sans-serif;
	font-size: 14px
}

@media (max-width:991px) {
	.view-consultation-landing-page .view-header {
		padding-left: 25px
	}
}

.field-consultation-tiles,
.field-consultations-under-review {
	padding-left: 0;
	padding-right: 0;
	padding-bottom: 0
}

.field-consultation-tiles .view-header,
.field-consultations-under-review .view-header {
	padding-top: 0
}

.field-consultation-tiles .block-title,
.field-consultations-under-review .block-title {
	padding-left: 25px
}

.field-consultation-tiles .field-opening-and-closing-dates,
.field-consultations-under-review .field-opening-and-closing-dates {
	padding-bottom: 5px
}

.field-consultation-tiles .consultation-tile-text,
.field-consultations-under-review .consultation-tile-text {
	padding: 5px;
	position: absolute;
	background-color: white;
	width: 45%;
	left: 15px;
	top: 10px
}

@media (max-width:991px) {
	.field-consultation-tiles .consultation-tile-text,
	.field-consultations-under-review .consultation-tile-text {
		width: 87%
	}
}

@media (max-width:767px) {
	.field-consultation-tiles .consultation-tile-text,
	.field-consultations-under-review .consultation-tile-text {
		width: 93%
	}
}

.field-consultation-tiles .field-consultation-image,
.field-consultations-under-review .field-consultation-image {
	width: 100%;
	padding-left: 0;
	padding-right: 0;
	padding-bottom: 10px
}

.field-consultations-under-review {
	padding-top: 2em
}

.consultations-footer {
	padding-top: 2em;
	padding-bottom: 2em;
	margin-top: 2em;
	background-color: #2C9986
}

.consultations-footer .row .consultations-footer-col {
	text-align: center
}

.consultations-footer .row .consultations-footer-col a {
	background: #2f667b;
	color: white;
	padding: 7px 22px;
	border: 3px solid #2f667b;
	border-radius: 20px;
	text-decoration: none
}

.consultations-footer .row .consultations-footer-col a:hover {
	border: 2px solid #2f667b;
	background-color: white;
	color: #2f667b
}

.view-consultation-landing-page .view-empty {
	padding-left: 25px;
	padding-bottom: 25px
}

.views-label-field-consultation-timeline {
	margin-top: 1em !important
}

.consultation-timeline {
	margin-bottom: 0 !important
}

.field-timeline-appendix {
	padding-bottom: 0
}

.field-consultation-timeline {
	margin: 1em 0 0 0;
	padding: 0;
	list-style: none;
	position: relative
}

.field-consultation-timeline .consultation-info .consultation-date {
	font-weight: bold
}

.field-consultation-timeline li:before {
	content: '';
	position: absolute;
	top: 0;
	bottom: 0;
	width: 2px;
	background: black;
	left: 15%;
	margin-left: -6px
}

.field-consultation-timeline li:last-child:before {
	display: none
}

.field-consultation-timeline>li {
	position: relative
}

.field-consultation-timeline>li .consultation-info {
	margin: 0 0 0 25%;
	padding: 0 1em 1em 1em;
	position: relative
}

.field-consultation-timeline>li .consultation-blue-icon {
	width: 40px;
	height: 40px;
	background: #114459;
	border: 1px solid black;
	border-radius: 50%;
	position: absolute;
	left: 15%;
	top: 0;
	margin: 0 0 0 -25px
}

.field-consultation-timeline>li .consultation-white-icon {
	width: 40px;
	height: 40px;
	background: white;
	border: 1px solid black;
	border-radius: 50%;
	position: absolute;
	left: 15%;
	top: 0;
	margin: 0 0 0 -25px
}

.no-js-message,
.legacy-browser {
	padding: 20px;
	margin: 20px 0
}

.main-container>.row>aside,
.main-container>.row>section {
	margin-top: 15px
}

.page-header {
	background-color: #ffffff;
	padding: 15px 15px 0;
	margin-bottom: 0;
	border: none
}

@media (min-width:992px) {
	.page-header {
		padding: 40px 25px 0
	}
}

.view-all-publications {
	padding: 10px 15px;
	display: block;
	background: #267998;
	color: #ffffff;
	text-transform: uppercase;
	-webkit-transition: background 0.2s;
	-o-transition: background 0.2s;
	transition: background 0.2s
}

.view-all-publications i {
	margin-right: 10px
}

.view-all-publications:hover,
.view-all-publications:focus {
	background: #216984;
	text-decoration: none;
	color: #ffffff
}

@media (min-width:992px) {
	.view-all-publications {
		padding: 10px 25px
	}
}

#backtotop {
	z-index: 1000
}

.ckeditor-accordion-container>dl {
	border: none;
	margin-bottom: 0
}

.ckeditor-accordion-container>dl dt {
	padding-top: 1px
}

.ckeditor-accordion-container>dl dt>a {
	background-color: #1B546B;
	border-bottom: none
}

.ckeditor-accordion-container>dl dt>a:hover {
	background-color: #008575
}

.ckeditor-accordion-container>dl dt.active>a {
	background-color: #008575
}

.ckeditor-accordion-container>dl dd {
	border: 1px solid #008575;
	border-top: none;
	padding-top: 10px
}

.page-404 .block-system-main {
	background: #ffffff;
	overflow: auto;
	padding: 15px
}

@media (min-width:992px) {
	.page-404 .block-system-main {
		padding: 25px
	}
}

#subForm input[type="checkbox"] {
	max-width: 10%;
	float: left;
	margin-right: 5px
}

#subForm input[type="checkbox"]+label {
	max-width: 90%
}

@media print {
	#header,
	#navbar,
	#toolbar,
	.footer,
	.title,
	ul.tabs--primary.nav.nav-tabs,
	.breadcrumb,
	.field-name-field-related-pages-in-this-site,
	.col-md-3,
	div#block-views-last-updated-date-block,
	.alert-success.messages.status,
	div#block-workbench-block {
		display: none !important
	}
}

#autocomplete {
	border: 1px solid;
	overflow: hidden;
	position: absolute;
	z-index: 100;
}

#autocomplete ul {
	list-style: none;
	list-style-image: none;
	margin: 0;
	padding: 0;
}

#autocomplete li {
	background: #fff;
	color: #000;
	cursor: default;
	white-space: pre;
	zoom: 1;
}

html.js input.form-autocomplete {
	background-image: url(./assets/misc/throbber-inactive.png);
	background-position: 100% center;
	background-repeat: no-repeat;
}

html.js input.throbbing {
	background-image: url(./assets/misc/throbber-active.gif);
	background-position: 100% center;
}

html.js fieldset.collapsed {
	border-bottom-width: 0;
	border-left-width: 0;
	border-right-width: 0;
	height: 1em;
}

html.js fieldset.collapsed .fieldset-wrapper {
	display: none;
}

fieldset.collapsible {
	position: relative;
}

fieldset.collapsible .fieldset-legend {
	display: block;
}

.form-textarea-wrapper textarea {
	display: block;
	margin: 0;
	width: 100%;
	-moz-box-sizing: border-box;
	-webkit-box-sizing: border-box;
	box-sizing: border-box;
}

.resizable-textarea .grippie {
	background: #eee url(./assets/misc/grippie.png) no-repeat center 2px;
	border: 1px solid #ddd;
	border-top-width: 0;
	cursor: s-resize;
	height: 9px;
	overflow: hidden;
}

body.drag {
	cursor: move;
}

.draggable a.tabledrag-handle {
	cursor: move;
	float: left;
	height: 1.7em;
	margin-left: -1em;
	overflow: hidden;
	text-decoration: none;
}

a.tabledrag-handle:hover {
	text-decoration: none;
}

a.tabledrag-handle .handle {
	background: url(./assets/misc/draggable.png) no-repeat 6px 9px;
	height: 13px;
	margin: -0.4em 0.5em;
	padding: 0.42em 0.5em;
	width: 13px;
}

a.tabledrag-handle-hover .handle {
	background-position: 6px -11px;
}

div.indentation {
	float: left;
	height: 1.7em;
	margin: -0.4em 0.2em -0.4em -0.4em;
	padding: 0.42em 0 0.42em 0.6em;
	width: 20px;
}

div.tree-child {
	background: url(./assets/misc/tree.png) no-repeat 11px center;
}

div.tree-child-last {
	background: url(./assets/misc/tree-bottom.png) no-repeat 11px center;
}

div.tree-child-horizontal {
	background: url(./assets/misc/tree.png) no-repeat -11px center;
}

.tabledrag-toggle-weight-wrapper {
	text-align: right;
}

table.sticky-header {
	background-color: #fff;
	margin-top: 0;
}

.progress .bar {
	background-color: #fff;
	border: 1px solid;
}

.progress .filled {
	background-color: #000;
	height: 1.5em;
	width: 5px;
}

.progress .percentage {
	float: right;
}

.ajax-progress {
	display: inline-block;
}

.ajax-progress .throbber {
	background: transparent url(./assets/misc/throbber-active.gif) no-repeat 0px center;
	float: left;
	height: 15px;
	margin: 2px;
	width: 15px;
}

.ajax-progress .message {
	padding-left: 20px;
}

tr .ajax-progress .throbber {
	margin: 0 2px;
}

.ajax-progress-bar {
	width: 16em;
}

.container-inline div,
.container-inline label {
	display: inline;
}

.container-inline .fieldset-wrapper {
	display: block;
}

.nowrap {
	white-space: nowrap;
}

html.js .js-hide {
	display: none;
}

.element-hidden {
	display: none;
}

.element-invisible {
	position: absolute !important;
	clip: rect(1px 1px 1px 1px);
	clip: rect(1px, 1px, 1px, 1px);
	overflow: hidden;
	height: 1px;
}

.element-invisible.element-focusable:active,
.element-invisible.element-focusable:focus {
	position: static !important;
	clip: auto;
	overflow: visible;
	height: auto;
}

.clearfix:after {
	content: ".";
	display: block;
	height: 0;
	clear: both;
	visibility: hidden;
}

* html .clearfix {
	height: 1%;
}

*:first-child+html .clearfix {
	min-height: 1%;
}

.calendar-calendar tr.odd,
.calendar-calendar tr.even {
	background-color: #fff;
}

.calendar-calendar table {
	border-collapse: collapse;
	border-spacing: 0;
	margin: 0 auto;
	padding: 0;
	width: 100%;
}

.calendar-calendar .month-view table {
	border: none;
	padding: 0;
	margin: 0;
	width: 100%;
}

.calendar-calendar .year-view td {
	width: 32%;
	padding: 1px;
	border: none;
}

.calendar-calendar .year-view td table td {
	width: 13%;
	padding: 0;
}

.calendar-calendar tr {
	padding: 0;
	margin: 0;
	background-color: white;
}

.calendar-calendar th {
	text-align: center;
	margin: 0;
}

.calendar-calendar th a {
	font-weight: bold;
}

.calendar-calendar td {
	width: 14%;
	border: 1px solid #ccc;
	color: #777;
	text-align: right;
	vertical-align: top;
	margin: 0;
	padding: 0;
}

.calendar-calendar .mini {
	border: none;
}

.calendar-calendar td.week {
	width: 1%;
	min-width: 1%;
}

.calendar-calendar .week {
	clear: both;
	font-style: normal;
	color: #555;
	font-size: 0.8em;
}

.calendar-calendar .inner {
	height: auto !important;
	height: 5em;
	padding: 0;
	margin: 0;
}

.calendar-calendar .inner div {
	padding: 0;
	margin: 0;
}

.calendar-calendar .inner p {
	padding: 0 0 .8em 0;
	margin: 0;
}

.calendar-calendar td a {
	font-weight: bold;
	text-decoration: none;
}

.calendar-calendar td a:hover {
	text-decoration: underline;
}

.calendar-calendar td.year,
.calendar-calendar td.month {
	text-align: center;
}

.calendar-calendar th.days {
	color: #ccc;
	background-color: #224;
	text-align: center;
	padding: 1px;
	margin: 0;
}

.calendar-calendar div.day {
	float: right;
	text-align: center;
	padding: 0.125em 0.25em 0 0.25em;
	margin: 0;
	background-color: #f3f3f3;
	border: 1px solid gray;
	border-width: 0 0 1px 1px;
	clear: both;
	width: 1.5em;
}

.calendar-calendar div.calendar {
	background-color: #fff;
	border: solid 1px #ddd;
	text-align: left;
	margin: 0 .25em .25em 0;
	width: 96%;
	float: right;
	clear: both;
}

.calendar-calendar .day-view div.calendar {
	float: none;
	width: 98%;
	margin: 1% 1% 0 1%;
}

.calendar-calendar div.title {
	font-size: .8em;
	text-align: center;
}

.calendar-calendar div.title a {
	color: #000;
}

.calendar-calendar div.title a:hover {
	color: #c00;
}

.calendar-calendar .content {
	clear: both;
	padding: 3px;
	padding-left: 5px;
}

.calendar div.form-item {
	white-space: normal;
}

table td.mini,
table th.mini,
table.mini td.week {
	padding: 0 1px 0 0;
	margin: 0;
}

table td.mini a {
	font-weight: normal;
}

.calendar-calendar .mini-day-off {
	padding: 0px;
}

.calendar-calendar .mini-day-on {
	padding: 0px;
}

table .mini-day-on a {
	text-decoration: underline;
}

.calendar-calendar .mini .title {
	font-size: .8em;
}

.mini .calendar-calendar .week {
	font-size: .7em;
}

.mini-row {
	width: 100%;
	border: none;
}

.mini {
	width: 32%;
	vertical-align: top;
}

.calendar-calendar .stripe {
	height: 5px;
	width: auto;
	font-size: 1px !important;
	line-height: 1px !important;
}

.calendar-calendar .day-view .stripe {
	width: 100%;
}

table.calendar-legend {
	background-color: #ccc;
	width: 100%;
	margin: 0;
	padding: 0;
}

table.calendar-legend tr.odd .stripe,
table.calendar-legend tr.even .stripe {
	height: 12px !important;
	font-size: 9px !important;
	line-height: 10px !important;
}

.calendar-legend td {
	text-align: left;
	padding-left: 5px;
}

.calendar-empty {
	font-size: 1px;
	line-height: 1px;
}

.calendar-calendar td.calendar-agenda-hour {
	text-align: right;
	border: none;
	border-top: 1px solid #CCCCCC;
	padding-top: .25em;
	width: 1%;
}

.calendar-calendar td.calendar-agenda-no-hours {
	min-width: 1%;
}

.calendar-calendar td.calendar-agenda-hour .calendar-hour {
	font-size: 1.2em;
	font-weight: bold;
}

.calendar-calendar td.calendar-agenda-hour .calendar-ampm {
	font-size: 1em;
}

.calendar-calendar td.calendar-agenda-items {
	border: 1px solid #CCCCCC;
	text-align: left;
}

.calendar-calendar td.calendar-agenda-items div.calendar {
	width: auto;
	padding: .25em;
	margin: 0;
}

.calendar-calendar div.calendar div.inner .calendar-agenda-empty {
	width: 100%;
	text-align: center;
	vertical-align: middle;
	padding: 1em 0;
	background-color: #fff;
}

.calendar-date-select form {
	text-align: right;
	float: right;
	width: 25%;
}

.calendar-date-select div,
.calendar-date-select input,
.calendar-date-select label {
	text-align: right;
	padding: 0;
	margin: 0;
	float: right;
	clear: both;
}

.calendar-date-select .description {
	float: right;
}

.calendar-label {
	font-weight: bold;
	display: block;
	clear: both;
}

.calendar-calendar div.date-nav {
	background-color: #ccc;
	color: #777;
	padding: 0.2em;
	width: auto;
	border: 1px solid #ccc;
}

.calendar-calendar div.date-nav a,
.calendar-calendar div.date-nav h3 {
	color: #777;
	text-decoration: none;
}

.calendar-calendar th.days {
	background-color: #eee;
	color: #777;
	font-weight: bold;
	border: 1px solid #ccc;
}

.calendar-calendar td.empty {
	background: #ccc;
	border-color: #ccc;
	color: #cc9;
}

.calendar-calendar table.mini td.empty {
	background: #fff;
	border-color: #fff;
}

.calendar-calendar td div.day {
	border: 1px solid #ccc;
	border-top: none;
	border-right: none;
	margin-bottom: 2px;
}

.calendar-calendar td .inner div,
.calendar-calendar td .inner div a {
	background: #eee;
}

.calendar-calendar div.calendar {
	border: none;
	font-size: x-small;
}

.calendar-calendar td .inner div.calendar div,
.calendar-calendar td .inner div.calendar div a {
	border: none;
	background: #ffc;
	padding: 0;
}

.calendar-calendar td .inner div.calendar div.calendar-more,
.calendar-calendar td .inner div.calendar div.calendar-more a {
	color: #444;
	background: #fff;
	text-align: right;
}

.calendar-calendar td .inner .view-field,
.calendar-calendar td .inner .view-field a {
	color: #444;
	font-weight: normal;
}

.calendar-calendar td span.date-display-single,
.calendar-calendar td span.date-display-start,
.calendar-calendar td span.date-display-end,
.calendar-calendar td span.date-display-separator {
	font-weight: bold;
}

.calendar-calendar td .inner div.day a {
	color: #4b85ac;
}

.calendar-calendar tr td.today,
.calendar-calendar tr.odd td.today,
.calendar-calendar tr.even td.today {
	background-color: #C3D6E4;
}

.calendar-calendar td .inner div.calendar div div.view-data-node-data-field-date-field-date-value {}

.calendar-calendar td.past {}

.calendar-calendar td.future {}

.calendar-calendar td.has-events {}

.calendar-calendar td.has-no-events {}

.calendar-calendar tbody {
	border-top: none;
}

.calendar-calendar .month-view .full .inner,
.calendar-calendar .week-view .full .multi-day .inner {
	height: auto;
	min-height: auto;
}

.calendar-calendar .week-view .full .calendar-agenda-hour .calendar-calendar .month-view .full .single-day .inner .view-item {
	float: left;
	width: 100%;
}

.calendar-calendar .week-view .full .calendar-agenda-hour,
.calendar-calendar .day-view .full .calendar-agenda-hour {
	padding-right: 2px;
}

.calendar-calendar .week-view .full .calendar-agenda-hour {
	width: 6%;
	min-width: 0px;
}

.calendar-calendar .week-view .full .days {
	width: 13%;
}

.calendar-calendar .month-view .full div.calendar,
.calendar-calendar .week-view .full div.calendar,
.calendar-calendar .day-view div.calendar {
	width: auto;
}

.calendar-calendar .month-view .full tr.date-box,
.calendar-calendar .month-view .full tr.date-box td,
.calendar-calendar .month-view .full tr.multi-day,
.calendar-calendar .month-view .full tr.multi-day td {
	height: 19px;
	max-height: 19px;
}

.calendar-calendar .month-view .full tr.single-day .no-entry,
.calendar-calendar .month-view .full tr.single-day .no-entry .inner {
	height: 44px !important;
	line-height: 44px;
}

.calendar-calendar .month-view .full tr.single-day .noentry-multi-day,
.calendar-calendar .month-view .full tr.single-day .noentry-multi-day .inner {
	height: 22px !important;
	line-height: 22px;
}

.calendar-calendar .month-view .full td,
.calendar-calendar .week-view .full td,
.calendar-calendar .day-view td {
	vertical-align: top;
	padding: 1px 2px 0 2px
}

.calendar-calendar .month-view .full td.date-box {
	height: 1%;
	border-bottom: 0px;
	padding-bottom: 2px;
}

.calendar-calendar .month-view .full .week {
	font-size: inherit;
}

.calendar-calendar .month-view .full .week a,
.calendar-calendar .week-view .full .week a {
	color: #4b85ac;
}

.calendar-calendar .month-view .full td .inner div.day,
.calendar-calendar .month-view .full td .inner div.day a {
	border: none;
	background: none;
	margin-bottom: 0px;
}

.calendar-calendar .month-view .full td.date-box .inner,
.calendar-calendar .week-view .full td.date-box .inner {
	min-height: inherit;
}

.calendar-calendar .month-view .full td.multi-day,
.calendar-calendar .week-view .full td.multi-day {
	border-top: 0px;
	border-bottom: 0px;
}

.calendar-calendar .week-view .full .first td.multi-day {
	border-top: 1px solid #CCCCCC;
}

.calendar-calendar .month-view .full td.single-day {
	border-top: 0px;
}

.calendar-calendar .month-view .full td.multi-day .inner,
.calendar-calendar .week-view .full td.multi-day .inner,
.calendar-calendar .day-view .full td.multi-day .inner {
	min-height: inherit;
	width: auto;
	position: relative;
}

.calendar-calendar .month-view .full td.multi-day.no-entry {
	min-height: 0px;
}

.calendar-calendar .month-view .full td.single-day .calendar-empty,
.calendar-calendar .month-view .full td.single-day.empty,
.calendar-calendar .month-view .full td.date-box.empty {
	background: #F4F4F4;
}

.calendar-calendar .month-view .full td.single-day .inner div,
.calendar-calendar .month-view .full td.single-day .inner div a,
.calendar-calendar .month-view .full td.multi-day .inner div,
.calendar-calendar .month-view .full td.multi-day .inner div a,
.calendar-calendar .month-view .full td .inner div.calendar.monthview div,
.calendar-calendar .month-view .full td .inner div.calendar.monthview div a,
.calendar-calendar .week-view .full td.single-day .inner div,
.calendar-calendar .week-view .full td.single-day .inner div a,
.calendar-calendar .week-view .full td.multi-day .inner div,
.calendar-calendar .week-view .full td.multi-day .inner div a,
.calendar-calendar .week-view .full td .inner div.calendar.weekview div,
.calendar-calendar .week-view .full td .inner div.calendar.weekview div a,
.calendar-calendar .day-view .full td .inner div.view-item,
.calendar-calendar .day-view .full td .inner div.calendar div,
.calendar-calendar .day-view .full td .inner div.calendar div a {
	background: none;
}

.calendar-calendar .day-view .full td .inner div.calendar div,
.calendar-calendar .day-view .full td .inner div.calendar div a {
	margin: 0px 3px;
}

.calendar-calendar .day-view .full td .inner div.calendar div.stripe {
	margin: 0px;
}

.calendar-calendar .month-view .full tr td.today,
.calendar-calendar .month-view .full tr.odd td.today,
.calendar-calendar .month-view .full tr.even td.today {
	background: none;
	border-left: 2px solid #7C7F12;
	border-right: 2px solid #7C7F12;
}

.calendar-calendar .month-view .full td.date-box.today {
	border-width: 2px 2px 0px 2px;
	border-style: solid;
	border-color: #7C7F12;
}

.calendar-calendar .month-view .full tr td.single-day.today {
	border-bottom: 2px solid #7C7F12;
}

.calendar-calendar .month-view .full tr td.multi-day.starts-today {
	border-left: 2px solid #7C7F12;
}

.calendar-calendar .month-view .full tr td.multi-day.ends-today {
	border-right: 2px solid #7C7F12;
}

.calendar-calendar .month-view .full tr td.multi-day,
.calendar-calendar .month-view .full tr td.single-day {
	border-top: 0px;
}

.calendar-calendar .month-view .full tr td.multi-day,
.calendar-calendar .month-view .full tr td.date-box {
	border-bottom: 0px;
}

.calendar-calendar .month-view .full .inner .monthview,
.calendar-calendar .week-view .full .inner .weekview,
.calendar-calendar .day-view .full .inner .dayview {
	-moz-border-radius: 5px;
	border-radius: 5px;
	width: auto;
	float: none;
	display: block;
	margin: .25em auto;
	position: relative;
}

.calendar-calendar .month-view .full td.single-day div.monthview,
.calendar-calendar .week-view .full td.single-day div.weekview,
.calendar-calendar .day-view .full td.single-day div.dayview {
	background: #ffc;
	width: auto;
	padding: 0px 3px;
	overflow: hidden;
}

.calendar-calendar .month-view .full td.single-day .calendar-more div.monthview {
	background: none;
}

.calendar-calendar .day-view td div.dayview {
	padding: 0px;
}

.calendar-calendar .month-view .full td.multi-day div.monthview,
.calendar-calendar .week-view .full td.multi-day div.weekview,
.calendar-calendar .day-view .full td.multi-day div.dayview {
	background: #74a5d7;
	height: 1.9em;
	overflow: hidden;
	margin: 0px auto;
	color: #ffffff;
	position: relative;
}

.calendar-calendar .week-view .full td.multi-day div.weekview {
	height: 3.5em;
}

.calendar-calendar .month-view .full td.multi-day .inner .view-field,
.calendar-calendar .month-view .full td.multi-day .inner .view-field a,
.calendar-calendar .week-view .full td.multi-day .inner .view-field,
.calendar-calendar .week-view .full td.multi-day .inner .view-field a,
.calendar-calendar .day-view .full td.multi-day .inner .view-field,
.calendar-calendar .day-view .full td.multi-day .inner .view-field a {
	color: #ffffff;
}

.calendar-calendar .full td.multi-day .calendar .view-field,
.calendar-calendar .full td.single-day .calendar .view-field {}

.calendar-calendar .day-view .full td.multi-day div.dayview,
.calendar-calendar .week-view .full td.multi-day div.weekview {
	margin-bottom: 2px;
}

.calendar-calendar .month-view .full td.multi-day .calendar.monthview .view-field {
	white-space: nowrap;
	float: left;
	margin-right: 3px;
}

.calendar-calendar .week-view .full td.multi-day .calendar.weekview .view-field {
	white-space: nowrap;
	display: inline;
	margin-right: 3px;
}

.calendar-calendar .day-view .full td.multi-day .calendar.weekview .view-field {
	display: block;
}

.calendar-calendar .month-view .full td.multi-day .calendar.monthview .contents,
.calendar-calendar .week-view .full td.multi-day .calendar.weekview .contents {
	position: absolute;
	width: 3000px;
	left: 5px;
}

.calendar-calendar .day-view td .stripe,
.calendar-calendar .month-view .full td .stripe,
.calendar-calendar .week-view .full td .stripe {
	-moz-border-radius: 5px 5px 0px 0px;
	border-radius: 5px 5px 0px 0px;
	left: 0px;
	top: 0px;
	width: 100%;
	height: 3px;
	z-index: 2;
}

.calendar-calendar .full td.single-day .continuation,
.calendar-calendar .full td.single-day .continues,
.calendar-calendar .full td.single-day .cutoff {
	display: none;
}

.calendar-calendar .month-view .full td.multi-day .inner .monthview .continuation,
.calendar-calendar .week-view .full td.multi-day .inner .weekview .continuation {
	float: left;
	margin-right: 3px;
	height: 1.9em;
}

.calendar-calendar .week-view .full td.multi-day .inner .weekview .continuation {
	height: 2.75em;
	padding-top: 0.75em;
	margin-right: 8px;
}

.calendar-calendar .month-view .full td.multi-day .inner .monthview .continues,
.calendar-calendar .month-view .full td.multi-day .inner .monthview .cutoff,
.calendar-calendar .week-view .full td.multi-day .inner .weekview .continues,
.calendar-calendar .week-view .full td.multi-day .inner .weekview .cutoff {
	position: absolute;
	right: 0px !important;
	right: -1px;
	width: 10px;
	text-align: left;
	background: #74a5d7;
	-moz-border-radius: 0px 5px 5px 0px;
	border-radius: 0px 5px 5px 0px;
	height: 1.9em;
	padding-left: 6px;
	z-index: 1;
}

.calendar-calendar .week-view .full td.multi-day .inner .weekview .continues,
.calendar-calendar .week-view .full td.multi-day .inner .weekview .cutoff {
	height: 2.75em;
	padding-top: 0.75em;
}

.calendar-calendar .month-view .full td.multi-day .inner .monthview .cutoff,
.calendar-calendar .week-view .full td.multi-day .inner .weekview .cutoff {
	width: 8px;
	padding-left: 0px;
}

.calendar-calendar .week-view .full td.multi-day {
	padding: 2px;
}

.calendar-calendar .week-view td.single-day div.calendar {
	width: 100%;
	padding-left: 0px;
	padding-right: 0px;
}

.calendar-calendar .week-view .full tr.last td.multi-day {
	border-bottom: 1px solid #CCCCCC;
}

.view .date-nav-wrapper .clear-block {
	margin-bottom: 10px;
}

.view .date-nav-wrapper {
	position: relative;
	margin-top: 5px;
	width: 100%;
}

.view .date-nav-wrapper .date-nav {
	background-color: transparent;
	border: 0px;
	height: 30px;
	height: auto;
	min-height: 30px;
	position: relative;
	margin-bottom: 10px;
}

.view .date-nav-wrapper .date-prev a,
.view .date-nav-wrapper .date-next a {
	text-decoration: none;
	color: inherit;
	font-size: 12px;
}

.view .date-nav-wrapper.date-nav a:hover {
	text-decoration: underline;
}

.view .date-nav-wrapper .date-prev {
	-moz-border-radius: 5px 0 0 5px;
	border-radius: 5px 0 0 5px;
	background: none repeat scroll 0 0 #dfdfdf;
	float: none;
	padding: 5px 0;
	position: absolute;
	right: 80px;
	left: auto;
	text-align: right;
	top: 0px;
	width: auto;
	z-index: 1;
	font-size: 12px;
}

.block-views .view .date-nav-wrapper .date-prev {
	left: 0;
	right: auto;
}

.view .date-nav-wrapper .date-prev a {
	margin-left: 10px;
	font-weight: bold;
}

.view .date-nav-wrapper .date-heading {
	position: relative;
	width: 100%;
	top: 0px;
	text-align: center;
	z-index: 0;
}

.view .date-nav-wrapper .date-heading h3 {
	line-height: 30px;
	font-size: 1.7em;
}

.view .date-nav-wrapper .date-next {
	-moz-border-radius: 0px 5px 5px 0px;
	border-radius: 0px 5px 5px 0px;
	background: none repeat scroll 0 0 #dfdfdf;
	float: none;
	padding: 5px 0;
	position: absolute;
	right: 0px;
	text-align: right;
	top: 0px;
	width: auto;
	z-index: 1;
	font-size: 12px;
}

.view .date-nav-wrapper .date-next a {
	margin-right: 10px;
	font-weight: bold;
}

.attachment .calendar-calendar {
	margin-top: 20px;
	clear: both;
}

.calendar-calendar th a,
.attachment .calendar-calendar th {
	background-color: transparent;
	border: 0px;
}

.attachment .calendar-calendar th.calendar-agenda-hour {
	color: #777777;
	font-weight: bold;
	text-align: right;
}

.view-calendar .feed-icon {
	margin-top: 5px;
}

.view table.mini .date-prev,
.view table.mini .date-next {
	background: none;
}

.date-nav div.date-prev,
.date-nav div.date-next,
.date-nav {
	width: auto;
}

ul.calendar-links,
.region-content ul.calendar-links {
	margin: 0;
	padding: 0;
}

.year-view div.month-view div.date-nav {
	background-color: #DFDFDF;
	min-height: 20px;
}

.ckeditor-accordion-container>dl:before,
.ckeditor-accordion-container>dl:after {
	content: " ";
	display: table;
}

.ckeditor-accordion-container>dl:after {
	clear: both;
}

.ckeditor-accordion-container>dl,
.ckeditor-accordion-container>dl dt,
.ckeditor-accordion-container>dl dd {
	box-sizing: border-box;
}

.ckeditor-accordion-container>dl {
	position: relative;
	border: 1px solid #0091ea;
}

.ckeditor-accordion-container>dl dt {
	position: relative;
}

.ckeditor-accordion-container>dl dt>.ckeditor-accordion-toggle {
	display: inline-block;
	position: absolute;
	padding: 0 10px;
	width: 30px;
	height: 30px;
	z-index: 1;
	top: calc(50% - 1px);
	left: 11px;
	box-sizing: border-box;
}

.ckeditor-accordion-container>dl dt>.ckeditor-accordion-toggle:before,
.ckeditor-accordion-container>dl dt>.ckeditor-accordion-toggle:after {
	background: #fff;
	-webkit-transition: all 600ms cubic-bezier(0.4, 0, 0.2, 1);
	transition: all 600ms cubic-bezier(0.4, 0, 0.2, 1);
	content: "";
	display: block;
	height: 2px;
	position: absolute;
	width: 10px;
	z-index: -1;
	left: 0;
	top: 0;
	-webkit-transform: rotate(-180deg);
	transform: rotate(-180deg);
	will-change: transform;
}

.ckeditor-accordion-container>dl dt>.ckeditor-accordion-toggle:before {
	left: 13px;
	-webkit-transform: rotate(135deg);
	transform: rotate(135deg);
}

.ckeditor-accordion-container>dl dt>.ckeditor-accordion-toggle:after {
	right: 13px;
	left: auto;
	-webkit-transform: rotate(-135deg);
	transform: rotate(-135deg);
}

.ckeditor-accordion-container>dl dt.active>.ckeditor-accordion-toggle:before {
	transform: rotate(45deg);
}

.ckeditor-accordion-container>dl dt.active>.ckeditor-accordion-toggle:after {
	transform: rotate(-45deg);
}

.ckeditor-accordion-container>dl dt>a {
	display: block;
	padding: 10px 15px 10px 50px;
	background-color: #00b0ff;
	color: #fff;
	cursor: pointer;
	-webkit-transition: background-color 300ms;
	transition: background-color 300ms;
	border-bottom: 1px solid #00a4ec;
}

.ckeditor-accordion-container>dl dt>a:hover {
	background-color: #00a5ef;
}

.ckeditor-accordion-container>dl dt.active>a {
	background-color: #0091ea;
}

.ckeditor-accordion-container>dl dt:last-of-type>a {
	border-bottom: 0;
}

.ckeditor-accordion-container>dl dd {
	display: none;
	padding: 0 15px;
	margin: 0;
	will-change: height;
}

#colorboxNodeLoading {
	background: url(https://ndhadeliver.natlib.govt.nz/webarchive/wayback/20210313012705/https://www.mfe.govt.nz/sites/all/modules/colorbox_node/images/loading.gif) no-repeat center center;
	width: 100%;
	height: 100%;
}

.container-inline-date {
	clear: both;
}

.container-inline-date .form-item {
	float: none;
	margin: 0;
	padding: 0;
}

.container-inline-date>.form-item {
	display: inline-block;
	margin-right: 0.5em;
	vertical-align: top;
}

fieldset.date-combo .container-inline-date>.form-item {
	margin-bottom: 10px;
}

.container-inline-date .form-item .form-item {
	float: left;
}

.container-inline-date .form-item,
.container-inline-date .form-item input {
	width: auto;
}

.container-inline-date .description {
	clear: both;
}

.container-inline-date .form-item input,
.container-inline-date .form-item select,
.container-inline-date .form-item option {
	margin-right: 5px;
}

.container-inline-date .date-spacer {
	margin-left: -5px;
}

.views-right-60 .container-inline-date div {
	margin: 0;
	padding: 0;
}

.container-inline-date .date-timezone .form-item {
	clear: both;
	float: none;
	width: auto;
}

.container-inline-date .date-padding {
	float: left;
}

fieldset.date-combo .container-inline-date .date-padding {
	padding: 10px;
}

.views-exposed-form .container-inline-date .date-padding {
	padding: 0;
}

#calendar_div,
#calendar_div td,
#calendar_div th {
	margin: 0;
	padding: 0;
}

#calendar_div,
.calendar_control,
.calendar_links,
.calendar_header,
.calendar {
	border-collapse: separate;
	margin: 0;
	width: 185px;
}

.calendar td {
	padding: 0;
}

span.date-display-single {}

span.date-display-start {}

span.date-display-end {}

.date-prefix-inline {
	display: inline-block;
}

.date-clear {
	clear: both;
	display: block;
	float: none;
}

.date-no-float {
	clear: both;
	float: none;
	width: 98%;
}

.date-float {
	clear: none;
	float: left;
	width: auto;
}

.date-float .form-type-checkbox {
	padding-right: 1em;
}

.form-type-date-select .form-type-select[class*=hour] {
	margin-left: .75em;
}

.date-container .date-format-delete {
	float: left;
	margin-top: 1.8em;
	margin-left: 1.5em;
}

.date-container .date-format-name {
	float: left;
}

.date-container .date-format-type {
	float: left;
	padding-left: 10px;
}

.date-container .select-container {
	clear: left;
	float: left;
}

div.date-calendar-day {
	background: #F3F3F3;
	border-top: 1px solid #EEE;
	border-left: 1px solid #EEE;
	border-right: 1px solid #BBB;
	border-bottom: 1px solid #BBB;
	color: #999;
	float: left;
	line-height: 1;
	margin: 6px 10px 0 0;
	text-align: center;
	width: 40px;
}

div.date-calendar-day span {
	display: block;
	text-align: center;
}

div.date-calendar-day span.month {
	background-color: #B5BEBE;
	color: white;
	font-size: .9em;
	padding: 2px;
	text-transform: uppercase;
}

div.date-calendar-day span.day {
	font-size: 2em;
	font-weight: bold;
}

div.date-calendar-day span.year {
	font-size: .9em;
	padding: 2px;
}

.date-form-element-content-multiline {
	padding: 10px;
	border: 1px solid #CCC;
}

.form-item.form-item-instance-widget-settings-input-format-custom,
.form-item.form-item-field-settings-enddate-required {
	margin-left: 1.3em;
}

#edit-field-settings-granularity .form-type-checkbox {
	margin-right: .6em;
}

.date-year-range-select {
	margin-right: 1em;
}

#ui-datepicker-div {
	font-size: 100%;
	font-family: Verdana, sans-serif;
	background: #eee;
	border-right: 2px #666 solid;
	border-bottom: 2px #666 solid;
	z-index: 9999;
}

.ui-datepicker {
	width: 17em;
	padding: .2em .2em 0;
}

.ui-datepicker .ui-datepicker-header {
	position: relative;
	padding: .2em 0;
}

.ui-datepicker .ui-datepicker-prev,
.ui-datepicker .ui-datepicker-next {
	position: absolute;
	top: 2px;
	width: 1.8em;
	height: 1.8em;
}

.ui-datepicker .ui-datepicker-prev-hover,
.ui-datepicker .ui-datepicker-next-hover {
	top: 1px;
}

.ui-datepicker .ui-datepicker-prev {
	left: 2px;
}

.ui-datepicker .ui-datepicker-next {
	right: 2px;
}

.ui-datepicker .ui-datepicker-prev-hover {
	left: 1px;
}

.ui-datepicker .ui-datepicker-next-hover {
	right: 1px;
}

.ui-datepicker .ui-datepicker-prev span,
.ui-datepicker .ui-datepicker-next span {
	display: block;
	position: absolute;
	left: 50%;
	margin-left: -8px;
	top: 50%;
	margin-top: -8px;
}

.ui-datepicker .ui-datepicker-title {
	margin: 0 2.3em;
	line-height: 1.8em;
	text-align: center;
}

.ui-datepicker .ui-datepicker-title select {
	float: left;
	font-size: 1em;
	margin: 1px 0;
}

.ui-datepicker select.ui-datepicker-month-year {
	width: 100%;
}

.ui-datepicker select.ui-datepicker-month,
.ui-datepicker select.ui-datepicker-year {
	width: 49%;
}

.ui-datepicker .ui-datepicker-title select.ui-datepicker-year {
	float: right;
}

.ui-datepicker table {
	width: 100%;
	font-size: .9em;
	border-collapse: collapse;
	margin: 0 0 .4em;
}

.ui-datepicker th {
	padding: .7em .3em;
	text-align: center;
	font-weight: bold;
	border: 0;
}

.ui-datepicker td {
	border: 0;
	padding: 1px;
}

.ui-datepicker td span,
.ui-datepicker td a {
	display: block;
	padding: .2em;
	text-align: right;
	text-decoration: none;
}

.ui-datepicker .ui-datepicker-buttonpane {
	background-image: none;
	margin: .7em 0 0 0;
	padding: 0 .2em;
	border-left: 0;
	border-right: 0;
	border-bottom: 0;
}

.ui-datepicker .ui-datepicker-buttonpane button {
	float: right;
	margin: .5em .2em .4em;
	cursor: pointer;
	padding: .2em .6em .3em .6em;
	width: auto;
	overflow: visible;
}

.ui-datepicker .ui-datepicker-buttonpane button.ui-datepicker-current {
	float: left;
}

.ui-datepicker.ui-datepicker-multi {
	width: auto;
}

.ui-datepicker-multi .ui-datepicker-group {
	float: left;
}

.ui-datepicker-multi .ui-datepicker-group table {
	width: 95%;
	margin: 0 auto .4em;
}

.ui-datepicker-multi-2 .ui-datepicker-group {
	width: 50%;
}

.ui-datepicker-multi-3 .ui-datepicker-group {
	width: 33.3%;
}

.ui-datepicker-multi-4 .ui-datepicker-group {
	width: 25%;
}

.ui-datepicker-multi .ui-datepicker-group-last .ui-datepicker-header {
	border-left-width: 0;
}

.ui-datepicker-multi .ui-datepicker-group-middle .ui-datepicker-header {
	border-left-width: 0;
}

.ui-datepicker-multi .ui-datepicker-buttonpane {
	clear: left;
}

.ui-datepicker-row-break {
	clear: both;
	width: 100%;
}

.ui-datepicker-rtl {
	direction: rtl;
}

.ui-datepicker-rtl .ui-datepicker-prev {
	right: 2px;
	left: auto;
}

.ui-datepicker-rtl .ui-datepicker-next {
	left: 2px;
	right: auto;
}

.ui-datepicker-rtl .ui-datepicker-prev:hover {
	right: 1px;
	left: auto;
}

.ui-datepicker-rtl .ui-datepicker-next:hover {
	left: 1px;
	right: auto;
}

.ui-datepicker-rtl .ui-datepicker-buttonpane {
	clear: right;
}

.ui-datepicker-rtl .ui-datepicker-buttonpane button {
	float: left;
}

.ui-datepicker-rtl .ui-datepicker-buttonpane button.ui-datepicker-current {
	float: right;
}

.ui-datepicker-rtl .ui-datepicker-group {
	float: right;
}

.ui-datepicker-rtl .ui-datepicker-group-last .ui-datepicker-header {
	border-right-width: 0;
	border-left-width: 1px;
}

.ui-datepicker-rtl .ui-datepicker-group-middle .ui-datepicker-header {
	border-right-width: 0;
	border-left-width: 1px;
}

.field .field-label {
	font-weight: bold;
}

form .field-multiple-table {
	margin: 0;
}

form .field-multiple-table th.field-label {
	padding-left: 0;
}

form .field-multiple-table td.field-multiple-drag {
	width: 30px;
	padding-right: 0;
}

form .field-multiple-table td.field-multiple-drag a.tabledrag-handle {
	padding-right: .5em;
}

form .field-add-more-submit {
	margin: .5em 0 0;
}

div.toboggan-container {
	text-align: center;
}

div.toboggan-login-link-container {
	text-align: left;
}

div.toboggan-login-link-container a {
	text-decoration: none;
}

div.toboggan-block-message {
	text-align: left;
}

div.user-login-block {
	text-align: left;
}

div.user-login-block a {
	text-align: left;
}

.toboggan-unified #login-message {
	text-align: center;
	font-size: 2em;
	line-height: 1.2;
}

.toboggan-unified #login-links {
	text-align: center;
	font-size: 1.5em;
	line-height: 2.7;
}

.toboggan-unified #login-links a {
	padding: .5em;
	border: 1px #666 solid;
	background-color: #EEE;
}

.toboggan-unified #login-links a:hover {
	background-color: #DDD;
}

.toboggan-unified #login-links a.lt-active,
.toboggan-unified #login-links a.lt-active:hover {
	background-color: #FFF19A;
	border: 2px #333 solid;
}

.toboggan-unified.login #register-form {
	display: none;
}

.toboggan-unified.register #login-form {
	display: none;
}

.node-unpublished {
	background-color: #fff4f4;
}

.preview .node {
	background-color: #ffffea;
}

td.revision-current {
	background: #ffc;
}

.views-exposed-form .views-exposed-widget {
	float: left;
	padding: .5em 1em 0 0;
}

.views-exposed-form .views-exposed-widget .form-submit {
	margin-top: 1.6em;
}

.views-exposed-form .form-item,
.views-exposed-form .form-submit {
	margin-top: 0;
	margin-bottom: 0;
}

.views-exposed-form label {
	font-weight: bold;
}

.views-exposed-widgets {
	margin-bottom: .5em;
}

.views-align-left {
	text-align: left;
}

.views-align-right {
	text-align: right;
}

.views-align-center {
	text-align: center;
}

.views-view-grid tbody {
	border-top: none;
}

.view .progress-disabled {
	float: none;
}

#backtotop {
	background: url(https://ndhadeliver.natlib.govt.nz/webarchive/wayback/20210313012705/https://www.mfe.govt.nz/sites/all/modules/back_to_top/backtotop.png) no-repeat center center;
	border: 0;
	bottom: 20px;
	cursor: pointer;
	display: none;
	height: 70px;
	position: fixed;
	right: 20px;
	text-indent: -9999px;
	width: 70px;
	z-index: 1;
}

#backtotop:hover {
	opacity: 0.8;
}

#backtotop:focus {
	opacity: 0.6;
	outline: none;
}

@media (-webkit-min-device-pixel-ratio:2),
(min-resolution:192dpi) {
	#backtotop {
		background: url(https://ndhadeliver.natlib.govt.nz/webarchive/wayback/20210313012705/https://www.mfe.govt.nz/sites/all/modules/back_to_top/backtotop2x.png) no-repeat center center;
		background-size: 70px 70px;
	}
}

@media print {
	#backtotop {
		visibility: hidden;
	}
}

.flickr-photo-img {
	vertical-align: text-bottom;
}

.flickr-photo-box {
	float: left;
	width: 260px;
	height: 260px;
	padding: 10px;
	text-align: center;
}

.flickr-photoset-img {
	vertical-align: text-bottom;
}

.flickr-photoset-box {
	float: left;
	padding: 10px;
	text-align: center;
}

span.flickr-wrap,
span.flickr-photoset-single {
	position: relative;
	display: inline-block;
	margin: 0 5px 5px 0;
	vertical-align: top;
}

span.flickr-credit {
	display: block;
	overflow: hidden;
	padding: 0 0 4px 4px;
	font-size: 80%;
	line-height: 100%;
}

.flickr-slideshow {
	position: relative;
	overflow: hidden;
	width: 100%;
	height: 0;
	padding-bottom: 75%;
}

.flickr-slideshow object {
	position: absolute;
	top: 0;
	left: 0;
	max-width: 100%;
	max-height: 100%;
}

.flickr-block-photosets .flickr-block-photoset {
	margin-top: 0.5em;
	padding-top: 0.5em;
	border-top: 1px solid #dfdfdf;
}

span.floatleft {
	float: left;
	margin: 5px 5px 0 0;
}

span.floatright {
	float: right;
	margin: 5px 0 0 10px;
}

span.flickr-tags {
	font-style: italic;
}

.flickr-wrap,
.flickr-wrap img,
.flickr-wrap .flickr-credit {
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
	max-width: 100%;
}

div.flickr-photoset {
	clear: both;
}

span.flickr-empty {
	display: inline-block;
	margin: 10px;
}

.page-flickr-sets h1#page-title {
	display: none;
}

.tips span.ctools-toggle {
	float: none !important;
}

span.grayed-out,
.form-disabled label,
input[disabled=disabled] label {
	color: darkgray;
}

span.grayed-out-help {
	color: #666;
}

.flickr-form-align {
	text-align: right;
}

.form-item.form-type-textfield.form-item-flickr-sswratio {
	float: left;
}

.flickr-preview {
	float: left;
	margin: 0 12px 15px 0;
	padding: 10px;
	border: 1px solid #ccc;
}

.flickr-image span.flickr-cc,
.flickr-image span.flickr-copyright {
	display: none;
}

.flickr-image:hover .flickr-image span.flickr-cc,
.flickr-wrap:hover .flickr-image span.flickr-cc,
.flickr-image:hover .flickr-image span.flickr-copyright,
.flickr-wrap:hover .flickr-image span.flickr-copyright {
	z-index: 100;
	display: inline;
}

@font-face {
	font-family: "CC-ICONS";
	font-weight: normal;
	font-style: normal;
	src: url("https://ndhadeliver.natlib.govt.nz/webarchive/wayback/20210313012705/https://cdn.jsdelivr.net/cc-icons/1.2.0/fonts/cc-icons.eot") format("embedded-opentype"), url("https://ndhadeliver.natlib.govt.nz/webarchive/wayback/20210313012705/https://cdn.jsdelivr.net/cc-icons/1.2.0/fonts/cc-icons.woff") format("woff"), url("https://ndhadeliver.natlib.govt.nz/webarchive/wayback/20210313012705/https://cdn.jsdelivr.net/cc-icons/1.2.0/fonts/cc-icons.ttf") format("truetype"), url("https://ndhadeliver.natlib.govt.nz/webarchive/wayback/20210313012705/https://cdn.jsdelivr.net/cc-icons/1.2.0/fonts/cc-icons.svg") format("svg");
}

span.flickr-cc,
span.flickr-copyright {
	position: relative;
	top: 0.2em;
	float: right;
	margin: 1px 0 6px 0;
	font-size: large;
}

#flickr-admin-settings .form-item-flickr-license span.flickr-cc,
#flickr-admin-settings .form-item-flickr-license span.flickr-copyright {
	float: none;
	margin-right: 8px;
}

span.flickr-cc a,
span.flickr-copyright a {
	text-decoration: none;
}

span.flickr-cc {
	font-family: "CC-ICONS";
}

span.flickr-copyright {
	font-family: "lucida grande", tahoma, verdana, arial, sans-serif;
	font-size: x-large;
	font-weight: bold;
}

.flickr-image span.flickr-cc,
.flickr-image span.flickr-copyright {
	position: absolute;
	top: 4px;
	left: 7px;
}

.flickr-image span.flickr-cc a,
.flickr-image span.flickr-copyright a {
	letter-spacing: 2px;
	color: white;
	text-shadow: -1px -1px 0 black, 1px -1px 0 black, -1px 1px 0 black, 1px 1px 0 black;
}

.flickr-image span.flickr-cc {
	top: 4px;
	left: 7px;
	font-size: 100%;
}

.flickr-image span.flickr-copyright {
	top: 3px;
	left: 7px;
	font-size: large;
}

div.style-settings-image-preview img {
	-ms-filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=0.85);
	filter: alpha(opacity=0.85);
	-moz-opacity: 0.85;
	opacity: 0.85;
}

.rteindent1 {
	margin-left: 40px;
}

.rteindent2 {
	margin-left: 80px;
}

.rteindent3 {
	margin-left: 120px;
}

.rteindent4 {
	margin-left: 160px;
}

.rteleft {
	text-align: left;
}

.rteright {
	text-align: right;
}

.rtecenter {
	text-align: center;
}

.rtejustify {
	text-align: justify;
}

.ibimage_left {
	float: left;
}

.ibimage_right {
	float: right;
}

.tb-megamenu .clearfix {
	*zoom: 1;
}

.tb-megamenu .clearfix:before,
.tb-megamenu .clearfix:after {
	display: table;
	content: "";
	line-height: 0;
}

.tb-megamenu .clearfix:after {
	clear: both;
}

.tb-megamenu .hide-text {
	font: 0/0 a;
	color: transparent;
	text-shadow: none;
	background-color: transparent;
	border: 0;
}

.tb-megamenu .input-block-level {
	display: block;
	width: 100%;
	min-height: 30px;
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
}

.tb-megamenu .row {
	margin-left: -20px;
	*zoom: 1;
}

.tb-megamenu .row:before,
.tb-megamenu .row:after {
	display: table;
	content: "";
	line-height: 0;
}

.tb-megamenu .row:after {
	clear: both;
}

.tb-megamenu [class*="span"] {
	float: left;
	min-height: 1px;
	margin-left: 20px;
}

.tb-megamenu .row-fluid {
	width: 100%;
	*zoom: 1;
}

.tb-megamenu .row-fluid:before,
.tb-megamenu .row-fluid:after {
	display: table;
	content: "";
	line-height: 0;
}

.tb-megamenu .row-fluid:after {
	clear: both;
}

.tb-megamenu .row-fluid [class*="span"] {
	display: block;
	width: 100%;
	min-height: 30px;
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
	float: left;
	margin-left: 2.127659574468085%;
	*margin-left: 2.074468085106383%;
}

.tb-megamenu .row-fluid [class*="span"]:first-child {
	margin-left: 0;
}

.tb-megamenu .row-fluid .controls-row [class*="span"]+[class*="span"] {
	margin-left: 2.127659574468085%;
}

.tb-megamenu .row-fluid .span12 {
	width: 100%;
	*width: 99.94680851063829%;
}

.tb-megamenu .row-fluid .span11 {
	width: 91.48936170212765%;
	*width: 91.43617021276594%;
}

.tb-megamenu .row-fluid .span10 {
	width: 82.97872340425532%;
	*width: 82.92553191489361%;
}

.tb-megamenu .row-fluid .span9 {
	width: 74.46808510638297%;
	*width: 74.41489361702126%;
}

.tb-megamenu .row-fluid .span8 {
	width: 65.95744680851064%;
	*width: 65.90425531914893%;
}

.tb-megamenu .row-fluid .span7 {
	width: 57.44680851063829%;
	*width: 57.39361702127659%;
}

.tb-megamenu .row-fluid .span6 {
	width: 48.93617021276595%;
	*width: 48.88297872340425%;
}

.tb-megamenu .row-fluid .span5 {
	width: 40.42553191489362%;
	*width: 40.37234042553192%;
}

.tb-megamenu .row-fluid .span4 {
	width: 31.914893617021278%;
	*width: 31.861702127659576%;
}

.tb-megamenu .row-fluid .span3 {
	width: 23.404255319148934%;
	*width: 23.351063829787233%;
}

.tb-megamenu .row-fluid .span2 {
	width: 14.893617021276595%;
	*width: 14.840425531914894%;
}

.tb-megamenu .row-fluid .span1 {
	width: 6.382978723404255%;
	*width: 6.329787234042553%;
}

.tb-megamenu .row-fluid .offset12 {
	margin-left: 104.25531914893617%;
	*margin-left: 104.14893617021275%;
}

.tb-megamenu .row-fluid .offset12:first-child {
	margin-left: 102.12765957446808%;
	*margin-left: 102.02127659574467%;
}

.tb-megamenu .row-fluid .offset11 {
	margin-left: 95.74468085106382%;
	*margin-left: 95.6382978723404%;
}

.tb-megamenu .row-fluid .offset11:first-child {
	margin-left: 93.61702127659574%;
	*margin-left: 93.51063829787232%;
}

.tb-megamenu .row-fluid .offset10 {
	margin-left: 87.23404255319149%;
	*margin-left: 87.12765957446807%;
}

.tb-megamenu .row-fluid .offset10:first-child {
	margin-left: 85.1063829787234%;
	*margin-left: 84.99999999999999%;
}

.tb-megamenu .row-fluid .offset9 {
	margin-left: 78.72340425531914%;
	*margin-left: 78.61702127659572%;
}

.tb-megamenu .row-fluid .offset9:first-child {
	margin-left: 76.59574468085106%;
	*margin-left: 76.48936170212764%;
}

.tb-megamenu .row-fluid .offset8 {
	margin-left: 70.2127659574468%;
	*margin-left: 70.10638297872339%;
}

.tb-megamenu .row-fluid .offset8:first-child {
	margin-left: 68.08510638297872%;
	*margin-left: 67.9787234042553%;
}

.tb-megamenu .row-fluid .offset7 {
	margin-left: 61.70212765957446%;
	*margin-left: 61.59574468085106%;
}

.tb-megamenu .row-fluid .offset7:first-child {
	margin-left: 59.574468085106375%;
	*margin-left: 59.46808510638297%;
}

.tb-megamenu .row-fluid .offset6 {
	margin-left: 53.191489361702125%;
	*margin-left: 53.085106382978715%;
}

.tb-megamenu .row-fluid .offset6:first-child {
	margin-left: 51.063829787234035%;
	*margin-left: 50.95744680851063%;
}

.tb-megamenu .row-fluid .offset5 {
	margin-left: 44.68085106382979%;
	*margin-left: 44.57446808510638%;
}

.tb-megamenu .row-fluid .offset5:first-child {
	margin-left: 42.5531914893617%;
	*margin-left: 42.4468085106383%;
}

.tb-megamenu .row-fluid .offset4 {
	margin-left: 36.170212765957444%;
	*margin-left: 36.06382978723405%;
}

.tb-megamenu .row-fluid .offset4:first-child {
	margin-left: 34.04255319148936%;
	*margin-left: 33.93617021276596%;
}

.tb-megamenu .row-fluid .offset3 {
	margin-left: 27.659574468085104%;
	*margin-left: 27.5531914893617%;
}

.tb-megamenu .row-fluid .offset3:first-child {
	margin-left: 25.53191489361702%;
	*margin-left: 25.425531914893618%;
}

.tb-megamenu .row-fluid .offset2 {
	margin-left: 19.148936170212764%;
	*margin-left: 19.04255319148936%;
}

.tb-megamenu .row-fluid .offset2:first-child {
	margin-left: 17.02127659574468%;
	*margin-left: 16.914893617021278%;
}

.tb-megamenu .row-fluid .offset1 {
	margin-left: 10.638297872340425%;
	*margin-left: 10.53191489361702%;
}

.tb-megamenu .row-fluid .offset1:first-child {
	margin-left: 8.51063829787234%;
	*margin-left: 8.404255319148938%;
}

.tb-megamenu [class*="span"].hide,
.tb-megamenu .row-fluid [class*="span"].hide {
	display: none;
}

.tb-megamenu [class*="span"].pull-right,
.tb-megamenu .row-fluid [class*="span"].pull-right {
	float: right;
}

.tb-megamenu .btn {
	display: inline-block;
	*display: inline;
	*zoom: 1;
	padding: 4px 12px;
	margin-bottom: 0;
	font-size: 14px;
	line-height: 20px;
	text-align: center;
	vertical-align: middle;
	cursor: pointer;
	color: #333333;
	text-shadow: 0 1px 1px rgba(255, 255, 255, 0.75);
	background-color: #f5f5f5;
	background-image: -moz-linear-gradient(top, #ffffff, #e6e6e6);
	background-image: -webkit-gradient(linear, 0 0, 0 100%, from(#ffffff), to(#e6e6e6));
	background-image: -webkit-linear-gradient(top, #ffffff, #e6e6e6);
	background-image: -o-linear-gradient(top, #ffffff, #e6e6e6);
	background-image: linear-gradient(to bottom, #ffffff, #e6e6e6);
	background-repeat: repeat-x;
	filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ffffffff', endColorstr='#ffe6e6e6', GradientType=0);
	border-color: #e6e6e6 #e6e6e6 #bfbfbf;
	border-color: rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.25);
	*background-color: #e6e6e6;
	filter: progid:DXImageTransform.Microsoft.gradient(enabled=false);
	border: 1px solid #cccccc;
	*border: 0;
	border-bottom-color: #b3b3b3;
	-webkit-border-radius: 4px;
	-moz-border-radius: 4px;
	border-radius: 4px;
	*margin-left: .3em;
	-webkit-box-shadow: inset 0 1px 0 rgba(255, 255, 255, .2), 0 1px 2px rgba(0, 0, 0, .05);
	-moz-box-shadow: inset 0 1px 0 rgba(255, 255, 255, .2), 0 1px 2px rgba(0, 0, 0, .05);
	box-shadow: inset 0 1px 0 rgba(255, 255, 255, .2), 0 1px 2px rgba(0, 0, 0, .05);
}

.tb-megamenu .btn:hover,
.tb-megamenu .btn:focus,
.tb-megamenu .btn:active,
.tb-megamenu .btn.active,
.tb-megamenu .btn.disabled,
.tb-megamenu .btn[disabled] {
	color: #333333;
	background-color: #e6e6e6;
	*background-color: #d9d9d9;
}

.tb-megamenu .btn:active,
.tb-megamenu .btn.active {
	background-color: #cccccc \9;
}

.tb-megamenu .btn:first-child {
	*margin-left: 0;
}

.tb-megamenu .btn:hover,
.tb-megamenu .btn:focus {
	color: #333333;
	text-decoration: none;
	background-position: 0 -15px;
	-webkit-transition: background-position 0.1s linear;
	-moz-transition: background-position 0.1s linear;
	-o-transition: background-position 0.1s linear;
	transition: background-position 0.1s linear;
}

.tb-megamenu .btn:focus {
	outline: thin dotted #333;
	outline: 5px auto -webkit-focus-ring-color;
	outline-offset: -2px;
}

.tb-megamenu .btn.active,
.tb-megamenu .btn:active {
	background-image: none;
	outline: 0;
	-webkit-box-shadow: inset 0 2px 4px rgba(0, 0, 0, .15), 0 1px 2px rgba(0, 0, 0, .05);
	-moz-box-shadow: inset 0 2px 4px rgba(0, 0, 0, .15), 0 1px 2px rgba(0, 0, 0, .05);
	box-shadow: inset 0 2px 4px rgba(0, 0, 0, .15), 0 1px 2px rgba(0, 0, 0, .05);
}

.tb-megamenu .btn.disabled,
.tb-megamenu .btn[disabled] {
	cursor: default;
	background-image: none;
	opacity: 0.65;
	filter: alpha(opacity=65);
	-webkit-box-shadow: none;
	-moz-box-shadow: none;
	box-shadow: none;
}

.tb-megamenu .btn-large {
	padding: 11px 19px;
	font-size: 17.5px;
	-webkit-border-radius: 6px;
	-moz-border-radius: 6px;
	border-radius: 6px;
}

.tb-megamenu .btn-small {
	padding: 2px 10px;
	font-size: 11.9px;
	-webkit-border-radius: 3px;
	-moz-border-radius: 3px;
	border-radius: 3px;
}

.tb-megamenu .btn-mini {
	padding: 0 6px;
	font-size: 10.5px;
	-webkit-border-radius: 3px;
	-moz-border-radius: 3px;
	border-radius: 3px;
}

.tb-megamenu .btn-block {
	display: block;
	width: 100%;
	padding-left: 0;
	padding-right: 0;
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
}

.tb-megamenu .btn-block+.btn-block {
	margin-top: 5px;
}

.tb-megamenu input[type="submit"].btn-block,
.tb-megamenu input[type="reset"].btn-block,
.tb-megamenu input[type="button"].btn-block {
	width: 100%;
}

.tb-megamenu .btn-primary.active,
.tb-megamenu .btn-warning.active,
.tb-megamenu .btn-danger.active,
.tb-megamenu .btn-success.active,
.tb-megamenu .btn-info.active,
.tb-megamenu .btn-inverse.active {
	color: rgba(255, 255, 255, 0.75);
}

.tb-megamenu .btn-primary {
	color: #ffffff;
	text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25);
	background-color: #006dcc;
	background-image: -moz-linear-gradient(top, #0088cc, #0044cc);
	background-image: -webkit-gradient(linear, 0 0, 0 100%, from(#0088cc), to(#0044cc));
	background-image: -webkit-linear-gradient(top, #0088cc, #0044cc);
	background-image: -o-linear-gradient(top, #0088cc, #0044cc);
	background-image: linear-gradient(to bottom, #0088cc, #0044cc);
	background-repeat: repeat-x;
	filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ff0088cc', endColorstr='#ff0044cc', GradientType=0);
	border-color: #0044cc #0044cc #002a80;
	border-color: rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.25);
	*background-color: #0044cc;
	filter: progid:DXImageTransform.Microsoft.gradient(enabled=false);
}

.tb-megamenu .btn-primary:hover,
.tb-megamenu .btn-primary:focus,
.tb-megamenu .btn-primary:active,
.tb-megamenu .btn-primary.active,
.tb-megamenu .btn-primary.disabled,
.tb-megamenu .btn-primary[disabled] {
	color: #ffffff;
	background-color: #0044cc;
	*background-color: #003bb3;
}

.tb-megamenu .btn-primary:active,
.tb-megamenu .btn-primary.active {
	background-color: #003399 \9;
}

.tb-megamenu .btn-warning {
	color: #ffffff;
	text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25);
	background-color: #faa732;
	background-image: -moz-linear-gradient(top, #fbb450, #f89406);
	background-image: -webkit-gradient(linear, 0 0, 0 100%, from(#fbb450), to(#f89406));
	background-image: -webkit-linear-gradient(top, #fbb450, #f89406);
	background-image: -o-linear-gradient(top, #fbb450, #f89406);
	background-image: linear-gradient(to bottom, #fbb450, #f89406);
	background-repeat: repeat-x;
	filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#fffbb450', endColorstr='#fff89406', GradientType=0);
	border-color: #f89406 #f89406 #ad6704;
	border-color: rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.25);
	*background-color: #f89406;
	filter: progid:DXImageTransform.Microsoft.gradient(enabled=false);
}

.tb-megamenu .btn-warning:hover,
.tb-megamenu .btn-warning:focus,
.tb-megamenu .btn-warning:active,
.tb-megamenu .btn-warning.active,
.tb-megamenu .btn-warning.disabled,
.tb-megamenu .btn-warning[disabled] {
	color: #ffffff;
	background-color: #f89406;
	*background-color: #df8505;
}

.tb-megamenu .btn-warning:active,
.tb-megamenu .btn-warning.active {
	background-color: #c67605 \9;
}

.tb-megamenu .btn-danger {
	color: #ffffff;
	text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25);
	background-color: #da4f49;
	background-image: -moz-linear-gradient(top, #ee5f5b, #bd362f);
	background-image: -webkit-gradient(linear, 0 0, 0 100%, from(#ee5f5b), to(#bd362f));
	background-image: -webkit-linear-gradient(top, #ee5f5b, #bd362f);
	background-image: -o-linear-gradient(top, #ee5f5b, #bd362f);
	background-image: linear-gradient(to bottom, #ee5f5b, #bd362f);
	background-repeat: repeat-x;
	filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ffee5f5b', endColorstr='#ffbd362f', GradientType=0);
	border-color: #bd362f #bd362f #802420;
	border-color: rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.25);
	*background-color: #bd362f;
	filter: progid:DXImageTransform.Microsoft.gradient(enabled=false);
}

.tb-megamenu .btn-danger:hover,
.tb-megamenu .btn-danger:focus,
.tb-megamenu .btn-danger:active,
.tb-megamenu .btn-danger.active,
.tb-megamenu .btn-danger.disabled,
.tb-megamenu .btn-danger[disabled] {
	color: #ffffff;
	background-color: #bd362f;
	*background-color: #a9302a;
}

.tb-megamenu .btn-danger:active,
.tb-megamenu .btn-danger.active {
	background-color: #942a25 \9;
}

.tb-megamenu .btn-success {
	color: #ffffff;
	text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25);
	background-color: #5bb75b;
	background-image: -moz-linear-gradient(top, #62c462, #51a351);
	background-image: -webkit-gradient(linear, 0 0, 0 100%, from(#62c462), to(#51a351));
	background-image: -webkit-linear-gradient(top, #62c462, #51a351);
	background-image: -o-linear-gradient(top, #62c462, #51a351);
	background-image: linear-gradient(to bottom, #62c462, #51a351);
	background-repeat: repeat-x;
	filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ff62c462', endColorstr='#ff51a351', GradientType=0);
	border-color: #51a351 #51a351 #387038;
	border-color: rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.25);
	*background-color: #51a351;
	filter: progid:DXImageTransform.Microsoft.gradient(enabled=false);
}

.tb-megamenu .btn-success:hover,
.tb-megamenu .btn-success:focus,
.tb-megamenu .btn-success:active,
.tb-megamenu .btn-success.active,
.tb-megamenu .btn-success.disabled,
.tb-megamenu .btn-success[disabled] {
	color: #ffffff;
	background-color: #51a351;
	*background-color: #499249;
}

.tb-megamenu .btn-success:active,
.tb-megamenu .btn-success.active {
	background-color: #408140 \9;
}

.tb-megamenu .btn-info {
	color: #ffffff;
	text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25);
	background-color: #49afcd;
	background-image: -moz-linear-gradient(top, #5bc0de, #2f96b4);
	background-image: -webkit-gradient(linear, 0 0, 0 100%, from(#5bc0de), to(#2f96b4));
	background-image: -webkit-linear-gradient(top, #5bc0de, #2f96b4);
	background-image: -o-linear-gradient(top, #5bc0de, #2f96b4);
	background-image: linear-gradient(to bottom, #5bc0de, #2f96b4);
	background-repeat: repeat-x;
	filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ff5bc0de', endColorstr='#ff2f96b4', GradientType=0);
	border-color: #2f96b4 #2f96b4 #1f6377;
	border-color: rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.25);
	*background-color: #2f96b4;
	filter: progid:DXImageTransform.Microsoft.gradient(enabled=false);
}

.tb-megamenu .btn-info:hover,
.tb-megamenu .btn-info:focus,
.tb-megamenu .btn-info:active,
.tb-megamenu .btn-info.active,
.tb-megamenu .btn-info.disabled,
.tb-megamenu .btn-info[disabled] {
	color: #ffffff;
	background-color: #2f96b4;
	*background-color: #2a85a0;
}

.tb-megamenu .btn-info:active,
.tb-megamenu .btn-info.active {
	background-color: #24748c \9;
}

.tb-megamenu .btn-inverse {
	color: #ffffff;
	text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25);
	background-color: #363636;
	background-image: -moz-linear-gradient(top, #444444, #222222);
	background-image: -webkit-gradient(linear, 0 0, 0 100%, from(#444444), to(#222222));
	background-image: -webkit-linear-gradient(top, #444444, #222222);
	background-image: -o-linear-gradient(top, #444444, #222222);
	background-image: linear-gradient(to bottom, #444444, #222222);
	background-repeat: repeat-x;
	filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ff444444', endColorstr='#ff222222', GradientType=0);
	border-color: #222222 #222222 #000000;
	border-color: rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.25);
	*background-color: #222222;
	filter: progid:DXImageTransform.Microsoft.gradient(enabled=false);
}

.tb-megamenu .btn-inverse:hover,
.tb-megamenu .btn-inverse:focus,
.tb-megamenu .btn-inverse:active,
.tb-megamenu .btn-inverse.active,
.tb-megamenu .btn-inverse.disabled,
.tb-megamenu .btn-inverse[disabled] {
	color: #ffffff;
	background-color: #222222;
	*background-color: #151515;
}

.tb-megamenu .btn-inverse:active,
.tb-megamenu .btn-inverse.active {
	background-color: #080808 \9;
}

.tb-megamenu button.btn,
.tb-megamenu input[type="submit"].btn {
	*padding-top: 3px;
	*padding-bottom: 3px;
}

.tb-megamenu button.btn::-moz-focus-inner,
.tb-megamenu input[type="submit"].btn::-moz-focus-inner {
	padding: 0;
	border: 0;
}

.tb-megamenu button.btn.btn-large,
.tb-megamenu input[type="submit"].btn.btn-large {
	*padding-top: 7px;
	*padding-bottom: 7px;
}

.tb-megamenu button.btn.btn-small,
.tb-megamenu input[type="submit"].btn.btn-small {
	*padding-top: 3px;
	*padding-bottom: 3px;
}

.tb-megamenu button.btn.btn-mini,
.tb-megamenu input[type="submit"].btn.btn-mini {
	*padding-top: 1px;
	*padding-bottom: 1px;
}

.tb-megamenu .btn-link,
.tb-megamenu .btn-link:active,
.tb-megamenu .btn-link[disabled] {
	background-color: transparent;
	background-image: none;
	-webkit-box-shadow: none;
	-moz-box-shadow: none;
	box-shadow: none;
}

.tb-megamenu .btn-link {
	border-color: transparent;
	cursor: pointer;
	color: #0088cc;
	-webkit-border-radius: 0;
	-moz-border-radius: 0;
	border-radius: 0;
}

.tb-megamenu .btn-link:hover,
.tb-megamenu .btn-link:focus {
	color: #005580;
	text-decoration: underline;
	background-color: transparent;
}

.tb-megamenu .btn-link[disabled]:hover,
.tb-megamenu .btn-link[disabled]:focus {
	color: #333333;
	text-decoration: none;
}

.tb-megamenu .btn-group {
	position: relative;
	display: inline-block;
	*display: inline;
	*zoom: 1;
	font-size: 0;
	vertical-align: middle;
	white-space: nowrap;
	*margin-left: .3em;
}

.tb-megamenu .btn-group:first-child {
	*margin-left: 0;
}

.tb-megamenu .btn-group+.btn-group {
	margin-left: 5px;
}

.tb-megamenu .btn-toolbar {
	font-size: 0;
	margin-top: 10px;
	margin-bottom: 10px;
}

.tb-megamenu .btn-toolbar>.btn+.btn,
.tb-megamenu .btn-toolbar>.btn-group+.btn,
.tb-megamenu .btn-toolbar>.btn+.btn-group {
	margin-left: 5px;
}

.tb-megamenu .btn-group>.btn {
	position: relative;
	-webkit-border-radius: 0;
	-moz-border-radius: 0;
	border-radius: 0;
}

.tb-megamenu .btn-group>.btn+.btn {
	margin-left: -1px;
}

.tb-megamenu .btn-group>.btn,
.tb-megamenu .btn-group>.dropdown-menu,
.tb-megamenu .btn-group>.popover {
	font-size: 14px;
}

.tb-megamenu .btn-group>.btn-mini {
	font-size: 10.5px;
}

.tb-megamenu .btn-group>.btn-small {
	font-size: 11.9px;
}

.tb-megamenu .btn-group>.btn-large {
	font-size: 17.5px;
}

.tb-megamenu .btn-group>.btn:first-child {
	margin-left: 0;
	-webkit-border-top-left-radius: 4px;
	-moz-border-radius-topleft: 4px;
	border-top-left-radius: 4px;
	-webkit-border-bottom-left-radius: 4px;
	-moz-border-radius-bottomleft: 4px;
	border-bottom-left-radius: 4px;
}

.tb-megamenu .btn-group>.btn:last-child,
.tb-megamenu .btn-group>.dropdown-toggle {
	-webkit-border-top-right-radius: 4px;
	-moz-border-radius-topright: 4px;
	border-top-right-radius: 4px;
	-webkit-border-bottom-right-radius: 4px;
	-moz-border-radius-bottomright: 4px;
	border-bottom-right-radius: 4px;
}

.tb-megamenu .btn-group>.btn.large:first-child {
	margin-left: 0;
	-webkit-border-top-left-radius: 6px;
	-moz-border-radius-topleft: 6px;
	border-top-left-radius: 6px;
	-webkit-border-bottom-left-radius: 6px;
	-moz-border-radius-bottomleft: 6px;
	border-bottom-left-radius: 6px;
}

.tb-megamenu .btn-group>.btn.large:last-child,
.tb-megamenu .btn-group>.large.dropdown-toggle {
	-webkit-border-top-right-radius: 6px;
	-moz-border-radius-topright: 6px;
	border-top-right-radius: 6px;
	-webkit-border-bottom-right-radius: 6px;
	-moz-border-radius-bottomright: 6px;
	border-bottom-right-radius: 6px;
}

.tb-megamenu .btn-group>.btn:hover,
.tb-megamenu .btn-group>.btn:focus,
.tb-megamenu .btn-group>.btn:active,
.tb-megamenu .btn-group>.btn.active {
	z-index: 2;
}

.tb-megamenu .btn-group .dropdown-toggle:active,
.tb-megamenu .btn-group.open .dropdown-toggle {
	outline: 0;
}

.tb-megamenu .btn-group>.btn+.dropdown-toggle {
	padding-left: 8px;
	padding-right: 8px;
	-webkit-box-shadow: inset 1px 0 0 rgba(255, 255, 255, .125), inset 0 1px 0 rgba(255, 255, 255, .2), 0 1px 2px rgba(0, 0, 0, .05);
	-moz-box-shadow: inset 1px 0 0 rgba(255, 255, 255, .125), inset 0 1px 0 rgba(255, 255, 255, .2), 0 1px 2px rgba(0, 0, 0, .05);
	box-shadow: inset 1px 0 0 rgba(255, 255, 255, .125), inset 0 1px 0 rgba(255, 255, 255, .2), 0 1px 2px rgba(0, 0, 0, .05);
	*padding-top: 5px;
	*padding-bottom: 5px;
}

.tb-megamenu .btn-group>.btn-mini+.dropdown-toggle {
	padding-left: 5px;
	padding-right: 5px;
	*padding-top: 2px;
	*padding-bottom: 2px;
}

.tb-megamenu .btn-group>.btn-small+.dropdown-toggle {
	*padding-top: 5px;
	*padding-bottom: 4px;
}

.tb-megamenu .btn-group>.btn-large+.dropdown-toggle {
	padding-left: 12px;
	padding-right: 12px;
	*padding-top: 7px;
	*padding-bottom: 7px;
}

.tb-megamenu .btn-group.open .dropdown-toggle {
	background-image: none;
	-webkit-box-shadow: inset 0 2px 4px rgba(0, 0, 0, .15), 0 1px 2px rgba(0, 0, 0, .05);
	-moz-box-shadow: inset 0 2px 4px rgba(0, 0, 0, .15), 0 1px 2px rgba(0, 0, 0, .05);
	box-shadow: inset 0 2px 4px rgba(0, 0, 0, .15), 0 1px 2px rgba(0, 0, 0, .05);
}

.tb-megamenu .btn-group.open .btn.dropdown-toggle {
	background-color: #e6e6e6;
}

.tb-megamenu .btn-group.open .btn-primary.dropdown-toggle {
	background-color: #0044cc;
}

.tb-megamenu .btn-group.open .btn-warning.dropdown-toggle {
	background-color: #f89406;
}

.tb-megamenu .btn-group.open .btn-danger.dropdown-toggle {
	background-color: #bd362f;
}

.tb-megamenu .btn-group.open .btn-success.dropdown-toggle {
	background-color: #51a351;
}

.tb-megamenu .btn-group.open .btn-info.dropdown-toggle {
	background-color: #2f96b4;
}

.tb-megamenu .btn-group.open .btn-inverse.dropdown-toggle {
	background-color: #222222;
}

.tb-megamenu .btn .caret {
	margin-top: 8px;
	margin-left: 0;
}

.tb-megamenu .btn-large .caret {
	margin-top: 6px;
}

.tb-megamenu .btn-large .caret {
	border-left-width: 5px;
	border-right-width: 5px;
	border-top-width: 5px;
}

.tb-megamenu .btn-mini .caret,
.tb-megamenu .btn-small .caret {
	margin-top: 8px;
}

.tb-megamenu .dropup .btn-large .caret {
	border-bottom-width: 5px;
}

.tb-megamenu .btn-primary .caret,
.tb-megamenu .btn-warning .caret,
.tb-megamenu .btn-danger .caret,
.tb-megamenu .btn-info .caret,
.tb-megamenu .btn-success .caret,
.tb-megamenu .btn-inverse .caret {
	border-top-color: #ffffff;
	border-bottom-color: #ffffff;
}

.tb-megamenu .btn-group-vertical {
	display: inline-block;
	*display: inline;
	*zoom: 1;
}

.tb-megamenu .btn-group-vertical>.btn {
	display: block;
	float: none;
	max-width: 100%;
	-webkit-border-radius: 0;
	-moz-border-radius: 0;
	border-radius: 0;
}

.tb-megamenu .btn-group-vertical>.btn+.btn {
	margin-left: 0;
	margin-top: -1px;
}

.tb-megamenu .btn-group-vertical>.btn:first-child {
	-webkit-border-radius: 4px 4px 0 0;
	-moz-border-radius: 4px 4px 0 0;
	border-radius: 4px 4px 0 0;
}

.tb-megamenu .btn-group-vertical>.btn:last-child {
	-webkit-border-radius: 0 0 4px 4px;
	-moz-border-radius: 0 0 4px 4px;
	border-radius: 0 0 4px 4px;
}

.tb-megamenu .btn-group-vertical>.btn-large:first-child {
	-webkit-border-radius: 6px 6px 0 0;
	-moz-border-radius: 6px 6px 0 0;
	border-radius: 6px 6px 0 0;
}

.tb-megamenu .btn-group-vertical>.btn-large:last-child {
	-webkit-border-radius: 0 0 6px 6px;
	-moz-border-radius: 0 0 6px 6px;
	border-radius: 0 0 6px 6px;
}

.tb-megamenu .nav {
	margin-left: 0;
	margin-bottom: 20px;
	list-style: none;
}

.tb-megamenu .nav>li>a {
	display: block;
}

.tb-megamenu .nav>li>a:hover,
.tb-megamenu .nav>li>a:focus {
	text-decoration: none;
	background-color: #eeeeee;
}

.tb-megamenu .nav>li>a>img {
	max-width: none;
}

.tb-megamenu .nav>.pull-right {
	float: right;
}

.tb-megamenu .nav-header {
	display: block;
	padding: 3px 15px;
	font-size: 11px;
	font-weight: bold;
	line-height: 20px;
	color: #999999;
	text-shadow: 0 1px 0 rgba(255, 255, 255, 0.5);
	text-transform: uppercase;
}

.tb-megamenu .nav li+.nav-header {
	margin-top: 9px;
}

.tb-megamenu .nav-list {
	padding-left: 15px;
	padding-right: 15px;
	margin-bottom: 0;
}

.tb-megamenu .nav-list>li>a,
.tb-megamenu .nav-list .nav-header {
	margin-left: -15px;
	margin-right: -15px;
	text-shadow: 0 1px 0 rgba(255, 255, 255, 0.5);
}

.tb-megamenu .nav-list>li>a {
	padding: 3px 15px;
}

.tb-megamenu .nav-list>.active>a,
.tb-megamenu .nav-list>.active>a:hover,
.tb-megamenu .nav-list>.active>a:focus {
	color: #ffffff;
	text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.2);
	background-color: #0088cc;
}

.tb-megamenu .nav-list .divider {
	*width: 100%;
	height: 1px;
	margin: 9px 1px;
	*margin: -5px 0 5px;
	overflow: hidden;
	background-color: #e5e5e5;
	border-bottom: 1px solid #ffffff;
}

.tb-megamenu .nav-tabs,
.tb-megamenu .nav-pills {
	*zoom: 1;
}

.tb-megamenu .nav-tabs:before,
.tb-megamenu .nav-pills:before,
.tb-megamenu .nav-tabs:after,
.tb-megamenu .nav-pills:after {
	display: table;
	content: "";
	line-height: 0;
}

.tb-megamenu .nav-tabs:after,
.tb-megamenu .nav-pills:after {
	clear: both;
}

.tb-megamenu .nav-tabs>li,
.tb-megamenu .nav-pills>li {
	float: left;
}

.tb-megamenu .nav-tabs>li>a,
.tb-megamenu .nav-pills>li>a {
	padding-right: 12px;
	padding-left: 12px;
	margin-right: 2px;
	line-height: 14px;
}

.tb-megamenu .nav-tabs {
	border-bottom: 1px solid #ddd;
}

.tb-megamenu .nav-tabs>li {
	margin-bottom: -1px;
}

.tb-megamenu .nav-tabs>li>a {
	padding-top: 8px;
	padding-bottom: 8px;
	line-height: 20px;
	border: 1px solid transparent;
	-webkit-border-radius: 4px 4px 0 0;
	-moz-border-radius: 4px 4px 0 0;
	border-radius: 4px 4px 0 0;
}

.tb-megamenu .nav-tabs>li>a:hover,
.tb-megamenu .nav-tabs>li>a:focus {
	border-color: #eeeeee #eeeeee #dddddd;
}

.tb-megamenu .nav-tabs>.active>a,
.tb-megamenu .nav-tabs>.active>a:hover,
.tb-megamenu .nav-tabs>.active>a:focus {
	color: #555555;
	background-color: #ffffff;
	border: 1px solid #ddd;
	border-bottom-color: transparent;
	cursor: default;
}

.tb-megamenu .nav-pills>li>a {
	padding-top: 8px;
	padding-bottom: 8px;
	margin-top: 2px;
	margin-bottom: 2px;
	-webkit-border-radius: 5px;
	-moz-border-radius: 5px;
	border-radius: 5px;
}

.tb-megamenu .nav-pills>.active>a,
.tb-megamenu .nav-pills>.active>a:hover,
.tb-megamenu .nav-pills>.active>a:focus {
	color: #ffffff;
	background-color: #0088cc;
}

.tb-megamenu .nav-stacked>li {
	float: none;
}

.tb-megamenu .nav-stacked>li>a {
	margin-right: 0;
}

.tb-megamenu .nav-tabs.nav-stacked {
	border-bottom: 0;
}

.tb-megamenu .nav-tabs.nav-stacked>li>a {
	border: 1px solid #ddd;
	-webkit-border-radius: 0;
	-moz-border-radius: 0;
	border-radius: 0;
}

.tb-megamenu .nav-tabs.nav-stacked>li:first-child>a {
	-webkit-border-top-right-radius: 4px;
	-moz-border-radius-topright: 4px;
	border-top-right-radius: 4px;
	-webkit-border-top-left-radius: 4px;
	-moz-border-radius-topleft: 4px;
	border-top-left-radius: 4px;
}

.tb-megamenu .nav-tabs.nav-stacked>li:last-child>a {
	-webkit-border-bottom-right-radius: 4px;
	-moz-border-radius-bottomright: 4px;
	border-bottom-right-radius: 4px;
	-webkit-border-bottom-left-radius: 4px;
	-moz-border-radius-bottomleft: 4px;
	border-bottom-left-radius: 4px;
}

.tb-megamenu .nav-tabs.nav-stacked>li>a:hover,
.tb-megamenu .nav-tabs.nav-stacked>li>a:focus {
	border-color: #ddd;
	z-index: 2;
}

.tb-megamenu .nav-pills.nav-stacked>li>a {
	margin-bottom: 3px;
}

.tb-megamenu .nav-pills.nav-stacked>li:last-child>a {
	margin-bottom: 1px;
}

.tb-megamenu .nav-tabs .dropdown-menu {
	-webkit-border-radius: 0 0 6px 6px;
	-moz-border-radius: 0 0 6px 6px;
	border-radius: 0 0 6px 6px;
}

.tb-megamenu .nav-pills .dropdown-menu {
	-webkit-border-radius: 6px;
	-moz-border-radius: 6px;
	border-radius: 6px;
}

.tb-megamenu .nav .dropdown-toggle .caret {
	border-top-color: #0088cc;
	border-bottom-color: #0088cc;
	margin-top: 6px;
}

.tb-megamenu .nav .dropdown-toggle:hover .caret,
.tb-megamenu .nav .dropdown-toggle:focus .caret {
	border-top-color: #005580;
	border-bottom-color: #005580;
}

.tb-megamenu .nav-tabs .dropdown-toggle .caret {
	margin-top: 8px;
}

.tb-megamenu .nav .active .dropdown-toggle .caret {
	border-top-color: #fff;
	border-bottom-color: #fff;
}

.tb-megamenu .nav-tabs .active .dropdown-toggle .caret {
	border-top-color: #555555;
	border-bottom-color: #555555;
}

.tb-megamenu .nav>.dropdown.active>a:hover,
.tb-megamenu .nav>.dropdown.active>a:focus {
	cursor: pointer;
}

.tb-megamenu .nav-tabs .open .dropdown-toggle,
.tb-megamenu .nav-pills .open .dropdown-toggle,
.tb-megamenu .nav>li.dropdown.open.active>a:hover,
.tb-megamenu .nav>li.dropdown.open.active>a:focus {
	color: #ffffff;
	background-color: #999999;
	border-color: #999999;
}

.tb-megamenu .nav li.dropdown.open .caret,
.tb-megamenu .nav li.dropdown.open.active .caret,
.tb-megamenu .nav li.dropdown.open a:hover .caret,
.tb-megamenu .nav li.dropdown.open a:focus .caret {
	border-top-color: #ffffff;
	border-bottom-color: #ffffff;
	opacity: 1;
	filter: alpha(opacity=100);
}

.tb-megamenu .tabs-stacked .open>a:hover,
.tb-megamenu .tabs-stacked .open>a:focus {
	border-color: #999999;
}

.tb-megamenu .tabbable {
	*zoom: 1;
}

.tb-megamenu .tabbable:before,
.tb-megamenu .tabbable:after {
	display: table;
	content: "";
	line-height: 0;
}

.tb-megamenu .tabbable:after {
	clear: both;
}

.tb-megamenu .tab-content {
	overflow: auto;
}

.tb-megamenu .tabs-below>.nav-tabs,
.tb-megamenu .tabs-right>.nav-tabs,
.tb-megamenu .tabs-left>.nav-tabs {
	border-bottom: 0;
}

.tb-megamenu .tab-content>.tab-pane,
.tb-megamenu .pill-content>.pill-pane {
	display: none;
}

.tb-megamenu .tab-content>.active,
.tb-megamenu .pill-content>.active {
	display: block;
}

.tb-megamenu .tabs-below>.nav-tabs {
	border-top: 1px solid #ddd;
}

.tb-megamenu .tabs-below>.nav-tabs>li {
	margin-top: -1px;
	margin-bottom: 0;
}

.tb-megamenu .tabs-below>.nav-tabs>li>a {
	-webkit-border-radius: 0 0 4px 4px;
	-moz-border-radius: 0 0 4px 4px;
	border-radius: 0 0 4px 4px;
}

.tb-megamenu .tabs-below>.nav-tabs>li>a:hover,
.tb-megamenu .tabs-below>.nav-tabs>li>a:focus {
	border-bottom-color: transparent;
	border-top-color: #ddd;
}

.tb-megamenu .tabs-below>.nav-tabs>.active>a,
.tb-megamenu .tabs-below>.nav-tabs>.active>a:hover,
.tb-megamenu .tabs-below>.nav-tabs>.active>a:focus {
	border-color: transparent #ddd #ddd #ddd;
}

.tb-megamenu .tabs-left>.nav-tabs>li,
.tb-megamenu .tabs-right>.nav-tabs>li {
	float: none;
}

.tb-megamenu .tabs-left>.nav-tabs>li>a,
.tb-megamenu .tabs-right>.nav-tabs>li>a {
	min-width: 74px;
	margin-right: 0;
	margin-bottom: 3px;
}

.tb-megamenu .tabs-left>.nav-tabs {
	float: left;
	margin-right: 19px;
	border-right: 1px solid #ddd;
}

.tb-megamenu .tabs-left>.nav-tabs>li>a {
	margin-right: -1px;
	-webkit-border-radius: 4px 0 0 4px;
	-moz-border-radius: 4px 0 0 4px;
	border-radius: 4px 0 0 4px;
}

.tb-megamenu .tabs-left>.nav-tabs>li>a:hover,
.tb-megamenu .tabs-left>.nav-tabs>li>a:focus {
	border-color: #eeeeee #dddddd #eeeeee #eeeeee;
}

.tb-megamenu .tabs-left>.nav-tabs .active>a,
.tb-megamenu .tabs-left>.nav-tabs .active>a:hover,
.tb-megamenu .tabs-left>.nav-tabs .active>a:focus {
	border-color: #ddd transparent #ddd #ddd;
	*border-right-color: #ffffff;
}

.tb-megamenu .tabs-right>.nav-tabs {
	float: right;
	margin-left: 19px;
	border-left: 1px solid #ddd;
}

.tb-megamenu .tabs-right>.nav-tabs>li>a {
	margin-left: -1px;
	-webkit-border-radius: 0 4px 4px 0;
	-moz-border-radius: 0 4px 4px 0;
	border-radius: 0 4px 4px 0;
}

.tb-megamenu .tabs-right>.nav-tabs>li>a:hover,
.tb-megamenu .tabs-right>.nav-tabs>li>a:focus {
	border-color: #eeeeee #eeeeee #eeeeee #dddddd;
}

.tb-megamenu .tabs-right>.nav-tabs .active>a,
.tb-megamenu .tabs-right>.nav-tabs .active>a:hover,
.tb-megamenu .tabs-right>.nav-tabs .active>a:focus {
	border-color: #ddd #ddd #ddd transparent;
	*border-left-color: #ffffff;
}

.tb-megamenu .nav>.disabled>a {
	color: #999999;
}

.tb-megamenu .nav>.disabled>a:hover,
.tb-megamenu .nav>.disabled>a:focus {
	text-decoration: none;
	background-color: transparent;
	cursor: default;
}

.tb-megamenu .navbar {
	overflow: visible;
	margin-bottom: 20px;
	*position: relative;
	*z-index: 2;
}

.tb-megamenu .navbar-inner {
	min-height: 40px;
	padding-left: 20px;
	padding-right: 20px;
	background-color: #fafafa;
	background-image: -moz-linear-gradient(top, #ffffff, #f2f2f2);
	background-image: -webkit-gradient(linear, 0 0, 0 100%, from(#ffffff), to(#f2f2f2));
	background-image: -webkit-linear-gradient(top, #ffffff, #f2f2f2);
	background-image: -o-linear-gradient(top, #ffffff, #f2f2f2);
	background-image: linear-gradient(to bottom, #ffffff, #f2f2f2);
	background-repeat: repeat-x;
	filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ffffffff', endColorstr='#fff2f2f2', GradientType=0);
	border: 1px solid #d4d4d4;
	-webkit-border-radius: 4px;
	-moz-border-radius: 4px;
	border-radius: 4px;
	-webkit-box-shadow: 0 1px 4px rgba(0, 0, 0, 0.065);
	-moz-box-shadow: 0 1px 4px rgba(0, 0, 0, 0.065);
	box-shadow: 0 1px 4px rgba(0, 0, 0, 0.065);
	*zoom: 1;
}

.tb-megamenu .navbar-inner:before,
.tb-megamenu .navbar-inner:after {
	display: table;
	content: "";
	line-height: 0;
}

.tb-megamenu .navbar-inner:after {
	clear: both;
}

.tb-megamenu .navbar .container {
	width: auto;
}

.tb-megamenu .nav-collapse.collapse {
	height: auto;
	overflow: visible;
}

.tb-megamenu .navbar .brand {
	float: left;
	display: block;
	padding: 10px 20px 10px;
	margin-left: -20px;
	font-size: 20px;
	font-weight: 200;
	color: #777777;
	text-shadow: 0 1px 0 #ffffff;
}

.tb-megamenu .navbar .brand:hover,
.tb-megamenu .navbar .brand:focus {
	text-decoration: none;
}

.tb-megamenu .navbar-text {
	margin-bottom: 0;
	line-height: 40px;
	color: #777777;
}

.tb-megamenu .navbar-link {
	color: #777777;
}

.tb-megamenu .navbar-link:hover,
.tb-megamenu .navbar-link:focus {
	color: #333333;
}

.tb-megamenu .navbar .divider-vertical {
	height: 40px;
	margin: 0 9px;
	border-left: 1px solid #f2f2f2;
	border-right: 1px solid #ffffff;
}

.tb-megamenu .navbar .btn,
.tb-megamenu .navbar .btn-group {
	margin-top: 5px;
}

.tb-megamenu .navbar .btn-group .btn,
.tb-megamenu .navbar .input-prepend .btn,
.tb-megamenu .navbar .input-append .btn,
.tb-megamenu .navbar .input-prepend .btn-group,
.tb-megamenu .navbar .input-append .btn-group {
	margin-top: 0;
}

.tb-megamenu .navbar-form {
	margin-bottom: 0;
	*zoom: 1;
}

.tb-megamenu .navbar-form:before,
.tb-megamenu .navbar-form:after {
	display: table;
	content: "";
	line-height: 0;
}

.tb-megamenu .navbar-form:after {
	clear: both;
}

.tb-megamenu .navbar-form input,
.tb-megamenu .navbar-form select,
.tb-megamenu .navbar-form .radio,
.tb-megamenu .navbar-form .checkbox {
	margin-top: 5px;
}

.tb-megamenu .navbar-form input,
.tb-megamenu .navbar-form select,
.tb-megamenu .navbar-form .btn {
	display: inline-block;
	margin-bottom: 0;
}

.tb-megamenu .navbar-form input[type="image"],
.tb-megamenu .navbar-form input[type="checkbox"],
.tb-megamenu .navbar-form input[type="radio"] {
	margin-top: 3px;
}

.tb-megamenu .navbar-form .input-append,
.tb-megamenu .navbar-form .input-prepend {
	margin-top: 5px;
	white-space: nowrap;
}

.tb-megamenu .navbar-form .input-append input,
.tb-megamenu .navbar-form .input-prepend input {
	margin-top: 0;
}

.tb-megamenu .navbar-search {
	position: relative;
	float: left;
	margin-top: 5px;
	margin-bottom: 0;
}

.tb-megamenu .navbar-search .search-query {
	margin-bottom: 0;
	padding: 4px 14px;
	font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
	font-size: 13px;
	font-weight: normal;
	line-height: 1;
	-webkit-border-radius: 15px;
	-moz-border-radius: 15px;
	border-radius: 15px;
}

.tb-megamenu .navbar-static-top {
	position: static;
	margin-bottom: 0;
}

.tb-megamenu .navbar-static-top .navbar-inner {
	-webkit-border-radius: 0;
	-moz-border-radius: 0;
	border-radius: 0;
}

.tb-megamenu .navbar-fixed-top,
.tb-megamenu .navbar-fixed-bottom {
	position: fixed;
	right: 0;
	left: 0;
	z-index: 1030;
	margin-bottom: 0;
}

.tb-megamenu .navbar-fixed-top .navbar-inner,
.tb-megamenu .navbar-static-top .navbar-inner {
	border-width: 0 0 1px;
}

.tb-megamenu .navbar-fixed-bottom .navbar-inner {
	border-width: 1px 0 0;
}

.tb-megamenu .navbar-fixed-top .navbar-inner,
.tb-megamenu .navbar-fixed-bottom .navbar-inner {
	padding-left: 0;
	padding-right: 0;
	-webkit-border-radius: 0;
	-moz-border-radius: 0;
	border-radius: 0;
}

.tb-megamenu .navbar-static-top .container,
.tb-megamenu .navbar-fixed-top .container,
.tb-megamenu .navbar-fixed-bottom .container {
	width: 940px;
}

.tb-megamenu .navbar-fixed-top {
	top: 0;
}

.tb-megamenu .navbar-fixed-top .navbar-inner,
.tb-megamenu .navbar-static-top .navbar-inner {
	-webkit-box-shadow: 0 1px 10px rgba(0, 0, 0, .1);
	-moz-box-shadow: 0 1px 10px rgba(0, 0, 0, .1);
	box-shadow: 0 1px 10px rgba(0, 0, 0, .1);
}

.tb-megamenu .navbar-fixed-bottom {
	bottom: 0;
}

.tb-megamenu .navbar-fixed-bottom .navbar-inner {
	-webkit-box-shadow: 0 -1px 10px rgba(0, 0, 0, .1);
	-moz-box-shadow: 0 -1px 10px rgba(0, 0, 0, .1);
	box-shadow: 0 -1px 10px rgba(0, 0, 0, .1);
}

.tb-megamenu .navbar .nav {
	position: relative;
	left: 0;
	display: block;
	float: left;
	margin: 0 10px 0 0;
}

.tb-megamenu .navbar .nav.pull-right {
	float: right;
	margin-right: 0;
}

.tb-megamenu .navbar .nav>li {
	float: left;
}

.tb-megamenu .navbar .nav>li>a {
	float: none;
	padding: 10px 15px 10px;
	color: #777777;
	text-decoration: none;
	text-shadow: 0 1px 0 #ffffff;
}

.tb-megamenu .navbar .nav .dropdown-toggle .caret {
	margin-top: 8px;
}

.tb-megamenu .navbar .nav>li>a:focus,
.tb-megamenu .navbar .nav>li>a:hover {
	background-color: transparent;
	color: #333333;
	text-decoration: none;
}

.tb-megamenu .navbar .nav>.active>a,
.tb-megamenu .navbar .nav>.active>a:hover,
.tb-megamenu .navbar .nav>.active>a:focus {
	color: #555555;
	text-decoration: none;
	background-color: #e5e5e5;
	-webkit-box-shadow: inset 0 3px 8px rgba(0, 0, 0, 0.125);
	-moz-box-shadow: inset 0 3px 8px rgba(0, 0, 0, 0.125);
	box-shadow: inset 0 3px 8px rgba(0, 0, 0, 0.125);
}

.tb-megamenu .navbar .btn-navbar {
	display: none;
	float: right;
	padding: 7px 10px;
	margin-left: 5px;
	margin-right: 5px;
	color: #ffffff;
	text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25);
	background-color: #ededed;
	background-image: -moz-linear-gradient(top, #f2f2f2, #e5e5e5);
	background-image: -webkit-gradient(linear, 0 0, 0 100%, from(#f2f2f2), to(#e5e5e5));
	background-image: -webkit-linear-gradient(top, #f2f2f2, #e5e5e5);
	background-image: -o-linear-gradient(top, #f2f2f2, #e5e5e5);
	background-image: linear-gradient(to bottom, #f2f2f2, #e5e5e5);
	background-repeat: repeat-x;
	filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#fff2f2f2', endColorstr='#ffe5e5e5', GradientType=0);
	border-color: #e5e5e5 #e5e5e5 #bfbfbf;
	border-color: rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.25);
	*background-color: #e5e5e5;
	filter: progid:DXImageTransform.Microsoft.gradient(enabled=false);
	-webkit-box-shadow: inset 0 1px 0 rgba(255, 255, 255, .1), 0 1px 0 rgba(255, 255, 255, .075);
	-moz-box-shadow: inset 0 1px 0 rgba(255, 255, 255, .1), 0 1px 0 rgba(255, 255, 255, .075);
	box-shadow: inset 0 1px 0 rgba(255, 255, 255, .1), 0 1px 0 rgba(255, 255, 255, .075);
}

.tb-megamenu .navbar .btn-navbar:hover,
.tb-megamenu .navbar .btn-navbar:focus,
.tb-megamenu .navbar .btn-navbar:active,
.tb-megamenu .navbar .btn-navbar.active,
.tb-megamenu .navbar .btn-navbar.disabled,
.tb-megamenu .navbar .btn-navbar[disabled] {
	color: #ffffff;
	background-color: #e5e5e5;
	*background-color: #d9d9d9;
}

.tb-megamenu .navbar .btn-navbar:active,
.tb-megamenu .navbar .btn-navbar.active {
	background-color: #cccccc \9;
}

.tb-megamenu .navbar .nav>li>.dropdown-menu:before {
	content: '';
	display: inline-block;
	border-left: 7px solid transparent;
	border-right: 7px solid transparent;
	border-bottom: 7px solid #ccc;
	border-bottom-color: rgba(0, 0, 0, 0.2);
	position: absolute;
	top: -7px;
	left: 9px;
}

.tb-megamenu .navbar .nav>li>.dropdown-menu:after {
	content: '';
	display: inline-block;
	border-left: 6px solid transparent;
	border-right: 6px solid transparent;
	border-bottom: 6px solid #ffffff;
	position: absolute;
	top: -6px;
	left: 10px;
}

.tb-megamenu .navbar-fixed-bottom .nav>li>.dropdown-menu:before {
	border-top: 7px solid #ccc;
	border-top-color: rgba(0, 0, 0, 0.2);
	border-bottom: 0;
	bottom: -7px;
	top: auto;
}

.tb-megamenu .navbar-fixed-bottom .nav>li>.dropdown-menu:after {
	border-top: 6px solid #ffffff;
	border-bottom: 0;
	bottom: -6px;
	top: auto;
}

.tb-megamenu .navbar .nav li.dropdown>a:hover .caret,
.tb-megamenu .navbar .nav li.dropdown>a:focus .caret {
	border-top-color: #333333;
	border-bottom-color: #333333;
}

.tb-megamenu .navbar .nav li.dropdown.open>.dropdown-toggle,
.tb-megamenu .navbar .nav li.dropdown.active>.dropdown-toggle,
.tb-megamenu .navbar .nav li.dropdown.open.active>.dropdown-toggle {
	background-color: #e5e5e5;
	color: #555555;
}

.tb-megamenu .navbar .nav li.dropdown>.dropdown-toggle .caret {
	border-top-color: #777777;
	border-bottom-color: #777777;
}

.tb-megamenu .navbar .nav li.dropdown.open>.dropdown-toggle .caret,
.tb-megamenu .navbar .nav li.dropdown.active>.dropdown-toggle .caret,
.tb-megamenu .navbar .nav li.dropdown.open.active>.dropdown-toggle .caret {
	border-top-color: #555555;
	border-bottom-color: #555555;
}

.tb-megamenu .navbar .pull-right>li>.dropdown-menu,
.tb-megamenu .navbar .nav>li>.dropdown-menu.pull-right {
	left: auto;
	right: 0;
}

.tb-megamenu .navbar .pull-right>li>.dropdown-menu:before,
.tb-megamenu .navbar .nav>li>.dropdown-menu.pull-right:before {
	left: auto;
	right: 12px;
}

.tb-megamenu .navbar .pull-right>li>.dropdown-menu:after,
.tb-megamenu .navbar .nav>li>.dropdown-menu.pull-right:after {
	left: auto;
	right: 13px;
}

.tb-megamenu .navbar .pull-right>li>.dropdown-menu .dropdown-menu,
.tb-megamenu .navbar .nav>li>.dropdown-menu.pull-right .dropdown-menu {
	left: auto;
	right: 100%;
	margin-left: 0;
	margin-right: -1px;
	-webkit-border-radius: 6px 0 6px 6px;
	-moz-border-radius: 6px 0 6px 6px;
	border-radius: 6px 0 6px 6px;
}

.tb-megamenu .navbar-inverse .navbar-inner {
	background-color: #1b1b1b;
	background-image: -moz-linear-gradient(top, #222222, #111111);
	background-image: -webkit-gradient(linear, 0 0, 0 100%, from(#222222), to(#111111));
	background-image: -webkit-linear-gradient(top, #222222, #111111);
	background-image: -o-linear-gradient(top, #222222, #111111);
	background-image: linear-gradient(to bottom, #222222, #111111);
	background-repeat: repeat-x;
	filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ff222222', endColorstr='#ff111111', GradientType=0);
	border-color: #252525;
}

.tb-megamenu .navbar-inverse .brand,
.tb-megamenu .navbar-inverse .nav>li>a {
	color: #999999;
	text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25);
}

.tb-megamenu .navbar-inverse .brand:hover,
.tb-megamenu .navbar-inverse .nav>li>a:hover,
.tb-megamenu .navbar-inverse .brand:focus,
.tb-megamenu .navbar-inverse .nav>li>a:focus {
	color: #ffffff;
}

.tb-megamenu .navbar-inverse .brand {
	color: #999999;
}

.tb-megamenu .navbar-inverse .navbar-text {
	color: #999999;
}

.tb-megamenu .navbar-inverse .nav>li>a:focus,
.tb-megamenu .navbar-inverse .nav>li>a:hover {
	background-color: transparent;
	color: #ffffff;
}

.tb-megamenu .navbar-inverse .nav .active>a,
.tb-megamenu .navbar-inverse .nav .active>a:hover,
.tb-megamenu .navbar-inverse .nav .active>a:focus {
	color: #ffffff;
	background-color: #111111;
}

.tb-megamenu .navbar-inverse .navbar-link {
	color: #999999;
}

.tb-megamenu .navbar-inverse .navbar-link:hover,
.tb-megamenu .navbar-inverse .navbar-link:focus {
	color: #ffffff;
}

.tb-megamenu .navbar-inverse .divider-vertical {
	border-left-color: #111111;
	border-right-color: #222222;
}

.tb-megamenu .navbar-inverse .nav li.dropdown.open>.dropdown-toggle,
.tb-megamenu .navbar-inverse .nav li.dropdown.active>.dropdown-toggle,
.tb-megamenu .navbar-inverse .nav li.dropdown.open.active>.dropdown-toggle {
	background-color: #111111;
	color: #ffffff;
}

.tb-megamenu .navbar-inverse .nav li.dropdown>a:hover .caret,
.tb-megamenu .navbar-inverse .nav li.dropdown>a:focus .caret {
	border-top-color: #ffffff;
	border-bottom-color: #ffffff;
}

.tb-megamenu .navbar-inverse .nav li.dropdown>.dropdown-toggle .caret {
	border-top-color: #999999;
	border-bottom-color: #999999;
}

.tb-megamenu .navbar-inverse .nav li.dropdown.open>.dropdown-toggle .caret,
.tb-megamenu .navbar-inverse .nav li.dropdown.active>.dropdown-toggle .caret,
.tb-megamenu .navbar-inverse .nav li.dropdown.open.active>.dropdown-toggle .caret {
	border-top-color: #ffffff;
	border-bottom-color: #ffffff;
}

.tb-megamenu .navbar-inverse .navbar-search .search-query {
	color: #ffffff;
	background-color: #515151;
	border-color: #111111;
	-webkit-box-shadow: inset 0 1px 2px rgba(0, 0, 0, .1), 0 1px 0 rgba(255, 255, 255, .15);
	-moz-box-shadow: inset 0 1px 2px rgba(0, 0, 0, .1), 0 1px 0 rgba(255, 255, 255, .15);
	box-shadow: inset 0 1px 2px rgba(0, 0, 0, .1), 0 1px 0 rgba(255, 255, 255, .15);
	-webkit-transition: none;
	-moz-transition: none;
	-o-transition: none;
	transition: none;
}

.tb-megamenu .navbar-inverse .navbar-search .search-query:-moz-placeholder {
	color: #cccccc;
}

.tb-megamenu .navbar-inverse .navbar-search .search-query:-ms-input-placeholder {
	color: #cccccc;
}

.tb-megamenu .navbar-inverse .navbar-search .search-query::-webkit-input-placeholder {
	color: #cccccc;
}

.tb-megamenu .navbar-inverse .navbar-search .search-query:focus,
.tb-megamenu .navbar-inverse .navbar-search .search-query.focused {
	padding: 5px 15px;
	color: #333333;
	text-shadow: 0 1px 0 #ffffff;
	background-color: #ffffff;
	border: 0;
	-webkit-box-shadow: 0 0 3px rgba(0, 0, 0, 0.15);
	-moz-box-shadow: 0 0 3px rgba(0, 0, 0, 0.15);
	box-shadow: 0 0 3px rgba(0, 0, 0, 0.15);
	outline: 0;
}

.tb-megamenu .navbar-inverse .btn-navbar {
	color: #ffffff;
	text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25);
	background-color: #0e0e0e;
	background-image: -moz-linear-gradient(top, #151515, #040404);
	background-image: -webkit-gradient(linear, 0 0, 0 100%, from(#151515), to(#040404));
	background-image: -webkit-linear-gradient(top, #151515, #040404);
	background-image: -o-linear-gradient(top, #151515, #040404);
	background-image: linear-gradient(to bottom, #151515, #040404);
	background-repeat: repeat-x;
	filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ff151515', endColorstr='#ff040404', GradientType=0);
	border-color: #040404 #040404 #000000;
	border-color: rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.25);
	*background-color: #040404;
	filter: progid:DXImageTransform.Microsoft.gradient(enabled=false);
}

.tb-megamenu .navbar-inverse .btn-navbar:hover,
.tb-megamenu .navbar-inverse .btn-navbar:focus,
.tb-megamenu .navbar-inverse .btn-navbar:active,
.tb-megamenu .navbar-inverse .btn-navbar.active,
.tb-megamenu .navbar-inverse .btn-navbar.disabled,
.tb-megamenu .navbar-inverse .btn-navbar[disabled] {
	color: #ffffff;
	background-color: #040404;
	*background-color: #000000;
}

.tb-megamenu .navbar-inverse .btn-navbar:active,
.tb-megamenu .navbar-inverse .btn-navbar.active {
	background-color: #000000 \9;
}

.tb-megamenu .dropup,
.tb-megamenu .dropdown {
	position: relative;
}

.tb-megamenu .dropdown-toggle {
	*margin-bottom: -3px;
}

.tb-megamenu .dropdown-toggle:active,
.tb-megamenu .open .dropdown-toggle {
	outline: 0;
}

.tb-megamenu .caret {
	display: inline-block;
	width: 0;
	height: 0;
	vertical-align: top;
	border-top: 4px solid #000000;
	border-right: 4px solid transparent;
	border-left: 4px solid transparent;
	content: "";
}

.tb-megamenu .dropdown .caret {
	margin-top: 8px;
	margin-left: 2px;
}

.tb-megamenu .dropdown-menu {
	position: absolute;
	top: 100%;
	left: 0;
	z-index: 1000;
	display: none;
	float: left;
	min-width: 160px;
	padding: 5px 0;
	margin: 2px 0 0;
	list-style: none;
	background-color: #ffffff;
	border: 1px solid #ccc;
	border: 1px solid rgba(0, 0, 0, 0.2);
	*border-right-width: 2px;
	*border-bottom-width: 2px;
	-webkit-border-radius: 6px;
	-moz-border-radius: 6px;
	border-radius: 6px;
	-webkit-box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
	-moz-box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
	box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
	-webkit-background-clip: padding-box;
	-moz-background-clip: padding;
	background-clip: padding-box;
}

.tb-megamenu .dropdown-menu.pull-right {
	right: 0;
	left: auto;
}

.tb-megamenu .dropdown-menu .divider {
	*width: 100%;
	height: 1px;
	margin: 9px 1px;
	*margin: -5px 0 5px;
	overflow: hidden;
	background-color: #e5e5e5;
	border-bottom: 1px solid #ffffff;
}

.tb-megamenu .dropdown-menu>li>a {
	display: block;
	padding: 3px 20px;
	clear: both;
	font-weight: normal;
	line-height: 20px;
	color: #333333;
	white-space: nowrap;
}

.tb-megamenu .dropdown-menu>li>a:hover,
.tb-megamenu .dropdown-menu>li>a:focus,
.tb-megamenu .dropdown-submenu:hover>a,
.tb-megamenu .dropdown-submenu:focus>a {
	text-decoration: none;
	color: #ffffff;
	background-color: #0081c2;
	background-image: -moz-linear-gradient(top, #0088cc, #0077b3);
	background-image: -webkit-gradient(linear, 0 0, 0 100%, from(#0088cc), to(#0077b3));
	background-image: -webkit-linear-gradient(top, #0088cc, #0077b3);
	background-image: -o-linear-gradient(top, #0088cc, #0077b3);
	background-image: linear-gradient(to bottom, #0088cc, #0077b3);
	background-repeat: repeat-x;
	filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ff0088cc', endColorstr='#ff0077b3', GradientType=0);
}

.tb-megamenu .dropdown-menu>.active>a,
.tb-megamenu .dropdown-menu>.active>a:hover,
.tb-megamenu .dropdown-menu>.active>a:focus {
	color: #ffffff;
	text-decoration: none;
	outline: 0;
	background-color: #0081c2;
	background-image: -moz-linear-gradient(top, #0088cc, #0077b3);
	background-image: -webkit-gradient(linear, 0 0, 0 100%, from(#0088cc), to(#0077b3));
	background-image: -webkit-linear-gradient(top, #0088cc, #0077b3);
	background-image: -o-linear-gradient(top, #0088cc, #0077b3);
	background-image: linear-gradient(to bottom, #0088cc, #0077b3);
	background-repeat: repeat-x;
	filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ff0088cc', endColorstr='#ff0077b3', GradientType=0);
}

.tb-megamenu .dropdown-menu>.disabled>a,
.tb-megamenu .dropdown-menu>.disabled>a:hover,
.tb-megamenu .dropdown-menu>.disabled>a:focus {
	color: #999999;
}

.tb-megamenu .dropdown-menu>.disabled>a:hover,
.tb-megamenu .dropdown-menu>.disabled>a:focus {
	text-decoration: none;
	background-color: transparent;
	background-image: none;
	filter: progid:DXImageTransform.Microsoft.gradient(enabled=false);
	cursor: default;
}

.tb-megamenu .open {
	*z-index: 1000;
}

.tb-megamenu .open>.dropdown-menu {
	display: block;
}

.tb-megamenu .dropdown-backdrop {
	position: fixed;
	left: 0;
	right: 0;
	bottom: 0;
	top: 0;
	z-index: 990;
}

.tb-megamenu .pull-right>.dropdown-menu {
	right: 0;
	left: auto;
}

.tb-megamenu .dropup .caret,
.tb-megamenu .navbar-fixed-bottom .dropdown .caret {
	border-top: 0;
	border-bottom: 4px solid #000000;
	content: "";
}

.tb-megamenu .dropup .dropdown-menu,
.tb-megamenu .navbar-fixed-bottom .dropdown .dropdown-menu {
	top: auto;
	bottom: 100%;
	margin-bottom: 1px;
}

.tb-megamenu .dropdown-submenu {
	position: relative;
}

.tb-megamenu .dropdown-submenu>.dropdown-menu {
	top: 0;
	left: 100%;
	margin-top: -6px;
	margin-left: -1px;
	-webkit-border-radius: 0 6px 6px 6px;
	-moz-border-radius: 0 6px 6px 6px;
	border-radius: 0 6px 6px 6px;
}

.tb-megamenu .dropdown-submenu:hover>.dropdown-menu {
	display: block;
}

.tb-megamenu .dropup .dropdown-submenu>.dropdown-menu {
	top: auto;
	bottom: 0;
	margin-top: 0;
	margin-bottom: -2px;
	-webkit-border-radius: 5px 5px 5px 0;
	-moz-border-radius: 5px 5px 5px 0;
	border-radius: 5px 5px 5px 0;
}

.tb-megamenu .dropdown-submenu>a:after {
	display: block;
	content: " ";
	float: right;
	width: 0;
	height: 0;
	border-color: transparent;
	border-style: solid;
	border-width: 5px 0 5px 5px;
	border-left-color: #cccccc;
	margin-top: 5px;
	margin-right: -10px;
}

.tb-megamenu .dropdown-submenu:hover>a:after {
	border-left-color: #ffffff;
}

.tb-megamenu .dropdown-submenu.pull-left {
	float: none;
}

.tb-megamenu .dropdown-submenu.pull-left>.dropdown-menu {
	left: -100%;
	margin-left: 10px;
	-webkit-border-radius: 6px 0 6px 6px;
	-moz-border-radius: 6px 0 6px 6px;
	border-radius: 6px 0 6px 6px;
}

.tb-megamenu .dropdown .dropdown-menu .nav-header {
	padding-left: 20px;
	padding-right: 20px;
}

.tb-megamenu .typeahead {
	z-index: 1051;
	margin-top: 2px;
	-webkit-border-radius: 4px;
	-moz-border-radius: 4px;
	border-radius: 4px;
}

.tb-megamenu .accordion {
	margin-bottom: 20px;
}

.tb-megamenu .accordion-group {
	margin-bottom: 2px;
	border: 1px solid #e5e5e5;
	-webkit-border-radius: 4px;
	-moz-border-radius: 4px;
	border-radius: 4px;
}

.tb-megamenu .accordion-heading {
	border-bottom: 0;
}

.tb-megamenu .accordion-heading .accordion-toggle {
	display: block;
	padding: 8px 15px;
}

.tb-megamenu .accordion-toggle {
	cursor: pointer;
}

.tb-megamenu .accordion-inner {
	padding: 9px 15px;
	border-top: 1px solid #e5e5e5;
}

@-ms-viewport {
	width: device-width;
}

.tb-megamenu .hidden {
	display: none;
	visibility: hidden;
}

.tb-megamenu .visible-phone {
	display: none !important;
}

.tb-megamenu .visible-tablet {
	display: none !important;
}

.tb-megamenu .hidden-desktop {
	display: none !important;
}

.tb-megamenu .visible-desktop {
	display: inherit !important;
}

@media (min-width:768px) and (max-width:979px) {
	.tb-megamenu .hidden-desktop {
		display: inherit !important;
	}
	.tb-megamenu .visible-desktop {
		display: none !important;
	}
	.tb-megamenu .visible-tablet {
		display: inherit !important;
	}
	.tb-megamenu .hidden-tablet {
		display: none !important;
	}
}

@media (max-width:767px) {
	.tb-megamenu .hidden-desktop {
		display: inherit !important;
	}
	.tb-megamenu .visible-desktop {
		display: none !important;
	}
	.tb-megamenu .visible-phone {
		display: inherit !important;
	}
	.tb-megamenu .hidden-phone {
		display: none !important;
	}
}

.tb-megamenu .visible-print {
	display: none !important;
}

@media print {
	.tb-megamenu .visible-print {
		display: inherit !important;
	}
	.tb-megamenu .hidden-print {
		display: none !important;
	}
}

@media (max-width:767px) {
	.tb-megamenu body {
		padding-left: 20px;
		padding-right: 20px;
	}
	.tb-megamenu .navbar-fixed-top,
	.tb-megamenu .navbar-fixed-bottom,
	.tb-megamenu .navbar-static-top {
		margin-left: -20px;
		margin-right: -20px;
	}
	.tb-megamenu .container-fluid {
		padding: 0;
	}
	.tb-megamenu .dl-horizontal dt {
		float: none;
		clear: none;
		width: auto;
		text-align: left;
	}
	.tb-megamenu .dl-horizontal dd {
		margin-left: 0;
	}
	.tb-megamenu .container {
		width: auto;
	}
	.tb-megamenu .row-fluid {
		width: 100%;
	}
	.tb-megamenu .row,
	.tb-megamenu .thumbnails {
		margin-left: 0;
	}
	.tb-megamenu .thumbnails>li {
		float: none;
		margin-left: 0;
	}
	.tb-megamenu [class*="span"],
	.tb-megamenu .uneditable-input[class*="span"],
	.tb-megamenu .row-fluid [class*="span"] {
		float: none;
		display: block;
		width: 100%;
		margin-left: 0;
		-webkit-box-sizing: border-box;
		-moz-box-sizing: border-box;
		box-sizing: border-box;
	}
	.tb-megamenu .span12,
	.tb-megamenu .row-fluid .span12 {
		width: 100%;
		-webkit-box-sizing: border-box;
		-moz-box-sizing: border-box;
		box-sizing: border-box;
	}
	.tb-megamenu .row-fluid [class*="offset"]:first-child {
		margin-left: 0;
	}
	.tb-megamenu .input-large,
	.tb-megamenu .input-xlarge,
	.tb-megamenu .input-xxlarge,
	.tb-megamenu input[class*="span"],
	.tb-megamenu select[class*="span"],
	.tb-megamenu textarea[class*="span"],
	.tb-megamenu .uneditable-input {
		display: block;
		width: 100%;
		min-height: 30px;
		-webkit-box-sizing: border-box;
		-moz-box-sizing: border-box;
		box-sizing: border-box;
	}
	.tb-megamenu .input-prepend input,
	.tb-megamenu .input-append input,
	.tb-megamenu .input-prepend input[class*="span"],
	.tb-megamenu .input-append input[class*="span"] {
		display: inline-block;
		width: auto;
	}
	.tb-megamenu .controls-row [class*="span"]+[class*="span"] {
		margin-left: 0;
	}
	.tb-megamenu .modal {
		position: fixed;
		top: 20px;
		left: 20px;
		right: 20px;
		width: auto;
		margin: 0;
	}
	.tb-megamenu .modal.fade {
		top: -100px;
	}
	.tb-megamenu .modal.fade.in {
		top: 20px;
	}
}

@media (max-width:480px) {
	.tb-megamenu .nav-collapse {
		-webkit-transform: translate3d(0, 0, 0);
	}
	.tb-megamenu .page-header h1 small {
		display: block;
		line-height: 20px;
	}
	.tb-megamenu input[type="checkbox"],
	.tb-megamenu input[type="radio"] {
		border: 1px solid #ccc;
	}
	.tb-megamenu .form-horizontal .control-label {
		float: none;
		width: auto;
		padding-top: 0;
		text-align: left;
	}
	.tb-megamenu .form-horizontal .controls {
		margin-left: 0;
	}
	.tb-megamenu .form-horizontal .control-list {
		padding-top: 0;
	}
	.tb-megamenu .form-horizontal .form-actions {
		padding-left: 10px;
		padding-right: 10px;
	}
	.tb-megamenu .media .pull-left,
	.tb-megamenu .media .pull-right {
		float: none;
		display: block;
		margin-bottom: 10px;
	}
	.tb-megamenu .media-object {
		margin-right: 0;
		margin-left: 0;
	}
	.tb-megamenu .modal {
		top: 10px;
		left: 10px;
		right: 10px;
	}
	.tb-megamenu .modal-header .close {
		padding: 10px;
		margin: -10px;
	}
	.tb-megamenu .carousel-caption {
		position: static;
	}
}

@media (min-width:768px) and (max-width:979px) {
	.tb-megamenu .row {
		margin-left: -20px;
		*zoom: 1;
	}
	.tb-megamenu .row:before,
	.tb-megamenu .row:after {
		display: table;
		content: "";
		line-height: 0;
	}
	.tb-megamenu .row:after {
		clear: both;
	}
	.tb-megamenu [class*="span"] {
		float: left;
		min-height: 1px;
		margin-left: 20px;
	}
	.tb-megamenu .row-fluid {
		width: 100%;
		*zoom: 1;
	}
	.tb-megamenu .row-fluid:before,
	.tb-megamenu .row-fluid:after {
		display: table;
		content: "";
		line-height: 0;
	}
	.tb-megamenu .row-fluid:after {
		clear: both;
	}
	.tb-megamenu .row-fluid [class*="span"] {
		display: block;
		width: 100%;
		min-height: 30px;
		-webkit-box-sizing: border-box;
		-moz-box-sizing: border-box;
		box-sizing: border-box;
		float: left;
		margin-left: 2.7624309392265194%;
		*margin-left: 2.709239449864817%;
	}
	.tb-megamenu .row-fluid [class*="span"]:first-child {
		margin-left: 0;
	}
	.tb-megamenu .row-fluid .controls-row [class*="span"]+[class*="span"] {
		margin-left: 2.7624309392265194%;
	}
	.tb-megamenu .row-fluid .span12 {
		width: 100%;
		*width: 99.94680851063829%;
	}
	.tb-megamenu .row-fluid .span11 {
		width: 91.43646408839778%;
		*width: 91.38327259903608%;
	}
	.tb-megamenu .row-fluid .span10 {
		width: 82.87292817679558%;
		*width: 82.81973668743387%;
	}
	.tb-megamenu .row-fluid .span9 {
		width: 74.30939226519337%;
		*width: 74.25620077583166%;
	}
	.tb-megamenu .row-fluid .span8 {
		width: 65.74585635359117%;
		*width: 65.69266486422946%;
	}
	.tb-megamenu .row-fluid .span7 {
		width: 57.18232044198895%;
		*width: 57.12912895262725%;
	}
	.tb-megamenu .row-fluid .span6 {
		width: 48.61878453038674%;
		*width: 48.56559304102504%;
	}
	.tb-megamenu .row-fluid .span5 {
		width: 40.05524861878453%;
		*width: 40.00205712942283%;
	}
	.tb-megamenu .row-fluid .span4 {
		width: 31.491712707182323%;
		*width: 31.43852121782062%;
	}
	.tb-megamenu .row-fluid .span3 {
		width: 22.92817679558011%;
		*width: 22.87498530621841%;
	}
	.tb-megamenu .row-fluid .span2 {
		width: 14.3646408839779%;
		*width: 14.311449394616199%;
	}
	.tb-megamenu .row-fluid .span1 {
		width: 5.801104972375691%;
		*width: 5.747913483013988%;
	}
	.tb-megamenu .row-fluid .offset12 {
		margin-left: 105.52486187845304%;
		*margin-left: 105.41847889972962%;
	}
	.tb-megamenu .row-fluid .offset12:first-child {
		margin-left: 102.76243093922652%;
		*margin-left: 102.6560479605031%;
	}
	.tb-megamenu .row-fluid .offset11 {
		margin-left: 96.96132596685082%;
		*margin-left: 96.8549429881274%;
	}
	.tb-megamenu .row-fluid .offset11:first-child {
		margin-left: 94.1988950276243%;
		*margin-left: 94.09251204890089%;
	}
	.tb-megamenu .row-fluid .offset10 {
		margin-left: 88.39779005524862%;
		*margin-left: 88.2914070765252%;
	}
	.tb-megamenu .row-fluid .offset10:first-child {
		margin-left: 85.6353591160221%;
		*margin-left: 85.52897613729868%;
	}
	.tb-megamenu .row-fluid .offset9 {
		margin-left: 79.8342541436464%;
		*margin-left: 79.72787116492299%;
	}
	.tb-megamenu .row-fluid .offset9:first-child {
		margin-left: 77.07182320441989%;
		*margin-left: 76.96544022569647%;
	}
	.tb-megamenu .row-fluid .offset8 {
		margin-left: 71.2707182320442%;
		*margin-left: 71.16433525332079%;
	}
	.tb-megamenu .row-fluid .offset8:first-child {
		margin-left: 68.50828729281768%;
		*margin-left: 68.40190431409427%;
	}
	.tb-megamenu .row-fluid .offset7 {
		margin-left: 62.70718232044199%;
		*margin-left: 62.600799341718584%;
	}
	.tb-megamenu .row-fluid .offset7:first-child {
		margin-left: 59.94475138121547%;
		*margin-left: 59.838368402492065%;
	}
	.tb-megamenu .row-fluid .offset6 {
		margin-left: 54.14364640883978%;
		*margin-left: 54.037263430116376%;
	}
	.tb-megamenu .row-fluid .offset6:first-child {
		margin-left: 51.38121546961326%;
		*margin-left: 51.27483249088986%;
	}
	.tb-megamenu .row-fluid .offset5 {
		margin-left: 45.58011049723757%;
		*margin-left: 45.47372751851417%;
	}
	.tb-megamenu .row-fluid .offset5:first-child {
		margin-left: 42.81767955801105%;
		*margin-left: 42.71129657928765%;
	}
	.tb-megamenu .row-fluid .offset4 {
		margin-left: 37.01657458563536%;
		*margin-left: 36.91019160691196%;
	}
	.tb-megamenu .row-fluid .offset4:first-child {
		margin-left: 34.25414364640884%;
		*margin-left: 34.14776066768544%;
	}
	.tb-megamenu .row-fluid .offset3 {
		margin-left: 28.45303867403315%;
		*margin-left: 28.346655695309746%;
	}
	.tb-megamenu .row-fluid .offset3:first-child {
		margin-left: 25.69060773480663%;
		*margin-left: 25.584224756083227%;
	}
	.tb-megamenu .row-fluid .offset2 {
		margin-left: 19.88950276243094%;
		*margin-left: 19.783119783707537%;
	}
	.tb-megamenu .row-fluid .offset2:first-child {
		margin-left: 17.12707182320442%;
		*margin-left: 17.02068884448102%;
	}
	.tb-megamenu .row-fluid .offset1 {
		margin-left: 11.32596685082873%;
		*margin-left: 11.219583872105325%;
	}
	.tb-megamenu .row-fluid .offset1:first-child {
		margin-left: 8.56353591160221%;
		*margin-left: 8.457152932878806%;
	}
	.tb-megamenu input,
	.tb-megamenu textarea,
	.tb-megamenu .uneditable-input {
		margin-left: 0;
	}
	.tb-megamenu .controls-row [class*="span"]+[class*="span"] {
		margin-left: 20px;
	}
}

@media (min-width:1200px) {
	.tb-megamenu .row {
		margin-left: -30px;
		*zoom: 1;
	}
	.tb-megamenu .row:before,
	.tb-megamenu .row:after {
		display: table;
		content: "";
		line-height: 0;
	}
	.tb-megamenu .row:after {
		clear: both;
	}
	.tb-megamenu [class*="span"] {
		float: left;
		min-height: 1px;
		margin-left: 30px;
	}
	.tb-megamenu .row-fluid {
		width: 100%;
		*zoom: 1;
	}
	.tb-megamenu .row-fluid:before,
	.tb-megamenu .row-fluid:after {
		display: table;
		content: "";
		line-height: 0;
	}
	.tb-megamenu .row-fluid:after {
		clear: both;
	}
	.tb-megamenu .row-fluid [class*="span"] {
		display: block;
		width: 100%;
		min-height: 30px;
		-webkit-box-sizing: border-box;
		-moz-box-sizing: border-box;
		box-sizing: border-box;
		float: left;
		margin-left: 2.564102564102564%;
		*margin-left: 2.5109110747408616%;
	}
	.tb-megamenu .row-fluid [class*="span"]:first-child {
		margin-left: 0;
	}
	.tb-megamenu .row-fluid .controls-row [class*="span"]+[class*="span"] {
		margin-left: 2.564102564102564%;
	}
	.tb-megamenu .row-fluid .span12 {
		width: 100%;
		*width: 99.94680851063829%;
	}
	.tb-megamenu .row-fluid .span11 {
		width: 91.45299145299145%;
		*width: 91.39979996362975%;
	}
	.tb-megamenu .row-fluid .span10 {
		width: 82.90598290598291%;
		*width: 82.8527914166212%;
	}
	.tb-megamenu .row-fluid .span9 {
		width: 74.35897435897436%;
		*width: 74.30578286961266%;
	}
	.tb-megamenu .row-fluid .span8 {
		width: 65.81196581196582%;
		*width: 65.75877432260411%;
	}
	.tb-megamenu .row-fluid .span7 {
		width: 57.26495726495726%;
		*width: 57.21176577559556%;
	}
	.tb-megamenu .row-fluid .span6 {
		width: 48.717948717948715%;
		*width: 48.664757228587014%;
	}
	.tb-megamenu .row-fluid .span5 {
		width: 40.17094017094017%;
		*width: 40.11774868157847%;
	}
	.tb-megamenu .row-fluid .span4 {
		width: 31.623931623931625%;
		*width: 31.570740134569924%;
	}
	.tb-megamenu .row-fluid .span3 {
		width: 23.076923076923077%;
		*width: 23.023731587561375%;
	}
	.tb-megamenu .row-fluid .span2 {
		width: 14.52991452991453%;
		*width: 14.476723040552828%;
	}
	.tb-megamenu .row-fluid .span1 {
		width: 5.982905982905983%;
		*width: 5.929714493544281%;
	}
	.tb-megamenu .row-fluid .offset12 {
		margin-left: 105.12820512820512%;
		*margin-left: 105.02182214948171%;
	}
	.tb-megamenu .row-fluid .offset12:first-child {
		margin-left: 102.56410256410257%;
		*margin-left: 102.45771958537915%;
	}
	.tb-megamenu .row-fluid .offset11 {
		margin-left: 96.58119658119658%;
		*margin-left: 96.47481360247316%;
	}
	.tb-megamenu .row-fluid .offset11:first-child {
		margin-left: 94.01709401709402%;
		*margin-left: 93.91071103837061%;
	}
	.tb-megamenu .row-fluid .offset10 {
		margin-left: 88.03418803418803%;
		*margin-left: 87.92780505546462%;
	}
	.tb-megamenu .row-fluid .offset10:first-child {
		margin-left: 85.47008547008548%;
		*margin-left: 85.36370249136206%;
	}
	.tb-megamenu .row-fluid .offset9 {
		margin-left: 79.48717948717949%;
		*margin-left: 79.38079650845607%;
	}
	.tb-megamenu .row-fluid .offset9:first-child {
		margin-left: 76.92307692307693%;
		*margin-left: 76.81669394435352%;
	}
	.tb-megamenu .row-fluid .offset8 {
		margin-left: 70.94017094017094%;
		*margin-left: 70.83378796144753%;
	}
	.tb-megamenu .row-fluid .offset8:first-child {
		margin-left: 68.37606837606839%;
		*margin-left: 68.26968539734497%;
	}
	.tb-megamenu .row-fluid .offset7 {
		margin-left: 62.393162393162385%;
		*margin-left: 62.28677941443899%;
	}
	.tb-megamenu .row-fluid .offset7:first-child {
		margin-left: 59.82905982905982%;
		*margin-left: 59.72267685033642%;
	}
	.tb-megamenu .row-fluid .offset6 {
		margin-left: 53.84615384615384%;
		*margin-left: 53.739770867430444%;
	}
	.tb-megamenu .row-fluid .offset6:first-child {
		margin-left: 51.28205128205128%;
		*margin-left: 51.175668303327875%;
	}
	.tb-megamenu .row-fluid .offset5 {
		margin-left: 45.299145299145295%;
		*margin-left: 45.1927623204219%;
	}
	.tb-megamenu .row-fluid .offset5:first-child {
		margin-left: 42.73504273504273%;
		*margin-left: 42.62865975631933%;
	}
	.tb-megamenu .row-fluid .offset4 {
		margin-left: 36.75213675213675%;
		*margin-left: 36.645753773413354%;
	}
	.tb-megamenu .row-fluid .offset4:first-child {
		margin-left: 34.18803418803419%;
		*margin-left: 34.081651209310785%;
	}
	.tb-megamenu .row-fluid .offset3 {
		margin-left: 28.205128205128204%;
		*margin-left: 28.0987452264048%;
	}
	.tb-megamenu .row-fluid .offset3:first-child {
		margin-left: 25.641025641025642%;
		*margin-left: 25.53464266230224%;
	}
	.tb-megamenu .row-fluid .offset2 {
		margin-left: 19.65811965811966%;
		*margin-left: 19.551736679396257%;
	}
	.tb-megamenu .row-fluid .offset2:first-child {
		margin-left: 17.094017094017094%;
		*margin-left: 16.98763411529369%;
	}
	.tb-megamenu .row-fluid .offset1 {
		margin-left: 11.11111111111111%;
		*margin-left: 11.004728132387708%;
	}
	.tb-megamenu .row-fluid .offset1:first-child {
		margin-left: 8.547008547008547%;
		*margin-left: 8.440625568285142%;
	}
	.tb-megamenu input,
	.tb-megamenu textarea,
	.tb-megamenu .uneditable-input {
		margin-left: 0;
	}
	.tb-megamenu .controls-row [class*="span"]+[class*="span"] {
		margin-left: 30px;
	}
	.tb-megamenu .thumbnails {
		margin-left: -30px;
	}
	.tb-megamenu .thumbnails>li {
		margin-left: 30px;
	}
	.tb-megamenu .row-fluid .thumbnails {
		margin-left: 0;
	}
}

@media (max-width:979px) {
	.tb-megamenu body {
		padding-top: 0;
	}
	.tb-megamenu .navbar-fixed-top,
	.tb-megamenu .navbar-fixed-bottom {
		position: static;
	}
	.tb-megamenu .navbar-fixed-top {
		margin-bottom: 20px;
	}
	.tb-megamenu .navbar-fixed-bottom {
		margin-top: 20px;
	}
	.tb-megamenu .navbar-fixed-top .navbar-inner,
	.tb-megamenu .navbar-fixed-bottom .navbar-inner {
		padding: 5px;
	}
	.tb-megamenu .navbar .container {
		width: auto;
		padding: 0;
	}
	.tb-megamenu .navbar .brand {
		padding-left: 10px;
		padding-right: 10px;
		margin: 0 0 0 -5px;
	}
	.tb-megamenu .nav-collapse {
		clear: both;
	}
	.tb-megamenu .nav-collapse .nav {
		float: none;
		margin: 0 0 10px;
	}
	.tb-megamenu .nav-collapse .nav>li {
		float: none;
	}
	.tb-megamenu .nav-collapse .nav>li>a {
		margin-bottom: 2px;
	}
	.tb-megamenu .nav-collapse .nav>.divider-vertical {
		display: none;
	}
	.tb-megamenu .nav-collapse .nav .nav-header {
		color: #777777;
		text-shadow: none;
	}
	.tb-megamenu .nav-collapse .nav>li>a,
	.tb-megamenu .nav-collapse .dropdown-menu a {
		padding: 9px 15px;
		font-weight: bold;
		color: #777777;
		-webkit-border-radius: 3px;
		-moz-border-radius: 3px;
		border-radius: 3px;
	}
	.tb-megamenu .nav-collapse .btn {
		padding: 4px 10px 4px;
		font-weight: normal;
		-webkit-border-radius: 4px;
		-moz-border-radius: 4px;
		border-radius: 4px;
	}
	.tb-megamenu .nav-collapse .dropdown-menu li+li a {
		margin-bottom: 2px;
	}
	.tb-megamenu .nav-collapse .nav>li>a:hover,
	.tb-megamenu .nav-collapse .nav>li>a:focus,
	.tb-megamenu .nav-collapse .dropdown-menu a:hover,
	.tb-megamenu .nav-collapse .dropdown-menu a:focus {
		background-color: #f2f2f2;
	}
	.tb-megamenu .navbar-inverse .nav-collapse .nav>li>a,
	.tb-megamenu .navbar-inverse .nav-collapse .dropdown-menu a {
		color: #999999;
	}
	.tb-megamenu .navbar-inverse .nav-collapse .nav>li>a:hover,
	.tb-megamenu .navbar-inverse .nav-collapse .nav>li>a:focus,
	.tb-megamenu .navbar-inverse .nav-collapse .dropdown-menu a:hover,
	.tb-megamenu .navbar-inverse .nav-collapse .dropdown-menu a:focus {
		background-color: #111111;
	}
	.tb-megamenu .nav-collapse.in .btn-group {
		margin-top: 5px;
		padding: 0;
	}
	.tb-megamenu .nav-collapse .dropdown-menu {
		position: static;
		top: auto;
		left: auto;
		float: none;
		display: none;
		max-width: none;
		margin: 0 15px;
		padding: 0;
		background-color: transparent;
		border: none;
		-webkit-border-radius: 0;
		-moz-border-radius: 0;
		border-radius: 0;
		-webkit-box-shadow: none;
		-moz-box-shadow: none;
		box-shadow: none;
	}
	.tb-megamenu .nav-collapse .open>.dropdown-menu {
		display: block;
	}
	.tb-megamenu .nav-collapse .dropdown-menu:before,
	.tb-megamenu .nav-collapse .dropdown-menu:after {
		display: none;
	}
	.tb-megamenu .nav-collapse .dropdown-menu .divider {
		display: none;
	}
	.tb-megamenu .nav-collapse .nav>li>.dropdown-menu:before,
	.tb-megamenu .nav-collapse .nav>li>.dropdown-menu:after {
		display: none;
	}
	.tb-megamenu .nav-collapse .navbar-form,
	.tb-megamenu .nav-collapse .navbar-search {
		float: none;
		padding: 10px 15px;
		margin: 10px 0;
		border-top: 1px solid #f2f2f2;
		border-bottom: 1px solid #f2f2f2;
		-webkit-box-shadow: inset 0 1px 0 rgba(255, 255, 255, .1), 0 1px 0 rgba(255, 255, 255, .1);
		-moz-box-shadow: inset 0 1px 0 rgba(255, 255, 255, .1), 0 1px 0 rgba(255, 255, 255, .1);
		box-shadow: inset 0 1px 0 rgba(255, 255, 255, .1), 0 1px 0 rgba(255, 255, 255, .1);
	}
	.tb-megamenu .navbar-inverse .nav-collapse .navbar-form,
	.tb-megamenu .navbar-inverse .nav-collapse .navbar-search {
		border-top-color: #111111;
		border-bottom-color: #111111;
	}
	.tb-megamenu .navbar .nav-collapse .nav.pull-right {
		float: none;
		margin-left: 0;
	}
	.tb-megamenu .nav-collapse,
	.tb-megamenu .nav-collapse.collapse {
		overflow: hidden;
		height: 0;
	}
	.tb-megamenu .navbar .btn-navbar {
		display: block;
	}
	.tb-megamenu .navbar-static .navbar-inner {
		padding-left: 10px;
		padding-right: 10px;
	}
}

@media (min-width:980px) {
	.tb-megamenu .nav-collapse.collapse {
		height: auto !important;
		overflow: visible !important;
	}
}

.tb-megamenu .clearfix {
	*zoom: 1;
}

.tb-megamenu .clearfix:before,
.tb-megamenu .clearfix:after {
	display: table;
	content: "";
	line-height: 0;
}

.tb-megamenu .clearfix:after {
	clear: both;
}

.tb-megamenu .mega-inner {
	padding: 10px;
	*zoom: 1;
}

.tb-megamenu .mega-inner:before,
.tb-megamenu .mega-inner:after {
	display: table;
	content: "";
	line-height: 0;
}

.tb-megamenu .mega-inner:after {
	clear: both;
}

.tb-megamenu .row-fluid+.row-fluid {
	padding-top: 10px;
	border-top: 1px solid #eeeeee;
}

.tb-megamenu .mega>.mega-dropdown-menu {
	min-width: 200px;
	display: none;
}

.tb-megamenu .mega.open>.mega-dropdown-menu,
.tb-megamenu .mega.dropdown-submenu:hover>.mega-dropdown-menu {
	display: block;
}

.tb-megamenu .mega-group {
	*zoom: 1;
}

.tb-megamenu .mega-group:before,
.tb-megamenu .mega-group:after {
	display: table;
	content: "";
	line-height: 0;
}

.tb-megamenu .mega-group:after {
	clear: both;
}

.tb-megamenu .mega-nav .mega-group>.mega-group-title,
.tb-megamenu .dropdown-menu .mega-nav .mega-group>.mega-group-title,
.tb-megamenu .dropdown-menu .active .mega-nav .mega-group>.mega-group-title {
	background: inherit;
	font-weight: bold;
	padding: 0 0 0 10px;
	margin: 0;
	text-transform: uppercase;
}

.tb-megamenu .mega-nav .mega-group>.mega-group-title:hover,
.tb-megamenu .dropdown-menu .mega-nav .mega-group>.mega-group-title:hover,
.tb-megamenu .dropdown-menu .active .mega-nav .mega-group>.mega-group-title:hover,
.tb-megamenu .mega-nav .mega-group>.mega-group-title:active,
.tb-megamenu .dropdown-menu .mega-nav .mega-group>.mega-group-title:active,
.tb-megamenu .dropdown-menu .active .mega-nav .mega-group>.mega-group-title:active,
.tb-megamenu .mega-nav .mega-group>.mega-group-title:focus,
.tb-megamenu .dropdown-menu .mega-nav .mega-group>.mega-group-title:focus,
.tb-megamenu .dropdown-menu .active .mega-nav .mega-group>.mega-group-title:focus {
	background: inherit;
	color: inherit;
}

.tb-megamenu .mega-group-ct {
	margin: 0;
	padding: 0;
	*zoom: 1;
}

.tb-megamenu .mega-group-ct:before,
.tb-megamenu .mega-group-ct:after {
	display: table;
	content: "";
	line-height: 0;
}

.tb-megamenu .mega-group-ct:after {
	clear: both;
}

.tb-megamenu .mega-group-ct>.row-fluid>[class*="span"]>.mega-inner {
	padding: 0;
}

.tb-megamenu .span12.mega-col-nav .mega-inner {
	padding: 10px;
}

.tb-megamenu .mega-nav,
.tb-megamenu .dropdown-menu .mega-nav {
	margin: 0;
	padding: 0;
	list-style: none;
}

.tb-megamenu .mega-nav>li,
.tb-megamenu .dropdown-menu .mega-nav>li {
	display: block;
	float: none;
	list-style: none;
	margin-left: 0;
}

.tb-megamenu .mega-nav>li a,
.tb-megamenu .dropdown-menu .mega-nav>li a {
	white-space: normal;
}

.tb-megamenu .mega-group>.mega-nav,
.tb-megamenu .dropdown-menu .mega-group>.mega-nav {
	margin-left: -5px;
	margin-right: -5px;
}

.tb-megamenu .mega-nav .dropdown-submenu>a::after {
	margin-right: 5px;
}

.tb-megamenu .tb-block {
	margin-bottom: 10px;
}

.tb-megamenu .tb-block .block-title {
	margin-bottom: 0;
}

.tb-megamenu .tb-block .block-ct {
	margin: 0;
	padding: 0;
}

.tb-megamenu .nav .caret,
.tb-megamenu .dropdown-submenu .caret,
.tb-megamenu .mega-menu .caret {
	display: none;
}

.tb-megamenu .nav>.dropdown>.dropdown-toggle .caret {
	display: inline-block;
}

.tb-megamenu .nav {
	margin-left: 0;
	margin-bottom: 0;
	list-style: none;
}

.tb-megamenu .nav>li>a {
	display: block;
}

.tb-megamenu .nav>li>a:hover {
	text-decoration: none;
	background-color: #eee;
}

.tb-megamenu .nav>.pull-right {
	float: right;
}

.tb-megamenu .dropup,
.tb-megamenu .dropdown {
	position: relative;
}

.tb-megamenu .dropdown-toggle {
	*margin-bottom: -3px;
}

.tb-megamenu .dropdown-toggle:active,
.tb-megamenu .open .dropdown-toggle {
	outline: 0;
}

.tb-megamenu .caret {
	display: inline-block;
	width: 0;
	height: 0;
	vertical-align: top;
	border-top: 4px solid #000;
	border-right: 4px solid transparent;
	border-left: 4px solid transparent;
	content: "";
}

.tb-megamenu .dropdown .caret {
	margin-top: 8px;
	margin-left: 2px;
}

.tb-megamenu .dropdown-menu {
	position: absolute;
	top: 100%;
	left: 0;
	z-index: 1000;
	display: none;
	float: left;
	min-width: 160px;
	padding: 5px 0;
	margin: 2px 0 0;
	list-style: none;
	background-color: #fff;
	border: 1px solid #ccc;
	border: 1px solid rgba(0, 0, 0, 0.2);
	*border-right-width: 2px;
	*border-bottom-width: 2px;
	-webkit-border-radius: 6px;
	-moz-border-radius: 6px;
	border-radius: 6px;
	-webkit-box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
	-moz-box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
	box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
	-webkit-background-clip: padding-box;
	-moz-background-clip: padding;
	background-clip: padding-box;
}

.tb-megamenu .dropdown-menu.pull-right {
	right: 0;
	left: auto;
}

.tb-megamenu .dropdown-menu .divider {
	*width: 100%;
	height: 1px;
	margin: 8px 1px;
	*margin: -5px 0 5px;
	overflow: hidden;
	background-color: #e5e5e5;
	border-bottom: 1px solid #fff;
}

.tb-megamenu .dropdown-menu a {
	display: block;
	padding: 3px 20px;
	clear: both;
	font-weight: normal;
	line-height: 18px;
	color: #333;
	white-space: nowrap;
}

.tb-megamenu .dropdown-menu li>a:hover,
.tb-megamenu .dropdown-menu li>a:focus,
.tb-megamenu .dropdown-submenu:hover>a {
	text-decoration: none;
	color: #fff;
	background-color: #08c;
	background-color: #0081c2;
	background-image: -moz-linear-gradient(top, #08c, #0077b3);
	background-image: -webkit-gradient(linear, 0 0, 0 100%, from(#08c), to(#0077b3));
	background-image: -webkit-linear-gradient(top, #08c, #0077b3);
	background-image: -o-linear-gradient(top, #08c, #0077b3);
	background-image: linear-gradient(to bottom, #08c, #0077b3);
	background-repeat: repeat-x;
	filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ff0088cc', endColorstr='#ff0076b2', GradientType=0);
}

.tb-megamenu .dropdown-menu .active>a,
.tb-megamenu .dropdown-menu .active>a:hover {
	color: #fff;
	text-decoration: none;
	outline: 0;
	background-color: #08c;
	background-color: #0081c2;
	background-image: -moz-linear-gradient(top, #08c, #0077b3);
	background-image: -webkit-gradient(linear, 0 0, 0 100%, from(#08c), to(#0077b3));
	background-image: -webkit-linear-gradient(top, #08c, #0077b3);
	background-image: -o-linear-gradient(top, #08c, #0077b3);
	background-image: linear-gradient(to bottom, #08c, #0077b3);
	background-repeat: repeat-x;
	filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ff0088cc', endColorstr='#ff0076b2', GradientType=0);
}

.tb-megamenu .dropdown-menu .disabled>a,
.tb-megamenu .dropdown-menu .disabled>a:hover {
	color: #999;
}

.tb-megamenu .dropdown-menu .disabled>a:hover {
	text-decoration: none;
	background-color: transparent;
	cursor: default;
}

.tb-megamenu .open {
	*z-index: 1000;
}

.tb-megamenu .open>.dropdown-menu {
	display: block;
}

.tb-megamenu .pull-right>.dropdown-menu {
	right: 0;
	left: auto;
}

.tb-megamenu .dropup .caret {
	border-top: 0;
	border-bottom: 4px solid #000;
	content: "\2191";
}

.tb-megamenu .dropup .dropdown-menu {
	top: auto;
	bottom: 100%;
	margin-bottom: 1px;
}

.tb-megamenu .dropdown-submenu {
	position: relative;
}

.tb-megamenu .dropdown-submenu>.dropdown-menu {
	top: 0;
	left: 100%;
	margin-top: -6px;
	margin-left: -1px;
	-webkit-border-radius: 0 6px 6px 6px;
	-moz-border-radius: 0 6px 6px 6px;
	border-radius: 0 6px 6px 6px;
}

.tb-megamenu .dropdown-submenu:hover .dropdown-menu {
	display: block;
}

.tb-megamenu .dropdown-submenu>a:after {
	display: block;
	content: " ";
	float: right;
	width: 0;
	height: 0;
	border-color: transparent;
	border-style: solid;
	border-width: 5px 0 5px 5px;
	border-left-color: #cccccc;
	margin-top: 5px;
	margin-right: -10px;
}

.tb-megamenu .dropdown-submenu:hover>a:after {
	border-left-color: #fff;
}

.tb-megamenu .dropdown .dropdown-menu .nav-header {
	padding-left: 20px;
	padding-right: 20px;
}

.tb-megamenu .mega-align-left>.dropdown-menu {
	left: 0;
}

.tb-megamenu .mega-align-right>.dropdown-menu {
	left: auto;
	right: 0;
}

.tb-megamenu .mega-align-center>.dropdown-menu {
	left: 50%;
	transform: translate(-50%);
	-webkit-transform: translate(-50%);
	-moz-transform: translate(-50%);
	-ms-transform: translate(-50%);
	-o-transform: translate(-50%);
}

.tb-megamenu .dropdown-submenu.mega-align-left>.dropdown-menu {
	left: 100%;
}

.tb-megamenu .dropdown-submenu.mega-align-right>.dropdown-menu {
	left: auto;
	right: 100%;
}

.tb-megamenu .mega-align-justify {
	position: static;
}

.tb-megamenu .mega-align-justify>.dropdown-menu {
	left: 0;
	margin-left: 0;
	top: auto;
}

.tb-megamenu .mega-align-justify>.dropdown-menu.span12 {
	width: 100%;
}

.tb-megamenu .mega-caption {
	display: block;
	white-space: nowrap;
}

.tb-megamenu .fade {
	opacity: 0;
	-webkit-transition: opacity .15s linear;
	-moz-transition: opacity .15s linear;
	-o-transition: opacity .15s linear;
	transition: opacity .15s linear;
}

.tb-megamenu .fade.in {
	opacity: 1;
}

.tb-megamenu .collapse {
	position: relative;
	height: 0;
	overflow: visible;
	-webkit-transition: height .35s ease;
	-moz-transition: height .35s ease;
	-o-transition: height .35s ease;
	transition: height .35s ease;
}

.tb-megamenu .collapse.in {
	height: auto;
}

.tb-megamenu .close {
	float: right;
	font-size: 20px;
	font-weight: bold;
	line-height: 18px;
	color: #000;
	text-shadow: 0 1px 0 #ffffff;
	opacity: 0.2;
	filter: alpha(opacity=20);
}

.tb-megamenu .close:hover {
	color: #000;
	text-decoration: none;
	cursor: pointer;
	opacity: 0.4;
	filter: alpha(opacity=40);
}

.tb-megamenu button.close {
	padding: 0;
	cursor: pointer;
	background: transparent;
	border: 0;
	-webkit-appearance: none;
}

.tb-megamenu .btn {
	display: inline-block;
	*display: inline;
	*zoom: 1;
	padding: 4px 14px;
	margin-bottom: 0;
	font-size: 13px;
	line-height: 18px;
	*line-height: 18px;
	text-align: center;
	vertical-align: middle;
	cursor: pointer;
	color: #333;
	text-shadow: 0 1px 1px rgba(255, 255, 255, 0.75);
	background-color: #f5f5f5;
	background-image: -moz-linear-gradient(top, #fff, #e6e6e6);
	background-image: -webkit-gradient(linear, 0 0, 0 100%, from(#fff), to(#e6e6e6));
	background-image: -webkit-linear-gradient(top, #fff, #e6e6e6);
	background-image: -o-linear-gradient(top, #fff, #e6e6e6);
	background-image: linear-gradient(to bottom, #fff, #e6e6e6);
	background-repeat: repeat-x;
	filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ffffffff', endColorstr='#ffe5e5e5', GradientType=0);
	border-color: #e6e6e6 #e6e6e6 #bfbfbf;
	border-color: rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.25);
	*background-color: #e6e6e6;
	filter: progid:DXImageTransform.Microsoft.gradient(enabled=false);
	border: 1px solid #bbb;
	*border: 0;
	border-bottom-color: #a2a2a2;
	-webkit-border-radius: 4px;
	-moz-border-radius: 4px;
	border-radius: 4px;
	*margin-left: .3em;
	-webkit-box-shadow: inset 0 1px 0 rgba(255, 255, 255, .2), 0 1px 2px rgba(0, 0, 0, .05);
	-moz-box-shadow: inset 0 1px 0 rgba(255, 255, 255, .2), 0 1px 2px rgba(0, 0, 0, .05);
	box-shadow: inset 0 1px 0 rgba(255, 255, 255, .2), 0 1px 2px rgba(0, 0, 0, .05);
}

.tb-megamenu .btn:hover,
.tb-megamenu .btn:active,
.tb-megamenu .btn.active,
.tb-megamenu .btn.disabled,
.tb-megamenu .btn[disabled] {
	color: #333;
	background-color: #e6e6e6;
	*background-color: #d9d9d9;
}

.tb-megamenu .btn:active,
.tb-megamenu .btn.active {
	background-color: #cccccc \9;
}

.tb-megamenu .btn:first-child {
	*margin-left: 0;
}

.tb-megamenu .btn:hover {
	color: #333;
	text-decoration: none;
	background-color: #e6e6e6;
	*background-color: #d9d9d9;
	background-position: 0 -15px;
	-webkit-transition: background-position .1s linear;
	-moz-transition: background-position .1s linear;
	-o-transition: background-position .1s linear;
	transition: background-position .1s linear;
}

.tb-megamenu .btn:focus {
	outline: thin dotted #333;
	outline: 5px auto -webkit-focus-ring-color;
	outline-offset: -2px;
}

.tb-megamenu .btn.active,
.tb-megamenu .btn:active {
	background-color: #e6e6e6;
	background-color: #d9d9d9 \9;
	background-image: none;
	outline: 0;
	-webkit-box-shadow: inset 0 2px 4px rgba(0, 0, 0, .15), 0 1px 2px rgba(0, 0, 0, .05);
	-moz-box-shadow: inset 0 2px 4px rgba(0, 0, 0, .15), 0 1px 2px rgba(0, 0, 0, .05);
	box-shadow: inset 0 2px 4px rgba(0, 0, 0, .15), 0 1px 2px rgba(0, 0, 0, .05);
}

.tb-megamenu .btn.disabled,
.tb-megamenu .btn[disabled] {
	cursor: default;
	background-color: #e6e6e6;
	background-image: none;
	opacity: 0.65;
	filter: alpha(opacity=65);
	-webkit-box-shadow: none;
	-moz-box-shadow: none;
	box-shadow: none;
}

.tb-megamenu .btn-danger {
	color: #fff;
	text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25);
	background-color: #da4f49;
	background-image: -moz-linear-gradient(top, #ee5f5b, #bd362f);
	background-image: -webkit-gradient(linear, 0 0, 0 100%, from(#ee5f5b), to(#bd362f));
	background-image: -webkit-linear-gradient(top, #ee5f5b, #bd362f);
	background-image: -o-linear-gradient(top, #ee5f5b, #bd362f);
	background-image: linear-gradient(to bottom, #ee5f5b, #bd362f);
	background-repeat: repeat-x;
	filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ffee5f5b', endColorstr='#ffbd362f', GradientType=0);
	border-color: #bd362f #bd362f #802420;
	border-color: rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.25);
	*background-color: #bd362f;
	filter: progid:DXImageTransform.Microsoft.gradient(enabled=false);
}

.tb-megamenu .btn-danger:hover,
.tb-megamenu .btn-danger:active,
.tb-megamenu .btn-danger.active,
.tb-megamenu .btn-danger.disabled,
.tb-megamenu .btn-danger[disabled] {
	color: #fff;
	background-color: #bd362f;
	*background-color: #a9302a;
}

.tb-megamenu .btn-danger:active,
.tb-megamenu .btn-danger.active {
	background-color: #942a25 \9;
}

.tb-megamenu .btn-success {
	color: #fff;
	text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25);
	background-color: #5bb75b;
	background-image: -moz-linear-gradient(top, #62c462, #51a351);
	background-image: -webkit-gradient(linear, 0 0, 0 100%, from(#62c462), to(#51a351));
	background-image: -webkit-linear-gradient(top, #62c462, #51a351);
	background-image: -o-linear-gradient(top, #62c462, #51a351);
	background-image: linear-gradient(to bottom, #62c462, #51a351);
	background-repeat: repeat-x;
	filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ff62c462', endColorstr='#ff51a351', GradientType=0);
	border-color: #51a351 #51a351 #387038;
	border-color: rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.25);
	*background-color: #51a351;
	filter: progid:DXImageTransform.Microsoft.gradient(enabled=false);
}

.tb-megamenu .btn-success:hover,
.tb-megamenu .btn-success:active,
.tb-megamenu .btn-success.active,
.tb-megamenu .btn-success.disabled,
.tb-megamenu .btn-success[disabled] {
	color: #fff;
	background-color: #51a351;
	*background-color: #499249;
}

.tb-megamenu .btn-success:active,
.tb-megamenu .btn-success.active {
	background-color: #408140 \9;
}

.tb-megamenu .btn-danger.active,
.tb-megamenu .btn-success.active {
	color: rgba(255, 255, 255, 0.75);
}

.tb-megamenu .btn-block {
	display: block;
	width: 100%;
	padding-left: 0;
	padding-right: 0;
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
}

.tb-megamenu .btn-block+.btn-block {
	margin-top: 5px;
}

.tb-megamenu .btn-primary.active,
.tb-megamenu .btn-warning.active,
.tb-megamenu .btn-danger.active,
.tb-megamenu .btn-success.active,
.tb-megamenu .btn-info.active,
.tb-megamenu .btn-inverse.active {
	color: rgba(255, 255, 255, 0.95);
}

.tb-megamenu .btn {
	border-color: #c5c5c5;
	border-color: rgba(0, 0, 0, 0.15) rgba(0, 0, 0, 0.15) rgba(0, 0, 0, 0.25);
}

.tb-megamenu button.btn,
.tb-megamenu input[type="submit"].btn {
	*padding-top: 3px;
	*padding-bottom: 3px;
}

.tb-megamenu button.btn::-moz-focus-inner,
.tb-megamenu input[type="submit"].btn::-moz-focus-inner {
	padding: 0;
	border: 0;
}

.tb-megamenu .btn-group {
	position: relative;
	font-size: 0;
	white-space: nowrap;
	*margin-left: .3em;
}

.tb-megamenu .btn-group:first-child {
	*margin-left: 0;
}

.tb-megamenu .btn-group+.btn-group {
	margin-left: 5px;
}

.tb-megamenu .btn-group>.btn {
	position: relative;
	-webkit-border-radius: 0;
	-moz-border-radius: 0;
	border-radius: 0;
}

.tb-megamenu .btn-group>.btn+.btn {
	margin-left: -1px;
}

.tb-megamenu .btn-group>.btn,
.tb-megamenu .btn-group>.dropdown-menu {}

.tb-megamenu .btn-group>.btn.first,
.tb-megamenu .btn-group>.btn:first-child {
	margin-left: 0;
	-webkit-border-top-left-radius: 4px;
	-moz-border-radius-topleft: 4px;
	border-top-left-radius: 4px;
	-webkit-border-bottom-left-radius: 4px;
	-moz-border-radius-bottomleft: 4px;
	border-bottom-left-radius: 4px;
}

.tb-megamenu .btn-group>.btn.last,
.tb-megamenu .btn-group>.btn:last-child,
.tb-megamenu .btn-group>.dropdown-toggle {
	-webkit-border-top-right-radius: 4px;
	-moz-border-radius-topright: 4px;
	border-top-right-radius: 4px;
	-webkit-border-bottom-right-radius: 4px;
	-moz-border-radius-bottomright: 4px;
	border-bottom-right-radius: 4px;
}

.tb-megamenu .btn-group>.btn.large:first-child {
	margin-left: 0;
	-webkit-border-top-left-radius: 6px;
	-moz-border-radius-topleft: 6px;
	border-top-left-radius: 6px;
	-webkit-border-bottom-left-radius: 6px;
	-moz-border-radius-bottomleft: 6px;
	border-bottom-left-radius: 6px;
}

.tb-megamenu .btn-group>.btn.large:last-child,
.tb-megamenu .btn-group>.large.dropdown-toggle {
	-webkit-border-top-right-radius: 6px;
	-moz-border-radius-topright: 6px;
	border-top-right-radius: 6px;
	-webkit-border-bottom-right-radius: 6px;
	-moz-border-radius-bottomright: 6px;
	border-bottom-right-radius: 6px;
}

.tb-megamenu .btn-group>.btn:hover,
.tb-megamenu .btn-group>.btn:focus,
.tb-megamenu .btn-group>.btn:active,
.tb-megamenu .btn-group>.btn.active {
	z-index: 2;
}

.tb-megamenu .btn-group .dropdown-toggle:active,
.tb-megamenu .btn-group.open .dropdown-toggle {
	outline: 0;
}

.tb-megamenu .btn-group>.btn+.dropdown-toggle {
	padding-left: 8px;
	padding-right: 8px;
	-webkit-box-shadow: inset 1px 0 0 rgba(255, 255, 255, .125), inset 0 1px 0 rgba(255, 255, 255, .2), 0 1px 2px rgba(0, 0, 0, .05);
	-moz-box-shadow: inset 1px 0 0 rgba(255, 255, 255, .125), inset 0 1px 0 rgba(255, 255, 255, .2), 0 1px 2px rgba(0, 0, 0, .05);
	box-shadow: inset 1px 0 0 rgba(255, 255, 255, .125), inset 0 1px 0 rgba(255, 255, 255, .2), 0 1px 2px rgba(0, 0, 0, .05);
	*padding-top: 5px;
	*padding-bottom: 5px;
}

.tb-megamenu .btn-group.open .dropdown-toggle {
	background-image: none;
	-webkit-box-shadow: inset 0 2px 4px rgba(0, 0, 0, .15), 0 1px 2px rgba(0, 0, 0, .05);
	-moz-box-shadow: inset 0 2px 4px rgba(0, 0, 0, .15), 0 1px 2px rgba(0, 0, 0, .05);
	box-shadow: inset 0 2px 4px rgba(0, 0, 0, .15), 0 1px 2px rgba(0, 0, 0, .05);
}

.tb-megamenu .btn-group.open .btn.dropdown-toggle {
	background-color: #e6e6e6;
}

.tb-megamenu a.disabled,
.tb-megamenu a.disabled:hover {
	color: #999999;
	background-color: transparent;
	cursor: default;
	text-decoration: none;
}

#tb-megamenu-admin fieldset {
	padding: 0;
	margin: 0;
	border: 0;
}

.tb-megamenu legend {
	display: block;
	width: 100%;
	padding: 0;
	margin-bottom: 18px;
	font-size: 19.5px;
	line-height: 36px;
	color: #333;
	border: 0;
	border-bottom: 1px solid #e5e5e5;
}

.tb-megamenu legend small {
	font-size: 13.5px;
	color: #999;
}

.tb-megamenu label,
.tb-megamenu input,
.tb-megamenu button,
.tb-megamenu select,
.tb-megamenu textarea {
	font-size: 13px;
	font-weight: normal;
	line-height: 18px;
}

.tb-megamenu input,
.tb-megamenu button,
.tb-megamenu select,
.tb-megamenu textarea {
	font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
}

.tb-megamenu label {
	display: block;
	margin-bottom: 5px;
}

.tb-megamenu select,
.tb-megamenu textarea,
.tb-megamenu input[type="text"],
.tb-megamenu input[type="password"],
.tb-megamenu input[type="datetime"],
.tb-megamenu input[type="datetime-local"],
.tb-megamenu input[type="date"],
.tb-megamenu input[type="month"],
.tb-megamenu input[type="time"],
.tb-megamenu input[type="week"],
.tb-megamenu input[type="number"],
.tb-megamenu input[type="email"],
.tb-megamenu input[type="url"],
.tb-megamenu input[type="search"],
.tb-megamenu input[type="tel"],
.tb-megamenu input[type="color"],
.tb-megamenu .uneditable-input {
	display: inline-block;
	height: 18px;
	padding: 4px 6px;
	margin-bottom: 9px;
	font-size: 13px;
	line-height: 18px;
	color: #555;
	-webkit-border-radius: 3px;
	-moz-border-radius: 3px;
	border-radius: 3px;
}

.tb-megamenu input,
.tb-megamenu textarea {
	width: 170px;
}

.tb-megamenu textarea {
	height: auto;
}

.tb-megamenu textarea,
.tb-megamenu input[type="text"],
.tb-megamenu input[type="password"],
.tb-megamenu input[type="datetime"],
.tb-megamenu input[type="datetime-local"],
.tb-megamenu input[type="date"],
.tb-megamenu input[type="month"],
.tb-megamenu input[type="time"],
.tb-megamenu input[type="week"],
.tb-megamenu input[type="number"],
.tb-megamenu input[type="email"],
.tb-megamenu input[type="url"],
.tb-megamenu input[type="search"],
.tb-megamenu input[type="tel"],
.tb-megamenu input[type="color"],
.tb-megamenu .uneditable-input {
	background-color: #fff;
	border: 1px solid #ccc;
	-webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
	-moz-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
	box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
	-webkit-transition: border linear .2s, box-shadow linear .2s;
	-moz-transition: border linear .2s, box-shadow linear .2s;
	-o-transition: border linear .2s, box-shadow linear .2s;
	transition: border linear .2s, box-shadow linear .2s;
}

.tb-megamenu textarea:focus,
.tb-megamenu input[type="text"]:focus,
.tb-megamenu input[type="password"]:focus,
.tb-megamenu input[type="datetime"]:focus,
.tb-megamenu input[type="datetime-local"]:focus,
.tb-megamenu input[type="date"]:focus,
.tb-megamenu input[type="month"]:focus,
.tb-megamenu input[type="time"]:focus,
.tb-megamenu input[type="week"]:focus,
.tb-megamenu input[type="number"]:focus,
.tb-megamenu input[type="email"]:focus,
.tb-megamenu input[type="url"]:focus,
.tb-megamenu input[type="search"]:focus,
.tb-megamenu input[type="tel"]:focus,
.tb-megamenu input[type="color"]:focus,
.tb-megamenu .uneditable-input:focus {
	border-color: rgba(82, 168, 236, 0.8);
	outline: 0;
	outline: thin dotted \9;
	-webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075), 0 0 8px rgba(82, 168, 236, .6);
	-moz-box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075), 0 0 8px rgba(82, 168, 236, .6);
	box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075), 0 0 8px rgba(82, 168, 236, .6);
}

.tb-megamenu input[type="radio"],
.tb-megamenu input[type="checkbox"] {
	margin: 4px 0 0;
	*margin-top: 0;
	margin-top: 1px \9;
	line-height: normal;
	cursor: pointer;
}

.tb-megamenu input[type="file"],
.tb-megamenu input[type="image"],
.tb-megamenu input[type="submit"],
.tb-megamenu input[type="reset"],
.tb-megamenu input[type="button"],
.tb-megamenu input[type="radio"],
.tb-megamenu input[type="checkbox"] {
	width: auto;
}

.tb-megamenu select,
.tb-megamenu input[type="file"] {
	height: 30px;
	*margin-top: 4px;
	line-height: 30px;
}

.tb-megamenu select {
	width: 220px;
	border: 1px solid #bbb;
	background-color: #fff;
}

.tb-megamenu select[multiple],
.tb-megamenu select[size] {
	height: auto;
}

.tb-megamenu select:focus,
.tb-megamenu input[type="file"]:focus,
.tb-megamenu input[type="radio"]:focus,
.tb-megamenu input[type="checkbox"]:focus {
	outline: thin dotted #333;
	outline: 5px auto -webkit-focus-ring-color;
	outline-offset: -2px;
}

@media (max-width:979px) {
	.tb-megamenu .navbar .brand {
		font-size: 13px;
	}
	.tb-megamenu .navbar .nav {
		margin: 0 2px 0 0;
	}
	.tb-megamenu .navbar .nav>li>a {
		padding: 6px;
	}
	.tb-megamenu .container-fluid {
		padding-left: 10px;
		padding-right: 10px;
	}
}

@media (max-width:767px) {
	.tb-megamenu .page-title {
		text-align: center;
	}
	.tb-megamenu .navbar-search.pull-right {
		float: none;
		text-align: center;
	}
	.tb-megamenu .subhead-fixed {
		position: static;
		width: auto;
	}
	.tb-megamenu .container-fluid {
		padding-left: 0;
		padding-right: 0;
	}
}

@media (min-width:738px) {
	.tb-megamenu body {
		padding-top: 30px;
	}
}

@media (max-width:738px) {
	.tb-megamenu .navbar .brand {
		font-size: 16px;
	}
}

.tb-megamenu .btn-subhead {
	display: none;
}

@media (min-width:481px) {
	.tb-megamenu #filter-bar {
		height: 29px;
	}
}

@media (max-width:480px) {
	.table th:nth-of-type(n+5),
	.table th:nth-of-type(3),
	.table th:nth-of-type(2),
	.table td:nth-of-type(n+5),
	.table td:nth-of-type(2),
	.table td:nth-of-type(3) {
		white-space: normal;
	}
	.tb-megamenu .pagination a {
		padding: 5px;
	}
	.tb-megamenu .btn-group.divider,
	.tb-megamenu .header .row-fluid .span3,
	.tb-megamenu .header .row-fluid .span7,
	.tb-megamenu .subhead-collapse {
		display: none;
	}
	.tb-megamenu .btn {
		font-size: 11px;
		padding: 1px 4px 2px 4px;
	}
	.tb-megamenu .navbar .btn {
		margin: 0;
	}
	.tb-megamenu .btn-subhead {
		display: block;
		margin: 10px 0;
	}
	.tb-megamenu .chzn-container,
	.tb-megamenu .chzn-container .chzn-results,
	.tb-megamenu .chzn-container-single .chzn-drop,
	.tb-megamenu .btn-toolbar>.btn-group,
	.tb-megamenu .btn-toolbar>.btn-group>.btn {
		width: 99% !important;
	}
	.tb-megamenu .login .chzn-single {
		width: 222px !important;
	}
	.tb-megamenu .login .chzn-container,
	.tb-megamenu .login .chzn-drop {
		width: 230px !important;
	}
}

@media (max-width:738px) {
	.tb-megamenu .navbar .brand {
		margin-top: 2px;
	}
	.tb-megamenu .navbar .btn-navbar {
		margin-top: 3px;
		margin-right: 3px;
		margin-bottom: 3px;
	}
	.tb-megamenu .nav-collapse .nav .nav-header {
		color: #fff;
	}
	.tb-megamenu .nav-collapse.collapse.in {
		height: auto !important;
	}
	.tb-megamenu .nav-collapse .nav,
	.tb-megamenu .navbar .nav-collapse .nav.pull-right {
		margin: 0;
	}
	.tb-megamenu .nav-collapse .dropdown-menu {
		margin: 0;
	}
	.tb-megamenu .nav-collapse .nav li a {
		margin-bottom: 0;
		border-top: 1px solid #d9d9d9;
	}
	.tb-megamenu .nav-collapse .nav li ul li ul.dropdown-menu,
	.tb-megamenu .nav-collapse .nav li ul li:hover ul.dropdown-menu,
	.tb-megamenu .nav-collapse .caret {
		display: none !important;
	}
	.tb-megamenu .nav-collapse .nav>li>a,
	.tb-megamenu .nav-collapse .dropdown-menu a {
		-webkit-border-radius: 0;
		-moz-border-radius: 0;
		border-radius: 0;
	}
	.tb-megamenu .navbar .nav-collapse .nav>li>.dropdown-menu::before,
	.tb-megamenu .navbar .nav-collapse .nav>li>.dropdown-menu::after,
	.tb-megamenu .navbar .nav-collapse .dropdown-submenu>a::after {
		display: none;
	}
	.tb-megamenu .nav-collapse .dropdown-menu li+li a {
		margin-bottom: 0;
	}
}

.tb-megamenu {
	background-color: #000;
}

.tb-megamenu:before,
.tb-megamenu:after {
	content: "";
	display: table;
	line-height: 0;
}

.tb-megamenu:after {
	clear: both;
}

.tb-megamenu .nav {
	margin: 0;
}

.tb-megamenu .nav>li {
	float: left;
	margin-left: 0;
}

.tb-megamenu .nav>li>a {
	border-right: 1px solid #222;
	border-top: 0;
	color: #fff;
	font-weight: bold;
	padding: 15px 20px;
	text-shadow: none;
}

.tb-megamenu .nav>li>a:focus,
.tb-megamenu .nav>li>a:hover {
	background-color: #fff;
	color: #e54e4b;
	text-decoration: none;
	text-shadow: none;
	outline: none;
}

.tb-megamenu .nav>.active>a,
.tb-megamenu .nav>.active>a:hover,
.tb-megamenu .nav>.active>a:focus {
	background-color: #e54e4b;
	color: #fff;
	text-shadow: none;
	-webkit-box-shadow: none;
	-moz-box-shadow: none;
	box-shadow: none;
	outline: none;
}

.tb-megamenu .btn-navbar {
	display: none;
	float: left;
	padding: 10px;
	margin: 0;
	color: #fff;
	text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25);
	background-color: #e8e8e8;
	*background-color: #d9d9d9;
	-webkit-box-shadow: inset 0 1px 0 rgba(255, 255, 255, .1), 0 1px 0 rgba(255, 255, 255, .075);
	-moz-box-shadow: inset 0 1px 0 rgba(255, 255, 255, .1), 0 1px 0 rgba(255, 255, 255, .075);
	box-shadow: inset 0 1px 0 rgba(255, 255, 255, .1), 0 1px 0 rgba(255, 255, 255, .075);
}

.tb-megamenu .btn-navbar:hover,
.tb-megamenu .btn-navbar:focus,
.tb-megamenu .btn-navbar:active,
.tb-megamenu .btn-navbar.active,
.tb-megamenu .btn-navbar.disabled,
.tb-megamenu .btn-navbar[disabled] {
	color: #fff;
	background-color: #d9d9d9;
	*background-color: #cccccc;
}

.tb-megamenu .btn-navbar:active,
.tb-megamenu .btn-navbar.active {
	background-color: #bfbfbf \9;
}

.tb-megamenu .dropdown .caret {
	margin-left: 5px;
	margin-top: 9px;
}

.tb-megamenu .dropdown-menu {
	-webkit-box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
	-moz-box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
	box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
}

.tb-megamenu .dropdown-menu li>a {
	border-top: 1px solid #eee;
	padding: 5px 20px;
	text-shadow: none;
}

.tb-megamenu .dropdown-menu li:first-child>a {
	border-top: 0;
}

.tb-megamenu .mega-nav>li:first-child>a,
.tb-megamenu .dropdown-menu .mega-nav>li:first-child>a {
	margin-top: 0;
	padding-top: 0;
}

.tb-megamenu .nav>li>.dropdown-menu:before {
	display: none;
}

.tb-megamenu .nav>li>.dropdown-menu:after {
	display: none;
}

.tb-megamenu .dropdown-menu .disabled>a,
.tb-megamenu .dropdown-menu .disabled>a:hover {
	color: #999;
}

.tb-megamenu .dropdown-menu .disabled>a:hover {
	background-color: transparent;
}

.tb-megamenu .nav li.dropdown.open>.dropdown-toggle {
	background-color: #fff;
	color: #e54e4b;
}

.tb-megamenu .nav li.dropdown.active>.dropdown-toggle,
.tb-megamenu .nav li.dropdown.open.active>.dropdown-toggle {
	background-color: #e54e4b;
	color: #fff;
}

.tb-megamenu .mega-inner {
	padding: 10px;
}

.tb-megamenu .row-fluid+.row-fluid {
	border-top: 1px solid #ddd;
}

.tb-megamenu .mega-nav>li a,
.tb-megamenu .dropdown-menu .mega-nav>li a {
	padding: 5px 0;
}

.tb-megamenu .mega-nav .dropdown-submenu>a::after {
	margin-right: 0;
}

.tb-megamenu .tb-block .block-title {
	font-size: 12px;
	color: #333;
	display: block;
	line-height: 20px;
	margin: 0 0 15px;
	text-transform: uppercase;
}

.tb-megamenu .tb-block ul,
.tb-megamenu .tb-block .nav {
	margin: 0;
}

.tb-megamenu .tb-block ul li,
.tb-megamenu .tb-block .nav li {
	background: none;
	list-style: disc;
	display: list-item;
	float: none;
	margin: 0;
	padding: 0;
	border: 0;
}

.tb-megamenu .tb-block ul li a,
.tb-megamenu .tb-block .nav li a {
	display: inline;
	padding: 0;
	margin: 0;
	border: 0;
	font-size: 100%;
	background: none;
	font: inherit;
	white-space: normal;
}

.tb-megamenu .tb-block ul li a:hover,
.tb-megamenu .tb-block ul li a:focus,
.tb-megamenu .tb-block ul li a:active,
.tb-megamenu .tb-block .nav li a:hover,
.tb-megamenu .tb-block .nav li a:focus,
.tb-megamenu .tb-block .nav li a:active {
	background: none;
	font: inherit;
}

.tb-megamenu .mega-caption {
	color: #999;
	font-size: 12px;
	margin-top: 3px;
	font-weight: normal;
}

.tb-megamenu .caret {
	display: inline-block;
	width: 0;
	height: 0;
	vertical-align: top;
	border-top: 4px solid #000;
	border-right: 4px solid transparent;
	border-left: 4px solid transparent;
	content: "";
}

.tb-megamenu .dropdown .caret {
	margin-top: 8px;
	margin-left: 2px;
}

.tb-megamenu .dropdown-menu {
	position: absolute;
	top: 100%;
	left: 0;
	z-index: 1000;
	display: none;
	float: left;
	min-width: 160px;
	list-style: none;
	background-color: #fff;
	border: 1px solid #ccc;
	border: 1px solid rgba(0, 0, 0, 0.2);
	border-bottom: 3px solid #e65855;
	border-top: none;
	*border-right-width: 2px;
	*border-bottom-width: 2px;
	padding: 0;
	margin: 0;
	-webkit-border-radius: 0;
	-moz-border-radius: 0;
	border-radius: 0;
	-webkit-box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
	-moz-box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
	box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
	-webkit-background-clip: padding-box;
	-moz-background-clip: padding;
	background-clip: padding-box;
}

.tb-megamenu .dropdown-menu.pull-right {
	right: 0;
	left: auto;
}

.tb-megamenu .dropdown-menu .divider {
	*width: 100%;
	height: 1px;
	margin: 9px 1px;
	*margin: -5px 0 5px;
	overflow: hidden;
	background-color: #e5e5e5;
	border-bottom: 1px solid #fff;
}

.tb-megamenu .dropdown-menu li>a {
	clear: both;
	color: #333;
	display: block;
	line-height: 20px;
	font-weight: normal;
	padding: 3px 20px;
	text-shadow: none;
	white-space: nowrap;
}

.tb-megamenu .dropdown-menu li>a:hover,
.tb-megamenu .dropdown-menu li>a:focus,
.tb-megamenu .dropdown-submenu:hover>a {
	background-color: inherit;
	background-image: none;
	filter: progid:DXImageTransform.Microsoft.gradient(enabled=false);
	color: #e54e4b;
	text-decoration: none;
}

.tb-megamenu .dropdown-menu .active>a,
.tb-megamenu .dropdown-menu .active>a:hover {
	color: #eee;
	text-decoration: none;
	outline: 0;
	background-color: #e54e4b;
}

.tb-megamenu .dropdown-menu .disabled>a,
.tb-megamenu .dropdown-menu .disabled>a:hover {
	color: #999;
}

.tb-megamenu .dropdown-menu .disabled>a:hover {
	text-decoration: none;
	background-color: transparent;
	background-image: none;
	cursor: default;
}

.tb-megamenu .open {
	*z-index: 1000;
}

.tb-megamenu .open>.dropdown-menu {
	display: block;
}

.tb-megamenu .nav li.dropdown.active>.dropdown-toggle,
.tb-megamenu .nav li.dropdown.open.active>.dropdown-toggle,
.tb-megamenu .nav>li.dropdown.open.active>a:hover {
	background-color: #e54e4b;
	border-color: #444;
	color: #eee;
}

.tb-megamenu .nav li.dropdown>.dropdown-toggle .caret {
	border-top-color: #666;
	border-bottom-color: #666;
	margin-top: 8px;
}

.tb-megamenu .nav li.dropdown>.dropdown-toggle:hover .caret {
	border-top-color: #eee;
	border-bottom-color: #eee;
}

.tb-megamenu .nav li.dropdown.open>.dropdown-toggle .caret,
.tb-megamenu .nav li.dropdown.active>.dropdown-toggle .caret,
.tb-megamenu .nav li.dropdown.open.active>.dropdown-toggle .caret {
	border-top-color: #eee;
	border-bottom-color: #eee;
}

.tb-megamenu .pull-right>.dropdown-menu {
	right: 0;
	left: auto;
}

.tb-megamenu .dropup .caret,
.tb-megamenu .navbar-fixed-bottom .dropdown .caret {
	border-top: 0;
	border-bottom: 4px solid #000;
	content: "";
}

.tb-megamenu .dropup .dropdown-menu,
.tb-megamenu .navbar-fixed-bottom .dropdown .dropdown-menu {
	top: auto;
	bottom: 100%;
	margin-bottom: 1px;
}

.tb-megamenu .dropdown-submenu {
	position: relative;
}

.tb-megamenu .dropdown-submenu>.dropdown-menu {
	top: 0;
	left: 100%;
	margin-top: -6px;
	margin-left: -1px;
	-webkit-border-radius: 0;
	-moz-border-radius: 0;
	border-radius: 0;
}

.tb-megamenu .dropdown-submenu:hover>.dropdown-menu {
	display: block;
}

.tb-megamenu .touch .dropdown-submenu:hover>.dropdown-menu {
	display: none;
}

.tb-megamenu .touch .dropdown-submenu.open>.dropdown-menu {
	display: block;
}

.tb-megamenu .dropup .dropdown-submenu>.dropdown-menu {
	top: auto;
	bottom: 0;
	margin-top: 0;
	margin-bottom: -2px;
	-webkit-border-radius: 0;
	-moz-border-radius: 0;
	border-radius: 0;
}

.tb-megamenu .dropdown-submenu>a:after {
	display: block;
	content: " ";
	float: right;
	width: 0;
	height: 0;
	border-color: transparent;
	border-style: solid;
	border-width: 5px 0 5px 5px;
	border-left-color: #cccccc;
	margin-top: 5px;
	margin-right: -10px;
}

.tb-megamenu .dropdown-submenu:hover>a:after {
	border-left-color: #eee;
}

.tb-megamenu .dropdown-submenu.pull-left {
	float: none;
}

.tb-megamenu .dropdown-submenu.pull-left>.dropdown-menu {
	left: -100%;
	margin-left: 10px;
	-webkit-border-radius: 6px 0 6px 6px;
	-moz-border-radius: 6px 0 6px 6px;
	border-radius: 6px 0 6px 6px;
}

.tb-megamenu .dropdown .dropdown-menu .nav-header {
	padding-left: 20px;
	padding-right: 20px;
}

@media (min-width:980px) and (max-width:1199px) {
	.tb-megamenu .row {
		margin-left: -40px;
		*zoom: 1;
	}
	.tb-megamenu .row:before,
	.tb-megamenu .row:after {
		display: table;
		content: "";
		line-height: 0;
	}
	.tb-megamenu .row:after {
		clear: both;
	}
	.tb-megamenu [class*="span"] {
		float: left;
		min-height: 1px;
		margin-left: 40px;
	}
	.tb-megamenu .container,
	.tb-megamenu .navbar-static-top .container,
	.tb-megamenu .navbar-fixed-top .container,
	.tb-megamenu .navbar-fixed-bottom .container {
		width: 932px;
	}
	.tb-megamenu .span12 {
		width: 932px;
	}
	.tb-megamenu .span11 {
		width: 851px;
	}
	.tb-megamenu .span10 {
		width: 770px;
	}
	.tb-megamenu .span9 {
		width: 689px;
	}
	.tb-megamenu .span8 {
		width: 608px;
	}
	.tb-megamenu .span7 {
		width: 527px;
	}
	.tb-megamenu .span6 {
		width: 446px;
	}
	.tb-megamenu .span5 {
		width: 365px;
	}
	.tb-megamenu .span4 {
		width: 284px;
	}
	.tb-megamenu .span3 {
		width: 203px;
	}
	.tb-megamenu .span2 {
		width: 122px;
	}
	.tb-megamenu .span1 {
		width: 41px;
	}
	.tb-megamenu .offset12 {
		margin-left: 1012px;
	}
	.tb-megamenu .offset11 {
		margin-left: 931px;
	}
	.tb-megamenu .offset10 {
		margin-left: 850px;
	}
	.tb-megamenu .offset9 {
		margin-left: 769px;
	}
	.tb-megamenu .offset8 {
		margin-left: 688px;
	}
	.tb-megamenu .offset7 {
		margin-left: 607px;
	}
	.tb-megamenu .offset6 {
		margin-left: 526px;
	}
	.tb-megamenu .offset5 {
		margin-left: 445px;
	}
	.tb-megamenu .offset4 {
		margin-left: 364px;
	}
	.tb-megamenu .offset3 {
		margin-left: 283px;
	}
	.tb-megamenu .offset2 {
		margin-left: 202px;
	}
	.tb-megamenu .offset1 {
		margin-left: 121px;
	}
	.tb-megamenu .row-fluid {
		width: 100%;
		*zoom: 1;
	}
	.tb-megamenu .row-fluid:before,
	.tb-megamenu .row-fluid:after {
		display: table;
		content: "";
		line-height: 0;
	}
	.tb-megamenu .row-fluid:after {
		clear: both;
	}
	.tb-megamenu .row-fluid [class*="span"] {
		display: block;
		width: 100%;
		min-height: 30px;
		-webkit-box-sizing: border-box;
		-moz-box-sizing: border-box;
		box-sizing: border-box;
		float: left;
		margin-left: 4.2918454935622%;
		*margin-left: 4.2381974248927%;
	}
	.tb-megamenu .row-fluid [class*="span"]:first-child {
		margin-left: 0;
	}
	.tb-megamenu .row-fluid .controls-row [class*="span"]+[class*="span"] {
		margin-left: 4.2918454935622%;
	}
	.tb-megamenu .row-fluid .span12 {
		width: 100%;
		*width: 99.94635193133%;
	}
	.tb-megamenu .row-fluid .span11 {
		width: 91.309012875536%;
		*width: 91.255364806867%;
	}
	.tb-megamenu .row-fluid .span10 {
		width: 82.618025751073%;
		*width: 82.564377682403%;
	}
	.tb-megamenu .row-fluid .span9 {
		width: 73.927038626609%;
		*width: 73.87339055794%;
	}
	.tb-megamenu .row-fluid .span8 {
		width: 65.236051502146%;
		*width: 65.182403433476%;
	}
	.tb-megamenu .row-fluid .span7 {
		width: 56.545064377682%;
		*width: 56.491416309013%;
	}
	.tb-megamenu .row-fluid .span6 {
		width: 47.854077253219%;
		*width: 47.800429184549%;
	}
	.tb-megamenu .row-fluid .span5 {
		width: 39.163090128755%;
		*width: 39.109442060086%;
	}
	.tb-megamenu .row-fluid .span4 {
		width: 30.472103004292%;
		*width: 30.418454935622%;
	}
	.tb-megamenu .row-fluid .span3 {
		width: 21.781115879828%;
		*width: 21.727467811159%;
	}
	.tb-megamenu .row-fluid .span2 {
		width: 13.090128755365%;
		*width: 13.036480686695%;
	}
	.tb-megamenu .row-fluid .span1 {
		width: 4.3991416309013%;
		*width: 4.3454935622318%;
	}
	.tb-megamenu .row-fluid .offset12 {
		margin-left: 108.58369098712%;
		*margin-left: 108.47639484979%;
	}
	.tb-megamenu .row-fluid .offset12:first-child {
		margin-left: 104.29184549356%;
		*margin-left: 104.18454935622%;
	}
	.tb-megamenu .row-fluid .offset11 {
		margin-left: 99.892703862661%;
		*margin-left: 99.785407725322%;
	}
	.tb-megamenu .row-fluid .offset11:first-child {
		margin-left: 95.600858369099%;
		*margin-left: 95.49356223176%;
	}
	.tb-megamenu .row-fluid .offset10 {
		margin-left: 91.201716738197%;
		*margin-left: 91.094420600858%;
	}
	.tb-megamenu .row-fluid .offset10:first-child {
		margin-left: 86.909871244635%;
		*margin-left: 86.802575107296%;
	}
	.tb-megamenu .row-fluid .offset9 {
		margin-left: 82.510729613734%;
		*margin-left: 82.403433476395%;
	}
	.tb-megamenu .row-fluid .offset9:first-child {
		margin-left: 78.218884120172%;
		*margin-left: 78.111587982833%;
	}
	.tb-megamenu .row-fluid .offset8 {
		margin-left: 73.81974248927%;
		*margin-left: 73.712446351931%;
	}
	.tb-megamenu .row-fluid .offset8:first-child {
		margin-left: 69.527896995708%;
		*margin-left: 69.420600858369%;
	}
	.tb-megamenu .row-fluid .offset7 {
		margin-left: 65.128755364807%;
		*margin-left: 65.021459227468%;
	}
	.tb-megamenu .row-fluid .offset7:first-child {
		margin-left: 60.836909871245%;
		*margin-left: 60.729613733906%;
	}
	.tb-megamenu .row-fluid .offset6 {
		margin-left: 56.437768240343%;
		*margin-left: 56.330472103004%;
	}
	.tb-megamenu .row-fluid .offset6:first-child {
		margin-left: 52.145922746781%;
		*margin-left: 52.038626609442%;
	}
	.tb-megamenu .row-fluid .offset5 {
		margin-left: 47.74678111588%;
		*margin-left: 47.639484978541%;
	}
	.tb-megamenu .row-fluid .offset5:first-child {
		margin-left: 43.454935622318%;
		*margin-left: 43.347639484979%;
	}
	.tb-megamenu .row-fluid .offset4 {
		margin-left: 39.055793991416%;
		*margin-left: 38.948497854077%;
	}
	.tb-megamenu .row-fluid .offset4:first-child {
		margin-left: 34.763948497854%;
		*margin-left: 34.656652360515%;
	}
	.tb-megamenu .row-fluid .offset3 {
		margin-left: 30.364806866953%;
		*margin-left: 30.257510729614%;
	}
	.tb-megamenu .row-fluid .offset3:first-child {
		margin-left: 26.072961373391%;
		*margin-left: 25.965665236052%;
	}
	.tb-megamenu .row-fluid .offset2 {
		margin-left: 21.673819742489%;
		*margin-left: 21.56652360515%;
	}
	.tb-megamenu .row-fluid .offset2:first-child {
		margin-left: 17.381974248927%;
		*margin-left: 17.274678111588%;
	}
	.tb-megamenu .row-fluid .offset1 {
		margin-left: 12.982832618026%;
		*margin-left: 12.875536480687%;
	}
	.tb-megamenu .row-fluid .offset1:first-child {
		margin-left: 8.6909871244635%;
		*margin-left: 8.5836909871245%;
	}
	.tb-megamenu input,
	.tb-megamenu textarea,
	.tb-megamenu .uneditable-input {
		margin-left: 0;
	}
	.tb-megamenu .controls-row [class*="span"]+[class*="span"] {
		margin-left: 40px;
	}
	.tb-megamenu input.span12,
	textarea.span12,
	.uneditable-input.span12 {
		width: 918px;
	}
	.tb-megamenu input.span11,
	textarea.span11,
	.uneditable-input.span11 {
		width: 837px;
	}
	.tb-megamenu input.span10,
	textarea.span10,
	.uneditable-input.span10 {
		width: 756px;
	}
	.tb-megamenu input.span9,
	textarea.span9,
	.uneditable-input.span9 {
		width: 675px;
	}
	.tb-megamenu input.span8,
	textarea.span8,
	.uneditable-input.span8 {
		width: 594px;
	}
	.tb-megamenu input.span7,
	textarea.span7,
	.uneditable-input.span7 {
		width: 513px;
	}
	.tb-megamenu input.span6,
	textarea.span6,
	.uneditable-input.span6 {
		width: 432px;
	}
	.tb-megamenu input.span5,
	textarea.span5,
	.uneditable-input.span5 {
		width: 351px;
	}
	.tb-megamenu input.span4,
	textarea.span4,
	.uneditable-input.span4 {
		width: 270px;
	}
	.tb-megamenu input.span3,
	textarea.span3,
	.uneditable-input.span3 {
		width: 189px;
	}
	.tb-megamenu input.span2,
	textarea.span2,
	.uneditable-input.span2 {
		width: 108px;
	}
	.tb-megamenu input.span1,
	textarea.span1,
	.uneditable-input.span1 {
		width: 27px;
	}
}

@media (min-width:768px) and (max-width:979px) {
	.tb-megamenu .offset-12 {
		margin-left: -736px;
	}
	.tb-megamenu .offset-11 {
		margin-left: -673px;
	}
	.tb-megamenu .offset-10 {
		margin-left: -610px;
	}
	.tb-megamenu .offset-9 {
		margin-left: -547px;
	}
	.tb-megamenu .offset-8 {
		margin-left: -484px;
	}
	.tb-megamenu .offset-7 {
		margin-left: -421px;
	}
	.tb-megamenu .offset-6 {
		margin-left: -358px;
	}
	.tb-megamenu .offset-5 {
		margin-left: -295px;
	}
	.tb-megamenu .offset-4 {
		margin-left: -232px;
	}
	.tb-megamenu .offset-3 {
		margin-left: -169px;
	}
	.tb-megamenu .offset-2 {
		margin-left: -106px;
	}
	.tb-megamenu .offset-1 {
		margin-left: -43px;
	}
}

@media (min-width:980px) and (max-width:1199px) {
	.tb-megamenu .offset-12 {
		margin-left: -932px;
	}
	.tb-megamenu .offset-11 {
		margin-left: -851px;
	}
	.tb-megamenu .offset-10 {
		margin-left: -770px;
	}
	.tb-megamenu .offset-9 {
		margin-left: -689px;
	}
	.tb-megamenu .offset-8 {
		margin-left: -608px;
	}
	.tb-megamenu .offset-7 {
		margin-left: -527px;
	}
	.tb-megamenu .offset-6 {
		margin-left: -446px;
	}
	.tb-megamenu .offset-5 {
		margin-left: -365px;
	}
	.tb-megamenu .offset-4 {
		margin-left: -284px;
	}
	.tb-megamenu .offset-3 {
		margin-left: -203px;
	}
	.tb-megamenu .offset-2 {
		margin-left: -122px;
	}
	.tb-megamenu .offset-1 {
		margin-left: -41px;
	}
}

@media (min-width:1200px) {
	.tb-megamenu .offset-12 {
		margin-left: -1196px;
	}
	.tb-megamenu .offset-11 {
		margin-left: -1093px;
	}
	.tb-megamenu .offset-10 {
		margin-left: -990px;
	}
	.tb-megamenu .offset-9 {
		margin-left: -887px;
	}
	.tb-megamenu .offset-8 {
		margin-left: -784px;
	}
	.tb-megamenu .offset-7 {
		margin-left: -681px;
	}
	.tb-megamenu .offset-6 {
		margin-left: -578px;
	}
	.tb-megamenu .offset-5 {
		margin-left: -475px;
	}
	.tb-megamenu .offset-4 {
		margin-left: -372px;
	}
	.tb-megamenu .offset-3 {
		margin-left: -269px;
	}
	.tb-megamenu .offset-2 {
		margin-left: -166px;
	}
	.tb-megamenu .offset-1 {
		margin-left: -63px;
	}
}

@media (min-width:600px) and (max-width:767px) {
	.tb-megamenu .row,
	.tb-megamenu .row-fluid {
		width: 100%;
		margin-left: 0;
		*zoom: 1;
	}
	.tb-megamenu .row:before,
	.tb-megamenu .row:after,
	.tb-megamenu .row-fluid:before,
	.tb-megamenu .row-fluid:after {
		display: table;
		content: "";
		line-height: 0;
	}
	.tb-megamenu .row:after,
	.tb-megamenu .row-fluid:after {
		clear: both;
	}
	.tb-megamenu .row [class*="span"],
	.tb-megamenu .row-fluid [class*="span"] {
		display: block;
		width: 100%;
		min-height: 30px;
		-webkit-box-sizing: border-box;
		-moz-box-sizing: border-box;
		box-sizing: border-box;
		float: left;
		margin-left: 2.7173913043478%;
		*margin-left: 2.6637432356783%;
	}
	.row [class*="span"]:first-child:not(.pull-right),
	.row-fluid [class*="span"]:first-child:not(.pull-right) {
		margin-left: 0;
	}
	.row [class*="span"].pull-right:first-child+[class*="span"]:not(.pull-right),
	.row-fluid [class*="span"].pull-right:first-child+[class*="span"]:not(.pull-right) {
		margin-left: 0;
	}
	.tb-megamenu .row .span12,
	.tb-megamenu .row-fluid .span12 {
		width: 100%;
		*width: 99.94635193133%;
	}
	.tb-megamenu .row .span11,
	.tb-megamenu .row-fluid .span11 {
		width: 91.440217391304%;
		*width: 91.386569322635%;
	}
	.tb-megamenu .row .span10,
	.tb-megamenu .row-fluid .span10 {
		width: 82.880434782609%;
		*width: 82.826786713939%;
	}
	.tb-megamenu .row .span9,
	.tb-megamenu .row-fluid .span9 {
		width: 74.320652173913%;
		*width: 74.267004105244%;
	}
	.tb-megamenu .row .span8,
	.tb-megamenu .row-fluid .span8 {
		width: 65.760869565217%;
		*width: 65.707221496548%;
	}
	.tb-megamenu .row .span7,
	.tb-megamenu .row-fluid .span7 {
		width: 57.201086956522%;
		*width: 57.147438887852%;
	}
	.tb-megamenu .row .span6,
	.tb-megamenu .row-fluid .span6 {
		width: 48.641304347826%;
		*width: 48.587656279157%;
	}
	.tb-megamenu .row .span5,
	.tb-megamenu .row-fluid .span5 {
		width: 40.08152173913%;
		*width: 40.027873670461%;
	}
	.tb-megamenu .row .span4,
	.tb-megamenu .row-fluid .span4 {
		width: 31.521739130435%;
		*width: 31.468091061765%;
	}
	.tb-megamenu .row .span3,
	.tb-megamenu .row-fluid .span3 {
		width: 22.961956521739%;
		*width: 22.90830845307%;
	}
	.tb-megamenu .row .span2,
	.tb-megamenu .row-fluid .span2 {
		width: 14.402173913043%;
		*width: 14.348525844374%;
	}
	.tb-megamenu .row .span1,
	.tb-megamenu .row-fluid .span1 {
		width: 5.8423913043478%;
		*width: 5.7887432356783%;
	}
	.tb-megamenu .span12 .row [class*="span"] {
		margin-left: 2.7173913043478%;
		*margin-left: 2.6637432356783%;
	}
	.tb-megamenu .span12 .row [class*="span"]:first-child {
		margin-left: 0;
	}
	.tb-megamenu .span12 .row .span12 {
		width: 100%;
		*width: 99.94635193133%;
	}
	.tb-megamenu .span12 .row .span11 {
		width: 91.440217391304%;
		*width: 91.386569322635%;
	}
	.tb-megamenu .span12 .row .span10 {
		width: 82.880434782609%;
		*width: 82.826786713939%;
	}
	.tb-megamenu .span12 .row .span9 {
		width: 74.320652173913%;
		*width: 74.267004105244%;
	}
	.tb-megamenu .span12 .row .span8 {
		width: 65.760869565217%;
		*width: 65.707221496548%;
	}
	.tb-megamenu .span12 .row .span7 {
		width: 57.201086956522%;
		*width: 57.147438887852%;
	}
	.tb-megamenu .span12 .row .span6 {
		width: 48.641304347826%;
		*width: 48.587656279157%;
	}
	.tb-megamenu .span12 .row .span5 {
		width: 40.08152173913%;
		*width: 40.027873670461%;
	}
	.tb-megamenu .span12 .row .span4 {
		width: 31.521739130435%;
		*width: 31.468091061765%;
	}
	.tb-megamenu .span12 .row .span3 {
		width: 22.961956521739%;
		*width: 22.90830845307%;
	}
	.tb-megamenu .span12 .row .span2 {
		width: 14.402173913043%;
		*width: 14.348525844374%;
	}
	.tb-megamenu .span12 .row .span1 {
		width: 5.8423913043478%;
		*width: 5.7887432356783%;
	}
	.tb-megamenu .span11 .row [class*="span"] {
		margin-left: 2.9717682020802%;
		*margin-left: 2.9181201334107%;
	}
	.tb-megamenu .span11 .row [class*="span"]:first-child {
		margin-left: 0;
	}
	.tb-megamenu .span11 .row .span11 {
		width: 91.440217391304%;
		*width: 91.386569322635%;
	}
	.tb-megamenu .span11 .row .span10 {
		width: 82.880434782609%;
		*width: 82.826786713939%;
	}
	.tb-megamenu .span11 .row .span9 {
		width: 74.320652173913%;
		*width: 74.267004105244%;
	}
	.tb-megamenu .span11 .row .span8 {
		width: 65.760869565217%;
		*width: 65.707221496548%;
	}
	.tb-megamenu .span11 .row .span7 {
		width: 57.201086956522%;
		*width: 57.147438887852%;
	}
	.tb-megamenu .span11 .row .span6 {
		width: 48.641304347826%;
		*width: 48.587656279157%;
	}
	.tb-megamenu .span11 .row .span5 {
		width: 40.08152173913%;
		*width: 40.027873670461%;
	}
	.tb-megamenu .span11 .row .span4 {
		width: 31.521739130435%;
		*width: 31.468091061765%;
	}
	.tb-megamenu .span11 .row .span3 {
		width: 22.961956521739%;
		*width: 22.90830845307%;
	}
	.tb-megamenu .span11 .row .span2 {
		width: 14.402173913043%;
		*width: 14.348525844374%;
	}
	.tb-megamenu .span11 .row .span1 {
		width: 5.8423913043478%;
		*width: 5.7887432356783%;
	}
	.tb-megamenu .span10 .row [class*="span"] {
		margin-left: 3.2786885245902%;
		*margin-left: 3.2250404559206%;
	}
	.tb-megamenu .span10 .row [class*="span"]:first-child {
		margin-left: 0;
	}
	.tb-megamenu .span10 .row .span10 {
		width: 90.638930163447%;
		*width: 90.585282094778%;
	}
	.tb-megamenu .span10 .row .span9 {
		width: 81.277860326894%;
		*width: 81.224212258225%;
	}
	.tb-megamenu .span10 .row .span8 {
		width: 71.916790490342%;
		*width: 71.863142421672%;
	}
	.tb-megamenu .span10 .row .span7 {
		width: 62.555720653789%;
		*width: 62.502072585119%;
	}
	.tb-megamenu .span10 .row .span6 {
		width: 53.194650817236%;
		*width: 53.141002748567%;
	}
	.tb-megamenu .span10 .row .span5 {
		width: 43.833580980684%;
		*width: 43.779932912014%;
	}
	.tb-megamenu .span10 .row .span4 {
		width: 34.472511144131%;
		*width: 34.418863075461%;
	}
	.tb-megamenu .span10 .row .span3 {
		width: 25.111441307578%;
		*width: 25.057793238908%;
	}
	.tb-megamenu .span10 .row .span2 {
		width: 15.750371471025%;
		*width: 15.696723402356%;
	}
	.tb-megamenu .span10 .row .span1 {
		width: 6.3893016344725%;
		*width: 6.335653565803%;
	}
	.tb-megamenu .span9 .row [class*="span"] {
		margin-left: 3.6563071297989%;
		*margin-left: 3.6026590611294%;
	}
	.tb-megamenu .span9 .row [class*="span"]:first-child {
		margin-left: 0;
	}
	.tb-megamenu .span9 .row .span9 {
		width: 89.672131147541%;
		*width: 89.618483078871%;
	}
	.tb-megamenu .span9 .row .span8 {
		width: 79.344262295082%;
		*width: 79.290614226412%;
	}
	.tb-megamenu .span9 .row .span7 {
		width: 69.016393442623%;
		*width: 68.962745373953%;
	}
	.tb-megamenu .span9 .row .span6 {
		width: 58.688524590164%;
		*width: 58.634876521494%;
	}
	.tb-megamenu .span9 .row .span5 {
		width: 48.360655737705%;
		*width: 48.307007669035%;
	}
	.tb-megamenu .span9 .row .span4 {
		width: 38.032786885246%;
		*width: 37.979138816576%;
	}
	.tb-megamenu .span9 .row .span3 {
		width: 27.704918032787%;
		*width: 27.651269964117%;
	}
	.tb-megamenu .span9 .row .span2 {
		width: 17.377049180328%;
		*width: 17.323401111658%;
	}
	.tb-megamenu .span9 .row .span1 {
		width: 7.0491803278689%;
		*width: 6.9955322591993%;
	}
	.tb-megamenu .span8 .row [class*="span"] {
		margin-left: 4.1322314049587%;
		*margin-left: 4.0785833362892%;
	}
	.tb-megamenu .span8 .row [class*="span"]:first-child {
		margin-left: 0;
	}
	.tb-megamenu .span8 .row .span8 {
		width: 88.482632541133%;
		*width: 88.428984472464%;
	}
	.tb-megamenu .span8 .row .span7 {
		width: 76.965265082267%;
		*width: 76.911617013597%;
	}
	.tb-megamenu .span8 .row .span6 {
		width: 65.4478976234%;
		*width: 65.394249554731%;
	}
	.tb-megamenu .span8 .row .span5 {
		width: 53.930530164534%;
		*width: 53.876882095864%;
	}
	.tb-megamenu .span8 .row .span4 {
		width: 42.413162705667%;
		*width: 42.359514636998%;
	}
	.tb-megamenu .span8 .row .span3 {
		width: 30.895795246801%;
		*width: 30.842147178131%;
	}
	.tb-megamenu .span8 .row .span2 {
		width: 19.378427787934%;
		*width: 19.324779719265%;
	}
	.tb-megamenu .span8 .row .span1 {
		width: 7.8610603290676%;
		*width: 7.8074122603981%;
	}
	.tb-megamenu .span7 .row [class*="span"] {
		margin-left: 4.750593824228%;
		*margin-left: 4.6969457555585%;
	}
	.tb-megamenu .span7 .row [class*="span"]:first-child {
		margin-left: 0;
	}
	.tb-megamenu .span7 .row .span7 {
		width: 86.98347107438%;
		*width: 86.929823005711%;
	}
	.tb-megamenu .span7 .row .span6 {
		width: 73.96694214876%;
		*width: 73.913294080091%;
	}
	.tb-megamenu .span7 .row .span5 {
		width: 60.950413223141%;
		*width: 60.896765154471%;
	}
	.tb-megamenu .span7 .row .span4 {
		width: 47.933884297521%;
		*width: 47.880236228851%;
	}
	.tb-megamenu .span7 .row .span3 {
		width: 34.917355371901%;
		*width: 34.863707303231%;
	}
	.tb-megamenu .span7 .row .span2 {
		width: 21.900826446281%;
		*width: 21.847178377611%;
	}
	.tb-megamenu .span7 .row .span1 {
		width: 8.8842975206612%;
		*width: 8.8306494519916%;
	}
	.tb-megamenu .span6 .row [class*="span"] {
		margin-left: 5.586592178771%;
		*margin-left: 5.5329441101014%;
	}
	.tb-megamenu .span6 .row [class*="span"]:first-child {
		margin-left: 0;
	}
	.tb-megamenu .span6 .row .span6 {
		width: 85.035629453682%;
		*width: 84.981981385012%;
	}
	.tb-megamenu .span6 .row .span5 {
		width: 70.071258907363%;
		*width: 70.017610838694%;
	}
	.tb-megamenu .span6 .row .span4 {
		width: 55.106888361045%;
		*width: 55.053240292376%;
	}
	.tb-megamenu .span6 .row .span3 {
		width: 40.142517814727%;
		*width: 40.088869746057%;
	}
	.tb-megamenu .span6 .row .span2 {
		width: 25.178147268409%;
		*width: 25.124499199739%;
	}
	.tb-megamenu .span6 .row .span1 {
		width: 10.21377672209%;
		*width: 10.160128653421%;
	}
	.tb-megamenu .span5 .row [class*="span"] {
		margin-left: 6.7796610169492%;
		*margin-left: 6.7260129482796%;
	}
	.tb-megamenu .span5 .row [class*="span"]:first-child {
		margin-left: 0;
	}
	.tb-megamenu .span5 .row .span5 {
		width: 82.402234636872%;
		*width: 82.348586568202%;
	}
	.tb-megamenu .span5 .row .span4 {
		width: 64.804469273743%;
		*width: 64.750821205073%;
	}
	.tb-megamenu .span5 .row .span3 {
		width: 47.206703910615%;
		*width: 47.153055841945%;
	}
	.tb-megamenu .span5 .row .span2 {
		width: 29.608938547486%;
		*width: 29.555290478817%;
	}
	.tb-megamenu .span5 .row .span1 {
		width: 12.011173184358%;
		*width: 11.957525115688%;
	}
	.tb-megamenu .span4 .row [class*="span"] {
		margin-left: 8.6206896551724%;
		*margin-left: 8.5670415865029%;
	}
	.tb-megamenu .span4 .row [class*="span"]:first-child {
		margin-left: 0;
	}
	.tb-megamenu .span4 .row .span4 {
		width: 78.64406779661%;
		*width: 78.590419727941%;
	}
	.tb-megamenu .span4 .row .span3 {
		width: 57.28813559322%;
		*width: 57.234487524551%;
	}
	.tb-megamenu .span4 .row .span2 {
		width: 35.932203389831%;
		*width: 35.878555321161%;
	}
	.tb-megamenu .span4 .row .span1 {
		width: 14.576271186441%;
		*width: 14.522623117771%;
	}
	.tb-megamenu .span3 .row [class*="span"] {
		margin-left: 11.834319526627%;
		*margin-left: 11.780671457958%;
	}
	.tb-megamenu .span3 .row [class*="span"]:first-child {
		margin-left: 0;
	}
	.tb-megamenu .span3 .row .span3 {
		width: 72.844827586207%;
		*width: 72.791179517537%;
	}
	.tb-megamenu .span3 .row .span2 {
		width: 45.689655172414%;
		*width: 45.636007103744%;
	}
	.tb-megamenu .span3 .row .span1 {
		width: 18.534482758621%;
		*width: 18.480834689951%;
	}
	.tb-megamenu .span2 .row [class*="span"] {
		margin-left: 18.867924528302%;
		*margin-left: 18.814276459632%;
	}
	.tb-megamenu .span2 .row [class*="span"]:first-child {
		margin-left: 0;
	}
	.tb-megamenu .span2 .row .span2 {
		width: 62.721893491124%;
		*width: 62.668245422455%;
	}
	.tb-megamenu .span2 .row .span1 {
		width: 25.443786982249%;
		*width: 25.390138913579%;
	}
	.tb-megamenu .span1 .row [class*="span"] {
		margin-left: 46.511627906977%;
		*margin-left: 46.457979838307%;
	}
	.tb-megamenu .span1 .row [class*="span"]:first-child {
		margin-left: 0;
	}
	.tb-megamenu .span1 .row .span1 {
		width: 40.566037735849%;
		*width: 40.51238966718%;
	}
	.tb-megamenu .spanfirst {
		margin-left: 0 !important;
		clear: left;
	}
}

.tb-megamenu .row .span50,
.tb-megamenu .row-fluid .span50 {
	width: 50%;
	float: left;
}

.tb-megamenu .row .span33,
.tb-megamenu .row-fluid .span33 {
	width: 33.3333%;
	float: left;
}

.tb-megamenu .row .span25,
.tb-megamenu .row-fluid .span25 {
	width: 25%;
	float: left;
}

.tb-megamenu .row .span20,
.tb-megamenu .row-fluid .span20 {
	width: 20%;
	float: left;
}

.tb-megamenu .row .span16,
.tb-megamenu .row-fluid .span16 {
	width: 16.6666%;
	float: left;
}

.tb-megamenu .hidden {
	display: none !important;
	visibility: hidden;
}

@media (max-width:979px) {
	.tb-megamenu .always-show .mega>.mega-dropdown-menu,
	.tb-megamenu .always-show .dropdown-menu {
		display: block !important;
	}
	.tb-megamenu .navbar-collapse-fixed-top,
	.tb-megamenu .navbar-collapse-fixed-bottom {
		position: fixed;
		left: 0;
		top: 0;
		width: 100%;
		z-index: 1000;
	}
	.tb-megamenu .navbar-collapse-fixed-top .nav-collapse.in,
	.tb-megamenu .navbar-collapse-fixed-bottom .nav-collapse.in {
		position: fixed;
		width: 100%;
		overflow-y: auto;
		top: 40px;
		bottom: 0;
	}
	.tb-megamenu .navbar-collapse-fixed-top .nav-collapse.in>*,
	.tb-megamenu .navbar-collapse-fixed-bottom .nav-collapse.in>* {
		padding-bottom: 50px;
	}
	.tb-megamenu .navbar-collapse-fixed-bottom {
		bottom: 0;
		top: auto;
	}
	.tb-megamenu .navbar-collapse-fixed-bottom .nav-collapse.in {
		top: 0;
		bottom: 40px;
	}
}

@media (max-width:979px) {
	.tb-megamenu {
		background-color: inherit;
		height: auto;
	}
	.tb-megamenu .btn-navbar {
		display: block;
		margin-bottom: 10px;
	}
	.tb-megamenu .btn-navbar:hover {
		cursor: pointer;
	}
	.tb-megamenu .nav-collapse {
		background: #000;
		margin-top: 10px;
		position: absolute;
		top: 27px;
		width: 100%;
		z-index: 999;
	}
	.tb-megamenu .nav-collapse .nav {
		margin: 0;
	}
	.tb-megamenu .nav-collapse .nav>li>a {
		font-size: 15px;
		margin-bottom: 0;
	}
	.tb-megamenu .nav-collapse .nav>li>a,
	.tb-megamenu .nav-collapse .dropdown-menu a {
		border-bottom: 1px solid #222;
		border-right: none;
		color: #fff;
		text-shadow: none;
		-webkit-border-radius: 0;
		-moz-border-radius: 0;
		border-radius: 0;
	}
	.tb-megamenu .nav-collapse .dropdown-menu li+li a {
		margin-bottom: 0;
	}
	.tb-megamenu .nav-collapse .nav>li>a:hover,
	.tb-megamenu .nav-collapse .nav>li>a:focus {
		background-color: #fff;
		background-image: none;
		filter: progid:DXImageTransform.Microsoft.gradient(enabled=false);
		color: #e54e4b;
	}
	.tb-megamenu .nav-collapse .nav>li.active>a:hover,
	.tb-megamenu .nav-collapse .nav>li.active>a:focus {
		background-color: #e54e4b;
	}
	.tb-megamenu .nav-collapse .dropdown-menu a {
		border-top: 1px solid #222;
		border-bottom: 0;
		font-weight: normal;
		padding: 10px 20px;
	}
	.tb-megamenu .nav-collapse .dropdown-menu a:hover,
	.tb-megamenu .nav-collapse .dropdown-menu a:focus {
		background-color: inherit;
		background-image: none;
		filter: progid:DXImageTransform.Microsoft.gradient(enabled=false);
	}
	.tb-megamenu .nav-collapse .dropdown-menu {
		margin: 0;
		padding: 0;
		background-color: #000;
		border: none;
		-webkit-border-radius: 0;
		-moz-border-radius: 0;
		border-radius: 0;
		-webkit-box-shadow: none;
		-moz-box-shadow: none;
		box-shadow: none;
		width: 100% !important;
	}
	.tb-megamenu.navbar-collapse-fixed-top {
		margin-left: 0;
		margin-right: 0;
	}
	.tb-megamenu .navbar .nav>li>.dropdown-menu:before,
	.tb-megamenu .navbar .nav>li>.dropdown-menu:after,
	.tb-megamenu .navbar .nav>li>.dropdown-menu .divider {
		display: none;
	}
	.tb-megamenu .customization {
		display: none;
	}
	.tb-megamenu .tb-block .block-title {
		color: #fff;
	}
}

@media (min-width:980px) {
	.tb-megamenu.animate .mega>.mega-dropdown-menu {
		transition: all 400ms;
		-webkit-transition: all 400ms;
		-ms-transition: all 400ms;
		-o-transition: all 400ms;
		-webkit-backface-visibility: hidden;
		-moz-backface-visibility: hidden;
		-o-backface-visibility: hidden;
		backface-visibility: hidden;
		opacity: 0;
	}
	.tb-megamenu.animate .mega.animating>.mega-dropdown-menu {
		display: block!important;
	}
	.tb-megamenu.animate .mega.open>.mega-dropdown-menu,
	.tb-megamenu.animate .mega.animating.open>.mega-dropdown-menu {
		opacity: 1;
	}
	.tb-megamenu.animate.zoom .mega>.mega-dropdown-menu {
		transform: scale(0, 0);
		transform-origin: 20% 20%;
		-webkit-transform: scale(0, 0);
		-webkit-transform-origin: 20% 20%;
		-ms-transform: scale(0, 0);
		-ms-transform-origin: 20% 20%;
		-o-transform: scale(0, 0);
		-o-transform-origin: 20% 20%;
	}
	.tb-megamenu.animate.zoom .mega.open>.mega-dropdown-menu {
		transform: scale(1, 1);
		-webkit-transform: scale(1, 1);
		-ms-transform: scale(1, 1);
		-o-transform: scale(1, 1);
	}
	.tb-megamenu.animate.elastic .level0>.mega>.mega-dropdown-menu {
		transform: scale(1, 0);
		-webkit-transform: scale(1, 0);
		-ms-transform: scale(1, 0);
		-o-transform: scale(1, 0);
	}
	.tb-megamenu.animate.elastic .mega>.mega-dropdown-menu {
		transform: scale(0, 1);
		transform-origin: 10% 0;
		-webkit-transform: scale(0, 1);
		-webkit-transform-origin: 10% 0;
		-ms-transform: scale(0, 1);
		-ms-transform-origin: 10% 0;
		-o-transform: scale(0, 1);
		-o-transform-origin: 10% 0;
	}
	.tb-megamenu.animate.elastic .mega.open>.mega-dropdown-menu {
		transform: scale(1, 1);
		-webkit-transform: scale(1, 1);
		-ms-transform: scale(1, 1);
		-o-transform: scale(1, 1);
	}
	.tb-megamenu.animate.slide .mega.animating>.mega-dropdown-menu {
		overflow: hidden;
	}
	.tb-megamenu.animate.slide .mega>.mega-dropdown-menu>div {
		transition: all 400ms;
		-webkit-transition: all 400ms;
		-ms-transition: all 400ms;
		-o-transition: all 400ms;
		-webkit-backface-visibility: hidden;
		-moz-backface-visibility: hidden;
		-o-backface-visibility: hidden;
		backface-visibility: hidden;
		margin-top: -100%;
	}
	.tb-megamenu.animate.slide .mega.open>.mega-dropdown-menu>div {
		margin-top: 0%;
	}
	.tb-megamenu.animate.slide .mega .mega>.mega-dropdown-menu {
		min-width: 0;
	}
	.tb-megamenu.animate.slide .mega .mega>.mega-dropdown-menu>div {
		min-width: 200px;
		margin-top: 0;
		margin-left: -500px;
	}
	.tb-megamenu.animate.slide .mega .mega.open>.mega-dropdown-menu>div {
		margin-left: 0;
	}
}

@media (max-width:979px) {
	.tb-megamenu .mega-inner {
		padding: 10px 20px;
	}
	.tb-megamenu .row-fluid,
	.tb-megamenu .mega-dropdown-menu,
	.tb-megamenu .row-fluid [class*="span"] {
		width: 100% !important;
		min-width: 100% !important;
		left: 0 !important;
		margin-left: 0 !important;
		transform: none !important;
		-webkit-transform: none !important;
		-moz-transform: none !important;
		-ms-transform: none !important;
		-o-transform: none !important;
	}
	.tb-megamenu .row-fluid+.row-fluid {
		padding-top: 10px;
		border-top: 1px solid #222;
	}
	.tb-megamenu .hidden-collapse,
	.tb-megamenu .always-show .caret,
	.tb-megamenu .sub-hidden-collapse>.nav-child,
	.tb-megamenu .sub-hidden-collapse .caret,
	.tb-megamenu .sub-hidden-collapse>a:after,
	.tb-megamenu .always-show .dropdown-submenu>a:after {
		display: none !important;
	}
	.tb-megamenu .mega-caption {
		display: none !important;
	}
	.tb-megamenu .mega-nav>li a,
	.tb-megamenu .dropdown-menu .mega-nav>li a {
		padding: 5px 0;
		margin-left: 20px;
	}
	.tb-megamenu .tb-block {
		margin-bottom: 0;
	}
}

.block-view-demo .views-field-title a {
	border-top: none !important;
	color: #eee;
	padding: 0 !important;
}

.tb-megamenu .block-view-demo .views-field-field-image {
	float: left;
}

.tb-megamenu .block-view-demo .views-field-field-image img {
	border: 1px solid #333;
	margin: 0 20px 0 0;
	max-width: none !important;
	padding: 4px;
	width: auto;
}

.tb-megamenu .block-view-demo .item-list ul li {
	border-bottom: 1px solid #333;
	margin: 0;
	min-height: 60px;
	padding: 10px 0;
}

.tb-megamenu .block-view-demo .item-list ul li.views-row-first {
	padding-top: 0;
}

.tb-megamenu .block-view-demo a {
	padding: 0 !important;
}

.tb-megamenu .block-view-demo .views-field-title a {
	border-top: none;
	color: #aaa;
	padding: 0;
}

.tb-megamenu .block-view-demo a:hover,
.tb-megamenu .block-view-demo a:focus {
	background: none !important;
	border: none !important;
	color: #e54e4e;
	text-decoration: none;
}

.tb-megamenu {
	position: relative;
}

.tb-megamenu .nav,
.tb-megamenu .tb-block ul,
.tb-megamenu .tb-block .nav {
	padding: 0;
}

.tb-megamenu .tb-block ul li,
.tb-megamenu .tb-block .nav li {
	list-style: none;
}

.tb-megamenu .block-view-demo .item-list ul li {
	border-bottom: 1px solid #444;
}

.tb-megamenu .dropdown-submenu:hover .dropdown-menu {
	display: none;
}

@media (max-width:979px) {
	.tb-megamenu .nav-collapse .dropdown-menu .views-field-field-image a {
		border-top: 0 none;
	}
	#user-login-form .item-list li a {
		border: 0 none;
	}
	.tb-megamenu .nav-collapse,
	.tb-megamenu .nav-collapse.collapse {
		z-index: 999;
	}
}